import React, { memo } from 'react';
import translate from '@/helpers/traslations';
import { Switch } from '@gluestack-ui/themed';
import { ICardCustomProps } from './interfaces.d';
import { THView, TText, TView } from '@/components/themed';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { TABLET_SCREEN } from '@/utils/globales';

const CardCustom = ({
  leftElement,
  idString,
  idStringSubtitle,
  switchProp,
  actions,
  middleElement,
  translateText = true,
  isActive = false,
  sx = {}
}: ICardCustomProps) => {
  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  return (
    <THView
      bgColor={isActive ? 'backgroundActive' : 'background'}
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 80,
        paddingHorizontal: isPc ? 16 : 4,
        gap: 16,
        marginVertical: 4,
        borderRadius: 8,
        ...sx,
        ':hover': {
          backgroundColor: '$backgroundActive'
        }
      }}
    >
      <THView
        style={{
          alignItems: 'center',
          gap: isPc ? 16 : 4,
          height: '100%',
          width: actions ? '70%' : '80%',
          overflow: 'hidden'
        }}
      >
        {leftElement && leftElement}

        <TView>
          <TText size="md">{translateText ? translate(idString) : idString}</TText>
          {idStringSubtitle && (
            <TText color="secondary" size="md">
              {translate(idStringSubtitle)}
            </TText>
          )}
        </TView>
        {middleElement && middleElement}
      </THView>
      <THView
        style={{
          alignItems: 'center',
          gap: isPc ? 16 : 4
        }}
      >
        <Switch
          value={switchProp?.value}
          onValueChange={switchProp?.onChange}
          isDisabled={switchProp?.disabled}
        />
        {actions && actions}
      </THView>
    </THView>
  );
};

export default memo(CardCustom);
