import React, { memo, useEffect, useMemo, useState } from 'react';
import MenuActions from '@/components/menu/MenuActions';
import { IconBot } from '@/assets/icons/Icons';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store/index';
import { setCustomPropietyOfWorkflow } from '@/redux/actionTypes/index';
import { ActionsParametersResponseTypes, TypeActions } from '@/types/enums/workflows';
import translate from '@/helpers/traslations';
import { Linking } from 'react-native';
import { CREATE_UER } from '@/utils/constants';
import { ActionsMenu } from '@/components/menu/interface';
import { Contacts } from '@/types/typesRedux/interfaces';
import { IAction } from './interface';
import { TText, TView } from '@/components/themed';

const MenuBot = ({ idString }: { idString: string }) => {
  const dispatch = useAppDispatch();
  const newWorkflow = useAppSelector((state: RootState) => state.automatization.newWorkflows);
  const usersBot = useAppSelector((state: RootState) =>
    state.contacts?.assigneeUsers?.filter((user: Contacts) => user?.isBot === true)
  );

  const currentId = useMemo(() => {
    return Object.keys(newWorkflow)?.length ? newWorkflow?.actions[0]?.parameters?.id : '';
  }, [newWorkflow]);

  const [actions, setActions] = useState<IAction[]>([
    {
      type: TypeActions.sendResponse,
      parameters: {
        response_type: ActionsParametersResponseTypes.apollo,
        id: currentId
      }
    }
  ]);

  const onChangeBot = () => {
    dispatch(
      setCustomPropietyOfWorkflow({
        idString,
        value: actions,
        propiety: 'actions'
      })
    );
  };

  useEffect(() => {
    onChangeBot();
  }, [actions]);

  const menuActions: ActionsMenu[] = useMemo(() => {
    if (usersBot?.length > 0) {
      return usersBot?.map((user: Contacts) => {
        return {
          label: user?.name,
          key: user?.id_user,
          icon: IconBot,
          available: true,
          action: (action: ActionsMenu) =>
            setActions([
              {
                type: TypeActions.sendResponse,
                parameters: {
                  response_type: ActionsParametersResponseTypes.apollo,
                  id: action.key
                }
              }
            ])
        };
      });
    } else {
      return [
        {
          label: 'Crear usuario BOT',
          key: 'create_user',
          icon: IconBot,
          action: () => Linking.openURL(CREATE_UER),
          available: true
        }
      ];
    }
  }, [usersBot]);

  return (
    <TView>
      <MenuActions
        trigger={
          actions[0]?.parameters?.id ? (
            <TText color="text">
              {
                usersBot?.find((user: Contacts) => user?.id_user === actions[0]?.parameters?.id)
                  ?.name
              }
            </TText>
          ) : (
            <TText color="secondary">{translate('actions.selectBot')}</TText>
          )
        }
        menuActions={menuActions}
        traslations={false}
        sx={{
          width: '100%',
          borderRadius: '$sm',
          borderWidth: 1,
          borderColor: '$border',
          p: '$2',
          height: 42,
          justifyContent: 'center',
          backgroundColor: actions[0]?.parameters?.id ? '$backgroundActive' : '$backgroundSecondary'
        }}
      />
    </TView>
  );
};

export default memo(MenuBot);
