import React, { memo } from 'react';
import ModalApp from './ModalApp';
import { View } from '@gluestack-ui/themed';
import { SliderItem } from '../slider/interface';
import SliderApp from '../slider/SliderApp';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { setViewSliderVersion } from '@/redux/actionTypes';

const InfoNewVersion = () => {
  const dispatch = useAppDispatch();
  const viewSliderVersion = useAppSelector((state: RootState) => state.settings.viewSliderVersion);

  const slider: SliderItem[] = [
    {
      key: 'one',
      title: '¡Nueva configuración de notificaciones!',
      description:
        'Ahora puedes gestionar tus notificaciones de manera más sencilla y rápida, ya sea por canal o por conversación, también implemetamos una alerta sonora para que no te pierdas de nada.',
      image: 778367,
      getFile: true
    },
    {
      key: 'two',
      title: 'Automatiza tus conversaciones',
      description:
        'También podrás automatizar tus conversaciones con plantillas o IA, para que puedas responder de manera rápida y eficiente y en cualquier horario, sin perder ninguna oportunidad de venta.',
      image: 778368,
      getFile: true
    }
  ];

  const setModalSlider = (bool) => {
    dispatch(setViewSliderVersion(bool));
  };

  return (
    <View>
      <ModalApp modalVisible={viewSliderVersion} setModalVisible={setModalSlider}>
        <SliderApp data={slider} handleEndSlider={() => dispatch(setViewSliderVersion(false))} />
      </ModalApp>
    </View>
  );
};

export default memo(InfoNewVersion);
