import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ItemDrawerSettings from '../../../atoms/ItemDrawerSettings';

import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { PropsContainerItemSidebarApp } from './interfaces';
import { formatNumber, getFilterWithOpenConversations, hasUnreadMessages } from '@/utils/functions';
import BadgeComponent from '@/atoms/BadgeComponent';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { IconFilterClosed } from '@/assets/icons/Icons';
import { setDialogConnect } from '@/redux/slices/theme';
import { TypeSections } from '@/types/enums';

function ContainerItemSidebarApp({
  avaliableChannel = true,
  hanldePressClients,
  routeName,
  activeRoute,
  icon,
  idString,
  translate = true
}: PropsContainerItemSidebarApp) {
  const dispatch = useAppDispatch();
  const themeMode = useAppSelector((state: RootState) => state.user.theme);
  const teamId = useAppSelector((state: RootState) => state.user.team.id);
  const filterConversations = useAppSelector(
    (state: RootState) => state.conversations.filterConversations?.[teamId]
  );
  const unread = useAppSelector((state: RootState) => state.conversations.unread);

  const [itemCount, setItemCount] = useState<number>(0);
  const [itemUnreadMessage, setItemUnreadMessage] = useState<boolean>(false);

  useEffect(() => {
    if (filterConversations && activeRoute === activeRoute) {
      setItemCount(
        getFilterWithOpenConversations({
          sectionName: routeName as TypeSections,
          filterConversations
        })
      );
      const chatHasUnread = hasUnreadMessages({
        sectionName: routeName,
        filterConversations,
        unread
      });
      setItemUnreadMessage(chatHasUnread);
    }
  }, [filterConversations, routeName, activeRoute, unread]);

  const getColorIcon = useCallback(() => {
    if (themeMode === 'dark') {
      if (avaliableChannel) {
        return 'text';
      } else {
        return 'secondary';
      }
    } else {
      if (avaliableChannel) {
        return 'blanco';
      } else {
        return 'secondary';
      }
    }
  }, [themeMode, avaliableChannel]);

  return (
    <ItemDrawerSettings
      color={getColorIcon()}
      icon={icon}
      style={{ routeName, activeRoute }}
      onPress={
        avaliableChannel ? () => hanldePressClients() : () => dispatch(setDialogConnect(true))
      }
      idString={idString}
      w="lg"
      direction="column"
      noAvaliable={!avaliableChannel}
      absolute={true}
      badge={
        avaliableChannel ? (
          itemCount > 0 && (
            <BadgeComponent
              absolute={true}
              badgeBackground={itemUnreadMessage ? '$pxsol' : '$background'}
              item={formatNumber(itemCount)}
              mr={-2}
              mt={-2}
            />
          )
        ) : (
          <MaterialCommunity size="md" icon={IconFilterClosed} color={getColorIcon()} />
        )
      }
      translate={translate}
      paddingRight={0}
    />
  );
}

export default React.memo(ContainerItemSidebarApp, (prevProps, nextProps) => {
  return (
    prevProps.avaliableChannel === nextProps.avaliableChannel &&
    prevProps.hanldePressClients === nextProps.hanldePressClients &&
    prevProps.routeName === nextProps.routeName &&
    prevProps.activeRoute === nextProps.activeRoute &&
    prevProps.icon === nextProps.icon &&
    prevProps.idString === nextProps.idString &&
    prevProps.translate === nextProps.translate
  );
});
