import { useAuth0 as useAuthWeb } from '@auth0/auth0-react';
import { Platform } from 'react-native';
import { useAuth0 as useAuthNative } from 'react-native-auth0';

interface User {
  id_empresa: any;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}

export interface Auth0ContextInterface {
  user: User;
  error: Error;
  isAuthenticated: boolean;
  isLoading: boolean;
  // Auth methods:
  getAccessTokenSilently: any;
  getAccessTokenWithPopup: any;
  getIdTokenClaims: any;
  loginWithRedirect: any;
  loginWithPopup: any;
  logout: any;
  clearSession: any;
}

export default function useAuth0(): Auth0ContextInterface {
  if (Platform.OS === 'web') {
    return useAuthWeb() as unknown as Auth0ContextInterface;
  }
  return useAuthNative() as unknown as Auth0ContextInterface;
}
