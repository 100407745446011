import React, { useState } from 'react';
import { MenuItem, MenuItemLabel, Pressable } from '@gluestack-ui/themed';
import { Menu } from '@gluestack-ui/themed';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import translate from '@/helpers/traslations';
import { IS_WEB_PC } from '@/utils/functions';
import { ActionsMenu } from './interface';
import { SxProps } from '@gluestack-style/react/lib/typescript/types';
import { StyleProp, ViewStyle } from 'react-native';
import { setDialogConnect } from '@/redux/slices/theme';
import { useAppDispatch } from '@/redux/store';

const MenuActions = ({
  menuActions,
  trigger,
  children = null,
  sx = {},
  callbackOnClose,
  traslations = true,
  disabled = false
}: {
  menuActions?: ActionsMenu[];
  trigger: React.ReactNode;
  children?: React.ReactNode;
  callbackOnClose?: () => void;
  sx?: Partial<SxProps<StyleProp<ViewStyle>>>;
  traslations?: boolean;
  disabled?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [openMenu, setOpenMenu] = useState(false);

  const closeMenu = () => {
    setOpenMenu(false);
    callbackOnClose && callbackOnClose();
  };

  const pressMenuItem = (action: ActionsMenu) => {
    if (action?.available) {
      action.action(action);
    } else {
      dispatch(setDialogConnect(true));
    }
  };
  return (
    <Menu
      isOpen={openMenu}
      onClose={() => closeMenu()}
      trigger={({ ...triggerProps }) => (
        <Pressable
          {...triggerProps}
          onPress={() => setOpenMenu(true)}
          disabled={disabled}
          sx={{
            borderRadius: '$full',
            width: IS_WEB_PC ? 'auto' : '$full',
            ...sx
          }}
        >
          {trigger}
        </Pressable>
      )}
    >
      {!children
        ? menuActions?.map((action) => (
            <MenuItem
              gap="$2"
              onPress={() => pressMenuItem(action)}
              textValue={action.key}
              key={action.key}
              maxWidth="100%"
              sx={{
                _web: {
                  ':hover': {
                    cursor: !action?.available ? 'not-allowed' : 'pointer'
                  }
                }
              }}
            >
              <MaterialCommunity
                size="lg"
                icon={action.icon}
                color={!action?.available ? '$disabled' : '$text'}
              />
              <MenuItemLabel color={!action?.available ? '$disabled' : '$text'}>
                {traslations ? translate(`${action.label}`) : action.label}
              </MenuItemLabel>
            </MenuItem>
          ))
        : children}
    </Menu>
  );
};

export default React.memo(MenuActions);
