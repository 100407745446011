import { setLabels, setNewLabel } from '@/redux/actionTypes';
import axiosInstance from '@/utils/network/instanceAxios';

export const getLabels = (teamId: string) => async (dispatch) => {
  try {
    if (!teamId) throw new Error('teamId is required');
    const response = await axiosInstance.get(`/labels/${teamId}`);
    console.log('LABELS getLabels', response.data);
    dispatch(setLabels(response.data?.data));
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

export const createLabel =
  (label: { tag_name: string; tag_description: string }) => async (dispatch) => {
    try {
      const response = await axiosInstance.post('/labels', label);
      //   console.log('CREATE LABEL', response.data);
      // dispatch(setNewLabel(response.data.data));
      return response.data.data;
    } catch (error) {
      console.error(error);
      throw error?.response?.data;
    }
  };

export const updateLabelsInConversation =
  (props: { conversationsId: string[]; action: 'add' | 'set' | 'remove'; labels: string[] }) =>
  async () => {
    try {
      const response = await axiosInstance.post(`/chat/update-labels-conversation`, props);
      console.log('UPDATE LABELS IN CONVERSATION', response.data);
    } catch (error) {
      console.error(error);
      throw error?.response?.data;
    }
  };

export const deleteLabel = (id: number) => async (dispatch) => {
  try {
    const response = await axiosInstance.delete(`/labels/${id}`);
    console.log('DELETE LABEL', response.data);
    // dispatch(setLabels(response.data?.data));
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

export const updateLabel =
  (label: { tag_name: string; tag_description: string; id: number }) => async () => {
    const { id, ...rest } = label;
    try {
      const response = await axiosInstance.patch(`/labels/${label.id}`, rest);
      console.log('UPDATE LABEL', response.data);
    } catch (error) {
      console.error(error);
      throw error?.response?.data;
    }
  };
