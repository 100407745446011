import { AxiosResponse } from 'axios';
import axiosInstance from '@/utils/network/instanceAxios';
import { setWorflowTemplates } from '@/redux/actionTypes';

export const getTemplates =
  ({ id }) =>
  async () => {
    try {
      const response = await axiosInstance.get(`/template/list-response-templates/${id}`);
      console.log('response GET TEMPLATES success ✅', response);
      return response?.data ? response?.data : [];
    } catch (error) {
      console.log('error GET TEMPLATES ❌', error);
      throw error?.response?.data;
    }
  };

export const listFirstResponseTemplates =
  ({ id }) =>
  async () => {
    try {
      const response = await axiosInstance.get(`/template/list-first-response-templates/${id}`);
      return response?.data ? response?.data : [];
    } catch (error) {
      console.error('error getTemplatesList', error?.response?.data);
      throw error?.response?.data;
    }
  };

export const getChangelog = () => async () => {
  try {
    const response = await axiosInstance.get(`/changelog/version`);
    console.log('response getChangelog success ✅', response);
    if (response.status === 200 && typeof response.data !== 'string') {
      return response?.data ? response?.data : [];
    }
    return response?.data as AxiosResponse;
  } catch (error) {
    console.log('error getChangelog ❌', error);
    throw error?.response?.data;
  }
};

export const getTemplatesWorkflows =
  ({ idStringWorkflow }) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/template/list-template-workflow`);
      console.log('response getTemplatesWorkflows success ✅', response?.data.data);
      dispatch(setWorflowTemplates({ idStringWorkflow, templates: response?.data.data }));
      return response?.data;
    } catch (error) {
      console.log('error getTemplatesWorkflows ❌', error);
      throw error?.response?.data;
    }
  };

export const replaceDinamicLinks =
  (props: { templateId: number; fragmentId: string; metadata: any }) => async () => {
    try {
      const response = await axiosInstance.post(`/template/replace-dynamic-link`, props);
      console.log('response getChangelog success ✅', response.data);
      return response?.data ? response?.data : [];
    } catch (error) {
      console.log('error getChangelog ❌', error);
      throw error?.response?.data;
    }
  };
