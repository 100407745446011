import { StyleSheet, View } from 'react-native';
import React, { useMemo } from 'react';
import translate from '@/helpers/traslations';
import { URL_PMS_NEW_CONTACT } from '@/utils/constants';
import { openURL } from '@/utils/functions';

import { Text } from '@gluestack-ui/themed';
import MainCta from '../../atoms/MainCta';
import { IconPlus } from '@/assets/icons/Icons';
import CustomTooltip from '../tooltip/CustomTooltip';

function EmptySearchContact() {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        flexRow: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }
      }),
    []
  );
  return (
    <View style={[styles.flexRow, { paddingVertical: 8, paddingHorizontal: 16, gap: 16 }]}>
      <Text size="sm">{translate('messages.noContactsForThisSearch')}</Text>
      <MainCta
        variant="outline"
        icon={IconPlus}
        onPress={() => openURL(URL_PMS_NEW_CONTACT)}
        sx={{
          borderColor: '$secondary',
          borderWidth: 1
        }}
      />
    </View>
  );
}

export default React.memo(EmptySearchContact);
