import {
  View,
  Badge,
  BadgeText,
  Pressable,
  ScrollView,
  Text,
  Actionsheet,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetItem,
  ActionsheetIcon,
  Spinner
} from '@gluestack-ui/themed';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { outConversationToFilter, setIdConversation } from '@/redux/actionTypes';
import { getAllConversationsByFilter, setLoading } from '@/redux/slices/conversation';
import { Filters } from '@/redux/slices/filters/interfaces';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';

import { ASIGNADOS_A_MI, filterToCount } from '@/utils/filters';
import { getCantConversOpenByFilter, getUnreadMessagesByFilter } from '@/utils/functions';
import MainCta from '../../atoms/MainCta';
import {
  ChecroDoubleRight,
  ChevronDoubleLeft,
  IconLabel,
  IconPlus,
  Pencil,
  Trash
} from '@/assets/icons/Icons';
import { Routes } from '@/routers/routes';
import { ActionsheetItemText } from '@gluestack-ui/themed';
import translate from '@/helpers/traslations';
import { Label } from '@/redux/slices/labels/interfaces';
import { isArray } from 'lodash';
import { useLinkss } from '@/providers/LinkProvider';
import { deleteLabel } from '@/redux/slices/labels/requests';
import { CustomTabBarProps } from './interfaces';
import { useCollapsed } from '@/providers/CollpasedProvider';
import MaterialCommunity from '../Icons/MaterialCommunity';
import { ThemedScrollView, THView, TPressable, TText } from '../themed';

function CustomTabBar({
  state,
  descriptors,
  navigation,
  filterByName,
  routeName,
  team,
  viewMore,
  setViewMore
}: CustomTabBarProps) {
  const filterConversations = useAppSelector(
    (state: RootState) => state.conversations.filterConversations?.[team]
  );
  const userID = useAppSelector((state: RootState) => state.user.user?.id);
  const labels = useAppSelector((state) => state.labels.labels);
  const outConversationToFilterQueue = useAppSelector(
    (state) => state.conversations.outConversationToFilterQueue
  );
  const unread = useAppSelector((state) => state.conversations.unread);
  const dispatch = useAppDispatch();
  const [badgeValues, setBadgeValues] = useState({});
  const [unreadMessagesByFilter, setUnreadMessagesByFilter] = useState<{
    [section: string]: boolean;
  }>({});
  const { hableModalLabel } = useCollapsed();
  const { showToast, handleCopyCode } = useLinkss();
  const [currentLabel, setCurrentLabel] = useState();
  const [loading, setLoadingState] = useState(false);
  const [showActionsheet, setShowActionsheet] = useState(false);
  const handleClose = () => setShowActionsheet(!showActionsheet);

  useEffect(() => {
    if (filterConversations) {
      const newBadgeValues = {};
      const newUnreadMessagesByFilter = {};
      filterByName.forEach((filter: Filters) => {
        if (filter?.types.includes(routeName) && filterToCount.includes(filter?.idString)) {
          newBadgeValues[filter?.idString] = getCantConversOpenByFilter({
            filterConversations,
            routeName,
            filterName: filter?.idString
          });
          newUnreadMessagesByFilter[filter?.idString] = getUnreadMessagesByFilter({
            filterConversations,
            routeName,
            filterName: filter?.idString,
            unread
          });
        }
      });
      setUnreadMessagesByFilter(newUnreadMessagesByFilter);
      setBadgeValues(newBadgeValues);
    }
  }, [filterConversations]);

  const onPressEdit = () => {
    hableModalLabel(currentLabel);
    setShowActionsheet(false);
  };

  const onPressNewLabel = () => {
    hableModalLabel();
    setShowActionsheet(false);
  };

  const handleDeleteLabel = (id: number) => {
    dispatch(deleteLabel(id))
      .then((res) => {
        console.log('DELETE LABEL', res);
        setShowActionsheet(false);
        setLoadingState(false);
        showToast({
          title: translate('success'),
          subtitle: translate('messages.labelDeleted'),
          variant: 'accent',
          type: 'success'
        });
      })
      .catch((err) => {
        console.error(err);
        setLoadingState(false);
        if (isArray(err?.message)) {
          err?.message.forEach((error: string) => {
            showToast({
              type: 'error',
              title: `ERROR: ${err?.transactionId}`,
              subtitle: error,
              variant: 'accent',
              cta: (
                <MainCta
                  labelId={'actions.copy'}
                  uppercase
                  variant="outline"
                  onPress={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    handleCopyCode(err?.transactionId);
                  }}
                />
              )
            });
          });
          return;
        }
        showToast({
          type: 'error',
          title: 'Error',
          subtitle: err.message,
          variant: 'accent'
        });
      });
  };

  const handlePressedFilter = useCallback(
    (item: Filters) => {
      if (Object.keys(outConversationToFilterQueue).length > 0) {
        Object.keys(outConversationToFilterQueue).forEach((key) => {
          dispatch(outConversationToFilter(outConversationToFilterQueue[key]));
        });
      }

      dispatch(setIdConversation('1'));
      let query = { ...item.query };
      if (item.idString === ASIGNADOS_A_MI) {
        query = {
          ...query,
          fragment: {
            ...query.fragment,
            associations: {
              assignee: [userID]
            }
          },
          page: 1,
          id_team: Number(team)
        };
      } else {
        query = {
          ...query,
          page: 1,
          id_team: Number(team)
        };
      }
      dispatch(setLoading(true));
      dispatch(
        getAllConversationsByFilter({
          query,
          filter: item.idString,
          type: item.types,
          teamId: team
        })
      )
        .catch((error) => {
          console.error('Error al obtener conversaciones por filtro', error);
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [outConversationToFilterQueue, team]
  );

  let deleteLabelById;

  return (
    <ThemedScrollView
      horizontal
      showsHorizontalScrollIndicator
      contentContainerStyle={{
        display: 'flex',
        alignItems: 'center'
      }}
      bgColor="backgroundSecondary"
      border="border"
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        maxHeight: 50,
        borderTopWidth: 1,
        paddingVertical: 5
      }}
    >
      {state.routes.map((route, index) => {
        const { options } = descriptors[route?.key];
        const label = options?.tabBarLabel;
        const isFocused = state?.index === index;
        const getCurretFilter = filterByName.find(
          (item: Filters) => item?.idString === route?.params?.filter
        );

        const onPress = () => {
          if (!isFocused) {
            navigation.navigate(getCurretFilter?.idString);
            handlePressedFilter(getCurretFilter);
          } else {
            handlePressedFilter(getCurretFilter);
          }
        };
        const onLongPress = () => {
          if (getCurretFilter?.label) {
            labels &&
              setCurrentLabel(labels?.find((label: Label) => label?.id == getCurretFilter?.id));
            setShowActionsheet(true);
          }
        };

        deleteLabelById = () => {
          setLoadingState(true);
          handleDeleteLabel(getCurretFilter?.id);
        };

        return (
          <TPressable
            key={index}
            onPress={onPress}
            bgColor={isFocused ? 'tint' : 'background'}
            style={{
              padding: 10,
              maxHeight: 40,
              marginVertical: 'auto',
              borderRadius: 8,
              marginHorizontal: 2,
              position: 'relative'
            }}
            onLongPress={onLongPress}
          >
            <THView
              style={{
                gap: 5
              }}
            >
              {getCurretFilter?.label && <MaterialCommunity icon={IconLabel} size="sm" />}
              <TText color={isFocused ? 'textTint' : 'secondary'} selectable={false}>
                {label}
              </TText>
            </THView>
            {badgeValues[route?.params?.filter] > 0 && (
              <Badge
                borderRadius="$full"
                zIndex={1}
                bg={
                  unreadMessagesByFilter[route?.params?.filter]
                    ? '$pxsol'
                    : '$backgroundActiveNavigationPxsol'
                }
                style={{
                  position: 'absolute',
                  top: 1,
                  right: 1
                }}
              >
                <BadgeText
                  selectable={false}
                  color={unreadMessagesByFilter[route?.params?.filter] ? '$text' : '$text'}
                >
                  {badgeValues[route?.params?.filter]}
                </BadgeText>
              </Badge>
            )}
          </TPressable>
        );
      })}

      {routeName === Routes.clientes && (
        <MainCta
          onPress={() => setViewMore(!viewMore)}
          icon={viewMore ? ChevronDoubleLeft : ChecroDoubleRight}
          buttonSize="xs"
          variant="outline"
          sx={{
            height: 40
          }}
        />
      )}
      <Actionsheet isOpen={showActionsheet} onClose={handleClose} zIndex={999}>
        <ActionsheetBackdrop />
        <ActionsheetContent height="auto" zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetItem onPress={() => onPressNewLabel()}>
            <ActionsheetIcon as={IconPlus} size="sm" />
            <ActionsheetItemText>{translate('actions.newLabel')}</ActionsheetItemText>
          </ActionsheetItem>
          <ActionsheetItem onPress={() => onPressEdit()}>
            <ActionsheetIcon as={Pencil} size="sm" />
            <ActionsheetItemText>{translate('actions.edit')}</ActionsheetItemText>
          </ActionsheetItem>
          <ActionsheetItem onPress={deleteLabelById}>
            {loading ? (
              <Spinner size="small" color="$pxsol" />
            ) : (
              <ActionsheetIcon as={Trash} size="sm" />
            )}
            <ActionsheetItemText>{translate('actions.delete')}</ActionsheetItemText>
          </ActionsheetItem>
        </ActionsheetContent>
      </Actionsheet>
    </ThemedScrollView>
  );
}

export default React.memo(CustomTabBar);
