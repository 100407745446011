import { Badge, BadgeText } from '@gluestack-ui/themed';
import * as React from 'react';

function BadgeComponent({
  item,
  badgeBackground,
  absolute = false,
  mt = -6,
  mr = -7
}: {
  item: string;
  badgeBackground: string;
  absolute?: boolean;
  mt?: number;
  mr?: number;
}) {
  return (
    <Badge
      h={20}
      bg={badgeBackground}
      borderRadius="$full"
      mt={absolute ? mt : 0}
      mr={absolute ? mr : 0}
      zIndex={100}
      variant="solid"
      alignSelf="center"
      alignItems="center"
      justifyContent="center"
    >
      <BadgeText fontWeight="bold" size="xs" color="$white">
        {item}
      </BadgeText>
    </Badge>
  );
}

export default React.memo(BadgeComponent);
