import React, { useMemo } from 'react';
import { IconClose } from '@/assets/icons/Icons';
import MainCta from '@/atoms/MainCta';
import SelectorTeams from '@/atoms/TeamPicker/SelectorTeams';
import translate from '@/helpers/traslations';
import { HEIGHT_HEADER_LAYOUT, TABLET_SCREEN } from '@/utils/globales';
import { Text } from '@gluestack-ui/themed';
import { HeaderSidebarFilterProps } from './interface';
import { Routes } from '@/routers/routes';
import { SIN_ASIGNAR } from '@/utils/filters/index';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { THView, TText } from '@/components/themed';
import { StyleSheet } from 'react-native';

const HeaderSidebarFilters = ({
  name,
  closeDrawer,
  navigateTo = {
    name: Routes.clientes,
    params: { id: ' ' },
    state: {
      index: 0,
      routes: [
        {
          name: SIN_ASIGNAR,
          params: { id: ' ' }
        }
      ]
    }
  }
}: HeaderSidebarFilterProps) => {
  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  const style = useMemo(
    () =>
      StyleSheet.create({
        header: {
          // borderBottomColor: '$border',
          borderBottomWidth: 1,
          height: HEIGHT_HEADER_LAYOUT,
          maxHeight: HEIGHT_HEADER_LAYOUT,
          width: '100%',
          paddingHorizontal: 12,
          paddingVertical: 4,
          alignItems: 'center',
          justifyContent: 'flex-start'
        }
      }),
    []
  );

  return name === 'chat' ? (
    <THView border="border" style={style.header}>
      {name === 'chat' && (
        <TText size="lg" style={{ fontWeight: 'bold' }}>
          {translate(`navigation.${name}`)}
        </TText>
      )}
    </THView>
  ) : (
    <THView border="border" style={style.header}>
      <SelectorTeams navigateTo={navigateTo} />
      {isPc ? null : <MainCta onPress={() => closeDrawer()} icon={IconClose} variant="outline" />}
    </THView>
  );
};

export default React.memo(HeaderSidebarFilters);
