export const minWidhtScreen = 768;
export const MOBILE_SCREEN = 480;
export const TABLET_SCREEN = 1024;
export const DESKTOP_SCREEN = 1280;
export const DESKTOP_SCREEN_LARGE = 1440;
export const HEIGHT_HEADER_LAYOUT = 47;

export const WIDTH_SIDEBAR_COLLAPSED = 60;

export const AGE_OF_MINORS = 12;
export const MIN_AGE_OF_MINORS = 1;
