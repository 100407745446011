import React, { useEffect } from 'react';
import { DrawerContentComponentProps } from '@react-navigation/drawer';

import { VStack } from '@gluestack-ui/themed';

import {
  Bells,
  CogsMultiple,
  Customization,
  DashboardCustomize,
  IconHelp,
  Update,
  Widget
} from '@/assets/icons/Icons';

import DrawerSection from '@/atoms/DrawerSection';
import ItemDrawerSettings from '@/atoms/ItemDrawerSettings';
import ItemLogout from '@/atoms/ItemLogout';
import BadgeComponent from '@/atoms/BadgeComponent';

import { RouteSettings, Routes } from '../../../routers/routes';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { setChangelog } from '@/redux/slices/theme';
import colors from '../../../../config/colors';
import HeaderSidebarFilters from '../sidebarFilters/HeaderSidebarFilters';
import { ScrollView } from '@gluestack-ui/themed';
import { getWorkflows } from '@/redux/slices/automatization/requests';
import { setGettingWorkflows } from '@/redux/slices/automatization/index';
import { setWorkflows } from '@/redux/actionTypes/index';
import { ThemedScrollView, TVView } from '@/components/themed';

/**
 *
 * @param props DrawerContentComponentProps
 * @returns {React.ReactElement}
 */

function ContentSiderbarLeftSettings(props: DrawerContentComponentProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const { state } = props;
  const [teamId, setTeamId] = React.useState<string | null>(null);

  const activeRoute = state.routeNames[state.index];
  const newChangelog = useAppSelector((state: RootState) => state.settings.newChangelog);
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);
  const idStringWorkflow = useAppSelector(
    (state: RootState) => state.automatization.idStringWorkflow
  );

  const onPressChangelog = () => {
    props.navigation.navigate(RouteSettings.settingsChangelog);
    dispatch(setChangelog(false));
  };

  useEffect(() => {
    state?.routes?.find((route: any) => {
      if (route?.name === activeRoute) {
        setTeamId(route?.params?.team);
      }
    });
  }, [state]);

  const handlePessAutimatization = () => {
    dispatch(setGettingWorkflows(true));
    dispatch(getWorkflows({ teamId }))
      .then((res) => {
        console.log('Workflows loaded');
        dispatch(
          setWorkflows({
            idString: idStringWorkflow,
            workflows: res?.data
          })
        );
      })
      .catch((err) => {
        console.log('Error getting workflows', err);
      })
      .finally(() => {
        dispatch(setGettingWorkflows(false));
      });
    props.navigation.navigate(RouteSettings.settingsChat);
  };

  const drawerSections = {
    ajustsOfHotel: [
      {
        id: '2',
        icon: CogsMultiple,
        routeName: RouteSettings.settingsChat,
        onPress: () => handlePessAutimatization(),
        idString: 'navigation.automatizations',
        avaliable: true
      },
      {
        id: '1',
        icon: DashboardCustomize,
        routeName: RouteSettings.settingsChannels,
        onPress: () => props.navigation.navigate(RouteSettings.settingsChannels),
        idString: 'navigation.channels',
        avaliable: true
      }
    ],
    ajustOfApp: [
      {
        id: '3',
        icon: Bells,
        routeName: RouteSettings.settingsNotifications,
        onPress: () => props.navigation.navigate(RouteSettings.settingsNotifications),
        idString: 'navigation.notifications',
        avaliable: true
      },
      {
        id: '4',
        icon: Customization,
        routeName: RouteSettings.settingsCustomizations,
        onPress: () => props.navigation.navigate(RouteSettings.settingsCustomizations),
        idString: 'navigation.customization',
        avaliable: true
      }
    ],
    centerOfHelp: [
      {
        id: '5',
        icon: IconHelp,
        routeName: RouteSettings.help,
        onPress: () => props.navigation.navigate(RouteSettings.help),
        idString: 'navigation.help',
        avaliable: true
      },
      {
        id: '6',
        icon: Update,
        routeName: RouteSettings.settingsChangelog,
        idString: 'navigation.settingsChangelog',
        onPress: () => onPressChangelog(),
        badge: newChangelog ? 1 : 0,
        avaliable: true
      }
    ]
  };

  return (
    <ThemedScrollView
      bgColor="backgroundSecondary"
      style={{
        height: '100%',
        width: '100%',
        padding: 0,
        margin: 0
      }}
      {...props}
    >
      <HeaderSidebarFilters
        name={activeRoute}
        closeDrawer={() => props.navigation.closeDrawer()}
        navigateTo={{
          name: Routes.Settings,
          params: { id: '' },
          state: {
            index: 0,
            routes: [
              {
                name: RouteSettings.settingsChat,
                params: { id: '' }
              }
            ]
          }
        }}
      />
      <TVView
        style={{
          padding: 10
        }}
      >
        {Object.entries(drawerSections).map(([sectionId, items]) => (
          <DrawerSection key={sectionId} idString={sectionId}>
            {items.map((item, index) => (
              <ItemDrawerSettings
                key={index}
                icon={item.icon}
                style={{ routeName: item.routeName, activeRoute: activeRoute }}
                onPress={item?.onPress}
                noAvaliable={!item.avaliable}
                idString={item.idString}
                absolute={false}
                color={item.avaliable ? 'text' : 'secondary'}
                badge={
                  item.badge > 0 && (
                    <BadgeComponent
                      absolute={true}
                      badgeBackground={'$background'}
                      item={item.badge}
                    />
                  )
                }
              />
            ))}
          </DrawerSection>
        ))}

        <ItemLogout />
      </TVView>
    </ThemedScrollView>
  );
}

/**
 * Exportamos el componente
 * @exports ContentSiderbarLeftSettings
 * @returns {React.ReactElement}
 * @description Exportamos el componente para poder usarlo en otros ficheros, retorna el contenido del sidebar izquierdo
 */
export default React.memo(ContentSiderbarLeftSettings);
