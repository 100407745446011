import { FRAGMENT_PENDING } from '@/utils/constants';
import { Badge } from '@gluestack-ui/themed';
import React from 'react';
import { PropsContainerBadges } from './interfaces';
import { RootState, useAppSelector } from '@/redux/store';
import { TView } from '@/components/themed';

const ContainerBadges = ({ fragmentId, conversationId }: PropsContainerBadges) => {
  const fragments = useAppSelector((state: RootState) => state.fragments.fragments);

  const unread = useAppSelector(
    (state: RootState) => state.conversations?.unread?.[conversationId] ?? 0
  );

  return (
    <TView>
      {unread > 0 && (
        <Badge
          bg="$red600"
          borderRadius="$full"
          variant="solid"
          size="sm"
          alignSelf="flex-end"
          h="$2"
          w="$1"
        />
      )}
      {fragments?.[fragmentId]?.status === FRAGMENT_PENDING && unread < 1 && (
        <Badge
          bg="$warning"
          borderRadius="$full"
          variant="solid"
          alignSelf="flex-end"
          size="sm"
          h="$2"
          w="$1"
        />
      )}
    </TView>
  );
};

export default React.memo(ContainerBadges);
