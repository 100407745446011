export enum OriginOfMessage {
  internal = 'internal',
  external = 'external',
  server = 'server'
}

export enum TypeOfMessage {
  text = 'text',
  image = 'image',
  video = 'video',
  audio = 'audio',
  file = 'file',
  template = 'template',
  sticker = 'sticker',
  gif = 'gif',
  info = 'info',
  whatsappSticker = 'whatsappSticker',
  location = 'location'
}
