import { TypesSections } from '@/types/index';
import { FragmentStatus } from '../fragments/interfaces';
import { ETypesSections } from '@/routers/MainRouter';

export enum filterTypes {
  chat = 'chat',
  clients = 'clients'
}

export enum OrderOptions {
  recent = 'recent',
  importants = '136'
}

export enum platforms {
  internal = 'internal',
  whatssap = 'whatsapp',
  telegram = 'telegram',
  facebook = 'facebook',
  twitter = 'twitter',
  instagram = 'instagram',
  widget = 'widget'
}

export enum contracts {
  chat = 'chat',
  internal = 'internal',
  widget = 'widget'
}

export const CONTRACTS = Object.values(contracts);

export type Filters = {
  idString: string;
  id: number;
  name: string;
  icon: string;
  size: number;
  label?: boolean;
  contracts: contracts;
  types: ETypesSections;
  query?: IQuery;
};

export type IQuery = {
  conversation: {
    platform: platforms[];
    group: boolean;
    updatedAt: any;
  };
  fragment?: {
    associations?:
      | {
          assignee?: boolean | string[];
          contact?: boolean;
        }
      | boolean;
    status?: FragmentStatus[];
  };
  page?: 1;
  // como pasammos el id del equipo por parametro, lo agregamos al query
  id_team?: number;
  filter_name: string;
};

export type FilterItemState = {
  assignee: Filters[];
  agents: Filters[];
};
export interface FiltersState {
  filterChat: string;
  filterClients: string;
  filters: FilterItemState;
  type: filterTypes;
  orderOptions: { label: string; value: string; id: number }[];
  currentOrder: OrderOptions;
}
