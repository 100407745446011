import React, { useEffect } from 'react';
import { HStack, Text } from '@gluestack-ui/themed';
import translate from '@/helpers/traslations';
import { VStack } from '@gluestack-ui/themed';
import FilterOmniboxComponent from '@/components/omnibox/FilterOmniboxComponent';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { setSelectedChannelToInitConversation } from '@/redux/slices/theme';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { InfoOutline } from '@/assets/icons/Icons';

function PopoverChannels({ channelWhatsapp }) {
  const dispatch = useAppDispatch();
  const selectedChannel = useAppSelector(
    (state: RootState) => state.settings.selectedChannelToInitConversation
  );
  const popoverChannel = useAppSelector((state: RootState) => state.settings.popoverChannel);

  const setSelectedChannel = (channel) => {
    dispatch(setSelectedChannelToInitConversation(channel));
  };

  return (
    <VStack borderColor="$border" space="sm">
      <Text size="sm">{translate('selectChannelToInitConversaton')}</Text>
      <HStack
        borderColor={popoverChannel ? '$pxsol' : 'transparent'}
        borderWidth={popoverChannel ? 1 : 0}
        borderRadius={8}
        padding={4}
      >
        <FilterOmniboxComponent
          filters={channelWhatsapp}
          selectedFilters={selectedChannel}
          setSelectedFilters={setSelectedChannel}
          unique
          type={'canales activos'}
        />
        {popoverChannel && <MaterialCommunity icon={InfoOutline} size="sm" color="$pxsol" />}
      </HStack>
    </VStack>
  );
}

export default React.memo(PopoverChannels);
