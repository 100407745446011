import React from 'react';
import { StyleSheet, TextStyle } from 'react-native';

import { TText } from '@/components/themed';
import { colorsProps } from '@/hooks/useTheme';

type TextFormatterProps = {
  children: string;
  numberOfLines?: number;
  bold?: boolean;
  color?: colorsProps;
};

const TextFormatter: React.FC<TextFormatterProps> = ({
  children,
  numberOfLines = 0,
  color,
  bold
}) => {
  const styls = StyleSheet.create({
    bold: { fontWeight: 'bold' },
    italic: { fontStyle: 'italic' },
    strikethrough: { textDecorationLine: 'line-through' },
    default: {} // Estilo por defecto
  });

  const parseAndRenderText = (text) => {
    const parts = text.split(/(\*|_|~)/g);
    let textStyle: TextStyle = styls.default;

    return parts.map((part, index) => {
      switch (part) {
        case '*':
          textStyle = textStyle?.fontWeight === 'bold' ? styls.default : styls.bold;
          return null;
        case '_':
          textStyle = textStyle?.fontStyle === 'italic' ? styls.default : styls.italic;
          return null;
        case '~':
          textStyle =
            textStyle?.textDecorationLine === 'line-through' ? styls?.default : styls.strikethrough;
          return null;
        default:
          return (
            <TText
              lineBreakMode="clip"
              numberOfLines={numberOfLines}
              key={index}
              style={{ ...textStyle }}
              color={color ?? 'text'}
            >
              {part}
            </TText>
          );
      }
    });
  };

  return (
    <TText
      lineBreakMode="clip"
      numberOfLines={numberOfLines}
      selectable
      color={color ?? 'text'}
      style={{ fontWeight: bold ? 'bold' : 'normal' }}
    >
      {typeof children === 'string' ? parseAndRenderText(children) : children}
    </TText>
  );
};

export default React.memo(TextFormatter, (prevProps, nextProps) => {
  return prevProps.children === nextProps.children;
});
