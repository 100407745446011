import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import Pusher from 'pusher-js';
import Constants from 'expo-constants';
import { AppState } from 'react-native';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { setAllMessagesReadByConverId } from '../redux/slices/messages/requests';
import {
  UpdateMessageStatusEventAndCountMessagesNoRead,
  newMessage
} from '@/redux/slices/messages';
import { addConverToState } from '../redux/slices/conversation';
import {
  clearMessagesByConverationId,
  clearOutConversationToStateById,
  clearParticipantsByConversationId,
  compareConversation,
  deleteLabel,
  outConversationToFilter,
  removeConversationFromFilter,
  setFragments,
  setNewsParticipants,
  setSections,
  setVersion,
  updateParticipant,
  updateTagNameLabelById
} from '../redux/actionTypes';
import { firstCharge } from '@/redux/slices/auth';
import { CompareParticipantsEvent } from './interfaces';
import { getLabels } from '@/redux/slices/labels/requests';

const PusherContext = createContext<Pusher | null>(null);

export const usePusher = () => useContext(PusherContext);

function PusherProvider({ children }) {
  const [pusherInstance, setPusherInstance] = useState<Pusher | null>(null);
  const userId = useAppSelector((state: RootState) => state.user.user?.id);
  const id_empresa = useAppSelector((state: RootState) => state.user.company.IDEmpresas);
  const token = useAppSelector((state: RootState) => state.user.auth0Token);
  const id_team = useAppSelector((state: RootState) => state.user.team?.id);
  const [appState, setAppState] = useState(AppState.currentState);
  const { pusherkey, pusherCluster, backendUrl } = Constants.expoConfig.extra;
  const lastUpdateTime = useRef(new Date().getTime()); // Almacenar la última vez que se activó la actualización
  const updateInterval = 30000;

  useEffect(() => {
    const handleAppStateChange = (nextAppState) => {
      if (appState.match(/inactive|background/) && nextAppState === 'active') {
        console.log('App has come to the foreground!');
        const currentTime = new Date().getTime();
        if (currentTime - lastUpdateTime.current >= updateInterval) {
          console.log('Actualizando información del usuario');
          dispatch(firstCharge()).catch((err) => console.log('err firstCharge', err));
          lastUpdateTime.current = currentTime;
        }
      }
      setAppState(nextAppState);
    };

    const appStateSubscription = AppState.addEventListener('change', handleAppStateChange);
    return () => {
      appStateSubscription.remove();
    };
  }, [AppState.currentState]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (token) {
      const pusher = new Pusher(pusherkey, {
        cluster: pusherCluster,
        forceTLS: true,
        authEndpoint: `${backendUrl}/chat/registerSocketId`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      setPusherInstance(pusher);
      console.log('🚀 ~ file: PusherProvider Naive');
    }
  }, [token]);

  if (pusherInstance) {
    const channel = pusherInstance.subscribe(`private-client_room_${userId}`);
    const channelTeam = pusherInstance.subscribe(`private-team_room_${id_team}`);
    const channelUpdate = pusherInstance.subscribe('public-update-app-pxsol');
    const channelCompany = pusherInstance.subscribe(`private-company_room_${id_empresa}`);

    channelUpdate.bind('pusher:subscription_succeeded', () => {
      // console.log('🚀 ~ file: PusherProvider.tsx ~ line 49 ~ channelCompany.bind ~ channelCompany');
    });

    channelUpdate.unbind_all();
    channelUpdate.bind('new-version-app', (data) => {
      console.log('new-version-app', data);
      dispatch(setVersion(true));
    });

    // Eventos de la compañia
    channelCompany.bind('pusher:subscription_succeeded', () => {
      // console.log('🚀 ~ file: PusherProvider.tsx ~ line 49 ~ channelCompany.bind ~ channelCompany');
    });
    channelCompany.bind('pusher:subscription_error', () => {
      // console.log('🚀 ~ file: PusherProvider.tsx ~ line 53 ~ ERROR ~ channelCompany');
    });
    channelCompany.bind('UpdateAllMessageStatusEvent', (data) => {
      // console.log('UpdateAllMessageStatusEvent : [channelCompany]', data.id_conversation);
      dispatch(setAllMessagesReadByConverId(data?.id_conversation));
    });
    channelCompany.bind('InsertMessageEvent', (data) => {
      // console.log('📨 InsertMessageEvent : [channelCompany]');
      dispatch(newMessage(data?.message));
    });
    channelCompany.bind('InsertConversationEvent', (data) => {
      // console.log('InsertConversationEvent : [channelCompany]', data);
      dispatch(
        outConversationToFilter({
          conversationId: data?.conversation._id,
          filter: '',
          teamId: id_team
        })
      );
      dispatch(setSections(data?.section));

      dispatch(
        addConverToState({
          fragments: data?.fragment,
          filter: data?.filter,
          conversation: data?.conversation,
          teamId: id_team
        })
      );
      dispatch(
        setNewsParticipants({
          participants: data?.participants[data?.conversation?._id],
          conversation_id: data?.conversation?._id
        })
      );
    });

    channelCompany.bind('UpdateFragmentEvent', (data) => {
      dispatch(setFragments(data?.fragmentInfo));
    });

    channelCompany.bind('UpdateMessageStatusEvent', (data) => {
      console.log('UpdateMessageStatusEvent : [channelCompany]');
      if (data?.messages?.length > 0) {
        dispatch(
          UpdateMessageStatusEventAndCountMessagesNoRead({
            status: data?.messages[0]?.status,
            msg: data?.messages[0]
          })
        );
      }
    });
    channelCompany.bind('YouWereRemovedEvent', () => {
      console.log('YouWereRemovedEvent : [channelCompany]');
      //  TODO sacar la conver del state, y de la lista de conversaciones, sacar los menjsaes y los participantes de la conver
    });
    // Eventos de usuario
    channel.bind('pusher:subscription_succeeded', (yes) => {
      console.log('🚀 ~ file: PusherProvider.tsx ~ line 45 ~ channel.bind ~ channel', yes);
    });
    channel.bind('pusher:subscription_error', (error) => {
      console.log('🚀 ~ file: PusherProvider.tsx ~ line 48 ~ ERROR ~ channel', error);
    });
    channel.bind('UpdateAllMessageStatusEvent', (data) => {
      console.log('UpdateAllMessageStatusEvent : [channelCompany]', data.id_conversation);
      dispatch(setAllMessagesReadByConverId(data.id_conversation));
    });
    channel.bind('CompareParticipantsEvent', (data: CompareParticipantsEvent) => {
      console.log('CompareParticipantsEvent', data);
      dispatch(
        setNewsParticipants({
          participants: data?.participants[data?.conversationId],
          conversation_id: data?.conversationId
        })
      );
    });

    channel.bind('InsertConversationEvent', (data) => {
      console.log('InsertConversationEvent', data);
      dispatch(
        outConversationToFilter({
          conversationId: data?.conversation._id,
          filter: '',
          teamId: id_team
        })
      );
      dispatch(setSections(data?.section));

      dispatch(
        addConverToState({
          fragments: data?.fragment,
          filter: data?.filter,
          conversation: data?.conversation,
          teamId: id_team
        })
      );
      dispatch(
        setNewsParticipants({
          participants: data?.participants[data?.conversation?._id],
          conversation_id: data?.conversation?._id
        })
      );
    });
    channel.bind('UpdateFragmentEvent', (data) => {
      dispatch(setFragments(data?.fragmentInfo));
    });
    channel.bind('InsertMessageEvent', (data) => {
      console.log('📨 InsertMessageEvent');
      dispatch(newMessage(data?.message));
    });

    channel.bind('compararConversacion', (data) => {
      console.log('compararConversacion', data);
      dispatch(compareConversation(data?.conversation));
      dispatch(
        setNewsParticipants({
          participants: data?.participant,
          conversation_id: data?.conversation?._id
        })
      );
    });

    channel.bind('CompareGroupNameEvent', (data) => {
      console.log('CompareGroupNameEvent', data);
      dispatch(compareConversation(data?.Namegroupchange[0]));
    });
    channel.bind('UpdateMessageStatusEvent', (data) => {
      console.log('UpdateMessageStatusEvent');
      if (data?.messages?.length > 0) {
        dispatch(
          UpdateMessageStatusEventAndCountMessagesNoRead({
            status: data?.messages[0]?.status,
            msg: data?.messages[0]
          })
        );
      }
    });
    channel.bind('YouWereRemovedEvent', (data) => {
      console.log('YouWereRemovedEvent', data);
      dispatch(clearOutConversationToStateById(data?.id_conversation));
      dispatch(removeConversationFromFilter(data?.id_conversation));
      dispatch(clearMessagesByConverationId(data?.id_conversation));
      dispatch(clearParticipantsByConversationId(data?.id_conversation));
      //  TODO sacar la conver del state, y de la lista de conversaciones, sacar los menjsaes y los participantes de la conver
    });

    channelTeam.bind('pusher:subscription_succeeded', (data) => {
      console.log(
        '%c 🚀 ~ file: PusherProvider.tsx ~ line 53 ~ channelTeam.bind ~ channelTeam',
        'color:#FFF; background-color:green; padding: 10px; font-size:16px',
        data
      );
    });

    channelTeam.bind('pusher:subscription_error', (err) => {
      console.log(
        '%c 🚀 ~ file: PusherProvider.tsx ~ line 53 ~ ERROR ~ channelTeam',
        'color:#FFF; background-color:red; padding: 10px; font-size:16px',
        err
      );
    });

    channelTeam.bind('UpdateFragmentEvent', (data) => {
      dispatch(setFragments(data?.fragmentInfo));
    });

    channelTeam.bind('InsertConversationEvent', (data) => {
      console.log('InsertConversationEvent', data);
      dispatch(
        outConversationToFilter({
          conversationId: data?.conversation._id,
          filter: '',
          teamId: id_team
        })
      );
      dispatch(setSections(data?.section));

      dispatch(
        addConverToState({
          fragments: data?.fragment,
          filter: data?.filter,
          conversation: data?.conversation,
          teamId: id_team
        })
      );
      dispatch(
        setNewsParticipants({
          participants: data?.participants[data?.conversation?._id],
          conversation_id: data?.conversation?._id
        })
      );
    });

    channelTeam.bind('CompareParticipantsEvent', (data: CompareParticipantsEvent) => {
      console.log('CompareParticipantsEvent', data);
      dispatch(
        setNewsParticipants({
          participants: data?.participants[data?.conversationId],
          conversation_id: data?.conversationId
        })
      );
    });

    channelTeam.bind('InsertMessageEvent', (data) => {
      console.log('📨 InsertMessageEvent', data);
      dispatch(newMessage(data?.message));
    });

    channelTeam.bind('CompareGroupNameEvent', (data) => {
      console.log('CompareGroupNameEvent', data);
      dispatch(compareConversation(data));
    });

    channelTeam?.bind('LabelsEvent', (data) => {
      console.log('LabelsEvent', data);
      const { type, tag_name, id } = data;
      switch (type) {
        case 'add':
          dispatch(getLabels(id_team));
          break;
        case 'delete':
          dispatch(deleteLabel({ id }));
          break;
        case 'update':
          dispatch(updateTagNameLabelById({ id, tag_name }));
          break;
        default:
          break;
      }
    });

    channelTeam.bind('CompareGroupNameEvent', (data) => {
      console.log('CompareGroupNameEvent', data, { new: data?.Namegroupchange[0] });
      dispatch(compareConversation(data?.Namegroupchange[0]));
    });
    channelTeam.bind('InsertSectionEvent', (data) => {
      console.log('InsertSectionEvent', data);
      dispatch(setSections(data?.section));
    });

    channelTeam.bind('UpdateMessageStatusEvent', (data) => {
      console.log('UpdateMessageStatusEvent', data);
      if (data?.messages?.length > 0) {
        dispatch(
          UpdateMessageStatusEventAndCountMessagesNoRead({
            status: data?.messages[0]?.status,
            msg: data?.messages[0]
          })
        );
      }
    });

    channelTeam.bind('UpdateInfoParticipantEvent', (data) => {
      console.log('UpdateInfoParticipantEvent', data);
      dispatch(updateParticipant(data?.participants));
    });

    channelTeam.bind('UpdateAllMessageStatusEvent', (data) => {
      console.log('UpdateAllMessageStatusEvent : [channelCompany]', data?.id_conversation);
      dispatch(setAllMessagesReadByConverId(data?.id_conversation));
    });
  }
  return <PusherContext.Provider value={pusherInstance}>{children}</PusherContext.Provider>;
}

export default PusherProvider;
