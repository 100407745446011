import React, { memo, useEffect, useState } from 'react';
import { RadioGroup } from '@gluestack-ui/themed';
import ItemRadio from './ItemRadio';
import { TVView } from '../themed';

const RadiosGroup = ({ data, callbackOnChange = null, initialValue }) => {
  const [values, setValues] = useState(initialValue);

  useEffect(() => {
    setValues(initialValue);
  }, [initialValue]);

  const handleChanges = (value) => {
    setValues(value);
    callbackOnChange && callbackOnChange(value);
  };

  return (
    <RadioGroup value={values} onChange={handleChanges}>
      <TVView style={{ gap: 8 }}>
        {data?.map((value) => (
          <ItemRadio key={value?.id} label={value?.label} value={value?.value} />
        ))}
      </TVView>
    </RadioGroup>
  );
};

export default memo(RadiosGroup);
