/**
 * Learn more about light and dark modes:
 * https://docs.expo.dev/guides/color-schemes/
 */

import { ThemeMode } from '@/types/theme';
import colors from '../../config/colors';
import { RootState, useAppSelector } from '@/redux/store';

export type colorsProps = keyof typeof colors.light & keyof typeof colors.dark;

export function useThemeColor(props: { light?: string; dark?: string }, colorName: colorsProps) {
  const themeMode: ThemeMode = useAppSelector((state: RootState) => state.settings.themeMode);

  const theme = themeMode ?? 'light';
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return colors[theme][colorName];
  }
}
