import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import MenuActions from '@/components/menu/MenuActions';
import { IconTemplate, MessageBadge } from '@/assets/icons/Icons';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store/index';
import { setCustomPropietyOfWorkflow } from '@/redux/actionTypes/index';
import { ActionsParametersResponseTypes, TypeActions } from '@/types/enums/workflows';
import translate from '@/helpers/traslations';
import { Action } from '@/components/automatizations/interface.d';
import { Linking } from 'react-native';
import { CREATE_TEMPLATE_PMS } from '@/utils/constants';
import { TText, TView } from '@/components/themed';

const MenuTemplate = ({ idString }: { idString: string }) => {
  const dispatch = useAppDispatch();
  const newWorkflow = useAppSelector((state: RootState) => state.automatization.newWorkflows);

  const currentId = useMemo(() => {
    return Object.keys(newWorkflow)?.length ? newWorkflow?.actions[0]?.parameters?.id : '';
  }, [newWorkflow]);

  const [actions, setActions] = useState<Action[]>([
    {
      type: TypeActions.sendResponse,
      parameters: {
        response_type: ActionsParametersResponseTypes.template,
        id: currentId
      }
    }
  ]);

  const workflowTemplates = useAppSelector(
    (state: RootState) => state.templates?.workflowTemplates
  );

  const onChangeTemplate = useCallback(() => {
    dispatch(
      setCustomPropietyOfWorkflow({
        idString,
        value: actions,
        propiety: 'actions'
      })
    );
  }, [actions]);

  useEffect(() => {
    onChangeTemplate();
  }, [actions]);

  const menuActions = useMemo(() => {
    if (workflowTemplates[idString] && workflowTemplates[idString]?.length > 0) {
      return workflowTemplates[idString]?.map((template) => {
        return {
          label: template?.nombre,
          key: template?.IDMensajes,
          icon: MessageBadge,
          available: true,
          action: (action) =>
            setActions([
              {
                type: TypeActions.sendResponse,
                parameters: {
                  response_type: ActionsParametersResponseTypes.template,
                  id: action.key
                }
              }
            ])
        };
      });
    } else {
      return [
        {
          label: 'Crear platilla',
          key: 'create',
          icon: IconTemplate,
          available: true,
          action: () => Linking.openURL(CREATE_TEMPLATE_PMS)
        }
      ];
    }
  }, [workflowTemplates]);

  return (
    <TView>
      <MenuActions
        trigger={
          actions[0]?.parameters?.id ? (
            <TText color="text">
              {
                workflowTemplates[idString]?.find(
                  (template) => template?.IDMensajes == actions[0]?.parameters?.id
                )?.nombre
              }
            </TText>
          ) : (
            <TText color="secondary">{translate('templates.selectTemplate')}</TText>
          )
        }
        menuActions={menuActions}
        traslations={false}
        sx={{
          width: '100%',
          borderRadius: 8,
          borderWidth: 1,
          padding: 4,
          height: 42,
          justifyContent: 'center',
          backgroundColor: actions[0]?.parameters?.id ? '$backgroundActive' : '$backgroundSecondary'
        }}
      />
    </TView>
  );
};

export default memo(MenuTemplate);
