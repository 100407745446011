import React, { memo } from 'react';
import { DatePickerModal } from 'react-native-paper-dates';
import moment, { locale } from 'moment';

const currentLocale = locale();

const DateModals = ({
  conditonDate,
  openStartDate,
  setOpenStartDate,
  setConditonDate,
  openEndDate,
  setOpenEndDate
}) => {
  return (
    <>
      <DatePickerModal
        locale={currentLocale}
        mode="single"
        date={conditonDate?.value[0] ? new Date(conditonDate?.value[0]) : new Date()}
        visible={openStartDate}
        onDismiss={() => setOpenStartDate(false)}
        validRange={{ startDate: moment().toDate(), endDate: undefined }}
        onChange={({ date }) => {
          setOpenStartDate(false);
          setConditonDate({
            ...conditonDate,
            value: [moment(date).startOf('day').format(), conditonDate?.value[1]]
          });
        }}
        onConfirm={({ date }) => {
          console.log('onChange', date);
          setOpenStartDate(false);
          setConditonDate({
            ...conditonDate,
            value: [moment(date).startOf('day').format(), conditonDate?.value[1]]
          });
        }}
      />

      <DatePickerModal
        locale={currentLocale}
        mode="single"
        date={conditonDate?.value[1] ? new Date(conditonDate?.value[1]) : new Date()}
        visible={openEndDate}
        onDismiss={() => setOpenEndDate(false)}
        validRange={{ startDate: new Date(conditonDate?.value[0]), endDate: undefined }}
        onChange={({ date }) => {
          setOpenEndDate(false);
          setConditonDate({
            ...conditonDate,
            value: [conditonDate?.value[0], moment(date).startOf('day').format()]
          });
        }}
        onConfirm={({ date }) => {
          setOpenEndDate(false);
          setConditonDate({
            ...conditonDate,
            value: [conditonDate?.value[0], moment(date).startOf('day').format()]
          });
        }}
      />
    </>
  );
};

export default memo(DateModals);
