import React from 'react';
import { InputSlot } from '@gluestack-ui/themed';
import MainCta from '@/atoms/MainCta';
import { IconIdea, IconSend, Trash } from '@/assets/icons/Icons';
import { InputSlotRightProps } from './interface';
import { Routes } from '@/routers/routes';
import { useCollapsed } from '@/providers/CollpasedProvider';
import { navigation } from '@/routers/router';
import { IS_WEB_PC } from '@/utils/functions';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store/index';
import { setMenuRigth } from '@/redux/slices/theme';

const InputSlotRight = ({
  value,
  iconSend,
  onPressIconSend,
  onPressIconTrash,
  iconIdea = false,
  setDataBc
}: InputSlotRightProps) => {
  const dispatch = useAppDispatch();
  const uploadFile = useAppSelector((state: RootState) => state.messages.uploadFile);
  const { setCollapsed } = useCollapsed();

  const handlePressIdea = () => {
    setDataBc({ answer: value, question: '' });
    if (IS_WEB_PC) {
      dispatch(setMenuRigth(true));
      setCollapsed(true);
      setTimeout(() => {
        navigation(Routes.Embedding);
      }, 100);
    } else {
      navigation(Routes.Embedding);
    }
  };

  return (
    <InputSlot flexDirection="row" gap="$1" paddingRight={4}>
      {value?.length > 0 && (
        <MainCta onPress={onPressIconTrash} icon={Trash} variant="outline" iconSize="md" />
      )}
      {iconIdea && (
        <MainCta onPress={handlePressIdea} icon={IconIdea} variant="outline" iconSize="md" />
      )}
      {iconSend && (
        <MainCta
          sx={{ backgroundColor: value?.length > 0 ? '$pxsol' : 'transparent' }}
          onPress={onPressIconSend}
          icon={IconSend}
          disabled={uploadFile}
          loading={uploadFile}
          variant="outline"
          iconSize="md"
        />
      )}
    </InputSlot>
  );
};

export default React.memo(InputSlotRight);
