import React, { useMemo } from 'react';
import { ChevronDown, IconBot, IconTemplates } from '@/assets/icons/Icons';

import MainCta from '@/atoms/MainCta';
import { navigation } from '@/routers/router';
import { IS_WEB_PC } from '@/utils/functions';
import { Routes } from '@/routers/routes';
import MenuActions from '@/components/menu/MenuActions';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store/index';
import {
  ActionsParametersResponseTypes,
  ConditionsWorkflow,
  PropertyConditions,
  TriggerWorkflow,
  TypeActions,
  TypeConditions
} from '@/types/enums/workflows';
import { setCreatingWorkflow } from '@/redux/actionTypes/index';
import { setDatesRanges } from '@/redux/slices/automatization/index';
import { setMenuRigth } from '@/redux/slices/theme';
import { ContractsEnum } from '@/types/enums/conversation';

const NewAutomatization = (props) => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state: RootState) => state.user?.company?.IDEmpresas);
  const activeChannels = useAppSelector((state: RootState) => state.user?.activeChannels);
  const contracts = useAppSelector((state: RootState) => state.user?.contracts);
  const isPxsol = useAppSelector((state: RootState) => state.user?.user?.isPxsol);
  const myWorkflow = useMemo(() => {
    return {
      company_id: companyId,
      name: '',
      description: '',
      trigger: TriggerWorkflow.new_incoming_message,
      priority: 1,
      object_type: 'message',
      enabled: false,
      conditions: [],
      actions: [],
      team_id: Number(props?.route?.params?.team)
    };
  }, [companyId, props?.route?.params?.team]);

  const openTemplate = () => {
    const templateWorkflow = {
      ...myWorkflow,
      actions: [
        {
          type: TypeActions.sendResponse,
          parameters: {
            response_type: ActionsParametersResponseTypes.template,
            id: ''
          }
        }
      ]
    };
    dispatch(setCreatingWorkflow(templateWorkflow));
    dispatch(setDatesRanges([]));
    if (IS_WEB_PC) {
      dispatch(setMenuRigth(true));
      setTimeout(() => {
        props?.navigation.navigate(Routes?.AutomatizationTemplate);
      }, 100);
    } else {
      props?.navigation.navigate(Routes.AutomatizationTemplate);
    }
  };

  const openBot = () => {
    const botWorkflow = {
      ...myWorkflow,
      conditions: [
        {
          type: TypeConditions.objectProperty,
          property: PropertyConditions.apolloAutoResponseEnabled,
          condition: ConditionsWorkflow.eq,
          value: true
        }
      ],
      actions: [
        {
          type: TypeActions.sendResponse,
          parameters: {
            response_type: ActionsParametersResponseTypes.apollo,
            id: ''
          }
        }
      ]
    };
    dispatch(setCreatingWorkflow(botWorkflow));
    dispatch(setDatesRanges([]));

    if (IS_WEB_PC) {
      dispatch(setMenuRigth(true));
      setTimeout(() => {
        navigation(Routes?.AutomatizationBot);
      }, 100);
    } else {
      navigation(Routes?.AutomatizationBot);
    }
  };

  const menuActions = useMemo(
    () => [
      {
        label: 'actions.sendOfTemplates',
        icon: IconTemplates,
        action: () => openTemplate(),
        key: 'sendOfTemplates',
        available: true
      },
      {
        label: 'actions.automaticResponse',
        icon: IconBot,
        action: () => openBot(),
        key: 'automaticResponse',
        available: contracts?.includes(ContractsEnum?.chat) || isPxsol
      }
    ],
    [contracts]
  );

  return activeChannels ? (
    <MenuActions
      menuActions={menuActions}
      disabled={
        !activeChannels[props?.route?.params?.team] ||
        activeChannels[props?.route?.params?.team]?.length === 0
      }
      trigger={
        <MainCta
          labelId="actions.newAutomatization"
          icon={ChevronDown}
          disabled={
            !activeChannels[props?.route?.params?.team] ||
            activeChannels[props?.route?.params?.team]?.length === 0
          }
          iconPosition="left"
          iconSize="sm"
          buttonSize="xs"
          sx={{ paddingVertical: 0, pointerEvents: 'none', width: '$full' }}
        />
      }
    />
  ) : (
    <MainCta
      labelId="actions.newAutomatization"
      icon={ChevronDown}
      disabled={true}
      iconPosition="left"
      action="secondary"
      iconSize="sm"
      buttonSize="xs"
      sx={{ paddingVertical: 0, pointerEvents: 'none', width: '$full' }}
    />
  );
};

export default React.memo(NewAutomatization);
