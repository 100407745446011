import { StyleSheet, View } from 'react-native';
import React, { useMemo, useState } from 'react';
import translate from '@/helpers/traslations';
import { useAppDispatch, useAppSelector, RootState } from '@/redux/store';

import { createConversation } from '@/redux/slices/conversation';
import { cleanPhoneNumber } from '@/utils/functions';
import { ALL } from '@/utils/filters';
import { HStack, Pressable, Spinner, Text } from '@gluestack-ui/themed';
import TextInfo from './TextInfo';
import MaterialCommunity from '../components/Icons/MaterialCommunity';
import { CellPhone } from '@/assets/icons/Icons';
import { ViewPhonesCardProps } from './interfaces';
import { navigateToConversation } from '@/providers/LinkProvider';
import {
  setModaNewChat,
  setPopoverChannel,
  setSelectedChannelToInitConversation
} from '@/redux/slices/theme';
import { PlatformsEnum } from '@/types/enums/conversation';

import { outConversationToFilter, setIdConversation } from '@/redux/actionTypes';

function ViewPhonesContactCard({ user, team, showToast }: ViewPhonesCardProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const id_empresa = useAppSelector((state: RootState) => state.user.company.IDEmpresas);
  const selectedChannel = useAppSelector(
    (state: RootState) => state.settings.selectedChannelToInitConversation
  );

  const handleCreateConversation = (phone, event) => {
    const numberPhone = cleanPhoneNumber(phone?.phone);
    setLoading({ ...loading, [phone?.phone]: true });
    event.preventDefault(); // Detener la propagación del evento,

    dispatch(
      createConversation(ALL, {
        status: 'new',
        participants: [
          {
            idContactPms: user?.id,
            numberPhone
          }
        ],
        company_id: `${id_empresa}`,
        team_id: team,
        platform: PlatformsEnum.whatsapp,
        getMessages: false,
        group: {
          status: false,
          name: '',
          avatar: ''
        },
        id_channel: selectedChannel[0]
      })
    )
      .then((res: unknown) => {
        console.log('createConversation', res);
        setLoading({ ...loading, [phone?.phone]: false });
        dispatch(setIdConversation(res as string));
        dispatch(setSelectedChannelToInitConversation([]));
        navigateToConversation({
          idConver: res as string,
          platform: PlatformsEnum.whatsapp,
          filter: ALL
        });
        dispatch(setModaNewChat(false));
      })
      .catch((err) => {
        console.log('err createConversation', err);
        showToast({
          title: translate('error'),
          subtitle: err?.message || err?.msg || translate('messageError.noCreateConversation'),
          variant: 'accent',
          type: 'error'
        });
        setLoading({ ...loading, [phone?.phone]: false });
      });
  };
  const handleSelectContact = (phone, event) => {
    if (selectedChannel?.length === 0 || !selectedChannel) {
      dispatch(setPopoverChannel(true));
      return;
    }
    handleCreateConversation(phone, event);
  };

  return (
    <View
      style={{
        width: '100%'
      }}
    >
      {user?.phones?.length > 0 ? (
        user?.phones?.map((phone) => (
          <Pressable
            onPress={(event) => handleSelectContact(phone, event)}
            style={styles.container}
            key={phone?.id}
            disabled={loading[phone?.phone]}
            sx={{
              ':hover': {
                backgroundColor: '$backgroundActive'
              }
            }}
          >
            <HStack
              space="sm"
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <HStack
                space="sm"
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 5
                }}
              >
                <MaterialCommunity size="sm" icon={CellPhone} />
                <Text key={phone?.phone}>{`${translate(`sendMessageTo`)} ${phone?.phone}`}</Text>
              </HStack>
              {loading[phone?.phone] && <Spinner size="small" />}
            </HStack>
          </Pressable>
        ))
      ) : (
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: 16
          }}
        >
          <TextInfo text={translate('noPhones')} />
        </View>
      )}
    </View>
  );
}

export default React.memo(ViewPhonesContactCard);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 5,
    paddingHorizontal: 16,
    paddingVertical: 8
  }
});
