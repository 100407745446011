import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, configureStore } from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';
import { Conversation } from '../../types/conversation';
import { Message } from '../../types/message';
import Constants from 'expo-constants';
//@ts-ignore
import persistedReducer from '../rootReducers/persist';

const { Node_env } = Constants.expoConfig.extra;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST']
      }
    });

    if (Node_env === 'development') {
      middleware.push(logger);
    }

    return middleware;
  },
  devTools: Node_env !== 'production'
});
const state = store.getState;
export type RootState = ReturnType<typeof state>;
export type AppDispatch = ThunkDispatch<RootState, unknown, Action>;
export const getReduxStore = store.getState;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type Thunk = ThunkAction<
  Promise<unknown | Conversation | Message>,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);
export default store;
