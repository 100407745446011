import React, { useState, useEffect, useMemo, memo } from 'react';
import { StyleSheet, Image, Dimensions } from 'react-native';
import { useAuth0 } from '@auth0/auth0-react';
import { Center, HStack, Spinner, Text, VStack, View } from '@gluestack-ui/themed';

import translate from '../helpers/traslations';
import { saveTokenAuth0, setUserlaoding } from '../redux/actionTypes';
import { firstCharge } from '../redux/slices/auth';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import MainCta from '@/atoms/MainCta';
import { SignIn } from '@/assets/icons/Icons';
import { navigation } from '@/routers/router';
import { Routes } from '@/routers/routes';

import { SUPORT_PHONE } from '@/utils/constants';

function Login() {
  const dispatch = useAppDispatch();
  const msgError = useAppSelector((state: RootState) => state.user.msgError);
  const userLoading = useAppSelector((state: RootState) => state.user.userLoading);

  const [errorMessage, setError] = useState('');

  const { loginWithPopup, getIdTokenClaims, isLoading, isAuthenticated, logout, error } =
    useAuth0();

  const objectError = {
    title: 'Error de inicio de sesión',
    message: translate('retryLogin'),
    getFormattedMessage: function (whatsapp, errorCode) {
      return this.message
        .replace('WHATSAPP_DE_SOPORTE', `${whatsapp}`)
        .replace('MENSAJE_DE_ERROR_ORIGINAL', errorCode);
    }
  };

  useMemo(() => {
    if (error) {
      dispatch(setUserlaoding(false));
      const errors = objectError.getFormattedMessage(SUPORT_PHONE, error.toString());
      setError(errors);
      return;
    }
    if (msgError) {
      const errors = objectError.getFormattedMessage(SUPORT_PHONE, msgError);
      setError(errors);
      return msgError;
    }
  }, [error, msgError]);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchIdToken = async () => {
        try {
          const res = await getIdTokenClaims();
          const idToken = res?.__raw;
          idToken && dispatch(saveTokenAuth0({ idToken }));
          dispatch(firstCharge())
            .then((res) => {
              console.log('getUser --LoginWeb---', res);
              dispatch(setUserlaoding(false));
            })
            .catch((error) => {
              console.error('getUser --LoginWeb---', error);
              dispatch(setUserlaoding(false));
            });
        } catch (e) {
          console.error('Error fetching ID token', e);
          throw e;
        }
      };

      fetchIdToken()
        .then((e) => {
          console.log('fetchIdToken --LoginWeb---', e);
          dispatch(setUserlaoding(false));
        })
        .catch((e) => {
          dispatch(setUserlaoding(false));
          console.error('Error fetching ID token', e);
          throw e;
        });
    }
    return () => {
      setError('');
    };
  }, [isAuthenticated]);

  const handleLogin = async () => {
    setError('');
    dispatch(setUserlaoding(true));
    try {
      const login = await loginWithPopup();
      if (login !== undefined) {
        navigation(Routes.Team);
      } else {
        dispatch(setUserlaoding(false));
      }
    } catch (e: any) {
      console.log('error --loginWithPopup--', e);
      dispatch(setUserlaoding(false));
      const errors = objectError.getFormattedMessage(SUPORT_PHONE, e?.message?.toString());
      setError(errors);
      throw e;
    }
  };

  const onPress = async () => {
    await handleLogin();
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      height: '100%'
    },
    card: {
      flex: 1,
      width: 500,
      maxWidth: '100%',
      boxSizing: 'inherit',
      borderColor: 'transparent',
      borderWidth: 0,
      borderStyle: 'solid',
      borderRadius: 20,
      paddingVertical: 20,
      paddingHorizontal: 40
    },
    logoContainer: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    logo: {
      maxWidth: Dimensions.get('window').width - 100,
      height: 400,
      width: 400
    },
    formContainer: {
      gap: 10,
      flex: 1
    }
  });

  return (
    <VStack
      bg="$backgroundSecondary"
      justifyContent="center"
      alignItems="center"
      style={styles.container}
    >
      <View style={styles.card}>
        <View style={styles.logoContainer}>
          <Image
            alt="logo"
            source={require('../assets/images/intro_main-min.png')}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <Text
          size="sm"
          style={{
            color: '$text',
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: 10,
            marginTop: 15
          }}
        >
          {translate('messages.welcome')}
        </Text>
        <Text
          size="sm"
          style={{
            color: '$text',
            textAlign: 'center'
          }}
        >
          {translate('messages.slogan')}
        </Text>
        <Center style={styles.formContainer}>
          {userLoading && (
            <HStack alignItems="center" gap={10}>
              <Spinner color="$pxsol" />
              <Text fontSize={12}>{translate('userData')}</Text>
            </HStack>
          )}

          {errorMessage && (
            <VStack alignItems="center">
              <Text size="sm" color="$pxsol" style={{ textAlign: 'center' }}>
                {errorMessage}
              </Text>
            </VStack>
          )}
          <MainCta
            labelId="actions.login"
            onPress={onPress}
            icon={SignIn}
            iconPosition="left"
            // disabled={userLoading}
            // loading={userLoading}
          />
        </Center>
      </View>
    </VStack>
  );
}

export default memo(Login);
