import getIconsToFilters from '@/assets/icons';
import { PreviewFilesInterface } from '@/components/conversation/footer/interfaces.d';
import { platforms } from '@/redux/slices/filters/interfaces';
import { PlatformsEnum } from '@/types/enums/conversation';
import { Channel } from '@/types/typesRedux/interfaces';
import { Audio } from 'expo-av';

export async function pasteImageFromClipboard(): Promise<PreviewFilesInterface> | null {
  const previewFile = {
    mimeType: '',
    uri: '',
    file: null,
    base64: '',
    error: false,
    temp: true
  };

  try {
    if (!navigator.clipboard) {
      console.log('Clipboard API not available');
      previewFile.error = true;
      return null;
    }

    const clipboardItems = await navigator.clipboard.read();
    for (const clipboardItem of clipboardItems) {
      for (const type of clipboardItem.types) {
        const blob = await clipboardItem.getType(type);
        if (blob && (blob.type === 'image/png' || blob.type === 'image/jpeg')) {
          previewFile.mimeType = blob.type;
          previewFile.uri = URL.createObjectURL(blob);
          previewFile.file = blob;

          // Optionally convert the Blob to Base64 if required
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            previewFile.base64 = reader.result.toString();
          };

          return previewFile;
        }
      }
    }
  } catch (error) {
    console.log('Failed to read from clipboard', error);
    previewFile.error = true;
  }

  return null;
}

export async function clearAllDataOfClipboard() {
  try {
    await navigator.clipboard.writeText('');
  } catch (error) {
    console.log('Error clearing clipboard:', error);
  }
}

export async function getClipboardPermission() {
  try {
    const result = await navigator.permissions.query({ name: 'push' });
    return result.state === 'granted';
  } catch (error) {
    console.log('Error requesting clipboard permission:', error);
    return false;
  }
}

// Función para obtener el color de fondo según el nombre del canal
export const getBgColor = (name) => {
  switch (name.toLowerCase()) {
    case 'whatsapp':
      return '#075E54';
    case 'instagram':
      return '#E1306C';
    case 'facebook':
      return '#1877F2';
    case 'widget':
      return '#00BFA5';
    default:
      return '#000'; // Color por defecto si no se encuentra el canal
  }
};

// Función para transformar y filtrar las plataformas según su disponibilidad
export const getPlatforms = (channels: Channel[]) => {
  return channels.map((channel) => {
    const name = channel.name.charAt(0).toUpperCase() + channel.name.slice(1);
    return {
      name: name,
      icon: getIconsToFilters({ idString: channel.name }),
      bgColor: getBgColor(channel.name),
      ctaLabel: channel.name === platforms?.widget ? 'actions.connectFree' : 'actions.talkToSales', // Aquí puedes personalizar más según el canal
      url: 'https://share.hsforms.com/19QZIMP4VRjiw_7FeK3pAiA4ekty', // Aquí puedes personalizar más según el canal
      eventLabel: `connect${name}NumberFromDialog`
    };
  });
};

export const playAudio = async () => {
  const { sound } = await Audio.Sound.createAsync(require('@/assets/sounds/noti.mp3'));
  try {
    await sound.playAsync();
  } catch (error) {
    console.log('Error playing sound:', error);
  }
};
