import { platforms } from '@/redux/slices/filters/interfaces';
import translate from '@/helpers/traslations';

export const getTitleChannelItem = (key: platforms): string => {
  console.log({ key });
  switch (key) {
    case platforms.whatssap:
      return `Mejora con WhatsApp `;
    case platforms.facebook:
      return `Optimiza con ${key?.charAt(0).toUpperCase() + key?.slice(1)}`;
    case platforms.instagram:
      return `Potencia con ${key?.charAt(0).toUpperCase() + key?.slice(1)}`;
    case platforms.widget:
      return `Transforma con tu ${key?.charAt(0).toUpperCase() + key?.slice(1)}`;
    default:
      return `Mejora ${key?.charAt(0).toUpperCase() + key?.slice(1)}`;
  }
};

export const getDescriptionChannelItem = (key: platforms): string => {
  switch (key) {
    case platforms.whatssap:
      return `Integra tu cuenta de WhatsApp para atender a tus huéspedes de manera rápida y eficiente`;
    case platforms.facebook:
      return `Gestiona tus mensajes y comentarios en Facebook para ofrecer una atención personalizada a tus clientes.`;
    case platforms.instagram:
      return `Responde mensajes directos y comentarios en tus publicaciones directamente desde nuestra aplicación.`;
    case platforms.widget:
      return `Automatiza respuestas y brinda asistencia inmediata con nuestro avanzado Chat Bot IA.`;
    default:
      return `Integra tu cuenta de ${key} para atender a tus huéspedes de manera rápida y eficiente`;
  }
};

export const getLabelCtaChannelItem = (key: platforms): string => {
  switch (key) {
    case platforms.whatssap:
      return translate(`actions.connect`);
    case platforms.facebook:
      return translate(`actions.configure`);

    case platforms.instagram:
      return translate(`actions.link`);
    case platforms.widget:
      return translate(`actions.activate`);
    default:
      return translate(`actions.connect`);
  }
};
