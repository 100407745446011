import React, { memo, useEffect, useState } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { filtersBase } from '@/utils/filters';
import CustomTabBar from '@/components/tabs/CustomTabBar';
import { Filters } from '@/redux/slices/filters/interfaces';
import colors from '../../../config/colors';
import { RootState, useAppSelector } from '@/redux/store';
import translate from '@/helpers/traslations';
import LayoutListConversation from '../LayoutListConversation';
import { getClientsFilters } from '@/utils/functions';

const Tab = createBottomTabNavigator();

const ClientStackMobile = (routeClient) => {
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);
  const filters = useAppSelector((state: RootState) => state.filters.filters);

  const [filterByName, setFiltersByName] = useState<Filters[]>([]);
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    if (routeClient?.route?.name) {
      const filterToGetClient = {
        assignee: filters?.assignee,
        labels: filters?.labels
      };
      const result = getClientsFilters(filterToGetClient, routeClient?.route?.name);

      setFiltersByName(result);
    }
  }, [filters, routeClient?.route?.name]);

  return (
    <Tab.Navigator
      tabBar={(props) => (
        <CustomTabBar
          {...props}
          filterByName={filterByName}
          viewMore={viewMore}
          setViewMore={setViewMore}
          routeName={routeClient?.route?.name}
          team={routeClient?.route?.params?.team}
        />
      )}
      screenOptions={{
        headerShown: false,
        tabBarActiveBackgroundColor: colors[themeMode]?.backgroundSecondary
      }}
    >
      {filtersBase?.map((filter) => {
        if (filter?.types?.includes(routeClient?.route?.name) && filter?.idString !== 'searchs') {
          return (
            <Tab.Screen
              key={filter?.id}
              name={filter?.idString?.toLowerCase()}
              component={LayoutListConversation}
              initialParams={{
                filter: filter?.idString,
                team: routeClient?.route?.params?.team
              }}
              options={{
                title: filter?.name,
                tabBarIcon: () => null,
                tabBarLabel: filter?.name,
                tabBarBadge: 10,
                tabBarBadgeStyle: {
                  position: 'absolute',
                  top: -5,
                  right: -20,
                  color: colors[themeMode]?.pxsol
                },
                tabBarStyle: {
                  flex: 1
                }
              }}
            />
          );
        }
        return null;
      })}
      {viewMore &&
        filters?.labels?.map((label: Filters) => (
          <Tab.Screen
            key={label?.id}
            name={label?.id?.toString()}
            component={LayoutListConversation}
            initialParams={{
              filter: label?.id?.toString(),
              team: routeClient?.route?.params?.team
            }}
            options={{
              title: label?.id === 136 ? translate(label?.name) : label?.name,
              tabBarIcon: () => null,
              tabBarLabel: label?.id === 136 ? translate(label?.name) : label?.name,
              tabBarBadge: 10,
              tabBarBadgeStyle: {
                position: 'absolute',
                top: -5,
                right: -20,
                color: colors[themeMode]?.pxsol
              },
              tabBarStyle: {
                flex: 1
              },
              tabBarLabelStyle: {
                textTransform: 'capitalize'
              }
            }}
          />
        ))}
    </Tab.Navigator>
  );
};

export default memo(ClientStackMobile, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
