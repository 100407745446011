import React, { memo, useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import AcordionCustom from '@/atoms/AcordionCustom';
import { TView } from '@/components/themed';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import ItemDrawerSettings from '@/atoms/ItemDrawerSettings';
import getIconsToFilters from '@/assets/icons';
import { Filters } from '@/redux/slices/filters/interfaces';
import { getCurrentFilterByState } from '@/utils/functions';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { Channel } from '@/types/typesRedux/interfaces';
import { getAllConversationsByFilter } from '@/redux/slices/conversation';
import { setDialogChannels } from '@/redux/slices/theme';
import { BadgeCheck, IconFilterClosed } from '@/assets/icons/Icons';
import CustomSkeleton from '@/components/CustomSckeleton';
const skeleton = 5;

const ContainerChannelsFilters = ({
  collapsed,
  activeRoute,
  teamId
}: {
  collapsed: boolean;
  activeRoute: string;
  teamId: string;
}) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state: RootState) => state.filters.filters);
  const channels = useAppSelector((state: RootState) => state.user?.channels);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        childrenAcordion: {
          minWidth: 150,
          flexGrow: 1,
          width: '100%'
        }
      }),
    []
  );

  const handlePressChannel = useCallback(
    (channel: Channel) => {
      const myFilter: Filters = getCurrentFilterByState(filters, activeRoute);
      if (channel?.available) {
        myFilter?.idString !== 'searchs' &&
          getAllConversationsByFilter({
            query: {
              ...myFilter?.query,
              page: 1,
              id_team: Number(teamId),
              conversation: {
                ...myFilter?.query?.conversation,
                platform: [channel?.name]
              }
            },
            filter: myFilter?.idString,
            type: myFilter?.types,
            teamId: teamId
          });
      } else {
        hanldeOpenDialog(channel);
      }
    },
    [filters, activeRoute, teamId]
  );

  const hanldeOpenDialog = useCallback((channel: Channel) => {
    dispatch(
      setDialogChannels({
        status: true,
        data: { name: channel.name, available: channel?.available, soon: !channel?.soon }
      })
    );
  }, []);

  return (
    <AcordionCustom collapsed={collapsed} idString="channels" defaultOpen>
      <TView bgColor="transparent" style={styles.childrenAcordion}>
        {channels?.length > 0
          ? channels?.map((item) => (
              <ItemDrawerSettings
                key={item?.id}
                onPress={() => handlePressChannel(item)}
                style={{ routeName: item?.idString, activeRoute }}
                idString={item?.name}
                icon={getIconsToFilters({ idString: item?.icon })}
                translate={false}
                name={item?.name}
                collapsed={collapsed}
                color={item?.available ? 'text' : 'secondary'}
                iconRight={
                  <MaterialCommunity
                    size="sm"
                    icon={item?.available ? BadgeCheck : IconFilterClosed}
                    color={item?.available ? '$text' : '$disabled'}
                  />
                }
              />
            ))
          : Array(skeleton)
              .fill(0)
              .map(
                (item, index) =>
                  index < 2 && (
                    <CustomSkeleton
                      key={index}
                      type="rectangle"
                      height={25}
                      width={170}
                      background="$secondary"
                    />
                  )
              )}
      </TView>
    </AcordionCustom>
  );
};

export default memo(ContainerChannelsFilters, (prevProps, nextProps) => {
  return (
    prevProps.collapsed === nextProps.collapsed &&
    prevProps.activeRoute === nextProps.activeRoute &&
    prevProps.teamId === nextProps.teamId
  );
});
