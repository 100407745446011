import React from 'react';
import { Platform } from 'react-native';
import { Auth0Provider as WebAuth0Provider } from '@auth0/auth0-react';
import { Auth0Provider as NativeAuth0Provider } from 'react-native-auth0';
import Constants from 'expo-constants';

function Auth0Provider({ children }) {
  const { auth0ClientId, auth0Domain } = Constants.expoConfig.extra;

  if (Platform.OS === 'web') {
    return (
      <WebAuth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        authorizationParams={{ redirect_uri: window?.location?.origin }}
        useRefreshTokens
      >
        {children}
      </WebAuth0Provider>
    );
  }
  return (
    <NativeAuth0Provider domain={auth0Domain} clientId={auth0ClientId}>
      {children}
    </NativeAuth0Provider>
  );
}

export default React.memo(Auth0Provider);
