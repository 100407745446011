import React, { memo } from 'react';
import { CircleIcon, Radio, RadioIcon, RadioIndicator, RadioLabel } from '@gluestack-ui/themed';

const ItemRadio = ({ label, value }) => {
  return (
    <Radio aria-label={label} aria-labelledby={label} value={value}>
      <RadioIndicator mr="$2">
        <RadioIcon as={CircleIcon} />
      </RadioIndicator>
      <RadioLabel>{label}</RadioLabel>
    </Radio>
  );
};

export default memo(ItemRadio);
