import CardCustom from '@/atoms/CardCustom';
import RenderBadges from '@/atoms/RenderBadges';
import translate from '@/helpers/traslations';
import { useLinkss } from '@/providers/LinkProvider';
import { IUpdateNotification } from '@/redux/slices/auth/interfaces.d';
import { updateNotification } from '@/redux/slices/auth/requests';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { IS_WEB_PC } from '@/utils/functions';
import { Text } from '@gluestack-ui/themed';
import { BadgeText } from '@gluestack-ui/themed';
import { Badge } from '@gluestack-ui/themed';
import { Switch } from '@gluestack-ui/themed';
import { HStack, View } from '@gluestack-ui/themed';
import React, { memo, useMemo } from 'react';

const ItemChannel = ({ channel, teamId }) => {
  const dispatch = useAppDispatch();
  const activeChannels = useAppSelector((state: RootState) => state.user?.activeChannels);
  const userSettings = useAppSelector((state: RootState) => state.user.user?.notifications_config);

  //   const itemValues = useMemo(() => , [activeChannels, channel]);
  const [updatingChannel, setUpdatingChannel] = React.useState<{ [key: string]: boolean }>({});

  const { showToast } = useLinkss();

  const disabledSwitch = useMemo(
    () => channel?.conection_status !== 'enable' || updatingChannel[channel?.id],
    [updatingChannel, channel]
  );
  const handleChangeStatus = (item) => {
    setUpdatingChannel({ ...updatingChannel, [item?.id]: true });
    dispatch(
      updateNotification({
        propertyNotification: IUpdateNotification.platforNotification,
        data: [item?.channel_id]
      })
    )
      .then((response) => {
        showToast({
          type: 'success',
          title: translate('success'),
          subtitle: translate('messages.successToUpdateStatus'),
          variant: 'accent'
        });
      })
      .catch((error) => {
        showToast({
          type: 'error',
          title: translate('error'),
          subtitle: translate('messageError.errorToUpdateStatus'),
          variant: 'accent'
        });
      })
      .finally(() => {
        setUpdatingChannel({ ...updatingChannel, [item?.id]: false });
      });
  };

  return (
    <CardCustom
      idString={channel?.channel_label}
      translateText={false}
      switchProp={{
        value: !userSettings?.channels_notification?.includes(channel?.channel_id),
        onChange: () => handleChangeStatus(channel),
        disabled: disabledSwitch
      }}
      middleElement={
        channel?.conection_status === 'enable' ? (
          <RenderBadges
            badges={[channel?.channel_id]}
            data={activeChannels[teamId] ?? []}
            findKey="channel_id"
            iconKey="channel_type"
            showKey="channel_data"
            secondShowKey="channel_type"
            size="2xs"
            numbers={1}
          />
        ) : (
          <Badge variant="solid" action="error" gap="$1">
            <BadgeText textTransform="capitalize" color="$text">
              canal no conectado
            </BadgeText>
          </Badge>
        )
      }
    />
  );
};

export default memo(ItemChannel);
