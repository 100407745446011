import React, { memo, useMemo } from 'react';

import RadiosGroup from '@/components/form/RadiosGroup';

const FirstMessageCheckbox = ({ setConditionMessage, conditionMessage }) => {
  const initialValue = useMemo(
    () => (conditionMessage?.value ? 'first_message' : 'any_message'),
    [conditionMessage]
  );
  console.log({ conditionMessage, initialValue });

  const data = [
    {
      id: 1,
      label: 'Primer mensaje entrante',
      value: 'first_message'
    },
    {
      id: 2,
      label: 'Cualquier mensaje entrante',
      value: 'any_message'
    }
  ];

  const callbackOnChange = (value) => {
    if (value === 'first_message') {
      setConditionMessage({
        ...conditionMessage,
        value: true
      });
    } else {
      setConditionMessage({
        ...conditionMessage,
        value: false
      });
    }
  };
  return (
    <RadiosGroup data={data} callbackOnChange={callbackOnChange} initialValue={initialValue} />
  );
};

export default memo(FirstMessageCheckbox);
