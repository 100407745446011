import {
  IconFilterClosed,
  IconFilterExpired,
  IconAssigneeToMe,
  IconNoAssignee,
  IconOpen,
  IconFilterAll,
  Group,
  Mangify,
  IconLabel,
  Star,
  Instagram,
  WhatsApp,
  Facebook,
  Widget,
  IconBot
} from './Icons';

// const Full = createIcon({

//   )
// });

export default function getIconsToFilters({ idString }) {
  switch (idString) {
    case 'account-remove':
      return IconNoAssignee;
    case 'contacts':
      return IconAssigneeToMe;
    case 'inbox-full':
      return IconFilterAll;
    case 'inbox':
      return IconOpen;
    case 'lock':
      return IconFilterClosed;
    case 'clock-alert':
      return IconFilterExpired;
    case 'account-group':
      return Group;
    case 'busqueda':
    case 'magnify':
      return Mangify;
    case 'inbox-full':
      return IconFilterAll;
    case 'labels':
      return IconLabel;
    case 'star':
      return Star;
    case 'facebook':
      return Facebook;
    case 'instagram':
      return Instagram;
    case 'whatsapp':
      return WhatsApp;
    case 'pxsol':
    case 'widget':
      return Widget;
    default:
      break;
  }
}
