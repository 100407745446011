import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import { TText } from '@/components/themed';

function TextInfo({ text }) {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        info: {
          textAlign: 'center',
          paddingVertical: 5,
          paddingHorizontal: 10
        }
      }),
    []
  );

  return (
    <TText
      lineBreakMode="middle"
      color="infoOpacity"
      style={{ ...styles.info, textAlign: 'left', fontStyle: 'italic' }}
    >
      {text}
    </TText>
  );
}

export default React.memo(TextInfo);
