import { View, Platform } from 'react-native';
import React, { useMemo } from 'react';
import { RootState, useAppSelector } from '@/redux/store';
import InfoConnection from './InfoConnection';
// import FlagWhatsapp from './FlagWhatsapp';
import NewVersion from './NewVersion';
// import { Channel } from '@/types/typesRedux/interfaces';
// import { verifyIsNotNativeDevice } from '@/utils/functions';

export default function HeaderInfo() {
  const connected = useAppSelector((state: RootState) => state.user.socket);
  const newVersion = useAppSelector((state: RootState) => state.settings.newVersion);
  // const channels = useAppSelector((state: RootState) => state.user.channels);

  // const channelsAvaliables = useMemo(
  //   () => channels?.filter((item: Channel) => !item?.available),
  //   [channels]
  // );

  return (
    <View
      style={{
        flexGrow: 1
      }}
    >
      {!connected && <InfoConnection message="messageError.noConnectSocket" />}
      {/* {channelsAvaliables?.length > 0 && verifyIsNotNativeDevice() && <FlagWhatsapp />} */}
      {Platform.OS === 'web' && newVersion && <NewVersion />}
    </View>
  );
}
