import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { HStack, Text } from '@gluestack-ui/themed';
import { InfoOutline } from '@/assets/icons/Icons';
import { Message } from '../types/message';
import MaterialCommunity from '../components/Icons/MaterialCommunity';

interface props {
  message: Message;
}

function LastMessageInfo({ message }: props) {
  const styles = StyleSheet.create({
    text: {
      overflow: 'hidden',
      fontStyle: 'italic'
    }
  });

  return (
    <HStack alignItems="center" justifyContent="flex-start">
      <MaterialCommunity icon={InfoOutline} color="$secondary" size="xs" />
      <Text color="$secondary" lineBreakMode="clip" numberOfLines={1} style={styles.text}>
        {message?.content?.text}
      </Text>
    </HStack>
  );
}

export default memo(LastMessageInfo);
