import { Platform, StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import translate from '@/helpers/traslations';
import { HStack, View, Text } from '@gluestack-ui/themed';
import { Group, Office, ShoppCar } from '@/assets/icons/Icons';
import { verifyIsMobile } from '@/utils/functions';
import MaterialCommunity from '../Icons/MaterialCommunity';

function ContainerCompany({ pos_name, team_name, product_name, direction }) {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          paddingVertical: 5,
          display: 'flex',
          flexDirection: direction,
          gap: 20
        },
        item: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 5
        },
        containerItems: {
          display: 'flex',
          flexDirection: 'column',
          gap: 5
        }
      }),
    [Platform.OS, verifyIsMobile, direction]
  );
  return (
    <View style={styles.container}>
      <View style={styles.containerItems}>
        <HStack space="sm">
          <MaterialCommunity icon={ShoppCar} size="sm" color="$gris" />
          <Text color="$gris" size="sm">
            {translate('pointofSale')}
          </Text>
        </HStack>
        <Text size="md">{pos_name}</Text>
      </View>
      <View style={styles.containerItems}>
        <View style={styles.item}>
          <MaterialCommunity icon={Group} size="sm" color="$gris" />
          <Text color="$gris" size="sm">
            {translate('team')}
          </Text>
        </View>
        <Text size="md">{team_name ?? 'Nombre de la empresa'}</Text>
      </View>

      <View style={styles.containerItems}>
        <View style={styles.item}>
          <MaterialCommunity icon={Office} size="sm" color="$gris" />
          <Text color="$gris" size="sm">
            {translate('establishment')}
          </Text>
        </View>
        <Text size="md">{product_name || 'Nombre del establecimiento'}</Text>
      </View>
    </View>
  );
}

export default React.memo(ContainerCompany);
