import { createSlice } from '@reduxjs/toolkit';

import {
  clearAllInfoContactos,
  setContacts,
  setIdContacto,
  setLoadingContactos,
  cleanContacts
} from '../../actionTypes';
import { Contacts } from '@/types/typesRedux/interfaces';
import axiosInstance from '@/utils/network/instanceAxios';

interface contactState {
  contacto: Contacts[];
  id: string;
  loadingContact: boolean;
  assigneeUsers: Contacts[];
}

const initialState: contactState = {
  contacto: [],
  id: '',
  loadingContact: false,
  assigneeUsers: []
};
const authSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setAssigneeUsers: (state, action) => {
      state.assigneeUsers = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setIdContacto, (state, action) => {
      state.id = action.payload;
    });
    builder.addCase(setLoadingContactos, (state, action) => {
      state.loadingContact = action.payload;
    });
    builder.addCase(clearAllInfoContactos, (state) => {
      state.contacto = [];
      state.id = '';
      state.loadingContact = false;
    });
    builder.addCase(setContacts, (state, action) => {
      state.contacto = action.payload;
      state.loadingContact = false;
    });
    builder.addCase(cleanContacts, (state) => {
      state.contacto = [];
      state.id = '';
      state.loadingContact = false;
      state.assigneeUsers = [];
    });
  }
});

export const { setAssigneeUsers } = authSlice.actions;

export default authSlice.reducer;
