// UpdateChecker.js

import { memo, useEffect } from 'react';

import { useAppDispatch } from '../../redux/store';
import { setVersion } from '../../redux/actionTypes';

function UpdateChecker() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const checkForUpdates = async () => {
      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.ready;
        if (registration.active) {
          const messageChannel = new MessageChannel();
          messageChannel.port1.onmessage = (event) => {
            console.log('serviceWorker event', event);
            if (event.data && event.data.type === 'UPDATE_READY') {
              // En lugar de guardar handleUpdate en Redux, simplemente actualizamos el estado para reflejar que hay una actualización disponible
              dispatch(setVersion(true));
            }
          };
          registration.active.postMessage({ type: 'CHECK_FOR_UPDATE' }, [messageChannel.port2]);
        }
      }
    };

    checkForUpdates();
  }, [dispatch]);

  return null;
}

export default memo(UpdateChecker);
