import { Text, HStack } from '@gluestack-ui/themed';
import React from 'react';
import translate from '@/helpers/traslations';

export default function InfoConnection({
  message,
  bg = '$pxsol',
  color = '$text'
}: {
  message: string;
  bg?: string;
  color?: string;
}) {
  return (
    <HStack
      bg={bg}
      justifyContent="space-between"
      style={{
        width: '100%',
        padding: 5
      }}
    >
      <Text color={color} style={{ textAlign: 'center' }}>
        {translate(message)}
      </Text>
    </HStack>
  );
}
