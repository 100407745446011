import React, { useMemo } from 'react';
import { Image } from 'expo-image';
import i18n from '@/providers/Localization';
import { Channel } from '@/types/typesRedux/interfaces';
import { RootState, useAppSelector } from '@/redux/store';
import { ThemedScrollView, THView, TText, TView, TVView } from '@/components/themed';
import ItemSettingChannel from '@/components/partials/settingsChannels/ItemSettingChannel';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { IconClose } from '@/assets/icons/Icons';
import { useWindowDimensions } from 'react-native';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { TABLET_SCREEN } from '@/utils/globales';

function Fallback() {
  const channels = useAppSelector((state: RootState) => state.user.channels);
  const contracts = useAppSelector((state: RootState) => state.user.contracts);
  const { width, height } = useWindowDimensions();

  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  const channelsAvaliables = useMemo(
    () => channels?.filter((item: Channel) => item?.available),
    [channels]
  );

  return (
    <THView
      style={{
        flex: 1,
        padding: 16,
        borderTopStartRadius: 12,
        justifyContent: 'center'
      }}
      bgColor="backgroundSecondary"
    >
      <TVView
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          width: width * 0.8,
          height: height * 0.8
        }}
      >
        <THView
          style={{
            justifyContent: isPc ? 'flex-start' : 'center',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            gap: 16
          }}
        >
          <TView
            style={{
              width: isPc ? 250 : 200,
              height: isPc ? 200 : 150
            }}
          >
            <Image
              alt="No data"
              source={require('@/assets/images/empty_upgrade.svg')}
              style={{
                width: isPc ? 250 : 200,
                height: isPc ? 200 : 150
                // maxWidth: 500,
                // maxHeight: 250
              }}
            />
          </TView>
          <TVView
            style={{
              gap: 8,
              flexBasis: isPc ? '50%' : '100%'
            }}
          >
            <TText
              style={{
                fontWeight: 'bold'
              }}
              size="lg"
            >
              {i18n.t('messages.youNotHavePermissionToAccess')}
            </TText>
            <TText>{i18n.t('messages.youNotHavePermissionToAccessDescription')}</TText>
          </TVView>
        </THView>
        <ThemedScrollView showsVerticalScrollIndicator={false}>
          {channelsAvaliables?.length === 0 && (
            <THView
              style={{
                alignItems: 'center'
              }}
            >
              <MaterialCommunity icon={IconClose} color="$primary" size="sm" />
              <TText
                style={{
                  textAlign: 'center'
                }}
                color="secondary"
                size="md"
              >
                {i18n.t('messages.yourTeamDonthaveChannelActive')}
              </TText>
            </THView>
          )}
          {!contracts?.includes('chat') && (
            <THView
              style={{
                alignItems: 'center'
              }}
            >
              <MaterialCommunity icon={IconClose} color="$primary" size="sm" />

              <TText
                style={{
                  textAlign: 'center'
                }}
                color="secondary"
                size="md"
              >
                {i18n.t('messages.yourTeamDonthaveChatContracts')}
              </TText>
            </THView>
          )}
          <TVView
            style={{
              gap: 10,
              borderRadius: 8,
              marginTop: 20,
              flexDirection: 'column-reverse'
            }}
          >
            {channels?.map((channel: Channel) => (
              <ItemSettingChannel key={channel.id} channel={channel} />
            ))}
          </TVView>
        </ThemedScrollView>
      </TVView>
    </THView>
  );
}

export default React.memo(Fallback);
