import { View, Text } from 'react-native';
import React, { memo } from 'react';
import { TimePickerModal } from 'react-native-paper-dates';
import moment, { locale } from 'moment';

const currentLocale = locale();

const TimeModals = ({
  openInitialTime,
  setOpenInitialTime,
  setConditonTime,
  conditonTime,
  openEndTime,
  setOpenEndTime
}) => {
  //   const [visible, setOpenInitialTime] = React.useState(false);
  const onDismissInitial = React.useCallback(() => {
    setOpenInitialTime(false);
  }, [openInitialTime]);

  const onDismiss = React.useCallback(() => {
    setOpenEndTime(false);
  }, [openEndTime]);

  const onConfirmInitial = React.useCallback(
    ({ hours, minutes }) => {
      const localTime = moment().hours(hours).minutes(minutes);
      const utcTime = localTime.utc().format('HH:mm');
      setOpenInitialTime(false);
      setConditonTime({
        ...conditonTime,
        value: [utcTime, conditonTime?.value[1]]
      });
    },
    [setOpenInitialTime, conditonTime]
  );

  const onConfirmEnd = React.useCallback(
    ({ hours, minutes }) => {
      const localTime = moment().hours(hours).minutes(minutes);
      const utcTime = localTime.utc().format('HH:mm');
      setOpenEndTime(false);
      setConditonTime({
        ...conditonTime,
        value: [conditonTime?.value[0], utcTime]
      });
    },
    [setOpenEndTime, conditonTime]
  );
  console.log({ currentLocale }, moment().format('LT'));

  return (
    <View>
      <TimePickerModal
        label="Initial time"
        locale={currentLocale}
        visible={openInitialTime}
        onDismiss={onDismissInitial}
        onConfirm={onConfirmInitial}
        use24HourClock
      />
      <TimePickerModal
        locale={currentLocale}
        visible={openEndTime}
        onDismiss={onDismiss}
        onConfirm={onConfirmEnd}
        use24HourClock
      />
    </View>
  );
};

export default memo(TimeModals);
