import React, { useMemo } from 'react';
import { HEIGHT_HEADER_LAYOUT } from '@/utils/globales';
import MainCta from '@/atoms/MainCta';
import { Group, Pencil } from '@/assets/icons/Icons';
import { GRUPOS } from '@/utils/filters';
import { THView } from '@/components/themed';
import { StyleSheet } from 'react-native';

const HeaderChat = ({ currentFilter, handleNewGroup, handleNewConver }) => {
  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        borderBottomWidth: 1,
        paddingHorizontal: 4,
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%'
      }
    });
  }, []);

  return (
    <THView
      border="border"
      style={{
        borderBottomWidth: 1,
        paddingHorizontal: 4,
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        marginBottom: 4,
        height: HEIGHT_HEADER_LAYOUT
      }}
    >
      {currentFilter?.toLowerCase() === GRUPOS ? (
        <MainCta
          onPress={handleNewGroup}
          icon={Group}
          iconPosition="left"
          labelId="newGroup"
          iconColor="$blanco"
          iconSize="sm"
          buttonSize="xs"
        />
      ) : (
        <MainCta
          onPress={handleNewConver}
          variant="solid"
          icon={Pencil}
          iconPosition="left"
          labelId="new"
          iconColor="$blanco"
          iconSize="sm"
          buttonSize="xs"
        />
      )}
    </THView>
  );
};

export default React.memo(HeaderChat);
