import React, { useEffect, useMemo, useState } from 'react';
import ContainerBadges from '@/components/partials/conversationCard.tsx/ContainerBadges';
import OriginMessage from '@/components/partials/conversationCard.tsx/OriginMessage';
import { THView, TText } from '@/components/themed';
import i18n from '@/providers/Localization';
import { RootState, useAppSelector } from '@/redux/store';
import { Message } from '@/types/message';
import { getLastMessage } from '@/utils/functions';
import { StyleSheet } from 'react-native';

const FooterConversationCard = ({ conversationId }: { conversationId: string }) => {
  const sections = useAppSelector((state: RootState) => state.messages.sections?.[conversationId]);
  const [lastMessage, setLastMessage] = useState<Message | null>(null);

  useEffect(() => {
    if (sections?.length > 0) {
      const lastMessage = getLastMessage(sections);
      if (lastMessage) {
        setLastMessage(lastMessage);
      }
    }
  }, [sections]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        lastMessage: {
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '94%'
        },
        children: {
          width: '100%'
        },
        noMessages: { color: '#555', fontStyle: 'italic' }
      }),
    []
  );

  return lastMessage ? (
    <THView style={styles.lastMessage}>
      <THView style={styles.children}>
        {Object.keys(lastMessage)?.length ? (
          <OriginMessage
            email={lastMessage?.from?.email}
            lastMessage={lastMessage}
            conversationId={conversationId}
          />
        ) : (
          <TText size="sm" style={styles.noMessages}>
            {i18n.t('noMessages')}
          </TText>
        )}
      </THView>
      <ContainerBadges fragmentId={lastMessage?.id_fragment} conversationId={conversationId} />
    </THView>
  ) : (
    <TText size="sm" style={styles.noMessages}>
      {i18n.t('noMessages')}
    </TText>
  );
};

export default React.memo(
  FooterConversationCard,
  (prevProps, nextProps) => prevProps.conversationId === nextProps.conversationId
);
