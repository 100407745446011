import { Message } from '../../../types/message';

export interface MessagesIndex {
  [key: string]: Message[];
}
export interface ImagesIndex {
  [key: string]: {
    url: string;
    expiredAt: string;
  };
}
export interface MoreMessages {
  [key: string]: boolean;
}
export interface SetMoreMessagesPayload {
  id_conversation: string;
  hasMoreMessages: boolean;
}

export interface UploadFileInterface {
  conversationId: string;
  originalName: string;
  platform: string;
  file: any;
  mimeType: string;
  uri: any;
  size?: number;
  temp: boolean;
}

export interface SetImagesStateInterface {
  id: string;
  url: string;
  expiredAt: string;
}

export interface statusMessage {
  id: string | undefined;
  group: boolean;
}

export interface PostMessage {
  // id_from: string;
  message: Message;
  // id_to: string[];
}

export interface PropsMessage {
  msg: Message;
  status: string;
}

export interface messagesState {
  error: {
    status: boolean;
    msg: string;
  };
  loadingMsg: boolean;
  bandera: boolean;
  id_to: string[];
  moreMessages: Record<string, boolean>;
  uploadFile: boolean;
  images: ImagesIndex;
  count: number;
  sections: { [key: string]: SectionInterface[] };
  temporallyPreviousMessage: { [key: string]: [] };
}
export interface SectionInterface {
  readonly type: TypeSection;
  readonly id: string;
  data: Message;
  showSender?: boolean;
  showTime?: boolean;
}

export enum TypeSection {
  message = 'message',
  footer = 'footer',
  header = 'header'
}
