import { Filters, contracts } from '@/redux/slices/filters/interfaces';
import {
  FRAGMENT_CLOSED,
  FRAGMENT_EXPIRED,
  FRAGMENT_FAILED,
  FRAGMENT_OPEN,
  FRAGMENT_PENDING
} from '../constants';
import { ETypesSections } from '@/routers/MainRouter';

export const ASIGNADOS_A_MI = 'asignados_a_mi';
export const SIN_ASIGNAR = 'sin_asignar';
export const TODOS = 'todos';
export const CERRADOS = 'cerrados';
export const GRUPOS = 'grupos';
export const ALL = 'all';
export const ABIERTOS = 'abiertos';
export const EXPIRED = 'expired';
export const SEARCHS = 'searchs';
export const IMPORTANTS = '136';

export const filterToCountSidebar = [SIN_ASIGNAR, ASIGNADOS_A_MI, EXPIRED, TODOS, GRUPOS, ABIERTOS];
export const filterToCount = [SIN_ASIGNAR, ASIGNADOS_A_MI, EXPIRED, TODOS, GRUPOS, ABIERTOS];

export const filtersToCheckOutConver = [CERRADOS, EXPIRED, ABIERTOS];
// array de filtros que no quiero que se ejecute el outConversationToFilter
export const filterNoOutConversation = [ABIERTOS, ALL, ASIGNADOS_A_MI, IMPORTANTS];
//filtros que traen en la primera carga las conversaciones
export const FILTERS_TO_GET_FIRST_CHARGE = [SIN_ASIGNAR, ABIERTOS, ASIGNADOS_A_MI, TODOS, GRUPOS];
//filtros que traen conversaciones al montar el componente
export const GET_DATA_IF_IS = [IMPORTANTS, ALL];

export const filtersBase: Filters[] = [
  {
    id: 8,
    name: 'Todos',
    idString: ALL,
    icon: 'inbox-full',
    size: 20,
    types: ETypesSections?.clients,
    contracts: contracts?.widget,
    query: {
      conversation: {
        platform: [],
        group: false,
        updatedAt: null
      },
      fragment: {
        associations: {
          assignee: []
        },
        status: [
          FRAGMENT_CLOSED,
          FRAGMENT_EXPIRED,
          FRAGMENT_OPEN,
          FRAGMENT_PENDING,
          FRAGMENT_FAILED
        ]
      },
      filter_name: ALL
    }
  },
  {
    id: 3,
    name: 'Abiertos',
    idString: ABIERTOS,
    icon: 'inbox',
    types: ETypesSections?.clients,
    contracts: contracts?.chat,
    size: 20,
    query: {
      conversation: {
        platform: [],
        group: false,
        updatedAt: null
      },
      fragment: {
        associations: {},
        status: [FRAGMENT_OPEN]
      },
      filter_name: ABIERTOS
    }
  },
  {
    id: 6,
    name: 'Sin asignar',
    idString: SIN_ASIGNAR,
    icon: 'account-remove',
    size: 20,
    types: ETypesSections?.clients,
    contracts: contracts?.chat,
    query: {
      conversation: {
        platform: [],
        group: false,
        updatedAt: null
      },
      fragment: {
        associations: {
          assignee: false
        },
        status: [FRAGMENT_OPEN]
      },
      filter_name: SIN_ASIGNAR
    }
  },
  {
    id: 2,
    name: 'Asignados a mi',
    idString: ASIGNADOS_A_MI,
    icon: 'contacts',
    types: ETypesSections?.clients,
    contracts: contracts?.chat,
    size: 20,
    query: {
      conversation: {
        platform: [],
        group: false,
        updatedAt: null
      },
      fragment: {
        associations: {
          assignee: []
        },
        status: [FRAGMENT_OPEN, FRAGMENT_PENDING, FRAGMENT_EXPIRED, FRAGMENT_FAILED]
      },
      filter_name: ASIGNADOS_A_MI
    }
  },

  {
    id: 1,
    name: 'Todos',
    idString: TODOS,
    icon: 'inbox-full',
    size: 20,
    types: ETypesSections?.chat,
    contracts: contracts?.internal,
    query: {
      conversation: {
        platform: [],
        group: false,
        updatedAt: null
      },
      filter_name: TODOS
    }
  },
  {
    id: 7,
    name: 'Grupos',
    idString: GRUPOS,
    icon: 'account-group',
    size: 20,
    types: ETypesSections?.chat,
    contracts: contracts?.internal,
    query: {
      conversation: {
        platform: [],
        group: true,
        updatedAt: null
      },
      filter_name: GRUPOS
    }
  },
  {
    id: 5,
    name: 'Vencidos',
    idString: EXPIRED,
    icon: 'clock-alert',
    size: 20,
    types: ETypesSections?.clients,
    contracts: contracts?.chat,
    query: {
      conversation: {
        platform: [],
        group: false,
        updatedAt: null
      },
      fragment: {
        associations: {},
        status: [FRAGMENT_EXPIRED]
      },
      filter_name: EXPIRED
    }
  },
  {
    id: 4,
    name: 'Cerrados',
    idString: CERRADOS,
    icon: 'lock',
    size: 20,
    types: ETypesSections?.clients,
    contracts: contracts?.chat,
    query: {
      conversation: {
        platform: [],
        group: false,
        updatedAt: null
      },
      fragment: {
        associations: {
          assignee: true
        },
        status: [FRAGMENT_CLOSED]
      },
      filter_name: CERRADOS
    }
  },
  {
    id: 7,
    name: 'Busqueda',
    idString: SEARCHS,
    icon: 'magnify',
    size: 20,
    types: ETypesSections?.clients,
    contracts: contracts?.chat
  }
];
