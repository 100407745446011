import React, { memo } from 'react';

import { ScrollView, VStack } from '@gluestack-ui/themed';
import SoundNotifications from '@/components/partials/notifications/SoundNotifications';
import ChannelsNotifications from '@/components/partials/notifications/ChannelsNotifications';
import BrowserNotifications from '@/components/partials/notifications/BrowserNotifications';
import { Platform } from 'react-native';

const { useNotifications } = Platform.select({
  native: () => require('@/providers/NotificationProvider.native'),
  default: () => require('@/providers/NotificationProvider.web')
})();

function SettingsNotifications(routeNotifications) {
  const { notificationsEnabled } = useNotifications();

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      padding={20}
      bg="$backgroundSecondary"
      h="$full"
    >
      <BrowserNotifications />
      {notificationsEnabled && (
        <>
          <SoundNotifications />
          <ChannelsNotifications teamId={routeNotifications?.route?.params.team} />
        </>
      )}
    </ScrollView>
  );
}

export default memo(SettingsNotifications);
