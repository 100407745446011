import { BadgeIcon, Pressable, Badge, BadgeText, HStack } from '@gluestack-ui/themed';

import React from 'react';
import { FilterOmniBox, FilterOmniBoxProps } from './interfaces';
import { Check } from '@/assets/icons/Icons';
import { Text } from '@gluestack-ui/themed';

function FilterOmniboxComponent({
  selectedFilters,
  setSelectedFilters,
  filters,
  unique = false,
  type = 'filtros'
}: FilterOmniBoxProps) {
  const isSelected = (value) => selectedFilters?.includes(value);

  const toggleSelect = (value) => {
    if (unique) {
      setSelectedFilters([value]);
      return;
    }
    if (isSelected(value)) {
      setSelectedFilters(selectedFilters.filter((filter) => filter !== value));
    } else {
      setSelectedFilters([...selectedFilters, value]);
    }
  };

  return (
    <HStack style={{ flexWrap: 'wrap', padding: 8, gap: 8 }}>
      {filters?.length > 0 ? (
        filters?.map((filter: FilterOmniBox) => (
          <Pressable
            key={filter.value}
            onPress={() => toggleSelect(filter.value)}
            sx={{
              ':hover': {
                backgroundColor: '$transparent'
              }
            }}
          >
            <Badge
              key={filter.key}
              variant="outline"
              borderRadius="$sm"
              action="muted"
              bg={isSelected(filter.value) ? '$backgroundSecondary' : '$background'}
              style={{
                marginRight: 4,
                marginBottom: 4
              }}
            >
              {isSelected(filter.value) && <BadgeIcon as={Check} size="xs" mr="$1" />}
              <BadgeText size="2xs">{filter.label}</BadgeText>
            </Badge>
          </Pressable>
        ))
      ) : (
        <Text>{`No hay ${type}`}</Text>
      )}
    </HStack>
  );
}

export default React.memo(FilterOmniboxComponent);
