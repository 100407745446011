import React, { useCallback, useMemo } from 'react';
import { Menu, MenuIcon, MenuItem } from '@gluestack-ui/themed';
import TextFormatter from '@/atoms/TextFormatter';

import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { ChevronDown, IconIdea } from '@/assets/icons/Icons';

import { Pressable } from '@gluestack-ui/themed';
import { MenuItemLabel } from '@gluestack-ui/themed';
import translate from '@/helpers/traslations';
import { getContext } from '@/utils/functions';
import { Routes } from '@/routers/routes';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParams } from '@/routers/interfaces';
import { useNavigation } from '@react-navigation/native';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { HeaderMessageComponentInterface } from './interfaces';
import { useCollapsed } from '@/providers/CollpasedProvider';
import { IS_WEB_PC } from '@/utils/functions';
import { setMenuRigth, setDialogConnect } from '@/redux/slices/theme';
import { ContractsEnum, PlatformsEnum } from '@/types/enums/conversation';
import { THView } from '@/components/themed';
import MemoizedAvatarCard from '@/atoms/AvatarCard';
import { StyleSheet } from 'react-native';

const HeaderMessageComponent = ({
  name,
  text,
  avatar,
  messageId,
  conversationId,
  showIconChevron,
  setDataBc
}: HeaderMessageComponentInterface) => {
  const dispatch = useAppDispatch();
  const sections = useAppSelector((state: RootState) => state.messages.sections?.[conversationId]);
  const platform = useAppSelector(
    (state: RootState) => state.conversations.conversations?.[conversationId]?.platform
  );
  const navigation = useNavigation<StackNavigationProp<RootStackParams>>();
  const contracts = useAppSelector((state: RootState) => state.user.contracts);

  const { setCollapsed } = useCollapsed();

  const handlePressIdea = useCallback(() => {
    if (!contracts?.includes(ContractsEnum?.chat)) {
      dispatch(setDialogConnect(true));
      return;
    }
    const data = getContext(sections, messageId);
    data && setDataBc(data);

    if (IS_WEB_PC) {
      dispatch(setMenuRigth(true));
      setCollapsed(true);
      setTimeout(() => {
        navigation.navigate(Routes.Embedding);
      }, 100);
    } else {
      navigation.navigate(Routes.Embedding);
    }
  }, [sections, messageId, setDataBc, navigation, dispatch, setCollapsed, contracts, platform]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          gap: 4,
          paddingVertical: 4
        },
        button: {
          width: 30,
          height: 30,
          borderRadius: 100,
          justifyContent: 'center',
          alignItems: 'center'
        }
      }),
    []
  );

  return (
    <THView bgColor="transparent" style={styles.container}>
      <MemoizedAvatarCard picture={avatar} width="$6" name={name} />
      <TextFormatter bold numberOfLines={1} color="text">
        {text}
      </TextFormatter>
      {showIconChevron && platform !== PlatformsEnum.internal && (
        <Menu
          trigger={({ ...triggerProps }) => (
            <Pressable
              style={styles.button}
              sx={{
                ':hover': {
                  backgroundColor: '$backgroundActive',
                  cursor: 'pointer'
                }
              }}
              {...triggerProps}
            >
              <MaterialCommunity icon={ChevronDown} size="md" />
            </Pressable>
          )}
        >
          <MenuItem key={'embedding'} textValue="embedding" onPress={handlePressIdea}>
            <MenuIcon as={IconIdea} size="sm" />
            <MenuItemLabel ml="$2" size="sm">
              {translate('embedding')}
            </MenuItemLabel>
          </MenuItem>
        </Menu>
      )}
    </THView>
  );
};

export default React.memo(HeaderMessageComponent, (prevProps, nextProps) => {
  return (
    prevProps.name === nextProps.name &&
    prevProps.text === nextProps.text &&
    prevProps.avatar === nextProps.avatar &&
    prevProps.messageId === nextProps.messageId &&
    prevProps.conversationId === nextProps.conversationId &&
    prevProps.showIconChevron === nextProps.showIconChevron &&
    prevProps.setDataBc === nextProps.setDataBc
  );
});
