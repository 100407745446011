import React, { useEffect } from 'react';
import { Asset } from 'expo-asset';
import { Avatar, AvatarBadge, AvatarFallbackText, AvatarImage } from '@gluestack-ui/themed';
import { colorRandom } from '@/utils/constants';
import { AvatarCardProps } from './interfaces';
import MaterialCommunity from '../components/Icons/MaterialCommunity';
import getIconsToFilters from '@/assets/icons';

function AvatarCard({
  picture,
  bgColor,
  iconIdString,
  sizeAvatar,
  name = 'NA',
  width,
  textSize = 'xs'
}: AvatarCardProps) {
  useEffect(() => {
    if (picture) {
      Asset.fromModule(picture).downloadAsync();
    }
  }, [picture]);

  return (
    <Avatar w={width} h={width} bg={bgColor ?? colorRandom(name)} size={sizeAvatar}>
      <AvatarFallbackText size={textSize} borderRadius="$full">
        {name}
      </AvatarFallbackText>
      <AvatarImage alt={name} source={{ uri: picture }} />
      {iconIdString && (
        <AvatarBadge bg="$transparent" borderColor="$transparent" w="$6" h="$6">
          <MaterialCommunity icon={getIconsToFilters({ idString: iconIdString })} size="xl" />
        </AvatarBadge>
      )}
    </Avatar>
  );
}

const MemoizedAvatarCard = React.memo(AvatarCard);
export default MemoizedAvatarCard;
