import { config as defaultConfig } from '@gluestack-ui/config';
import { Switch } from '@gluestack-ui/config/build/theme';

export default {
  ...defaultConfig.components,
  //   Alert: {
  //     theme: {
  //       alignItems: 'center',
  //       p: '$3',
  //       flexDirection: 'row',
  //       borderRadius: '$sm',
  //       variants: {
  //         action: {
  //           error: {
  //             bg: '$errorLight',
  //             borderColor: '$error',
  //             _icon: {
  //               color: '$error'
  //             },
  //             _dark: {
  //               bg: '$errorDarken',
  //               borderColor: '$error700',
  //               _icon: {
  //                 color: '$error500'
  //               }
  //             }
  //           },
  //           warning: {
  //             bg: '$backgroundLightWarning',
  //             borderColor: '$warning300',
  //             _icon: {
  //               color: '$warning500'
  //             },
  //             _dark: {
  //               bg: '$backgroundDarkWarning',
  //               borderColor: '$warning700',
  //               _icon: {
  //                 color: '$warning500'
  //               }
  //             }
  //           },
  //           success: {
  //             bg: '$backgroundLightSuccess',
  //             borderColor: '$success300',
  //             _icon: {
  //               color: '$success500'
  //             },
  //             _dark: {
  //               bg: '$backgroundDarkSuccess',
  //               borderColor: '$success700',
  //               _icon: {
  //                 color: '$success500'
  //               }
  //             }
  //           },
  //           info: {
  //             bg: '$info',
  //             borderColor: '$info300',
  //             _icon: {
  //               color: '$info500'
  //             },
  //             _dark: {
  //               bg: '$infoOpacity',
  //               borderColor: '$info700',
  //               _icon: {
  //                 color: '$info500'
  //               }
  //             }
  //           },
  //           muted: {
  //             bg: '$backgroundLightMuted',
  //             borderColor: '$secondary300',
  //             _icon: {
  //               color: '$secondary500'
  //             },
  //             _dark: {
  //               bg: '$backgroundDarkMuted',
  //               borderColor: '$secondary700',
  //               _icon: {
  //                 color: '$secondary500'
  //               }
  //             }
  //           }
  //         },

  //         variant: {
  //           solid: {},
  //           outline: {
  //             borderWidth: '$1',
  //             bg: '$white',
  //             _dark: {
  //               bg: '$black'
  //             }
  //           },
  //           accent: {
  //             borderLeftWidth: '$4'
  //           }
  //         }
  //       },

  //       defaultProps: {
  //         variant: 'solid',
  //         action: 'info'
  //       }
  //     }
  //   },
  AvatarFallbackText: {
    theme: {
      color: '$textLight0',
      fontWeight: '$semibold',
      variants: {
        size: {
          xs: {
            fontSize: 7,
            lineHeight: '$xs'
          },
          sm: {
            fontSize: '$sm',
            lineHeight: '$sm'
          },
          md: {
            fontSize: '$md',
            lineHeight: '$md'
          },
          lg: {
            fontSize: '$lg',
            lineHeight: '$lg'
          },
          xl: {
            fontSize: '$xl',
            lineHeight: '$xl'
          }
        }
      },
      _dark: {
        color: '$textDark0'
      }
    }
  },
  AccordionIcon: {
    theme: {
      color: '$textLight',
      variants: {
        size: {
          xs: {
            fontSize: 'xs',
            lineHeight: '$xs'
          },
          sm: {
            fontSize: '$sm',
            lineHeight: '$sm'
          },
          md: {
            fontSize: '$md',
            lineHeight: '$md'
          },
          lg: {
            fontSize: '$lg',
            lineHeight: '$lg'
          },
          xl: {
            fontSize: '$xl',
            lineHeight: '$xl'
          }
        }
      },
      _dark: {
        color: '$text'
      }
    }
  },
  AccordionTitleText: {
    theme: {
      color: '$textLight',
      variants: {
        size: {
          xs: {
            fontSize: 'xs',
            lineHeight: '$xs'
          },
          sm: {
            fontSize: '$sm',
            lineHeight: '$sm'
          },
          md: {
            fontSize: '$md',
            lineHeight: '$md'
          },
          lg: {
            fontSize: '$lg',
            lineHeight: '$lg'
          },
          xl: {
            fontSize: '$xl',
            lineHeight: '$xl'
          }
        }
      },
      _dark: {
        color: '$text'
      }
    }
  },
  Accordion: {
    theme: {
      backgroundColor: '$backgroundSecondaryLight',
      width: '$full',
      _dark: {
        backgroundColor: '$backgroundSecondary'
      }
    }
  },
  Badge: {
    theme: {
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '$xs',
      variants: {
        action: {
          error: {
            bg: '$backgroundLightError',
            borderColor: '$error300',
            _icon: {
              color: '$error600'
            },
            _text: {
              color: '$error600'
            },
            _dark: {
              bg: '$backgroundDarkError',
              borderColor: '$error700',
              _text: {
                color: '$error400'
              },
              _icon: {
                color: '$error400'
              }
            }
          },
          warning: {
            bg: '$backgroundLightWarning',
            borderColor: '$warning300',
            _icon: {
              color: '$warning600'
            },
            _text: {
              color: '$warning600'
            },
            _dark: {
              bg: '$backgroundDarkWarning',
              borderColor: '$warning700',
              _text: {
                color: '$warning400'
              },
              _icon: {
                color: '$warning400'
              }
            }
          },
          success: {
            bg: '$success',
            borderColor: '$success300',
            _icon: {
              color: '$success600'
            },
            _text: {
              color: '$success600'
            },
            _dark: {
              bg: '$backgroundDarkSuccess',
              borderColor: '$success700',
              _text: {
                color: '$success400'
              },
              _icon: {
                color: '$success400'
              }
            }
          },
          info: {
            bg: '$backgroundLightInfo',
            borderColor: '$info300',
            _icon: {
              color: '$info600'
            },
            _text: {
              color: '$info600'
            },
            _dark: {
              bg: '$backgroundDarkInfo',
              borderColor: '$info700',
              _text: {
                color: '$info400'
              },
              _icon: {
                color: '$info400'
              }
            }
          },
          muted: {
            bg: '$backgroundLightMuted',
            borderColor: '$secondary300',
            _icon: {
              color: '$secondary600'
            },
            _text: {
              color: '$secondary600'
            },
            _dark: {
              bg: '$backgroundDarkMuted',
              borderColor: '$secondary700',
              _text: {
                color: '$secondary400'
              },
              _icon: {
                color: '$secondary400'
              }
            }
          }
        },

        variant: {
          solid: {},
          outline: {
            borderWidth: '$1'
          }
        },

        size: {
          sm: {
            px: '$1',
            _icon: {
              props: {
                size: '2xs'
              }
            },
            _text: {
              props: {
                size: '2xs'
              }
            }
          },
          md: {
            px: '$2',
            _icon: {
              props: {
                size: 'xs'
              }
            },
            _text: {
              props: {
                size: 'xs'
              }
            }
          },
          lg: {
            px: '$2',
            _icon: {
              props: { size: 'sm' }
            },
            _text: {
              props: { size: 'sm' }
            }
          }
        }
      },

      ':disabled': {
        opacity: 0.5
      },
      defaultProps: {
        action: 'info',
        variant: 'solid',
        size: 'md'
      }
    }
  },
  BadgeText: {
    theme: {
      color: '$text',
      variants: {
        size: {
          xs: {
            fontSize: 10,
            lineHeight: '$xs'
          },
          sm: {
            fontSize: '$sm',
            lineHeight: '$sm'
          },
          md: {
            fontSize: '$md',
            lineHeight: '$md'
          },
          lg: {
            fontSize: '$lg',
            lineHeight: '$lg'
          },
          xl: {
            fontSize: '$xl',
            lineHeight: '$xl'
          }
        }
      },
      _dark: {
        color: '$text'
      }
    }
  },
  Button: {
    theme: {
      borderRadius: '$sm',
      backgroundColor: '$primary',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      _text: {
        color: '$textLight0',
        fontWeight: '$semibold',
        _dark: {
          color: '$textDark0'
        }
      },

      _icon: {
        color: '$textLight0',
        _dark: {
          color: '$textDark0'
        }
      },

      _spinner: {
        props: {
          color: '$backgroundLight0'
        },
        _dark: {
          props: {
            color: '$primary'
          }
        }
      },

      variants: {
        action: {
          primary: {
            bg: '$primary',
            borderColor: '$primary300',

            ':hover': {
              bg: '$pxsolDarken',
              borderColor: '$primary400'
            },

            ':active': {
              bg: '$primary',
              borderColor: '$pxsol'
            },

            _text: {
              color: '$textLight0',
              ':hover': {
                color: '$textLight0'
              },
              ':active': {
                color: '$textLight0'
              }
            },

            _icon: {
              color: '$textLight0',
              ':hover': {
                color: '$textLight0'
              },
              ':active': {
                color: '$textLight'
              }
            },

            _spinner: {
              props: {
                color: '$primary600'
              },
              ':hover': {
                props: {
                  color: '$primary600'
                }
              },
              ':active': {
                props: {
                  color: '$primary700'
                }
              }
            },

            _dark: {
              bg: '$primary',
              borderColor: '$primary',
              ':hover': {
                bg: '$pxsolDarken',
                borderColor: '$pxsolDarken'
              },
              ':active': {
                bg: '$pxsolDarken',
                borderColor: '$pxsolDarken'
              },
              _text: {
                color: '$text',
                ':hover': {
                  color: '$text'
                },
                ':active': {
                  color: '$text'
                }
              },
              _icon: {
                color: '$text',
                ':hover': {
                  color: '$text'
                },
                ':active': {
                  color: '$text'
                }
              },
              _spinner: {
                props: { color: '$text' },
                ':hover': {
                  props: { color: '$text' }
                },
                ':active': {
                  props: { color: '$text' }
                }
              },

              ':focusVisible': {
                _web: {
                  boxShadow: 'offset 0 0 0 2px $info400'
                }
              }
            }
          },
          secondary: {
            bg: '$secondary',
            borderColor: '$secondary300',

            ':hover': {
              bg: '$secondary600',
              borderColor: '$secondary400'
            },

            ':active': {
              bg: '$secondary700',
              borderColor: '$secondary700'
            },

            _text: {
              color: '$secondary600',
              ':hover': {
                color: '$secondary600'
              },
              ':active': {
                color: '$secondary700'
              }
            },
            _icon: {
              color: '$secondary600',
              ':hover': {
                color: '$secondary600'
              },
              ':active': {
                color: '$secondary700'
              }
            },

            _spinner: {
              props: {
                color: '$secondary600'
              },
              ':hover': {
                props: { color: '$secondary600' }
              },
              ':active': {
                props: { color: '$secondary700' }
              }
            },

            _dark: {
              bg: '$secondary400',
              borderColor: '$secondary700',
              ':hover': {
                bg: '$secondary500',
                borderColor: '$secondary400'
              },
              ':active': {
                bg: '$secondary600',
                borderColor: '$secondary300'
              },
              _text: {
                color: '$secondary300',
                ':hover': {
                  color: '$secondary300'
                },
                ':active': {
                  color: '$secondary200'
                }
              },
              _icon: {
                color: '$secondary300',
                ':hover': {
                  color: '$secondary300'
                },
                ':active': {
                  color: '$secondary200'
                }
              },
              _spinner: {
                props: {
                  color: '$secondary300'
                },
                ':hover': {
                  props: { color: '$secondary300' }
                },
                ':active': {
                  props: { color: '$secondary200' }
                }
              }
            }
          },
          positive: {
            bg: '$success500',
            borderColor: '$success300',
            ':hover': {
              bg: '$success600',
              borderColor: '$success400'
            },

            ':active': {
              bg: '$success700',
              borderColor: '$success700'
            },

            _text: {
              color: '$success600',
              ':hover': {
                color: '$success600'
              },
              ':active': {
                color: '$success700'
              }
            },
            _icon: {
              color: '$success600',
              ':hover': {
                color: '$success600'
              },
              ':active': {
                color: '$success700'
              }
            },
            _spinner: {
              props: {
                color: '$success600'
              },
              ':hover': {
                props: { color: '$success600' }
              },
              ':active': {
                props: { color: '$success700' }
              }
            },
            _dark: {
              bg: '$success400',
              borderColor: '$success700',
              ':hover': {
                bg: '$success500',
                borderColor: '$success400'
              },
              ':active': {
                bg: '$success600',
                borderColor: '$success300'
              },
              _text: {
                color: '$success300',
                ':hover': {
                  color: '$success300'
                },
                ':active': {
                  color: '$success200'
                }
              },
              _icon: {
                color: '$success300',
                ':hover': {
                  color: '$success300'
                },
                ':active': {
                  color: '$success200'
                }
              },
              _spinner: {
                props: {
                  color: '$success300'
                },
                ':hover': {
                  props: { color: '$success300' }
                },
                ':active': {
                  props: { color: '$success200' }
                }
              },
              ':focusVisible': {
                _web: {
                  boxShadow: 'offset 0 0 0 2px $info400'
                }
              }
            }
          },
          negative: {
            bg: '$error500',
            borderColor: '$error300',
            ':hover': {
              bg: '$error600',
              borderColor: '$error400'
            },

            ':active': {
              bg: '$error700',
              borderColor: '$error700'
            },
            _text: {
              color: '$error600',
              ':hover': {
                color: '$error600'
              },
              ':active': {
                color: '$error700'
              }
            },
            _icon: {
              color: '$error600',
              ':hover': {
                color: '$error600'
              },
              ':active': {
                color: '$error700'
              }
            },
            _spinner: {
              props: {
                color: '$error600'
              },
              ':hover': {
                props: { color: '$error600' }
              },
              ':active': {
                props: { color: '$error700' }
              }
            },
            _dark: {
              bg: '$error400',
              borderColor: '$error700',
              ':hover': {
                bg: '$error500',
                borderColor: '$error400'
              },
              ':active': {
                bg: '$error600',
                borderColor: '$error300'
              },
              _text: {
                color: '$error300',
                ':hover': {
                  color: '$error300'
                },
                ':active': {
                  color: '$error200'
                }
              },
              _icon: {
                color: '$error300',
                ':hover': {
                  color: '$error300'
                },
                ':active': {
                  color: '$error200'
                }
              },
              _spinner: {
                props: {
                  color: '$error300'
                },
                ':hover': {
                  props: { color: '$error300' }
                },
                ':active': {
                  props: { color: '$error200' }
                }
              },
              ':focusVisible': {
                _web: {
                  boxShadow: 'offset 0 0 0 2px $info400'
                }
              }
            }
          },

          default: {
            bg: '$transparent',
            ':hover': {
              bg: '$backgroundLight50'
            },
            ':active': {
              bg: 'transparent'
            },
            _dark: {
              bg: 'transparent',
              ':hover': {
                bg: '$backgroundDark900'
              },
              ':active': {
                bg: 'transparent'
              }
            }
          }
        },

        variant: {
          link: {
            px: '$0',
            ':hover': {
              _text: {
                textDecorationLine: 'underline'
              }
            },
            ':active': {
              _text: {
                textDecorationLine: 'underline'
              }
            }
          },
          outline: {
            bg: 'transparent',
            borderWidth: '$0',
            borderColor: '$secondary',
            ':hover': {
              bg: '$backgroundLight50'
            },
            ':active': {
              bg: 'transparent'
            },
            _dark: {
              bg: 'transparent',
              ':hover': {
                bg: '$backgroundDark900'
              },
              ':active': {
                bg: 'transparent'
              }
            }
          },
          solid: {
            _text: {
              color: '$textLight0',
              ':hover': {
                color: '$textLight0'
              },
              ':active': {
                color: '$textLight0'
              }
            },
            _spinner: {
              props: { color: '$textLight0' },
              ':hover': {
                props: { color: '$textLight0' }
              },
              ':active': {
                props: { color: '$textLight0' }
              }
            },
            _icon: {
              props: { color: '$textLight0' },
              ':hover': {
                props: { color: '$textLight0' }
              },
              ':active': {
                props: { color: '$textLight0' }
              }
            },
            _dark: {
              _text: {
                color: '$textDark0',
                ':hover': {
                  color: '$textDark0'
                },
                ':active': {
                  color: '$textDark0'
                }
              },
              _spinner: {
                props: { color: '$textDark0' },
                ':hover': {
                  props: { color: '$textDark0' }
                },
                ':active': {
                  props: { color: '$textDark0' }
                }
              },
              _icon: {
                props: { color: '$textDark0' },
                ':hover': {
                  props: { color: '$textDark0' }
                },
                ':active': {
                  props: { color: '$textDark0' }
                }
              }
            }
          }
        },

        size: {
          xs: {
            px: '$3.5',
            h: '$7',
            _icon: {
              props: {
                size: '2xs',
                ml: '$1'
              }
            },
            _text: {
              props: {
                size: 'xs'
              }
            }
          },
          sm: {
            px: '$4',
            h: '$9',
            _icon: {
              props: {
                size: 'sm'
              }
            },
            _text: {
              props: {
                size: 'sm'
              }
            }
          },
          md: {
            px: '$5',
            h: '$10',
            _icon: {
              props: {
                size: 'md'
              }
            },
            _text: {
              props: {
                size: 'md'
              }
            }
          },
          lg: {
            px: '$6',
            h: '$11',
            _icon: {
              props: {
                size: 'md'
              }
            },
            _text: {
              props: {
                size: 'lg'
              }
            }
          },
          xl: {
            px: '$7',
            h: '$12',
            _icon: {
              props: {
                size: 'lg'
              }
            },
            _text: {
              props: {
                size: 'xl'
              }
            }
          }
        }
      },
      compoundVariants: [
        {
          action: 'primary',
          variant: 'link',
          value: {
            px: '$0',
            bg: 'transparent',
            ':hover': {
              bg: 'transparent'
            },
            ':active': {
              bg: 'transparent'
            },
            _dark: {
              bg: 'transparent',
              ':hover': {
                bg: 'transparent'
              },
              ':active': {
                bg: 'transparent'
              }
            }
          }
        },
        {
          action: 'secondary',
          variant: 'link',
          value: {
            px: '$0',
            bg: 'transparent',
            ':hover': {
              bg: 'transparent'
            },
            ':active': {
              bg: 'transparent'
            },
            _dark: {
              bg: 'transparent',
              ':hover': {
                bg: 'transparent'
              },
              ':active': {
                bg: 'transparent'
              }
            }
          }
        },
        {
          action: 'positive',
          variant: 'link',
          value: {
            px: '$0',
            bg: 'transparent',
            ':hover': {
              bg: 'transparent'
            },
            ':active': {
              bg: 'transparent'
            },
            _dark: {
              bg: 'transparent',
              ':hover': {
                bg: 'transparent'
              },
              ':active': {
                bg: 'transparent'
              }
            }
          }
        },
        {
          action: 'negative',
          variant: 'link',
          value: {
            px: '$0',
            bg: 'transparent',
            ':hover': {
              bg: 'transparent'
            },
            ':active': {
              bg: 'transparent'
            },
            _dark: {
              bg: 'transparent',
              ':hover': {
                bg: 'transparent'
              },
              ':active': {
                bg: 'transparent'
              }
            }
          }
        },
        {
          action: 'primary',
          variant: 'outline',
          value: {
            bg: 'transparent',
            ':hover': {
              bg: '$backgroundLight50'
            },
            ':active': {
              bg: 'transparent'
            },
            _dark: {
              bg: 'transparent',
              ':hover': {
                bg: '$backgroundDark900'
              },
              ':active': {
                bg: 'transparent'
              }
            }
          }
        },
        {
          action: 'secondary',
          variant: 'outline',
          value: {
            bg: 'transparent',
            ':hover': {
              bg: '$backgroundLight50'
            },
            ':active': {
              bg: 'transparent'
            },
            _dark: {
              bg: 'transparent',
              ':hover': {
                bg: '$backgroundDark900'
              },
              ':active': {
                bg: 'transparent'
              }
            }
          }
        },
        {
          action: 'positive',
          variant: 'outline',
          value: {
            bg: 'transparent',
            ':hover': {
              bg: '$backgroundLight50'
            },
            ':active': {
              bg: 'transparent'
            },
            _dark: {
              bg: 'transparent',
              ':hover': {
                bg: '$backgroundDark900'
              },
              ':active': {
                bg: 'transparent'
              }
            }
          }
        },
        {
          action: 'negative',
          variant: 'outline',
          value: {
            bg: 'transparent',
            ':hover': {
              bg: '$backgroundLight50'
            },
            ':active': {
              bg: 'transparent'
            },
            _dark: {
              bg: 'transparent',
              ':hover': {
                bg: '$backgroundDark900'
              },
              ':active': {
                bg: 'transparent'
              }
            }
          }
        },
        {
          action: 'primary',
          variant: 'solid',
          value: {
            _text: {
              color: '$textLight0',
              ':hover': {
                color: '$textLight0'
              },
              ':active': {
                color: '$textLight0'
              }
            },
            _icon: {
              color: '$textLight0',
              ':hover': {
                color: '$textLight0'
              },
              ':active': {
                color: '$textLight0'
              }
            },
            _spinner: {
              props: { color: '$textLight0' },
              ':hover': {
                props: { color: '$textLight0' }
              },
              ':active': {
                props: { color: '$textLight0' }
              }
            },
            _dark: {
              _text: {
                color: '$textDark0',
                ':hover': {
                  color: '$textDark0'
                },
                ':active': {
                  color: '$textDark0'
                }
              },
              _icon: {
                color: '$textDark0',
                ':hover': {
                  color: '$textDark0'
                },
                ':active': {
                  color: '$textDark0'
                }
              },
              _spinner: {
                props: { color: '$textDark0' },
                ':hover': {
                  props: { color: '$textDark0' }
                },
                ':active': {
                  props: { color: '$textDark0' }
                }
              }
            }
          }
        },
        {
          action: 'secondary',
          variant: 'solid',
          value: {
            _text: {
              color: '$textLight0',
              ':hover': {
                color: '$textLight0'
              },
              ':active': {
                color: '$textLight0'
              }
            },
            _icon: {
              color: '$textLight0',
              ':hover': {
                color: '$textLight0'
              },
              ':active': {
                color: '$textLight0'
              }
            },
            _spinner: {
              props: { color: '$textLight0' },
              ':hover': {
                props: { color: '$textLight0' }
              },
              ':active': {
                props: { color: '$textLight0' }
              }
            },
            _dark: {
              _text: {
                color: '$textDark0',
                ':hover': {
                  color: '$textDark0'
                },
                ':active': {
                  color: '$textDark0'
                }
              },
              _icon: {
                color: '$textDark0',
                ':hover': {
                  color: '$textDark0'
                },
                ':active': {
                  color: '$textDark0'
                }
              },
              _spinner: {
                props: { color: '$textDark0' },
                ':hover': {
                  props: { color: '$textDark0' }
                },
                ':active': {
                  props: { color: '$textDark0' }
                }
              }
            }
          }
        },
        {
          action: 'positive',
          variant: 'solid',
          value: {
            _text: {
              color: '$textLight0',
              ':hover': {
                color: '$textLight0'
              },
              ':active': {
                color: '$textLight0'
              }
            },
            _icon: {
              color: '$textLight0',
              ':hover': {
                color: '$textLight0'
              },
              ':active': {
                color: '$textLight0'
              },
              props: { color: '$textLight0' }
            },
            _spinner: {
              props: { color: '$textLight0' },
              ':hover': {
                props: { color: '$textLight0' }
              },
              ':active': {
                props: { color: '$textLight0' }
              }
            },

            _dark: {
              _text: {
                color: '$textDark0',
                ':hover': {
                  color: '$textDark0'
                },
                ':active': {
                  color: '$textDark0'
                }
              },
              _icon: {
                color: '$textDark0',
                ':hover': {
                  color: '$textDark0'
                },
                ':active': {
                  color: '$textDark0'
                }
              },
              _spinner: {
                props: { color: '$textDark0' },
                ':hover': {
                  props: { color: '$textDark0' }
                },
                ':active': {
                  props: { color: '$textDark0' }
                }
              }
            }
          }
        },
        {
          action: 'negative',
          variant: 'solid',
          value: {
            _text: {
              color: '$textLight0',
              ':hover': {
                color: '$textLight0'
              },
              ':active': {
                color: '$textLight0'
              }
            },
            _icon: {
              color: '$textLight0',
              ':hover': {
                color: '$textLight0'
              },
              ':active': {
                color: '$textLight0'
              }
            },
            _spinner: {
              props: { color: '$textLight0' },
              ':hover': {
                props: { color: '$textLight0' }
              },
              ':active': {
                props: { color: '$textLight0' }
              }
            },
            _dark: {
              _text: {
                color: '$textDark0',
                ':hover': {
                  color: '$textDark0'
                },
                ':active': {
                  color: '$textDark0'
                }
              },
              _icon: {
                color: '$textDark0',
                ':hover': {
                  color: '$textDark0'
                },
                ':active': {
                  color: '$textDark0'
                }
              },
              _spinner: {
                props: { color: '$textDark0' },
                ':hover': {
                  props: { color: '$textDark0' }
                },
                ':active': {
                  props: { color: '$textDark0' }
                }
              }
            }
          }
        }
      ],

      props: {
        size: 'md',
        variant: 'solid',
        action: 'primary'
      },

      _web: {
        ':focusVisible': {
          outlineWidth: '$0.5',
          outlineColor: '$primary700',
          outlineStyle: 'solid',
          _dark: {
            outlineColor: '$primary300'
          }
        }
      },

      ':disabled': {
        opacity: 0.4
      }
    },
    componentConfig: {
      descendantStyle: ['_text']
    }
  },
  Icon: {
    theme: {
      color: '$iconosDarken',
      variants: {
        size: {
          xs: {
            fontSize: 'xs',
            lineHeight: '$xs'
          },
          sm: {
            fontSize: '$sm',
            lineHeight: '$sm'
          },
          md: {
            fontSize: '$md',
            lineHeight: '$md'
          },
          lg: {
            fontSize: '$lg',
            lineHeight: '$lg'
          },
          xl: {
            fontSize: '$xl',
            lineHeight: '$xl'
          }
        }
      },
      _dark: {
        color: '$text'
      }
    }
  },
  Input: {
    theme: {
      borderWidth: '$1',
      borderColor: '$secondary',
      borderRadius: '$md',
      flexDirection: 'row',
      overflow: 'hidden',
      alignContent: 'center',

      ':hover': {
        borderColor: '$transparent'
      },

      ':focus': {
        borderColor: '$transparent',
        ':hover': {
          borderColor: '$transparent'
        }
      },

      ':disabled': {
        opacity: 0.4,
        ':hover': {
          borderColor: '$transparent'
        }
      },

      _input: {
        py: 'auto',
        px: '$3'
      },

      _icon: {
        color: '$textLight400'
      },

      _dark: {
        borderColor: '$transparent',
        ':hover': {
          borderColor: '$transparent'
        },
        ':focus': {
          borderColor: '$transparent',
          ':hover': {
            borderColor: '$transparent'
          }
        },
        ':disabled': {
          ':hover': {
            borderColor: '$transparent'
          }
        }
      },

      variants: {
        size: {
          xl: {
            h: '$12',
            _input: {
              props: {
                size: 'xl'
              }
            },
            _icon: {
              props: {
                size: 'xl'
              }
            }
          },
          lg: {
            h: '$11',
            _input: {
              props: {
                size: 'lg'
              }
            },
            _icon: {
              props: {
                size: 'lg'
              }
            }
          },
          md: {
            h: '$10',
            _input: {
              props: {
                size: 'md'
              }
            },
            _icon: {
              props: {
                size: 'sm'
              }
            }
          },
          sm: {
            h: '$9',
            _input: {
              props: {
                size: 'sm'
              }
            },
            _icon: {
              props: {
                size: 'xs'
              }
            }
          }
        },
        variant: {
          underlined: {
            _input: {
              _web: {
                outlineWidth: 0,
                outline: 'none'
              },
              px: '$0'
            },
            borderWidth: 1,
            borderRadius: 8,
            ':focus': {
              borderColor: '$border',
              _web: {
                boxShadow: 'inset 0 -1px 0 0 $border'
              }
            },
            ':invalid': {
              borderWidth: 2,
              borderBottomColor: '$error700',
              _web: {
                boxShadow: 'inset 0 -1px 0 0 $error700'
              },
              ':hover': {
                borderBottomColor: '$error700'
              },
              ':focus': {
                borderBottomColor: '$error700',
                ':hover': {
                  borderBottomColor: '$error700',
                  _web: {
                    boxShadow: 'inset 0 -1px 0 0 $error700'
                  }
                }
              },
              ':disabled': {
                ':hover': {
                  borderBottomColor: '$error700',
                  _web: {
                    boxShadow: 'inset 0 -1px 0 0 $error700'
                  }
                }
              }
            },
            _dark: {
              ':focus': {
                borderColor: '$border',
                _web: {
                  boxShadow: 'inset 0 -1px 0 $border'
                }
              },
              ':invalid': {
                borderColor: '$error400',
                _web: {
                  boxShadow: 'inset 0 -1px 0 0 $error400'
                },
                ':hover': {
                  borderBottomColor: '$error400'
                },
                ':focus': {
                  borderBottomColor: '$error400',
                  ':hover': {
                    borderBottomColor: '$error400',
                    _web: {
                      boxShadow: 'inset 0 -1px 0 0 $error400'
                    }
                  }
                },
                ':disabled': {
                  ':hover': {
                    borderBottomColor: '$error400',
                    _web: {
                      boxShadow: 'inset 0 -1px 0 0 $error400'
                    }
                  }
                }
              }
            }
          },

          outline: {
            _input: {
              _web: {
                outlineWidth: 0,
                outline: 'none'
              }
            },
            ':focus': {
              borderColor: '$transparent',
              _web: {
                boxShadow: 'inset 0 0 0 1px $transparent'
              }
            },
            ':invalid': {
              borderColor: '$error700',
              _web: {
                boxShadow: 'inset 0 0 0 1px $error700'
              },
              ':hover': {
                borderColor: '$error700'
              },
              ':focus': {
                borderColor: '$error700',
                ':hover': {
                  borderColor: '$error700',
                  _web: {
                    boxShadow: 'inset 0 0 0 1px $error700'
                  }
                }
              },
              ':disabled': {
                ':hover': {
                  borderColor: '$error700',
                  _web: {
                    boxShadow: 'inset 0 0 0 1px $error700'
                  }
                }
              }
            },
            _dark: {
              ':focus': {
                borderColor: '$transparent',
                _web: {
                  boxShadow: 'inset 0 0 0 1px $transparent'
                }
              },
              ':invalid': {
                borderColor: '$error400',
                _web: {
                  boxShadow: 'inset 0 0 0 1px $error400'
                },
                ':hover': {
                  borderColor: '$error400'
                },
                ':focus': {
                  borderColor: '$error400',
                  ':hover': {
                    borderColor: '$error400',
                    _web: {
                      boxShadow: 'inset 0 0 0 1px $error400'
                    }
                  }
                },
                ':disabled': {
                  ':hover': {
                    borderColor: '$error400',
                    _web: {
                      boxShadow: 'inset 0 0 0 1px $error400'
                    }
                  }
                }
              }
            }
          },

          rounded: {
            borderRadius: 999,
            _input: {
              px: '$4',
              _web: {
                outlineWidth: 0,
                outline: 'none'
              }
            },
            ':focus': {
              borderColor: '$transparent',
              _web: {
                boxShadow: 'inset 0 0 0 1px $transparent'
              }
            },
            ':invalid': {
              borderColor: '$error700',
              _web: {
                boxShadow: 'inset 0 0 0 1px $error700'
              },
              ':hover': {
                borderColor: '$error700'
              },
              ':focus': {
                borderColor: '$error700',
                ':hover': {
                  borderColor: '$error700',
                  _web: {
                    boxShadow: 'inset 0 0 0 1px $error700'
                  }
                }
              },
              ':disabled': {
                ':hover': {
                  borderColor: '$error700',
                  _web: {
                    boxShadow: 'inset 0 0 0 1px $error700'
                  }
                }
              }
            },

            _dark: {
              ':focus': {
                borderColor: '$transparent',
                _web: {
                  boxShadow: 'inset 0 0 0 1px $transparent'
                }
              },
              ':invalid': {
                borderColor: '$error400',
                _web: {
                  boxShadow: 'inset 0 0 0 1px $error400'
                },
                ':hover': {
                  borderColor: '$error400'
                },
                ':focus': {
                  borderColor: '$error400',
                  ':hover': {
                    borderColor: '$error400',
                    _web: {
                      boxShadow: 'inset 0 0 0 1px $error400'
                    }
                  }
                },
                ':disabled': {
                  ':hover': {
                    borderColor: '$error400',
                    _web: {
                      boxShadow: 'inset 0 0 0 1px $error400'
                    }
                  }
                }
              }
            }
          }
        }
      },

      defaultProps: {
        size: 'md',
        variant: 'outline'
      }
    }
  },
  ModalContent: {
    theme: {
      backgroundColor: '$backgroundSecondaryLight',
      borderRadius: '$md',
      padding: '$6',
      width: '$full',
      height: '100%',
      _dark: {
        backgroundColor: '$backgroundSecondary'
      }
    }
  },
  SelectTrigger: {
    theme: {
      borderWidth: 1,
      borderColor: '$border',
      borderRadius: '$sm',
      flexDirection: 'row',
      overflow: 'hidden',
      alignItems: 'center',

      ':hover': {
        borderColor: '$border'
      },

      ':focus': {
        borderColor: '$border'
      },

      ':disabled': {
        opacity: 0.4,
        ':hover': {
          borderColor: '$transparent'
        }
      },

      _input: {
        py: 'auto',
        px: '$3'
      },

      _icon: {
        color: '$backgroundLight500',
        _dark: {
          color: '$backgroundLight500'
        }
      },

      _dark: {
        borderColor: '$border',
        ':hover': {
          borderColor: '$borderDark400'
        },
        ':focus': {
          borderColor: '$border'
        },
        ':disabled': {
          ':hover': {
            borderColor: '$transparent'
          }
        }
      },

      variants: {
        size: {
          xl: {
            h: '$12',
            _input: {
              fontSize: '$xl'
            },
            _icon: {
              h: '$6',
              w: '$6'
            }
          },
          lg: {
            h: '$11',
            _input: {
              fontSize: '$lg'
            },
            _icon: {
              h: '$5',
              w: '$5'
            }
          },
          md: {
            h: '$10',
            _input: {
              fontSize: '$md'
            },
            _icon: {
              h: '$4',
              w: '$4'
            }
          },
          sm: {
            h: '$9',
            _input: {
              fontSize: '$sm'
            },
            _icon: {
              h: '$3.5',
              w: '$3.5'
            }
          }
        },
        variant: {
          underlined: {
            _input: {
              _web: {
                outlineWidth: 0,
                outline: 'none'
              },
              px: '$0'
            },
            borderWidth: 0,
            borderRadius: 0,
            ':focus': {
              borderColor: '$transparent',
              _web: {
                boxShadow: 'inset 0 -1px 0 0 $transparent'
              },
              ':hover': {
                borderColor: '$primary700',
                _web: {
                  boxShadow: 'inset 0 -1px 0 0 $transparent'
                }
              }
            },
            ':invalid': {
              borderBottomWidth: 2,
              borderBottomColor: '$error700',
              _web: {
                boxShadow: 'inset 0 -1px 0 0 $error700'
              },
              ':hover': {
                borderBottomColor: '$error700'
              },
              ':focus': {
                borderBottomColor: '$error700',
                ':hover': {
                  borderBottomColor: '$error700',
                  _web: {
                    boxShadow: 'inset 0 -1px 0 0 $transparent'
                  }
                }
              },
              ':disabled': {
                ':hover': {
                  borderBottomColor: '$transparent',
                  _web: {
                    boxShadow: 'inset 0 -1px 0 0 $transparent'
                  }
                }
              }
            },
            _dark: {
              ':focus': {
                borderColor: '$transparent',
                _web: {
                  boxShadow: 'inset 0 -1px 0 0 $transparent'
                }
              },
              ':invalid': {
                borderColor: '$transparent',
                _web: {
                  boxShadow: 'inset 0 -1px 0 0 $transparent'
                },
                ':hover': {
                  borderBottomColor: '$transparent'
                },
                ':focus': {
                  borderBottomColor: '$transparent',
                  ':hover': {
                    borderBottomColor: '$transparent',
                    _web: {
                      boxShadow: 'inset 0 -1px 0 0 $transparent'
                    }
                  }
                },

                ':disabled': {
                  ':hover': {
                    borderBottomColor: '$transparent',
                    _web: {
                      boxShadow: 'inset 0 -1px 0 0 $transparent'
                    }
                  }
                }
              }
            }
          },
          outline: {
            _input: {
              _web: {
                outlineWidth: 0,
                outline: 'none'
              }
            },
            ':focus': {
              borderColor: '$primary700',
              _web: {
                boxShadow: 'inset 0 0 0 1px $primary700'
              },
              ':hover': {
                borderColor: '$primary700',
                _web: {
                  boxShadow: 'inset 0 0 0 1px $primary600'
                }
              }
            },
            ':invalid': {
              borderColor: '$error700',
              _web: {
                boxShadow: 'inset 0 0 0 1px $error700'
              },
              ':hover': {
                borderColor: '$error700'
              },
              ':focus': {
                borderColor: '$error700',
                ':hover': {
                  borderColor: '$error700',
                  _web: {
                    boxShadow: 'inset 0 0 0 1px $error700'
                  }
                }
              },
              ':disabled': {
                ':hover': {
                  borderColor: '$error700',
                  _web: {
                    boxShadow: 'inset 0 0 0 1px $error700'
                  }
                }
              }
            },
            _dark: {
              ':focus': {
                borderColor: '$primary400',
                _web: {
                  boxShadow: 'inset 0 0 0 1px $primary400'
                }
              },
              ':invalid': {
                borderColor: '$error400',
                _web: {
                  boxShadow: 'inset 0 0 0 1px $error400'
                },
                ':hover': {
                  borderColor: '$error400'
                },
                ':focus': {
                  borderColor: '$error400',
                  ':hover': {
                    borderColor: '$error400',
                    _web: {
                      boxShadow: 'inset 0 0 0 1px $error400'
                    }
                  }
                },
                ':disabled': {
                  ':hover': {
                    borderColor: '$error400',
                    _web: {
                      boxShadow: 'inset 0 0 0 1px $error400'
                    }
                  }
                }
              }
            }
          },
          rounded: {
            borderRadius: 999,
            _input: {
              px: '$4',
              _web: {
                outlineWidth: 0,
                outline: 'none'
              }
            },
            ':focus': {
              borderColor: '$primary700',
              _web: {
                boxShadow: 'inset 0 0 0 1px $primary700'
              },
              ':hover': {
                borderColor: '$primary700',
                _web: {
                  boxShadow: 'inset 0 0 0 1px $primary600'
                }
              }
            },
            ':invalid': {
              borderColor: '$error700',
              _web: {
                boxShadow: 'inset 0 0 0 1px $error700'
              },
              ':hover': {
                borderColor: '$error700'
              },
              ':focus': {
                borderColor: '$error700',
                ':hover': {
                  borderColor: '$error700',
                  _web: {
                    boxShadow: 'inset 0 0 0 1px $error700'
                  }
                }
              },
              ':disabled': {
                ':hover': {
                  borderColor: '$error700',
                  _web: {
                    boxShadow: 'inset 0 0 0 1px $error700'
                  }
                }
              }
            },

            _dark: {
              ':focus': {
                borderColor: '$primary400',
                _web: {
                  boxShadow: 'inset 0 0 0 1px $primary400'
                }
              },
              ':invalid': {
                borderColor: '$error400',
                _web: {
                  boxShadow: 'inset 0 0 0 1px $error400'
                },
                ':hover': {
                  borderColor: '$error400'
                },
                ':focus': {
                  borderColor: '$error400',
                  ':hover': {
                    borderColor: '$error400',
                    _web: {
                      boxShadow: 'inset 0 0 0 1px $error400'
                    }
                  }
                },
                ':disabled': {
                  ':hover': {
                    borderColor: '$error400',
                    _web: {
                      boxShadow: 'inset 0 0 0 1px $error400'
                    }
                  }
                }
              }
            }
          }
        }
      },

      defaultProps: {
        size: 'md',
        variant: 'outline'
      }
    }
  },
  Text: {
    theme: {
      color: '$textLigth',
      variants: {
        size: {
          xs: {
            fontSize: 'xs',
            lineHeight: '$xs'
          },
          sm: {
            fontSize: '$sm',
            lineHeight: '$sm'
          },
          md: {
            fontSize: '$md',
            lineHeight: '$md'
          },
          lg: {
            fontSize: '$lg',
            lineHeight: '$lg'
          },
          xl: {
            fontSize: '$xl',
            lineHeight: '$xl'
          }
        }
      },
      _dark: {
        color: '$text'
      }
    }
  },
  Pressable: {
    theme: {
      _web: {
        cursor: 'pointer',
        ':hover': {
          opacity: 0.8,
          backgroundColor: '$backgroundActive'
        },
        ':active': {
          opacity: 0.6
        }
      }
    }
  },
  PopoverBackdrop: {
    theme: {
      ':initial': {
        opacity: 0
      },
      ':animate': {
        opacity: 0.6
      },
      ':exit': {
        opacity: 0
      },
      ':transition': {
        type: 'spring',
        damping: 18,
        stiffness: 250,
        opacity: {
          type: 'timing',
          duration: 250
        }
      },
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      bg: '$backgroundLight950',
      // @ts-ignore
      _dark: {
        bg: '$backgroundDark950'
      },
      // @ts-ignore
      _web: {
        cursor: 'default'
      }
    }
  },
  Switch: {
    theme: {
      props: {
        // todo: add support for this in style.gluestack.io
        // trackColor: { false: '$backgroundLight300', true: '$primary600' },

        // hacky fix for the above
        //@ts-ignore
        trackColor: { false: '$disabled', true: '$pxsol' },
        thumbColor: '$disabled',
        //@ts-ignore
        activeThumbColor: '$backgroundLight0',

        // for ios specifically in unchecked state
        ios_backgroundColor: '$backgroundLight300'
      },
      borderRadius: '$full',
      variants: {
        //@ts-ignore

        size: {
          sm: {
            transform: [
              {
                scale: 0.75
              }
            ]
          },
          md: {},
          lg: {
            transform: [
              {
                scale: 1.25
              }
            ]
          }
        }
      },
      _web: {
        ':focus': {
          outlineWidth: 0,
          outlineColor: '$primary700',
          outlineStyle: 'solid',
          _dark: {
            // @ts-ignore
            outlineColor: '$primary600',
            outlineWidth: 0,
            outlineStyle: 'solid'
          }
        }
      },

      defaultProps: {
        size: 'md'
      },
      ':disabled': {
        _web: {
          cursor: 'pointer',
          ':disabled': {
            cursor: 'not-allowed'
          }
        },
        opacity: 0.4,
        //@ts-ignore
        trackColor: { false: '$backgroundLight300', true: '$pxsol' },
        // for ios specifically in unchecked state
        ios_backgroundColor: '$backgroundLight300',
        ':hover': {
          props: {
            //@ts-ignore
            trackColor: { false: '$backgroundLight300', true: '$pxsol500' }
          }
        }
      },
      ':invalid': {
        borderColor: '$error700',
        borderRadius: 12,
        borderWidth: 2
      },
      ':hover': {
        props: {
          // todo: add support for this in style.gluestack.io
          // trackColor: { false: '$backgroundLight400', true: '$primary700' },

          // hacky fix for the above
          //@ts-ignore

          trackColor: { false: '$backgroundLight400', true: '$pxsol500' },
          ios_backgroundColor: '$backgroundLight400'
        },
        ':invalid': {
          props: {
            // todo: add support for this in style.gluestack.io
            // trackColor: { false: '$backgroundLight400', true: '$primary700' },

            // hacky fix for the above
            //@ts-ignore

            trackColor: { false: '$backgroundLight300', true: '$error' }
          }
        }
      },
      ':checked': {
        props: {
          //@ts-ignore
          thumbColor: '$backgroundLight0'
        }
      },
      _dark: {
        props: {
          //@ts-ignore
          trackColor: { false: '$disabled', true: '$pxsol500' },
          thumbColor: '$disabled',
          //@ts-ignore
          activeThumbColor: '$backgroundLight300'
        },
        ':invalid': {
          borderColor: '$error400',
          borderRadius: 12,
          borderWidth: 2
        },
        ':hover': {
          props: {
            //@ts-ignore
            trackColor: { false: '$backgroundDark600', true: '$pxsol500' },
            ios_backgroundColor: '$backgroundLight400'
          },
          ':invalid': {
            props: {
              // todo: add support for this in style.gluestack.io
              // trackColor: { false: '$backgroundLight400', true: '$primary700' },

              // hacky fix for the above
              //@ts-ignore

              trackColor: { false: '$backgroundDark700', true: '$error' }
            }
          }
        },
        ':disabled': {
          _web: {
            cursor: 'pointer',
            ':disabled': {
              cursor: 'not-allowed'
            }
          },
          opacity: 0.4,
          //@ts-ignore
          trackColor: { false: '$backgroundLight300', true: '$pxsol600' },
          // for ios specifically in unchecked state
          ios_backgroundColor: '$backgroundLight300',
          ':hover': {
            props: {
              //@ts-ignore
              trackColor: { false: '$backgroundDark700', true: '$primary500' }
            }
          }
        }
      }
    }
  }
};
