import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import { HStack, Pressable, Spinner, VStack } from '@gluestack-ui/themed';
import { Message } from '@/types/message';
import ItemMessage from './ItemMessage';

function ResponseMessages({ loading, messages, onPressMessage }) {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flex: 1,
          gap: 5
        },
        message: {
          flex: 1,
          gap: 5,
          width: '100%',
          borderWidth: 1,
          padding: 10,
          borderRadius: 10
        },
        leftMessage: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flex: 1,
          gap: 5
        },
        headerMessage: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flex: 1,
          gap: 5
        }
      }),
    []
  );

  return (
    <VStack justifyContent="flex-start" alignItems="flex-start" style={styles.container}>
      {messages?.map((message: Message) => (
        <Pressable
          onPress={() => onPressMessage(message)}
          key={message?._id}
          disabled={loading[message?.id_message]}
          style={{
            width: '100%',
            borderRadius: 10
          }}
        >
          <HStack
            justifyContent="space-between"
            alignItems="center"
            borderColor="$border"
            style={styles.message}
          >
            <ItemMessage message={message} />
            {loading[message?.id_message] && <Spinner color="$pxsol" />}
          </HStack>
        </Pressable>
      ))}
    </VStack>
  );
}

export default React.memo(ResponseMessages);
