import React, { memo, useState } from 'react';
import AcordionCustom from '@/atoms/AcordionCustom';

import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { InfoOutline, VolumeMedium, VolumeOff } from '@/assets/icons/Icons';
import translate from '@/helpers/traslations';

import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';

import { updateNotification } from '@/redux/slices/auth/requests';
import { IUpdateNotification } from '@/redux/slices/auth/interfaces.d';
import { useLinkss } from '@/providers/LinkProvider';
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import CardCustom from '@/atoms/CardCustom';
import { playAudio } from '@/utils/web/functions';

const SoundNotifications = () => {
  const dispatch = useAppDispatch();
  const soundNotification = useAppSelector(
    (state: RootState) => state.user.user?.notifications_config?.sound_notification
  );
  const [updatingNotification, setUpdatingNotification] = useState<boolean>(false);

  const { showToast } = useLinkss();

  const disableSoundNotifications = () => {
    setUpdatingNotification(true);
    if (!soundNotification) {
      playAudio();
    }
    dispatch(
      updateNotification({
        propertyNotification: IUpdateNotification.soundNotificaciton,
        data: !soundNotification
      })
    )
      .then((response) => {
        showToast({
          type: 'success',
          title: translate('success'),
          subtitle: translate('messages.successToUpdateStatus'),
          variant: 'accent'
        });
      })
      .catch((error) => {
        showToast({
          type: 'error',
          title: translate('error'),
          subtitle: translate('messageError.errorToUpdateStatus'),
          variant: 'accent'
        });
      })
      .finally(() => {
        setUpdatingNotification(false);
      });
  };

  return (
    <AcordionCustom
      iconLeft={soundNotification ? VolumeMedium : VolumeOff}
      idString="notification.sound"
      titleSize="lg"
      bgColor="$background2"
      mbItem="$0"
      defaultOpen={false}
      sx={{
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginBottom: 20,
        borderRadius: 8
      }}
      middleElement={
        <CustomTooltip title={translate('notification.soundTooltip')}>
          <MaterialCommunity icon={InfoOutline} size="md" color="$info" />
        </CustomTooltip>
      }
    >
      <CardCustom
        idString={'soundNotifications'}
        idStringSubtitle="soundOfAllMessages"
        leftElement={
          <MaterialCommunity
            icon={soundNotification ? VolumeMedium : VolumeOff}
            color="$text"
            size="xl"
          />
        }
        switchProp={{
          value: soundNotification,
          onChange: disableSoundNotifications,
          disabled: updatingNotification
        }}
      />
    </AcordionCustom>
  );
};

export default memo(SoundNotifications);
