import React, { memo } from 'react';
import { Button, ButtonIcon, ButtonText, ButtonSpinner } from '@gluestack-ui/themed';
import translate from '@/helpers/traslations';
import { StyleProp, ViewStyle } from 'react-native';
import { SxProps } from '@gluestack-style/react/lib/typescript/types';

function MainCta({
  uppercase = false,
  onPress = null,
  labelId,
  icon,
  variant = 'solid',
  disabled = false,
  iconPosition = 'right',
  loading = false,
  iconSize = 'sm',
  buttonSize = 'md',
  action = 'primary',
  w = '$5',
  borderRadius = '$full',
  iconColor = '$text',
  sx = {},
  trl = true
}: {
  onPress?: any;
  labelId?: string;
  icon?: any;
  variant?: 'solid' | 'outline' | 'link';
  disabled?: boolean;
  iconPosition?: 'left' | 'right';
  iconSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xs';
  buttonSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  action?: 'primary' | 'secondary' | 'default' | 'positive' | 'negative';
  loading?: boolean;
  borderRadius?: '$full' | '$none' | '$xs' | '$sm' | '$md' | '$lg' | '$xl' | '$2xl' | '$3xl';
  iconColor?: string;
  w?:
    | '$px'
    | '$1'
    | '$2'
    | '$3'
    | '$4'
    | '$5'
    | '$0'
    | '$0.5'
    | '$1.5'
    | '$2.5'
    | '$3.5'
    | '$4.5'
    | '$6'
    | '$7'
    | '$8'
    | '$9'
    | '$10'
    | '$11'
    | '$12'
    | '$16'
    | '$full';
  sx?: Partial<SxProps<StyleProp<ViewStyle>>>;
  uppercase?: boolean;
  trl?: boolean;
}) {
  return labelId ? (
    <Button
      onPress={onPress}
      isDisabled={disabled}
      size={buttonSize}
      action={action}
      variant={variant}
      borderRadius={borderRadius}
      sx={sx}
    >
      {iconPosition === 'left' &&
        icon &&
        (loading ? (
          <ButtonSpinner mr="$1" color="$pxsol" />
        ) : (
          <ButtonIcon color={iconColor} as={icon} w={w} h={w} size={iconSize ?? null} />
        ))}
      <ButtonText
        color={iconColor}
        numberOfLines={1}
        lineBreakMode="head"
        textTransform={uppercase ? 'uppercase' : 'none'}
        ml="$2"
        mr="$2"
      >
        {trl ? translate(labelId) : labelId}
      </ButtonText>

      {iconPosition === 'right' &&
        icon &&
        (loading ? (
          <ButtonSpinner mr="$1" />
        ) : (
          <ButtonIcon color={iconColor} as={icon} w={w} h={w} size={iconSize ?? null} />
        ))}
    </Button>
  ) : (
    <Button
      onPress={onPress}
      isDisabled={disabled}
      size={buttonSize}
      action={action}
      p={buttonSize === 'lg' ? '$3.5' : '$3'}
      variant={variant}
      borderRadius={borderRadius}
      sx={sx}
      // bg={background}
    >
      {loading ? (
        <ButtonSpinner mr="$1" color="$pxsol" />
      ) : (
        <ButtonIcon color={iconColor} as={icon} size={iconSize ?? null} />
      )}
    </Button>
  );
}

export default memo(MainCta);
