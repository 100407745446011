import { View, Platform, Linking } from 'react-native';
import React from 'react';

import { Menu, MenuItem, MenuItemLabel, Pressable, Icon } from '@gluestack-ui/themed';
import translate from '@/helpers/traslations';
import { cleanContacts, cleanStateConversation, cleanStateUser } from '@/redux/actionTypes';
import { cleanMessages } from '@/redux/slices/messages';
import { clearParticipants } from '@/redux/slices/participants';
import { cleanTemplates } from '@/redux/slices/templates';
import { clearFragments } from '@/redux/slices/fragments';
import { crearFilters } from '@/redux/slices/filters';
import useAuth0 from '@/hooks/useAuth0';
import { IconArrowLeft, Logout } from '@/assets/icons/Icons';
import ItemUser from '../../atoms/ItemUser';
import MemoizedAvatarCard from '../../atoms/AvatarCard';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import ContainerCompany from './ContainerCompany';

function ContextMenu() {
  const name = useAppSelector((state: RootState) => state.user.user?.name);
  const avatar = useAppSelector((state: RootState) => state.user.user?.avatar);
  const team = useAppSelector((state: RootState) => state.user.team);
  const product = useAppSelector((state: RootState) => state.user.product);
  const salesPoint = useAppSelector((state: RootState) => state.user.salesPoint);

  const dispatch = useAppDispatch();
  const { logout, clearSession } = useAuth0();

  const onPressLogout = async () => {
    dispatch(cleanStateConversation());
    dispatch(cleanStateUser());
    dispatch(cleanContacts());
    dispatch(cleanMessages());
    dispatch(clearParticipants());
    dispatch(cleanTemplates());
    dispatch(clearFragments());
    dispatch(crearFilters());
    try {
      if (Platform.OS === 'web') {
        await logout();
      } else {
        await clearSession();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Menu
      bg="$background"
      placement="bottom left"
      onSelectionChange={(item: any) => {
        switch (item?.currentKey) {
          case 'logout':
            onPressLogout();
            break;
          case 'GoToPms':
            Linking.openURL('https://pms.pxsol.com');
            break;
          default:
            break;
        }
      }}
      closeOnSelect={true}
      selectionMode="single"
      trigger={({ ...triggerProps }) => {
        return (
          <Pressable
            {...triggerProps}
            style={{
              borderRadius: 100
            }}
          >
            <MemoizedAvatarCard picture={avatar} width="$9" name={name} />
          </Pressable>
        );
      }}
    >
      <MenuItem key="user" textValue="user" onPress={() => console.log('sadasdas')}>
        <ItemUser />
      </MenuItem>

      <MenuItem key="company" textValue="company" onPress={() => console.log('sadasdas')}>
        <View style={{ paddingHorizontal: 12, paddingVertical: 0 }}>
          <ContainerCompany
            direction="column"
            pos_name={salesPoint?.Title}
            team_name={team?.name}
            product_name={product?.NombreComercial}
          />
        </View>
      </MenuItem>

      <MenuItem key="GoToPms" textValue="GoToPms">
        <Icon as={IconArrowLeft} size="md" />
        <MenuItemLabel ml="$4" size="md">
          {translate('gotToPms')}
        </MenuItemLabel>
      </MenuItem>
      <MenuItem key="logout" textValue="logout">
        <Icon as={Logout} size="md" />
        <MenuItemLabel ml="$4" size="md">
          {translate('logout')}
        </MenuItemLabel>
      </MenuItem>
    </Menu>
  );
}

export default React.memo(ContextMenu);
