import { StyleSheet, View } from 'react-native';
import React from 'react';
import translate from '@/helpers/traslations';
import { openURL } from '@/utils/functions';
import { HStack, Pressable, Text } from '@gluestack-ui/themed';
import TextInfo from './TextInfo';
import MaterialCommunity from '../components/Icons/MaterialCommunity';
import { Email } from '@/assets/icons/Icons';

function ViewEmailsContactCard({ user }) {
  const URL_SEND_EMAIL = `https://pms.pxsol.com/apps/home/main.html?secondaryWindow=/tickets/compose.php?to_email=`;

  const onPressEmail = (email) => {
    openURL(URL_SEND_EMAIL + email?.email);
  };

  return (
    <View
      style={{
        width: '100%'
      }}
    >
      {user?.emails?.length > 0 ? (
        user?.emails?.map((email) => (
          <Pressable
            onPress={() => onPressEmail(email)}
            style={styles.container}
            key={email?.id}
            sx={{
              ':hover': {
                backgroundColor: '$backgroundActive'
              }
            }}
          >
            <HStack
              space="sm"
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <HStack
                space="sm"
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                <MaterialCommunity size="sm" icon={Email} />
                <Text key={email?.email}>{`${translate(`sendEmailTo`)} ${email?.email}`}</Text>
              </HStack>
            </HStack>
          </Pressable>
        ))
      ) : (
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1,
            paddingRight: 16
          }}
        >
          <TextInfo text={translate('noEmails')} />
        </View>
      )}
    </View>
  );
}

export default React.memo(ViewEmailsContactCard);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 5,
    paddingHorizontal: 16,
    paddingVertical: 8
  }
});
