import React, { Text, type TextProps, StyleSheet } from 'react-native';
import { colorsProps, useThemeColor } from '@/hooks/useTheme';

export type ThemedTextProps = TextProps & {
  lightColor?: string;
  darkColor?: string;
  size?: 'xs' | '2xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  color?: colorsProps;
};

export function TText({
  style,
  lightColor,
  darkColor,
  size = 'md',
  color = 'text',
  ...rest
}: ThemedTextProps) {
  const textColor = useThemeColor({ light: lightColor, dark: darkColor }, color);

  return <Text style={[{ color: textColor }, size ? styles[size] : undefined, style]} {...rest} />;
}

const styles = StyleSheet.create({
  xs: {
    fontSize: 10,
    lineHeight: 16
  },
  sm: {
    fontSize: 12,
    lineHeight: 20
  },
  md: {
    fontSize: 14,
    lineHeight: 24
  },
  xl: {
    fontSize: 32,
    lineHeight: 32
  },
  lg: {
    fontSize: 18
  },
  '2xl': {
    fontSize: 40,
    lineHeight: 40
  }
});
