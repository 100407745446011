import { locale } from 'moment';

import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import { EsResource, EnResource } from '../assets/localization';

const i18n = new I18n(
  {
    en: EnResource,
    es: EsResource
  },
  {
    locale: Localization.locale,
    enableFallback: true,
    defaultLocale: 'es'
  }
);

locale(Localization.locale);

export default i18n;
