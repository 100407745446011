import { TText, TVView } from '@/components/themed';
import React from 'react';

const SectionFrom = ({ title, idStringSubtitle, children }) => {
  return (
    <TVView
      bgColor="backgroundSecondary"
      style={{
        gap: 8,
        padding: 16,
        borderRadius: 8,
        marginBottom: 4
      }}
    >
      <TVView style={{ justifyContent: 'flex-start', alignItems: 'flex-start', marginVertical: 2 }}>
        <TText size="md" style={{ fontWeight: 'bold' }}>
          {title}
        </TText>
        <TText size="sm" color="secondary">
          {idStringSubtitle}
        </TText>
      </TVView>
      {children}
    </TVView>
  );
};

export default React.memo(SectionFrom);
