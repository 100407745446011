import React, { useMemo } from 'react';
import { Switch } from '@gluestack-ui/themed';
import translate from '@/helpers/traslations';
import { useChangeTheme } from '@/providers/CustomThemeProvider';
import { THView, TText, TView, TVView } from '@/components/themed';
import { StyleSheet } from 'react-native';
import CardCustom from '@/atoms/CardCustom';

function SettingsCustomizations() {
  const { toggleTheme, themeMode } = useChangeTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: '100%',
          height: '100%',
          padding: 20,
          alignItems: 'flex-start',
          justifyContent: 'flex-start'
        },
        content: {
          width: '100%',
          height: '100%',
          paddingHorizontal: 20,
          paddingVertical: 12,
          borderRadius: 10,
          alignItems: 'center',
          justifyContent: 'space-between'
        }
      }),
    []
  );

  return (
    <TView style={styles.container} bgColor="backgroundSecondary">
      <CardCustom
        idString="darkTheme"
        // leftElement={<MaterialCommunity icon={Web} color="$text" size="xl" />}
        idStringSubtitle="colorAplication"
        switchProp={{
          value: themeMode === 'dark',
          onChange: () => toggleTheme(),
          disabled: false
        }}
      />
    </TView>
  );
}

export default React.memo(SettingsCustomizations);
