import React, { useEffect, useState } from 'react';
import { FlatList, Platform } from 'react-native';
import { HStack, Text, VStack, View } from '@gluestack-ui/themed';
import { ChangeLogEntry } from '../types';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
// import i18n from '@/providers/Localization';
import { base64ToBlob, getColorByType, getIcons } from '@/utils/functions';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { getChangelog } from '@/redux/slices/templates/requests';
import FormatDate from '@/utils/date/formatDate';
import MainCta from '@/atoms/MainCta';
import { IconArrowUp, IconImage } from '@/assets/icons/Icons';
import * as ImagePicker from 'expo-image-picker';
import PreviewImages from '@/components/conversation/footer/PreviewImages';
import { uploadFileAndSendMessage } from '@/redux/slices/messages/requests';
import { PlatformsEnum } from '@/types/enums/conversation';

type ChangeLogProps = {
  data: ChangeLogEntry[];
};

const SettingsChangeLog: React.FC<ChangeLogProps> = () => {
  const dispatch = useAppDispatch();
  const isPxsol = useAppSelector((state) => state.user.user?.isPxsol);
  const [chagelog, setChangelog] = React.useState<ChangeLogEntry[]>();

  const [imageState, setImageState] = useState({
    loadingPickImage: false,
    previewFiles: [],
    idImage: null
  });

  const converId = '6617fdd5ee30089f3370803c';
  const pickImage = async () => {
    if (Platform.OS !== 'web') {
      const mediaLibraryStatus = await ImagePicker.requestMediaLibraryPermissionsAsync();

      if (mediaLibraryStatus.status !== 'granted') {
        console.error('Permiso de biblioteca de medios denegado');
        setImageState({
          ...imageState,
          loadingPickImage: false
        });
        return;
      }
    }
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      quality: 0.5,
      base64: true,
      videoQuality: 1,
      selectionLimit: 5
    });

    if (!result.canceled) {
      setImageState({
        ...imageState,
        loadingPickImage: true
      });

      if (Platform.OS === 'web') {
        const file = await base64ToBlob(result.assets[0].uri);

        // setPreviewFiles([...previewFiles, { file, uri: result.assets[0].uri }]);

        // setLoadingPickImage(false);
        setImageState({
          ...imageState,
          loadingPickImage: false,
          previewFiles: [...imageState.previewFiles, { file, uri: result.assets[0].uri }]
        });
      } else {
        const uriParts = result.assets[0].uri.split('.');
        const fileExtension = uriParts[uriParts.length - 1];

        // Determina el tipo MIME de la imagen en función de la extensión del archivo
        let mimeType = '';
        switch (fileExtension.toLowerCase()) {
          case 'jpg':
            mimeType = 'image/jpg';
            break;
          case 'jpeg':
            mimeType = 'image/jpeg';
            break;
          case 'png':
            mimeType = 'image/png';
            break;
          // Añade más casos si es necesario, según los tipos de archivo que esperes
          default:
            console.error('Tipo de archivo desconocido:', fileExtension);
            return;
        }
        setImageState({
          ...imageState,
          loadingPickImage: false,
          previewFiles: [
            ...imageState.previewFiles,
            { file: result.assets[0].uri, uri: result.assets[0].uri, mimeType }
          ]
        });
      }
    } else {
      setImageState({
        ...imageState,
        loadingPickImage: false
      });
    }
  };

  useEffect(() => {
    dispatch(getChangelog()).then((res) => {
      setChangelog(res);
    });
  }, []);

  const uploadImage = () => {
    dispatch(
      uploadFileAndSendMessage({
        fileData: {
          conversationId: converId,
          // file: previewFiles[0].file,
          // uri: previewFiles[0].uri,
          file: imageState.previewFiles[0].file,
          uri: imageState.previewFiles[0].uri,
          originalName: 'image',
          // mimeType: previewFiles[0].mimeType,
          mimeType: imageState.previewFiles[0].mimeType,
          platform: PlatformsEnum.whatsapp,
          temp: false
        }
      })
    )
      .then((upload: { id?: number; name?: string }) => {
        setImageState({
          ...imageState,
          idImage: upload?.id,
          previewFiles: []
        });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const previewFiles = (prev) => {
    setImageState({
      ...imageState,
      previewFiles: prev
    });
  };

  return (
    <View
      bg="$backgroundSecondary"
      style={{
        flex: 1,
        overflow: 'hidden',
        width: '100%'
      }}
    >
      {isPxsol &&
        (previewFiles?.length > 0 ? (
          <HStack width="100%" justifyContent="space-between" alignItems="center" space="sm">
            <PreviewImages
              pickImage={pickImage}
              previewFiles={imageState?.previewFiles}
              setPreviewFiles={previewFiles}
            />
            <MainCta
              icon={IconArrowUp}
              onPress={uploadImage}
              loading={imageState?.loadingPickImage}
              disabled={imageState?.loadingPickImage}
              variant="outline"
              iconSize="lg"
              sx={{
                borderColor: '$secondary',
                borderWidth: 1,
                paddingHorizontal: 10
              }}
            />
          </HStack>
        ) : (
          <HStack alignItems="center" justifyContent="center">
            <MainCta
              icon={IconImage}
              onPress={pickImage}
              loading={imageState?.loadingPickImage}
              disabled={imageState?.loadingPickImage}
              variant="outline"
              iconSize="lg"
              sx={{
                borderColor: '$secondary',
                borderWidth: 1
              }}
            />
          </HStack>
        ))}
      {imageState?.idImage && <Text selectable>{imageState?.idImage}</Text>}
      <FlatList
        data={chagelog}
        keyExtractor={(item) => item.version}
        renderItem={({ item }) => (
          <VStack
            bg="$background"
            padding={10}
            style={{
              marginVertical: 10,
              width: '95%',
              alignSelf: 'center'
            }}
          >
            <Text $sm-bg="lg">{`${FormatDate(item.date)} - v${item.version}`}</Text>

            {item?.changes?.map((change) => (
              <VStack
                alignContent="flex-start"
                justifyContent="flex-start"
                style={{
                  gap: 5,
                  marginTop: 10
                }}
                key={JSON.stringify(change)}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 5
                  }}
                >
                  <MaterialCommunity
                    icon={getIcons(change.type)}
                    size="md"
                    color={getColorByType(change.type)}
                  />
                  <Text size="sm">{change.title}</Text>
                </View>
                <Text size="md">{change.description}</Text>
              </VStack>
            ))}
          </VStack>
        )}
      />
    </View>
  );
};

export default React.memo(SettingsChangeLog);
