import React, { memo } from 'react';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import translate from '@/helpers/traslations';
import { ChevronDown } from '@/assets/icons/Icons';
import { THView, TText } from '@/components/themed';
import { colorsProps } from '@/hooks/useTheme';

const CustomSelectForm = ({
  idString,
  sx,
  trlate = true,
  color = 'secondary'
}: {
  idString: string;
  sx?: any;
  trlate?: boolean;
  color?: colorsProps;
}) => {
  return (
    <THView
      border="border"
      style={[
        sx,
        {
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between'
        }
      ]}
    >
      <TText numberOfLines={1} lineBreakMode="clip" color={color}>
        {trlate ? translate(idString) : idString}
      </TText>
      <MaterialCommunity size="lg" icon={ChevronDown} />
    </THView>
  );
};

export default memo(CustomSelectForm);
