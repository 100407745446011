import React, { memo } from 'react';
import { IconPlus, Trash } from '@/assets/icons/Icons';
import {
  renderDiasSeleccionados,
  renderHorasSeleccionadas
} from '@/redux/slices/automatization/functions';
import MainCta from '@/atoms/MainCta';
import { useAppDispatch } from '@/redux/store/index';
import { deleteRangeDate } from '@/redux/slices/automatization/index';
import { PropertyType } from '@/types/enums/workflows';
import { Condition } from '@/components/automatizations/interface.d';
import { Routes } from '@/routers/routes';
import { THView, TText, TVView } from '@/components/themed';

const ItemRangeDate = ({ rangeDate, routeForm }) => {
  const dispatch = useAppDispatch();

  return (
    <TVView style={{ width: '100%', gap: 8 }}>
      {rangeDate &&
        rangeDate?.length > 0 &&
        rangeDate?.map((range, index) => (
          <THView
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              borderWidth: 1,
              borderRadius: 8,
              padding: 4
            }}
            border="secondary"
            bgColor="background"
          >
            <TText>
              {renderDiasSeleccionados(
                range?.conditions?.find(
                  (condition: Condition) => condition?.property_type === PropertyType.day_of_week
                )?.value
              ) + ' '}
              {renderHorasSeleccionadas(
                range?.conditions?.find(
                  (condition: Condition) => condition?.property_type === PropertyType.time
                )?.value
              )}
            </TText>
            <MainCta
              icon={Trash}
              variant="outline"
              onPress={() => dispatch(deleteRangeDate(index))}
            />
          </THView>
        ))}
      <TVView>
        <MainCta
          labelId="actions.addRangeDayAndTime"
          variant="outline"
          sx={{
            borderWidth: 1,
            borderColor: '$border'
          }}
          loading={false}
          disabled={false}
          icon={IconPlus}
          iconPosition="right"
          onPress={() => routeForm?.navigation.navigate(Routes.AutomatizationRange)}
        />
      </TVView>
    </TVView>
  );
};

export default memo(ItemRangeDate);
