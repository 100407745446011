import React from 'react';
import i18n from '@/providers/Localization';
import { useNavigation } from '@react-navigation/native';
import { HStack, Text } from '@gluestack-ui/themed';
import MainCta from './MainCta';
import { HEIGHT_HEADER_LAYOUT } from '@/utils/globales';
import { IconArrowLeft } from '@/assets/icons/Icons';

function HeaderAppBar({
  idStringTitle,
  ctaBack
}: {
  idStringTitle: string;
  ctaBack?: React.ReactElement;
}) {
  const navigation = useNavigation();

  return (
    <HStack
      w="$full"
      space="sm"
      bg="$backgroundSecondary"
      justifyContent="flex-start"
      alignItems="center"
      borderBottomColor="$border"
      borderBottomWidth={1}
      sx={{
        backgroundColor: '$backgroundSecondaryLight',
        height: HEIGHT_HEADER_LAYOUT,
        paddingHorizontal: '$1',
        _dark: {
          backgroundColor: '$backgroundSecondary'
        }
      }}
    >
      {ctaBack ? (
        ctaBack
      ) : (
        <MainCta
          iconSize="md"
          icon={IconArrowLeft}
          variant="outline"
          onPress={() => navigation?.goBack()}
        />
      )}
      <Text numberOfLines={1} size="md">
        {i18n.t(idStringTitle)}
      </Text>
    </HStack>
  );
}

export default React.memo(HeaderAppBar);
