import React from 'react';
import i18n from '@/providers/Localization';
import { HStack, Text, View } from '@gluestack-ui/themed';
import { HEIGHT_HEADER_LAYOUT } from '@/utils/globales';

/**
 *
 * @param idString : string
 * @returns  {React.ReactElement}
 */
function HeaderSettingsStack({
  idString,
  rightElement
}: {
  idString: string;
  rightElement?: React.ReactElement;
}): React.ReactElement {
  return (
    <HStack
      bg="$backgroundSecondary"
      borderBottomColor="$border"
      justifyContent="space-between"
      style={{
        height: HEIGHT_HEADER_LAYOUT,
        justifyContent: 'center',
        paddingHorizontal: 20
      }}
    >
      <Text color="$pxsol">{i18n.t(idString)}</Text>
      {rightElement}
    </HStack>
  );
}
/**
 * Exportamos el componente
 * @param idString : string - Es el idString que se quiere tradicir, todos estan en los archivos dentro de assets/localization/
 * @exports HeaderSettingsStack
 * @returns {React.ReactElement}
 * @description Exportamos el componente para poder usarlo en otros ficheros, retorna el idString traducido para el header del drawer
 *
 */
export default React.memo(HeaderSettingsStack);
