import React, { memo, useMemo, useCallback } from 'react';
import { THView, TPressable, TText } from '@/components/themed';
import translate from '@/helpers/traslations';
import { BadgeText } from '@gluestack-ui/themed';
import { Badge } from '@gluestack-ui/themed';
import { StyleSheet } from 'react-native';

const RenderTeam = ({ item, onPressItem, route }) => {
  const current = useMemo(() => route?.params?.team === item?.id, [route, item]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        teamName: {
          marginLeft: 8
        },
        button: {
          paddingVertical: 10,
          borderRadius: 5,
          width: '95%'
        },
        item: {
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: 5
        }
      }),
    []
  );

  const handlePress = useCallback(() => {
    onPressItem(item);
  }, [onPressItem, item]);

  return (
    <TPressable
      key={item?.id}
      bgColor={current ? 'backgroundSecondary' : 'backgroundActive'}
      style={styles.button}
      disabled={current}
      onPress={handlePress}
    >
      <THView style={styles.item}>
        <TText color="text" style={current ? { ...styles.teamName } : styles.teamName}>
          {item?.name?.split('-')[0]}
        </TText>
        {current && (
          <Badge action="muted">
            <BadgeText>{translate('current')}</BadgeText>
          </Badge>
        )}
      </THView>
    </TPressable>
  );
};

export default memo(RenderTeam, (prevProps, nextProps) => {
  return (
    prevProps.item.id === nextProps.item.id &&
    prevProps.route.params.team === nextProps.route.params.team
  );
});
