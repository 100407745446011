import React, { useMemo } from 'react';
import { Image } from 'expo-image';

import { IS_WEB_PC, verifyIsNotNativeDevice } from '../../utils/functions';
import SearchComponent from '../omnibox/SearchComponent';
import ContainerIconsHeader from './ContainerIconsHeader';
import { TABLET_SCREEN } from '@/utils/globales';
import { TView } from '../themed';
import { StyleSheet } from 'react-native';
import { useMediaQuery } from '@/hooks/useMediaQuery';

function HeaderApp() {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          height: 50,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: IS_WEB_PC ? 'flex-end' : 'space-between',
          gap: 15,
          alignItems: 'center',
          paddingHorizontal: 20
        },
        image: {
          width: 40,
          height: 40
        }
      }),
    []
  );

  const [isMobile] = useMediaQuery([
    {
      maxWidth: TABLET_SCREEN
    }
  ]);

  return (
    <TView
      bgColor="backgroundNavigationPxsol"
      aria-label="Pxsol Conversations"
      style={styles.container}
    >
      {verifyIsNotNativeDevice() ||
        (isMobile && (
          <Image
            alt="logo pxsol"
            source={require('../../assets/px_iso.png')}
            style={styles.image}
          />
        ))}
      <SearchComponent />
      <ContainerIconsHeader />
    </TView>
  );
}

export default React.memo(HeaderApp);
