import React, { createContext, useEffect } from 'react';
import { setVersion } from '../redux/actionTypes';
import { useAppDispatch } from '../redux/store';

export const VersionContext = createContext<any>(null);

type VersionProviderProps = {
  children: React.ReactNode;
};

export const useVersion = () => {
  const context = React.useContext(VersionContext);
  if (context === undefined) {
    throw new Error('useVersion must be used within a VersionProvider');
  }
  return context;
};

const VersionProvider: React.FC<VersionProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  let isControllerChangeHandled = false;
  if ('serviceWorker' in navigator) {
    // Registra un controlador de eventos para escuchar mensajes del Service Worker
    window.addEventListener('offline', () => {
      navigator?.serviceWorker?.controller?.postMessage({
        type: 'OFFLINE'
      });
    });

    window.addEventListener('online', () => {
      navigator.serviceWorker.controller?.postMessage({
        type: 'ONLINE'
      });
    });

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'ONLINE') {
        requestBackgroundSync('queueSendMessage');
      }
    });

    navigator.serviceWorker.getRegistration().then((registration) => {
      // Si hay un Service Worker esperando, envía el mensaje para activarlo
      if (registration && registration.waiting) {
        console.log('waiting');
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        dispatch(setVersion(true));
        // window.location.reload();
      }
    });
    // Añadir el listener para cuando el nuevo Service Worker tome el control
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // Asegúrate de que no se recargue la página más de una vez
      if (!isControllerChangeHandled) {
        // Marcar que el controlador ha cambiado para evitar recargas adicionales
        console.log('controllerchange');
        isControllerChangeHandled = true;
        dispatch(setVersion(true));
        // window.location.reload();
      }
    });
  }

  async function requestBackgroundSync(idString: string) {
    const registration = (await navigator.serviceWorker.ready) as ServiceWorkerRegistration & {
      sync: { register: (idString: string) => Promise<void> };
    };
    await registration.sync.register(idString);
  }

  async function requestNotificationPermission() {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Permiso de notificación concedido');
    } else {
      console.log('Permiso de notificación denegado');
    }
  }

  useEffect(() => {
    if ('Notification' in window) {
      requestNotificationPermission();
    } else {
      console.log('No hay soporte para notificaciones');
    }
  }, []);

  return <VersionContext.Provider value={null}>{children}</VersionContext.Provider>;
};

export default React.memo(VersionProvider);
