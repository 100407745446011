import React, { useEffect, useState } from 'react';
import { GestureResponderEvent } from 'react-native';
import { Spinner, Text, View } from '@gluestack-ui/themed';
import EmptyContacts from './emptystates/EmptyContacts';
import i18n from '../providers/Localization';
import { Contacts } from '@/types/typesRedux/interfaces';
import RenderContactsCard from '../atoms/RenderContactsCard';
import { TText, TView } from './themed';

interface Props {
  filtros: Contacts[];
  callbackToSelectUser?: (user: Contacts) => void;
  loading?: boolean;
  children?: React.ReactNode | null;
  id?: number[];
  separateFracuents?: boolean;
  viewBots?: boolean;
}

function RenderContacts({
  filtros,
  callbackToSelectUser,
  id,
  separateFracuents = false,
  viewBots = true
}: Props) {
  const [bots, setBoots] = useState<Contacts[]>([]);
  const [users, setUsers] = useState<Contacts[]>([]);
  const [frecuent, setFrecuent] = useState<Contacts[]>([]);

  useEffect(() => {
    if (filtros?.length > 0) {
      const bots = filtros?.filter((contact) => contact?.isBot);
      setBoots(bots);
      const users = filtros?.filter((contact) => !contact.isBot && !contact?.isFrecuent);
      setUsers(users);
      if (separateFracuents) {
        const frecuent = filtros?.filter((contact) => contact?.isFrecuent);
        setFrecuent(frecuent);
      }
    }
  }, [filtros]);

  // const dispatch = useAppDispatch();
  const handleContactPress = (user: Contacts, event: GestureResponderEvent) => {
    event.preventDefault(); // Detener la propagación del evento,
    // dispatch(setIdContacto(user?.IDUsuarios));

    callbackToSelectUser(user);
  };

  return (
    <TView
      style={{
        width: '100%',
        marginVertical: 10
      }}
    >
      {frecuent?.length > 0 && (
        <TView
          border="secondary"
          style={{
            borderBottomWidth: 1,
            marginBottom: 10
          }}
        >
          <TText size="lg">{i18n.t('frecuentContacts')}:</TText>
          {frecuent.map((user) => (
            <RenderContactsCard
              key={JSON.stringify(user)}
              id={id}
              callbackOnPress={handleContactPress}
              user={user}
            />
          ))}
        </TView>
      )}
      {bots?.length > 0 && viewBots && (
        <TView
          border="secondary"
          style={{
            borderBottomWidth: 1,
            marginBottom: 10
          }}
        >
          <TText size="lg">{i18n.t('bots')}:</TText>
          {bots.map((bot) => (
            <RenderContactsCard
              key={JSON.stringify(bot)}
              id={id}
              callbackOnPress={handleContactPress}
              user={bot}
            />
          ))}
        </TView>
      )}
      <TText size="lg">{i18n.t('contactos')}</TText>
      {users?.length > 0 ? (
        users?.map((user) => (
          <RenderContactsCard
            key={JSON.stringify(user)}
            id={id}
            callbackOnPress={handleContactPress}
            user={user}
          />
        ))
      ) : (
        <EmptyContacts message={i18n.t('messageError.noContacts')} />
      )}
    </TView>
  );
}

export default React.memo(RenderContacts);
