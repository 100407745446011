import React from 'react';
import HeaderClients from './HeaderClients';
import HeaderChat from './HeaderChat';
import { HeaderLayoutProps } from './interfaces';

const HeaderLayoutList = ({
  type,
  currentFilter,
  handleNewGroup,
  handleNewConver
}: HeaderLayoutProps) => {
  const renderHeader = {
    clients: <HeaderClients />,
    chat: (
      <HeaderChat
        currentFilter={currentFilter}
        handleNewGroup={handleNewGroup}
        handleNewConver={handleNewConver}
      />
    )
  };

  return renderHeader[type];
};

export default React.memo(HeaderLayoutList, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
