import React, { memo } from 'react';
import { HStack, VStack } from '@gluestack-ui/themed';
import LayoutForm from './LayoutForm';
import { Switch } from '@gluestack-ui/themed';
import CustomSelectForm from '@/atoms/CustomSelectForm';
import { formatToLocalTime } from '@/utils/functions';
import { Text } from '@gluestack-ui/themed';
import { TPressable } from '@/components/themed';

const TimeComponent = ({
  allHours,
  conditonTime,
  setAllHours,
  setOpenEndTime,
  setOpenInitialTime
}) => {
  return (
    <VStack space="sm">
      <HStack
        justifyContent="space-between"
        alignItems="center"
        paddingVertical="$2"
        sx={{
          ':hover': {
            backgroundColor: '$background'
          }
        }}
      >
        <Text>Todo el día</Text>
        <Switch
          value={allHours}
          size="md"
          onToggle={() => {
            setAllHours(!allHours);
          }}
        />
      </HStack>
      {!allHours && (
        <VStack space="sm">
          <LayoutForm
            idString="automatizations.initTime"
            icon={true}
            idStringTooltip="automatizations.initTimeTooltip"
            color={conditonTime?.value[0]?.length > 0 ? 'text' : 'secondary'}
          >
            <TPressable
              bgColor={conditonTime?.value[0]?.length > 0 ? 'background' : 'backgroundSecondary'}
              style={{
                borderRadius: 8
              }}
              onPress={() => setOpenInitialTime(true)}
            >
              <CustomSelectForm
                idString={
                  conditonTime?.value[0]?.length > 0
                    ? formatToLocalTime(conditonTime?.value[0])
                    : 'Seleccionar fecha'
                }
                color={conditonTime?.value[0]?.length > 0 ? 'text' : 'secondary'}
                trlate={false}
                sx={{
                  borderRadius: 8,
                  borderWidth: 1,
                  padding: 4,
                  height: 42,
                  gap: 10,
                  backgroundColor: 'transparent',
                  width: '100%'
                }}
              />
            </TPressable>
          </LayoutForm>

          <LayoutForm
            idString="automatizations.endTime"
            icon={true}
            idStringTooltip="automatizations.endTimeTooltip"
            color={conditonTime?.value[1]?.length > 0 ? 'text' : 'secondary'}
          >
            <TPressable
              border="border"
              bgColor={conditonTime?.value[1]?.length > 0 ? 'background' : 'backgroundSecondary'}
              style={{
                borderRadius: 8
              }}
              onPress={() => setOpenEndTime(true)}
            >
              <CustomSelectForm
                idString={
                  conditonTime?.value[1]?.length > 0
                    ? formatToLocalTime(conditonTime?.value[1])
                    : 'Seleccionar hora'
                }
                color={conditonTime?.value[0]?.length > 0 ? 'text' : 'secondary'}
                trlate={false}
                sx={{
                  borderRadius: 8,
                  borderWidth: 1,
                  padding: 4,
                  height: 42,
                  gap: 10,
                  backgroundColor: 'transparent',
                  width: '100%'
                }}
              />
            </TPressable>
          </LayoutForm>
        </VStack>
      )}
    </VStack>
  );
};

export default memo(TimeComponent);
