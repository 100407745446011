import React, { useMemo } from 'react';
import { View, type ViewProps } from 'react-native';
import { colorsProps, useThemeColor } from '@/hooks/useTheme';

export type ThemedViewProps = ViewProps & {
  lightColor?: string;
  darkColor?: string;
  bgColor?: colorsProps;
  border?: colorsProps;
};

export const TView = React.memo(
  ({ style, lightColor, darkColor, bgColor, border, ...otherProps }: ThemedViewProps) => {
    const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, bgColor);
    const borderColor = useThemeColor({ light: lightColor, dark: darkColor }, border);

    const memoizedStyle = useMemo(
      () => [{ backgroundColor, borderColor }, style],
      [backgroundColor, borderColor, style]
    );

    return <View style={memoizedStyle} {...otherProps} />;
  }
);

TView.displayName = 'TView';
