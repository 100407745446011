import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Thunk } from '../../../redux/store';
import axiosInstance from '../../../utils/network/instanceAxios';
import {
  AuthReducer,
  Channel,
  Company,
  ListCurrency,
  ListLanguage,
  Product,
  SalesPoint,
  Team,
  Teams,
  User
} from '../../../types/typesRedux/interfaces';
import {
  cleanStateUser,
  expiredToken,
  login,
  LoginError,
  saveTokenAuth0,
  setChannels,
  setContacts,
  setCurrency,
  setErrorToGetConversations,
  setIntroShown,
  setLanguage,
  setProgress,
  setSocket,
  setTeamUser,
  setUserlaoding,
  setUserNotificationConfig,
  setWaitingForResponse
} from '../../../redux/actionTypes';
import { OrderOptions, contracts, platforms } from '../filters/interfaces';
import { getAssigneeByTeamId, listChannelsCompany } from '../conversation/requests';
import { getLabels } from '../labels/requests';
import { filtersBase } from '@/utils/filters';
import { setLoading } from '../conversation';
import { getAllFilterData } from '../filters/requests';
import { ETypesSections } from '@/routers/MainRouter';

const initialState: AuthReducer = {
  isSignedIn: false,
  userColorScheme: 'light',
  user: {} as User,
  token: '',
  expiredToken: false,
  socket: true,
  msgError: '',
  expoNotificationToken: '',
  auth0Token: '',
  userLoading: false,
  introShown: false,
  company: {} as Company,
  team: {} as Team,
  teams: [] as Teams[],
  product: {} as Product,
  salesPoint: {} as SalesPoint,
  loadingChangeTeam: false,
  channels: [],
  activeChannels: {},
  listCurrency: [],
  listLanguage: [],
  contracts: [],
  progress: 0,
  links: []
};

export const authSlice = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    setLoadingChangeTeam: (state, action) => {
      state.loadingChangeTeam = action.payload;
    },
    setCurrency: (state, action) => {
      state.listCurrency = action.payload;
    },
    clearContract: (state) => {
      state.contracts = [];
    },
    setActiveChannels: (state, action) => {
      state.activeChannels = {
        ...state.activeChannels,
        [action.payload.teamId]: action.payload.channels
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login, (state, action) => {
      // console.log(
      //   '%c USER DATA: ',
      //   'color:#FFF; background-color:violet; padding: 10px; font-size:16px;',
      //   action.payload
      // );

      state.progress = 0;
      state.isSignedIn = true;
      state.user = action.payload.user;
      state.company = action.payload.company;
      state.team = action.payload.team;
      state.product = action.payload.product;
      state.salesPoint = action.payload.salesPoint;
      state.teams = action.payload.teams;
      state.msgError = '';
      state.expiredToken = false;
      state.contracts = [contracts?.internal, ...action.payload.contracts];
      state.links = action.payload.links;
      state.userLoading = false;
    });
    builder.addCase(setTeamUser, (state, action) => {
      state.team = { ...state.team, ...action.payload };
    });
    builder.addCase(saveTokenAuth0, (state, action) => {
      console.log(
        '%c SetTokenAuth0: ',
        'color:#FFF; background-color:violet; padding: 10px; font-size:16px;'
      );
      state.auth0Token = action.payload.idToken;
    });
    builder.addCase(setSocket, (state, action) => {
      state.socket = action.payload;
    });
    builder.addCase(cleanStateUser, (state) => {
      console.log(
        '%c cleanStateUser ',
        'color:#FFF; background-color:violet; padding: 10px; font-size:16px;'
      );
      state.isSignedIn = false;
      state.userColorScheme = 'light';
      state.user = {} as User;
      state.token = '';
      state.expiredToken = false;
      state.socket = true;
      state.msgError = '';
      state.expoNotificationToken = '';
      state.auth0Token = '';
      state.userLoading = false;
      state.company = {} as Company;
      state.team = {} as Team;
      state.teams = [];
      state.product = {} as Product;
      state.salesPoint = {} as SalesPoint;
      state.loadingChangeTeam = false;
      state.channels = [];
      state.listCurrency = [];
      state.listLanguage = [];
      state.contracts = [];
      state.progress = 0;
    });
    builder.addCase(LoginError, (state, action) => {
      state.msgError = action.payload;
    });
    builder.addCase(expiredToken, (state) => {
      state.expiredToken = true;
      state.user = {} as User;
      state.auth0Token = '';
      state.expoToken = '';
      state.token = '';
      state.isSignedIn = false;
      state.msgError = 'Token expirado';
    });
    builder.addCase(setUserlaoding, (state, action) => {
      state.userLoading = action.payload;
    });
    builder.addCase(setChannels, (state, action: PayloadAction<Channel[]>) => {
      state.channels = action.payload;
    });
    builder.addCase(setLanguage, (state, action: PayloadAction<ListLanguage[]>) => {
      state.listLanguage = action.payload;
    });
    builder.addCase(setCurrency, (state, action: PayloadAction<ListCurrency[]>) => {
      state.listCurrency = action.payload;
    });
    builder.addCase(setProgress, (state, action) => {
      if (state.progress <= 100) {
        state.progress = state.progress + action.payload;
      }
    });
    builder.addCase(setIntroShown, (state, action) => {
      state.introShown = action.payload;
    });
    builder.addCase(setUserNotificationConfig, (state, action) => {
      state.user = {
        ...state.user,
        notifications_config: action.payload
      };
    });
  }
});

export const { setLoadingChangeTeam, clearContract, setActiveChannels } = authSlice.actions;

export default authSlice.reducer;

export const firstCharge = (): Thunk => async (dispatch) => {
  try {
    const initialData = await axiosInstance.get('/user/comprehensiveDetails');

    if (initialData?.data?.user) {
      dispatch(
        login({
          user: initialData?.data?.user,
          product: initialData?.data?.product,
          team: initialData?.data?.team,
          company: initialData?.data?.company,
          salesPoint: initialData?.data?.salesPoint,
          teams: initialData?.data?.teams,
          contracts: initialData?.data?.contracts,
          links: initialData?.data?.listDynamicLinks?.links
        })
      );
      dispatch(setContacts(initialData?.data?.contacts));
      dispatch(setChannels(initialData?.data?.channels));
      dispatch(setProgress(10));
      dispatch(
        getDataOfFilters({
          channels: initialData?.data?.channels,
          teamId: initialData?.data?.team?.id,
          userId: initialData?.data?.user?.id
        })
      )
        .then(() => {
          dispatch(setWaitingForResponse(false));
          dispatch(setUserlaoding(false));
        })
        .catch((error) => {
          console.error('Error en firstCharge: --getDataOfFilters--', error);
          throw error;
        });
      return initialData.data;
    } else {
      dispatch(setWaitingForResponse(false));
      dispatch(setUserlaoding(false));
      throw 'Error en firstCharge: No se pudo obtener el usuario';
    }
  } catch (error) {
    console.log('Error en firstCharge LoginError: ', error);
    dispatch(setWaitingForResponse(false));
    dispatch(setUserlaoding(false));
    dispatch(LoginError(JSON.stringify(error?.response?.data?.message)));
    throw error;
  }
};

export const RegisterToken =
  (Object: any): Thunk =>
  async () => {
    try {
      console.info(
        '%c [Register Token] init RegisterToken ',
        'color:#FFF; background-color:blue; padding: 10px;'
      );
      const data = await axiosInstance.post('/chat/registerToken', Object);
      console.log('despues de mandar el token: ', data.data);
    } catch (error) {
      console.error('Error en RegisterToken: ', error);
      throw error;
    }
  };

export const getDataOfFilters =
  ({ channels, teamId, userId }: { channels: Channel[]; teamId: string; userId: string }) =>
  async (dispatch, getState) => {
    const { filters } = getState();
    const currentOrder = filters?.currentOrder;
    const channelsAvaliables = channels?.filter((item: Channel) => item?.available);
    try {
      let filterChat = [...filtersBase.filter((item) => item?.types === ETypesSections?.chat)];

      let filterClients = [
        ...filtersBase.filter((item) => item?.types === ETypesSections?.clients)
      ];

      // Preparar queries para chat y clientes con la plataforma adecuada
      filterChat = filterChat.map((item) => {
        return {
          ...item,
          query: {
            ...item?.query,
            conversation: {
              ...item?.query?.conversation,
              platform: [platforms?.internal],
              order: currentOrder === OrderOptions?.recent ? false : currentOrder
            }
          }
        };
      });

      filterClients = filterClients.map((item) => {
        return {
          ...item,
          query: {
            ...item?.query,
            conversation: {
              ...item?.query?.conversation,
              platform: channelsAvaliables?.map((item: Channel) => item?.name),
              order: currentOrder === OrderOptions?.recent ? false : currentOrder
            }
          }
        };
      });
      console.time('PROMISSE getAssigneeByTeamId');
      Promise.all([
        dispatch(getAssigneeByTeamId(teamId)),
        dispatch(getLabels(teamId)),
        dispatch(listChannelsCompany({ teamId }))
      ])
        .then((res) => {
          dispatch(setProgress(20));
          console.log('RES PROMISSE getAssigneeByTeamId : ', res);
        })
        .catch((errors) => {
          console.log('PROMISSE getAssigneeByTeamId : ', errors);
          throw errors;
        })
        .finally(() => console.timeEnd('PROMISSE getAssigneeByTeamId'));

      dispatch(setLoading(true));
      dispatch(
        getAllFilterData({
          userID: userId,
          filters: [...filterClients, ...filterChat],
          id_team: Number(teamId)
        })
      )
        .then((res) => {
          console.log('RES PROMISSE getAllFilterData2: ', res);
          dispatch(
            setErrorToGetConversations({
              status: false,
              message: 'ok'
            })
          );
          dispatch(setLoading(false));
          dispatch(setLoadingChangeTeam(false));
        })
        .catch((errors) => {
          console.log(' ERROR PROMISSE getAllFilterData: ', errors);
          dispatch(
            setErrorToGetConversations({
              status: true,
              message: 'Error al obtener las conversaciones'
            })
          );
          dispatch(setLoading(false));
          dispatch(setLoadingChangeTeam(false));
          throw errors;
        });
    } catch (error) {
      console.error('Error en RegisterToken: ', error);
      dispatch(setLoading(false));
      dispatch(setLoadingChangeTeam(false));
      dispatch(setWaitingForResponse(false));
      dispatch(setUserlaoding(false));
      throw error;
    }
  };
