import { useWindowDimensions, Platform, StyleSheet, Modal } from 'react-native';
import React, { useEffect, useMemo } from 'react';
import { VStack } from '@gluestack-ui/themed';
import HeaderBottomSheet from '../../atoms/HeaderBottomSheet';
import { TABLET_SCREEN } from '@/utils/globales';

const ModalApp = ({ children, modalVisible, setModalVisible, title = null }) => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (Platform.OS !== 'web') return;
    const handleKeyDown = (event: { key: string }) => {
      if (event.key === 'Escape') {
        setModalVisible(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setModalVisible]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          height: '100%',
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center'
        }
      }),
    []
  );

  return (
    <Modal
      animationType="fade"
      transparent
      visible={modalVisible}
      onRequestClose={() => setModalVisible(false)}
    >
      {/* <ModalBackdrop /> */}
      <VStack
        width={width <= TABLET_SCREEN ? '100%' : '90%'}
        minWidth={width <= TABLET_SCREEN ? '100%' : '70%'}
        style={styles.container}
      >
        <VStack
          bg="$backgroundSecondary"
          paddingHorizontal="$4"
          paddingVertical="$2"
          style={{
            alignSelf: 'center',
            width: '100%',
            height: '96%',
            borderRadius: 12
          }}
        >
          <HeaderBottomSheet title={title} onClose={() => setModalVisible(false)} />
          {children}
        </VStack>
      </VStack>
    </Modal>
  );
};

export default React.memo(ModalApp);
