import axiosInstance from '@/utils/network/instanceAxios';

import {
  setLanguage,
  setCurrency,
  updateAnyPropsOfOneConversations,
  setUserNotificationConfig
} from '@/redux/actionTypes';
import { IUpdateNotification } from './interfaces';

export const listCurrency = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/user/listCurrency');
    dispatch(setCurrency(response.data.listCurrency.data));
    return response.data;
  } catch (error) {
    console.log('error listCurrency', error);
    throw error;
  }
};

export const listLanguage = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/user/listLanguage');
    dispatch(setLanguage(response.data.listLanguage.data));

    return response.data;
  } catch (error) {
    console.log('error listLanguage', error);
    throw error;
  }
};

export const changeLanguage =
  ({ id, language }) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.put(`chat/updateLenguage/${id}/${language}`);
      dispatch(updateAnyPropsOfOneConversations({ id, key: 'language', value: language }));
      return response.data;
    } catch (error) {
      console.log('error changeLanguage', error);
      throw error?.response?.data;
    }
  };

export const changeCurrency =
  ({ id, currency }) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.put(`/chat/updateCurrency/${id}/${currency}`);
      dispatch(updateAnyPropsOfOneConversations({ id, key: 'currency', value: currency }));
      return response.data;
    } catch (error) {
      console.log('error changeCurrency', error);
      throw error?.response?.data;
    }
  };

export const replaceLinkValue =
  ({ metadata, bookingId, linkId }) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.post(`/template/replace-dynamic-link/${linkId}`, {
        metadata,
        bookingId
      });
      console.log('response', response);

      return response?.data?.data;
    } catch (error) {
      console.log('error listLanguage', error);
      throw error;
    }
  };

export const updateNotification =
  (body: { propertyNotification: IUpdateNotification; data: boolean | string[] }) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.patch(`/user/notification`, body);
      dispatch(setUserNotificationConfig(response.data?.data));
      return response.data;
    } catch (error) {
      console.log('error updateNotification', error);
      throw error;
    }
  };
