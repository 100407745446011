import { Platform, StyleSheet } from 'react-native';
import React, { useMemo } from 'react';

import translate from '@/helpers/traslations';
import useAuth0 from '@/hooks/useAuth0';
import { clearParticipants } from '@/redux/slices/participants';
import { cleanTemplates } from '@/redux/slices/templates';
import { clearFragments } from '@/redux/slices/fragments';
import { crearFilters } from '@/redux/slices/filters';

import { useAppDispatch } from '@/redux/store';
import { cleanMessages } from '@/redux/slices/messages';
import {
  cleanContacts,
  cleanStateConversation,
  cleanStateUser,
  clearLabels,
  setUserlaoding,
  setWaitingForResponse
} from '@/redux/actionTypes';
import MaterialCommunity from '../components/Icons/MaterialCommunity';
import { Logout } from '@/assets/icons/Icons';
import { TPressable, TText, TView } from '@/components/themed';

function ItemLogout() {
  const dispatch = useAppDispatch();
  const { logout, clearSession } = useAuth0();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        ripple: {
          borderRadius: 10,
          paddingHorizontal: 10,
          width: '100%'
        },
        item: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 5,
          paddingVertical: 10
        }
      }),
    []
  );

  const onPressLogout = async () => {
    Promise.all([
      dispatch(setWaitingForResponse(false)),
      dispatch(setUserlaoding(false)),
      dispatch(cleanStateConversation()),
      dispatch(cleanStateUser()),
      dispatch(cleanContacts()),
      dispatch(cleanMessages()),
      dispatch(clearParticipants()),
      dispatch(cleanTemplates()),
      dispatch(clearFragments()),
      dispatch(crearFilters()),
      dispatch(clearLabels())
    ]);

    try {
      if (Platform.OS === 'web') {
        await logout();
      } else {
        await clearSession();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <TPressable style={styles.ripple} onPress={() => onPressLogout()}>
      <TView style={styles.item}>
        <MaterialCommunity icon={Logout} />
        <TText>{translate('logout')}</TText>
      </TView>
    </TPressable>
  );
}

export default React.memo(ItemLogout);
