import { filterTypes } from '@/redux/slices/filters/interfaces';

export enum Routes {
  Team = 'team',
  Chat = filterTypes.chat,
  Intro = 'intro',
  ChatStack = 'chatstack',
  clientes = filterTypes.clients,
  AssociateUser = 'associateuser',
  Conversations = 'conversations',
  LayoutLsit = 'layoutlist',
  Login = 'login',
  Settings = 'settings',
  NewConversationScreen = 'newconversationscreen',
  Conversation = 'conversation',
  Embedding = 'embedding',
  DinamicContent = 'dinamiccontent',
  ConversationStack = 'conversationstack',
  InfoConversation = 'info',
  AddParticipants = 'add',
  NewConversation = 'newconversation',
  NewGroup = 'newgroup',
  Todos = 'todos',
  Filter = 'filter',
  Fallback = 'fallback',
  Grupos = 'grupos',
  Home = 'home',
  Welcome = 'welcome',
  Associations = 'associations',
  AssociationContacts = 'associationcontacts',
  AssociationBoooking = 'associationbooking',
  searchContact = 'searchcontact',
  createContact = 'createcontact',
  searchBooking = 'searchbooking',
  createBooking = 'createbooking',
  ObjectAssociations = 'objectassociations',
  AssociationList = 'associationlist',
  NewConversationExternal = 'newconversationexternal',
  AutomatizationTemplate = 'automatizationtemplate',
  AutomatizationBot = 'automatizationbot',
  AutomatizationRange = 'automatizationrange'
}

export enum RouteSettings {
  settingsProfile = 'settingsprofile',
  settingsChat = 'settingschat',
  settingsChannels = 'settingschannels',
  settingsNotifications = 'settingsnotifications',
  settingsCustomizations = 'settingscustomizations',
  help = 'help',
  settingsChangelog = 'settingschangelog',
  settingsFallback = 'fallback',
  automatizationStack = 'automatizationstack'
}

export enum RouteWelcome {
  welcome = 'welcome',
  welcomeChannels = 'welcomechannels',
  welcomeCustomizations = 'welcomecustomizations',
  welcomeChangelog = 'welcomechangelog',
  welcomeFallback = 'welcomefallback'
}
