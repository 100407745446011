import React from 'react';
import { Routes } from '@/routers/routes';
import { RootStackParams } from '@/routers/interfaces';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import TeamPicker from './TeamPicker';
import { useAppDispatch } from '@/redux/store';

function SelectorTeams({ navigateTo }) {
  const navigation = useNavigation<StackNavigationProp<RootStackParams, Routes.Filter>>();
  const dispatch = useAppDispatch();
  return <TeamPicker dispatch={dispatch} navigation={navigation} navigateTo={navigateTo} />;
}

export default React.memo(SelectorTeams);
