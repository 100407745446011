import {
  AlertDialogBackdrop,
  AlertDialogFooter,
  AlertDialogHeader,
  HStack,
  AlertDialogContent,
  AlertDialogCloseButton,
  Heading,
  AlertDialog,
  AlertDialogBody,
  Text,
  Image,
  VStack
} from '@gluestack-ui/themed';
import React from 'react';
import { IconClose } from '../assets/icons/Icons';
import translate from '../helpers/traslations';
import MaterialCommunity from './Icons/MaterialCommunity';
import MainCta from '../atoms/MainCta';

function DialogComponent({
  dialogVisible,
  setDialogVisible,
  children,
  idStringTitle,
  idStringDescription,
  buttonCancel = true,
  Cta,
  inverted = false
}: {
  dialogVisible: boolean;
  setDialogVisible: (arg0: boolean) => void;
  children?: React.ReactNode;
  idStringTitle: string;
  idStringDescription: string;
  Cta: React.ReactNode;
  buttonCancel?: React.ReactNode;
  inverted?: boolean;
}) {
  const hideDialog = () => setDialogVisible(false);

  return (
    <AlertDialog
      isOpen={dialogVisible}
      onClose={() => {
        hideDialog();
      }}
    >
      <AlertDialogBackdrop />
      <AlertDialogContent bg="$background">
        <AlertDialogHeader>
          <Heading size="lg">{translate(idStringTitle)}</Heading>
          <AlertDialogCloseButton>
            <MaterialCommunity icon={IconClose} />
          </AlertDialogCloseButton>
        </AlertDialogHeader>
        <AlertDialogBody>
          <VStack alignItems="center" space="sm">
            {inverted ? (
              <>
                {children}
                <Text size="sm">{translate(idStringDescription)}</Text>
              </>
            ) : (
              <>
                <Text size="sm">{translate(idStringDescription)}</Text>
                {children}
              </>
            )}
          </VStack>
        </AlertDialogBody>
        <AlertDialogFooter paddingHorizontal="$3">
          <HStack space="sm">
            {buttonCancel && (
              <MainCta
                iconSize="xs"
                labelId="actions.cancel"
                action="secondary"
                variant="outline"
                onPress={() => {
                  hideDialog();
                }}
              />
            )}
            {Cta}
          </HStack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default React.memo(DialogComponent);
