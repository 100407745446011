import { PropertyConditions } from '@/types/enums/workflows';
import { formatToLocalTime } from '@/utils/functions';

export const getPlatforms = (conditions) => {
  const platformCondition = conditions?.find(
    (cond) => cond.property === PropertyConditions?.idChannel
  );
  console.log('getPlatforms', platformCondition);
  return platformCondition ? [platformCondition?.value] : [];
};

export function renderDiasSeleccionados(dias) {
  if (!dias) return 'Todos los días';
  const newDiasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  if (!dias) return '';
  if (dias.length === 0) return '';
  let newDias = [...dias];
  // Ordenamos los días seleccionados
  newDias.sort((a, b) => a - b);

  // Función para detectar consecutividad
  function sonConsecutivos(arr) {
    for (let i = 0; i < arr.length - 1; i++) {
      if (arr[i] + 1 !== arr[i + 1]) return false;
    }
    return true;
  }

  // Casos
  if (newDias.length === 1) {
    return newDiasSemana[newDias[0] - 1];
  } else if (newDias.length === 2) {
    return `${newDiasSemana[newDias[0] - 1]} y ${newDiasSemana[newDias[1] - 1]}`;
  } else if (sonConsecutivos(newDias)) {
    return `${newDiasSemana[newDias[0] - 1]} a ${newDiasSemana[newDias[newDias.length - 1] - 1]}`;
  } else {
    let resultado = [];
    let rangoInicio = newDias[0];
    let rangoFin = newDias[0];

    for (let i = 1; i < newDias.length; i++) {
      if (newDias[i] === rangoFin + 1) {
        rangoFin = newDias[i];
      } else {
        if (rangoInicio === rangoFin) {
          resultado.push(newDiasSemana[rangoInicio - 1]);
        } else {
          resultado.push(`${newDiasSemana[rangoInicio - 1]} a ${newDiasSemana[rangoFin - 1]}`);
        }
        rangoInicio = newDias[i];
        rangoFin = newDias[i];
      }
    }

    // Añadimos el último rango
    if (rangoInicio === rangoFin) {
      resultado.push(newDiasSemana[rangoInicio - 1]);
    } else {
      resultado.push(`${newDiasSemana[rangoInicio - 1]} a ${newDiasSemana[rangoFin - 1]}`);
    }

    return resultado.join(', ');
  }
}

export function renderHorasSeleccionadas(horas) {
  if (!horas || horas.length !== 2) return 'todo el día';
  return `de ${formatToLocalTime(horas[0])} a ${formatToLocalTime(horas[1])} hs`;
}

export function findIdChannelValue(obj) {
  if (!obj.conditions || !Array.isArray(obj.conditions)) return null;

  for (let condition of obj.conditions) {
    if (condition.property === 'id_channel') {
      return condition.value;
    }
  }
  return null;
}

export function findLabelRangeDate(obj) {
  if (!obj.conditions || !Array.isArray(obj.conditions)) return null;

  for (let condition of obj?.conditions) {
    if (condition?.label === 'range_date') {
      return condition;
    }
  }
  return null;
}
