// Import module
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { Platform } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { RegisterToken } from '../redux/slices/auth';
import { filterConversationById, setIdConversation } from '../redux/actionTypes';

console.log('Notifications.registerForPushNotificationsAsync', Platform.OS);
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false
  })
});

const NotificationContext = createContext<{
  beamsClient: any;
  toggleNotifications: () => void;
  notificationsEnabled: boolean;
  dennied: boolean;
}>({
  beamsClient: undefined,
  toggleNotifications(): void {
    throw new Error('Function not implemented.');
  },
  notificationsEnabled: false,
  dennied: false
});

export const useNotifications = () => {
  return useContext(NotificationContext);
};

function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState<any>(false);
  // const navigate = useNavigation<StackNavigationProp<RootStackParams>>();
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [dennied, setDennied] = useState(false);
  const notificationListener = useRef(null);
  const responseListener = useRef(null);
  const dispatch = useAppDispatch();
  const idUser = useAppSelector((state: RootState) => state.user.user?.id);
  let value = {};

  async function schedulePushNotification() {
    await Notifications.scheduleNotificationAsync({
      content: {
        title: "You've got mail! 📬",
        body: 'Here is the notification body',
        data: { data: 'goes here' }
      },
      trigger: { seconds: 2 }
    });
  }
  async function registerForPushNotificationsAsync() {
    let token;
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C'
      });
    }
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      token = (
        await Notifications.getExpoPushTokenAsync({
          projectId: 'pxsol01'
        })
      ).data;
      dispatch(RegisterToken({ id: idUser, token, device: Platform.OS })).catch((error) => {
        console.error('error', error);
      });
      console.log(token);
    } else {
      alert('Must use physical device for Push Notifications');
    }
    return token;
  }
  value = {
    expoPushToken,
    notification,
    schedulePushNotification
  };

  const toggleNotifications = async () => {
    if (notificationsEnabled) {
      // Aquí desactivas las notificaciones
      setNotificationsEnabled(false);
      if (notificationListener.current) {
        Notifications.removeNotificationSubscription(notificationListener.current);
      }

      if (responseListener.current) {
        Notifications.removeNotificationSubscription(responseListener.current);
      }
      // ... (cualquier otro código necesario para desactivar las notificaciones)
    } else {
      const token = await registerForPushNotificationsAsync();
      if (token) {
        setNotificationsEnabled(true);
      } else {
        setDennied(true);
      }
    }
  };

  useEffect(() => {
    registerForPushNotificationsAsync().then((token) => setExpoPushToken(token || ''));
    // This listener is fired whenever a notification is received while the app is foregrounded

    Notifications.addNotificationReceivedListener((notification: any) => {
      console.log('notification', notification);
      setNotification(notification);
    });

    Notifications.addNotificationResponseReceivedListener((response) => {
      console.log('response Notifications.addNotificationResponseReceivedListener: ', response);
    });

    return () => {
      notificationListener.current &&
        Notifications.removeNotificationSubscription(notificationListener.current);
      responseListener.current &&
        Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  useEffect(() => {
    // Suscríbete al evento de notificaciones entrantes
    const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
      console.log('response', response.notification.request.content.data);
      if (response.notification.request.content.data.type === 'navigateToConverstation') {
        console.log('conversationId', response.notification.request.content.data.conversationId);

        dispatch(setIdConversation(response.notification.request.content.data.conversationId));

        dispatch(filterConversationById(response.notification.request.content.data.conversationId));
      }
    });

    // Limpia la suscripción al desmontar el componente
    return () => {
      subscription.remove();
    };
  }, []);
  return (
    <NotificationContext.Provider
      value={{ beamsClient: value, toggleNotifications, notificationsEnabled, dennied }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export default React.memo(NotificationProvider);
