import React from 'react';
import { IconFile, IconImage, MessageVideo, Microphone, Sticker } from '@/assets/icons/Icons';
import LastMessage from './LastMessage';
import LastMessageIcon from './LasMesageIcon';
import MaterialCommunity from '../components/Icons/MaterialCommunity';
import { Message } from '@/types/message';
import { TypeOfMessage } from '@/types/enums/message';
import { THView } from '@/components/themed';

function ContainerLastMessage({
  messages,
  icon,
  color
}: {
  messages: Message;
  icon: any;
  color: string;
}) {
  return (
    <THView
      style={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 4,
        width: '100%'
      }}
    >
      <THView>
        <MaterialCommunity icon={icon} color={color} size="xs" />
      </THView>
      <THView
        style={{
          flexBasis: '90%'
        }}
      >
        {messages?.content?.type === TypeOfMessage?.image && (
          <LastMessageIcon color="secondary" icon={IconImage} message="Imagen" italic={false} />
        )}
        {messages?.type === TypeOfMessage?.sticker && (
          <LastMessageIcon color="secondary" icon={Sticker} message="Sticker" italic={false} />
        )}
        {messages?.content?.type === TypeOfMessage?.gif && (
          <LastMessageIcon color="secondary" icon={IconFile} message="Gif" italic={false} />
        )}
        {messages?.type === TypeOfMessage?.file && (
          <LastMessageIcon
            color="secondary"
            icon={IconFile}
            message={messages?.content?.file?.name}
            italic={false}
          />
        )}
        {messages?.content?.type === TypeOfMessage?.audio && (
          <LastMessageIcon color="secondary" icon={Microphone} message="Audio" italic={false} />
        )}
        {messages?.type === TypeOfMessage?.video && (
          <LastMessageIcon color="secondary" icon={MessageVideo} message="Video" italic={false} />
        )}
        {(messages?.type === TypeOfMessage?.text || messages?.type === TypeOfMessage?.template) && (
          <LastMessage message={messages} italic={false} isGroup={false} />
        )}
      </THView>
    </THView>
  );
}

export default React.memo(ContainerLastMessage);
