import { StyleSheet } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { RouteProp, useRoute } from '@react-navigation/native';

import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { getCurrentFilterByState } from '@/utils/functions';

import { ASIGNADOS_A_MI, GET_DATA_IF_IS, SEARCHS } from '@/utils/filters';
import { useLinkss } from '@/providers/LinkProvider';
import { ArrowCollapseRight, ArrowCollapsedLeft } from '@/assets/icons/Icons';
import { Filters } from '@/redux/slices/filters/interfaces';
import {
  outConversationToFilter,
  setIdConversation as setReduxIdConversation
} from '@/redux/actionTypes';

import { getAllConversationsByFilter, setLoading } from '@/redux/slices/conversation';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';

import { RootStackParams } from '@/routers/interfaces';

import MainCta from '@/atoms/MainCta';
import { useCollapsed } from '@/providers/CollpasedProvider';
import HeaderSidebarFilters from './HeaderSidebarFilters';
import { ThemedScrollView, THView, TView } from '@/components/themed';
import ContainerAcordionsFilters from './ContainerAcordionsFilters';

function SidebarFilters(props: Readonly<DrawerContentComponentProps>): React.ReactElement {
  const route = useRoute<RouteProp<RootStackParams>>();
  const dispatch = useAppDispatch();
  const userID = useAppSelector((state: RootState) => state.user.user?.id);
  const loading = useAppSelector((state: RootState) => state.conversations.loading);
  const filters = useAppSelector((state: RootState) => state.filters.filters);
  const currentOrder = useAppSelector((state: RootState) => state.filters.currentOrder);
  const outConversationToFilterQueue = useAppSelector(
    (state: RootState) => state.conversations.outConversationToFilterQueue
  );
  const { collapsed, setCollapsed } = useCollapsed();
  const { state } = props;
  const { setIsReady } = useLinkss();

  const [activeRoute, setActiveRoute] = useState<string>('');

  const teamId = useMemo(() => {
    return route?.params?.team;
  }, [route?.params?.team]);

  const routeName = useMemo(() => {
    return route?.name;
  }, [route?.name]);

  useEffect(() => {
    setActiveRoute(state?.routeNames[state?.index]);
  }, [props]);

  useEffect(() => {
    setIsReady(true);
  }, []);

  useEffect(() => {
    const myFilter = getCurrentFilterByState(filters, activeRoute);
    myFilter?.idString !== SEARCHS &&
      getConversationsByFilter({
        query: { ...myFilter?.query, page: 1, id_team: Number(teamId) },
        idString: myFilter?.idString,
        types: myFilter?.types,
        cancell: false
      });
  }, [currentOrder]);

  useEffect(() => {
    if (GET_DATA_IF_IS?.includes(activeRoute)) {
      const filterToGetData = filters?.assignee?.find(
        (filter: Filters) => filter.idString === activeRoute
      );
      getConversationsByFilter({
        query: { ...filterToGetData?.query, page: 1, id_team: Number(teamId) },
        idString: filterToGetData?.idString,
        types: filterToGetData?.types,
        cancell: false
      });
    }
  }, [activeRoute]);

  /**
   *
   * @description Funcion que se ejecuta cuando se presiona un filtro, primero se revisa en outConversationToFilterQueue
   * si no hay ninguna conversacion para sacar del filtro y si hay se despacha la accion outConversationToFilter, despues
   * se despacha la accion setLoading para mostrar el loading y despues se despacha la accion getAllConversationsByFilter
   * para traer las conversaciones de ese filtro, y por ultimo navega hacia ese filtro.
   *
   * @param item Filter
   * @returns void
   */
  const handlePressedFilter = useCallback(
    (item: Filters) => {
      if (Object.keys(outConversationToFilterQueue).length > 0) {
        Object.keys(outConversationToFilterQueue).forEach((key) => {
          dispatch(outConversationToFilter(outConversationToFilterQueue[key]));
        });
      }

      dispatch(setReduxIdConversation(' '));
      props.navigation.setParams({ id: '' });

      //No se el get filter mas abajo porque ya se esta ejecutando cuando se en el useEffect de linea 120

      let query = { ...item.query };
      if (item.idString === ASIGNADOS_A_MI) {
        query = {
          ...query,
          fragment: {
            ...query.fragment,
            associations: {
              assignee: [userID]
            }
          },
          page: 1,
          // como pasammos el id del equipo por parametro, lo agregamos al query
          id_team: Number(teamId)
        };
      } else {
        query = {
          ...query,
          page: 1,
          // como pasammos el id del equipo por parametro, lo agregamos al query
          id_team: Number(teamId)
        };
      }
      if (item.idString !== SEARCHS && !loading) {
        getConversationsByFilter({ query, idString: item.idString, types: item.types });
      }
      props.navigation.reset({
        index: 0,
        routes: [
          {
            name: item.idString,
            params: { filter: item.idString, id: '' }
          }
        ]
      });
    },
    [loading, teamId]
  );

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: '100%',
          width: '100%'
        },
        scrollView: {
          padding: 8,
          flex: 1,
          width: '100%',
          borderRightWidth: 1
        },
        containerCollapse: {
          width: '95%',
          justifyContent: 'flex-end'
        }
      }),
    []
  );

  const getConversationsByFilter = ({ query, idString, types, cancell = true }) => {
    dispatch(setLoading(true));
    dispatch(
      getAllConversationsByFilter({
        query,
        filter: idString,
        type: types,
        teamId: teamId
      })
    )
      .then((res) => {
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  return (
    <TView bgColor="backgroundSecondary" style={styles.container}>
      <HeaderSidebarFilters name={routeName} closeDrawer={() => props.navigation.closeDrawer()} />
      <ThemedScrollView
        bgColor="transparent"
        border="border"
        showsVerticalScrollIndicator={false}
        style={styles.scrollView}
      >
        <ContainerAcordionsFilters
          collapsed={collapsed}
          routeName={routeName}
          activeRoute={activeRoute}
          handlePressedFilter={handlePressedFilter}
          teamId={teamId}
        />
      </ThemedScrollView>

      <THView style={styles.containerCollapse}>
        <MainCta
          icon={collapsed ? ArrowCollapseRight : ArrowCollapsedLeft}
          variant="outline"
          onPress={() => setCollapsed(!collapsed)}
        />
      </THView>
    </TView>
  );
}

export default React.memo(SidebarFilters, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
