import React, { useMemo } from 'react';
import { HEIGHT_HEADER_LAYOUT } from '@/utils/globales';
import MainCta from '@/atoms/MainCta';
import { Pencil, PersonAdd } from '@/assets/icons/Icons';
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import translate from '@/helpers/traslations';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { Linking } from 'react-native';
import { URL_PMS_NEW_CONTACT } from '@/utils/constants';

import OrderOfFilters from './OrderOfFilters';
import { setModaNewChat } from '@/redux/slices/theme';
import { THView } from '@/components/themed';
import { StyleSheet } from 'react-native';

const HeaderClients = () => {
  const dispatch = useAppDispatch();
  const team = useAppSelector((state: RootState) => state.user.team);

  const handleNewChat = async () => {
    const canOpen = await Linking.canOpenURL(URL_PMS_NEW_CONTACT);
    if (canOpen) {
      Linking.openURL(URL_PMS_NEW_CONTACT);
    } else {
      console.log('No se puede abrir la URL');
    }
  };
  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        borderBottomWidth: 1,
        padding: 4,
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 4,
        marginBottom: 4,
        height: HEIGHT_HEADER_LAYOUT,
        width: '100%'
      },
      buttonContainer: {
        gap: 4,
        flexBasis: '55%',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }
    });
  }, []);

  return (
    <THView border="border" style={styles.container}>
      <OrderOfFilters />
      <THView style={styles.buttonContainer}>
        <MainCta
          borderRadius="$full"
          iconSize="md"
          onPress={handleNewChat}
          icon={PersonAdd}
          buttonSize="sm"
          variant="outline"
        />
        <CustomTooltip
          title={
            !team?.isChannelTeam
              ? translate('messages.currentTeamNotHaveAChannelAvaliable')
              : translate('messages.currentTeamHaveAvaliableChannel')
          }
        >
          <MainCta
            onPress={() => dispatch(setModaNewChat(true))}
            icon={Pencil}
            disabled={!team?.isChannelTeam}
            iconPosition="left"
            labelId="new"
            iconColor="$blanco"
            iconSize="sm"
            buttonSize="xs"
            borderRadius="$full"
          />
        </CustomTooltip>
      </THView>
    </THView>
  );
};

export default React.memo(HeaderClients, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
