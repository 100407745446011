import { useWindowDimensions } from 'react-native';
import React, { memo, useEffect, useMemo } from 'react';

import { isArray } from 'lodash';

import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import MainCta from '@/atoms/MainCta';
import { useLinkss } from '@/providers/LinkProvider';
import AutomatizationStack from '@/routers/AutomatizationStack';

import DraggableList from '@/components/automatizations/DraggableList';
import { setCreatingWorkflow, setWorkflows } from '../redux/actionTypes';
import { Workflow } from '@/components/automatizations/interface.d';
import DialogComponent from '@/components/DialogComponent';
import { getWorkflows } from '@/redux/slices/automatization/requests';
import {
  setDatesRanges,
  setGettingWorkflows,
  setIdStringWorkflow
} from '../redux/slices/automatization';
import { getTemplatesWorkflows } from '@/redux/slices/templates/requests';
import { setMenuRigth } from '@/redux/slices/theme';
import { ThemedScrollView, THView, TView } from '@/components/themed';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { TABLET_SCREEN } from '@/utils/globales';
import ChannelsAutomatizations from '@/components/partials/automatizations/ChannelsAutomatizations';

function SettingsChat(routeAutomatization) {
  const dispatch = useAppDispatch();
  const newWorkflow = useAppSelector((state: RootState) => state.automatization.newWorkflows);
  const companyId = useAppSelector((state: RootState) => state.user?.company?.IDEmpresas);
  const menuRigth = useAppSelector((state: RootState) => state.settings.menuRigth);
  const workflowTemplates = useAppSelector(
    (state: RootState) => state.templates?.workflowTemplates
  );

  const idStringWorkflow = useAppSelector(
    (state: RootState) => state.automatization.idStringWorkflow
  );

  const teamId = useMemo(
    () => routeAutomatization?.route?.params?.team,
    [routeAutomatization?.route?.params?.team]
  );
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const { handleCopyCode, showToast } = useLinkss();
  const { width } = useWindowDimensions();

  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  useEffect(() => {
    const idString = `${companyId}_${routeAutomatization?.route?.params?.team}`;
    if (teamId) {
      dispatch(setIdStringWorkflow(idString));
    }
  }, [companyId, teamId]);

  useEffect(() => {
    if (!idStringWorkflow) return;
    //si no existe workflowTemplates o si la idString no existe en workflowTemplates se busca plantillas
    if (!workflowTemplates || !(idStringWorkflow in workflowTemplates)) {
      dispatch(getTemplatesWorkflows({ idStringWorkflow }))
        .then((res: any) => {
          console.log('getTemplatesWorkflows', res?.data);
        })
        .catch((err) => {
          console.log('getTemplatesWorkflows', err);
        });
    }
  }, [workflowTemplates, idStringWorkflow]);

  const handleGetWorkflows = () => {
    dispatch(setGettingWorkflows(true));
    dispatch(getWorkflows({ teamId }))
      .then((res: any) => {
        console.log('handleGetWorkflows', res?.data);
        dispatch(setGettingWorkflows(false));
        dispatch(
          setWorkflows({
            idString: `${companyId}_${teamId}`,
            workflows: res?.data
          })
        );
      })
      .catch((err) => {
        dispatch(setGettingWorkflows(false));
        if (isArray(err?.message)) {
          err?.message?.forEach((error: string) => {
            showToast({
              type: 'error',
              title: `ERROR: ${err?.transactionId}`,
              subtitle: error,
              cta: err?.transactionId && (
                <MainCta
                  labelId={'actions.copy'}
                  uppercase
                  variant="outline"
                  onPress={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    handleCopyCode({ text: err?.transactionId });
                  }}
                />
              )
            });
          });
          return;
        }

        showToast({
          type: 'error',
          title: 'Error',
          subtitle: 'Ha ocurrido un error al obtener los workflows',
          cta: err?.transactionId && (
            <MainCta
              labelId={'actions.copy'}
              uppercase
              variant="outline"
              onPress={(e: { preventDefault: () => void }) => {
                e.preventDefault();
                handleCopyCode({ text: err?.transactionId });
              }}
            />
          )
        });
      });
  };

  useEffect(() => {
    if (!idStringWorkflow) return;
    handleGetWorkflows();
  }, [idStringWorkflow]);

  const onClose = () => {
    if (Object.keys(newWorkflow).length > 0) {
      setDialogVisible(true);
    } else {
      dispatch(setCreatingWorkflow({} as Workflow));
      dispatch(setDatesRanges([]));
      dispatch(setMenuRigth(false));
    }
  };

  const onConfirmDialog = () => {
    dispatch(setCreatingWorkflow({} as Workflow));
    dispatch(setDatesRanges([]));
    dispatch(setMenuRigth(false));
    setDialogVisible(false);
  };

  return (
    <THView
      style={{
        flexGrow: 1,
        maxHeight: '100%',
        flexBasis: menuRigth ? (width <= 1024 ? '50%' : '60%') : '100%'
      }}
    >
      <ThemedScrollView
        bgColor="backgroundSecondary"
        style={{
          height: '100%',
          padding: 18
        }}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          flex: 1
        }}
      >
        <ChannelsAutomatizations teamId={teamId} />
        <DraggableList teamId={teamId} routeAutomatization={routeAutomatization} />
      </ThemedScrollView>
      {menuRigth && isPc && (
        <TView
          style={{
            flexBasis: width <= TABLET_SCREEN ? '50%' : '40%',
            height: '100%'
          }}
        >
          <AutomatizationStack {...routeAutomatization} onClose={onClose} />
        </TView>
      )}
      <DialogComponent
        dialogVisible={dialogVisible}
        setDialogVisible={setDialogVisible}
        idStringTitle="actions.cancel"
        idStringDescription="automatizations.youSureCancelEdit"
        Cta={<MainCta labelId={'actions.confirm'} onPress={onConfirmDialog} />}
      />
    </THView>
  );
}

export default memo(SettingsChat);
