import { View, Text } from 'react-native';
import React, { memo } from 'react';
import AppIntroSlider from 'react-native-app-intro-slider';
import translate from '@/helpers/traslations';
import { verifyIsMobile } from '@/utils/functions';
import { Platform } from 'react-native';
import MainCta from '@/atoms/MainCta';
import { DondeDecargam, IconArrowLeft, IconArrowRight, Skip } from '@/assets/icons/Icons';
import RenderItemSlider from './RenderItemSlider';

const SliderApp = ({ handleEndSlider, data }) => {
  return (
    <AppIntroSlider
      renderItem={(props) => <RenderItemSlider {...props} />}
      data={data}
      onDone={handleEndSlider}
      activeDotStyle={{ backgroundColor: '#cc3030' }}
      contentContainerStyle={{
        paddingBottom: 20,
        flex: 1
      }}
      dotStyle={{ backgroundColor: '#3a3a3a' }}
      onSkip={handleEndSlider}
      nextLabel={translate('continue')}
      doneLabel={translate('finish')}
      prevLabel={translate('back')}
      skipLabel={translate('skip')}
      bottomButton={!!(Platform.OS !== 'web' || verifyIsMobile())}
      renderSkipButton={() => (
        <MainCta
          icon={Skip}
          sx={{ pointerEvents: 'none', marginTop: 10 }}
          labelId="skipIntroSlider"
        />
      )}
      showSkipButton
      showPrevButton
      renderDoneButton={() => (
        <MainCta icon={DondeDecargam} sx={{ pointerEvents: 'none' }} labelId="doneIntroSlider" />
      )}
      renderPrevButton={() => (
        <MainCta
          icon={IconArrowLeft}
          variant="outline"
          action="secondary"
          iconPosition="left"
          labelId="goPrevIntroSlider"
          sx={{ pointerEvents: 'none', marginTop: 10 }}
        />
      )}
      renderNextButton={() => (
        <MainCta
          icon={IconArrowRight}
          labelId="goNextIntroSlider"
          sx={{ pointerEvents: 'none', marginTop: 10 }}
        />
      )}
    />
  );
};

export default memo(SliderApp);
