import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  setFlagMessage,
  setLoadingMessage,
  updateUpdateAtConversation,
  setImagesToState,
  clearMessagesByConverationId,
  setSectionsByConverId,
  addOrUpdateMessageInFragment,
  setSections,
  setUploadFile,
  setZeroUnreadCount,
  addNewFragmentoToSection,
  clearSections,
  setSectionBefore,
  setUnreadCountToConversation
} from '../../actionTypes';
import { SectionInterface, SetMoreMessagesPayload, TypeSection, messagesState } from './interfaces';
import { tieneMensajesNoLeidos } from '@/utils/functions';
import { OriginOfMessage, TypeOfMessage } from '@/types/enums/message';
import { TypeSections } from '@/types/enums/index';
import { Message } from '@/types/message';
import { ETypesSections } from '@/routers/MainRouter';

const initialState: messagesState = {
  error: {
    status: false,
    msg: ''
  },
  loadingMsg: false,
  bandera: false,
  id_to: [],
  moreMessages: {},
  uploadFile: false,
  images: {},
  count: 0,
  sections: {},
  temporallyPreviousMessage: {}
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMoreMessages: (state, action: PayloadAction<SetMoreMessagesPayload>) => {
      const { id_conversation, hasMoreMessages } = action.payload;
      state.moreMessages[id_conversation] = hasMoreMessages;
    },
    cleanMessages: (state) => {
      state.error = {
        status: false,
        msg: ''
      };
      state.loadingMsg = false;
      state.bandera = false;
      state.id_to = [];
      state.moreMessages = {};
      state.uploadFile = false;
      state.images = {};
      state.count = 0;
      state.sections = {};
      state.temporallyPreviousMessage = {};
    },
    setTemporallyPreviousMessage: (state, action) => {
      state.temporallyPreviousMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setImagesToState, (state, action) => {
      state.images[action.payload.id] = {
        url: action.payload.url,
        expiredAt: action.payload.expiredAt
      };
    });
    builder.addCase(setLoadingMessage, (state, action) => {
      state.loadingMsg = action.payload;
    });
    builder.addCase(setFlagMessage, (state, action) => {
      state.bandera = action.payload;
    });
    builder.addCase(setUploadFile, (state, action) => {
      state.uploadFile = action.payload;
    });
    builder.addCase(setZeroUnreadCount, (state, action) => {
      const messages = state.sections[action.payload.id]?.map((item: SectionInterface) => {
        if (item.type === TypeSection.message) {
          item.data.status = action.payload.status;
        }
        return item;
      });
      state.sections[action.payload.id] = messages;
    });
    builder.addCase(clearMessagesByConverationId, (state, action) => {
      delete state.sections[action.payload];
    });
    builder.addCase(setSections, (state, action) => {
      state.sections = {
        ...state.sections,
        ...action.payload
      };
    });
    builder.addCase(setSectionBefore, (state, action) => {
      const { idConver, newSection } = action.payload;
      state.sections[idConver] = [...newSection, ...state.sections[idConver]];

      delete state.temporallyPreviousMessage[idConver];
    });
    builder.addCase(setSectionsByConverId, (state, action) => {
      state.sections[action.payload.id] = [];
      state.sections[action.payload.id] = action.payload.sections;
    });
    builder.addCase(addOrUpdateMessageInFragment, (state, action) => {
      const { newMessage, isUpdate } = action.payload;
      const fragmentId = newMessage?.id_fragment;
      const idConver = newMessage?.id_conversation;

      const conversationMessages = state.sections[idConver];

      if (!conversationMessages || conversationMessages.length === 0) {
        return;
      }

      const footerIndex = conversationMessages?.findIndex(
        (item: SectionInterface) => item.type === TypeSection.footer && item.id === fragmentId
      );

      let showSender = true;

      if (conversationMessages.length > 0) {
        // Comprueba el último mensaje antes del footer (si existe) para determinar showSender
        const lastMessageBeforeFooterIndex =
          footerIndex !== -1 ? footerIndex + 1 : conversationMessages.length - 2;
        if (lastMessageBeforeFooterIndex >= 0) {
          const lastMessage = conversationMessages[lastMessageBeforeFooterIndex].data;
          showSender = lastMessage?.from?._id !== newMessage?.from?._id;
        }
      }

      const existingMessageIndex = conversationMessages?.findIndex(
        (item: SectionInterface) => item.id === newMessage?.id_message
      );

      if (existingMessageIndex !== -1) {
        conversationMessages[existingMessageIndex] = {
          ...conversationMessages[existingMessageIndex],
          data: {
            ...conversationMessages[existingMessageIndex].data,
            status: newMessage?.status,
            content: newMessage?.content,
            _id: newMessage?._id,
            metadata: newMessage?.metadata
          }
        };
      } else {
        conversationMessages.splice(footerIndex + 1, 0, {
          type: TypeSection.message,
          id: newMessage?.id_message,
          data: newMessage,
          showSender: showSender
        });
      }
    });
    builder.addCase(addNewFragmentoToSection, (state, action) => {
      const { newSection, idConver } = action.payload;
      // Encuentra el índice del footer del fragmento anterior y agrega la nueva sección después de este
      // state.sections[idConver] = [...state.sections[idConver], ...newSection];
      state.sections = {
        ...state.sections,
        [idConver]: [...(state.sections[idConver] || []), ...newSection]
      };
    });
    builder.addCase(clearSections, (state, action) => {
      state.sections = {};
    });
  }
});

export const newMessage = (props: Message) => (dispatch, getState) => {
  const state = getState();
  const conversationState = state.conversations.conversations;
  const user = state.user?.user;

  if (!conversationState[props?.id_conversation]) {
    return;
  }

  const type =
    conversationState[props.id_conversation]?.platform === OriginOfMessage.internal
      ? ETypesSections.chat
      : ETypesSections.clients;

  dispatch(
    addOrUpdateMessageInFragment({
      newMessage: props,
      isUpdate: false
    })
  );

  const updateConversation = () => {
    const state2 = getState();
    const messagesState = state2.messages;
    const teamId = state2?.user?.team?.id;

    dispatch(updateUpdateAtConversation({ type, conversationId: props.id_conversation, teamId }));

    const shouldUpdateUnreadCount =
      props?.type !== TypeOfMessage?.info &&
      (type === ETypesSections.clients
        ? props?.origin !== OriginOfMessage?.internal && props?.origin !== OriginOfMessage?.server
        : props?.from?.id_user !== user?.id?.toString());

    if (shouldUpdateUnreadCount) {
      const numberOfMessagesNoRead = messagesState.sections[props.id_conversation]?.filter(
        (item: SectionInterface) =>
          item?.type === TypeSection.message &&
          item?.data?.status === 'delivered' &&
          item?.data?.origin !== OriginOfMessage?.internal &&
          item?.data?.origin !== OriginOfMessage?.server
      );

      dispatch(
        setUnreadCountToConversation({
          conversationId: props.id_conversation,
          count: numberOfMessagesNoRead?.length || 0
        })
      );
    }
  };

  updateConversation();
};

export const UpdateMessageStatusEventAndCountMessagesNoRead = (props) => async (dispatch) => {
  const { status, msg } = props;

  dispatch(addOrUpdateMessageInFragment({ newMessage: msg, isUpdate: true }));
  if (status === 'read' || msg?.origin === OriginOfMessage.internal) {
    dispatch(
      setUnreadCountToConversation({
        conversationId: msg.id_conversation,
        count: 0
      })
    );
  } else if (
    status === 'delivered' &&
    msg?.type !== 'template' &&
    msg?.origin !== OriginOfMessage.internal
  ) {
    dispatch(
      setUnreadCountToConversation({
        conversationId: msg.id_conversation,
        count: 1
      })
    );
  }
};

export default messagesSlice.reducer;
export const { setMoreMessages, cleanMessages, setTemporallyPreviousMessage } =
  messagesSlice.actions;
