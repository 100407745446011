import React from 'react';
import { Center, Icon } from '@gluestack-ui/themed';

function MaterialCommunity({
  icon,
  color,
  style,
  size = 'xl'
}: {
  icon: any;
  color?: string;
  style?: any;
  w?:
    | '$px'
    | '$1'
    | '$2'
    | '$3'
    | '$4'
    | '$5'
    | '$0'
    | '$0.5'
    | '$1.5'
    | '$2.5'
    | '$3.5'
    | '$4.5'
    | '$6'
    | '$7'
    | '$8'
    | '$9'
    | '$10'
    | '$11'
    | '$12'
    | '$16'
    | '$full';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xs';
}) {
  return (
    <Center>
      <Icon m="$0.5" as={icon} size={size} color={color || null} style={style} />
    </Center>
  );
}

export default React.memo(MaterialCommunity);
