import React from 'react';

import {
  Badge,
  BadgeText,
  HStack,
  Text,
  TooltipContent,
  VStack,
  Tooltip
} from '@gluestack-ui/themed';
import MemoizedAvatarCard from '@/atoms/AvatarCard';
import translate from '@/helpers/traslations';
import { FragmentAssignee } from './interfaces';

function ChipAssignee({
  assignees,
  icon = false
}: {
  assignees: FragmentAssignee[];
  icon?: boolean;
}) {
  return (
    <Tooltip
      placement="top"
      trigger={(triggerProps) => {
        return (
          <Badge
            {...triggerProps}
            bg={!icon ? null : '$transparent'}
            size="sm"
            variant="solid"
            action="success"
            paddingVertical="$1"
            paddingHorizontal={!icon ? '$1' : '$0'}
          >
            {assignees?.length === 1 ? (
              <MemoizedAvatarCard
                picture={assignees[0]?.foto}
                width="$4"
                name={assignees[0]?.nombre}
              />
            ) : (
              <HStack>
                {assignees?.slice(0, 3).map((avatar, index) => {
                  return (
                    <MemoizedAvatarCard
                      key={`${avatar?.IDUsuarios}-${index}`}
                      picture={avatar?.foto}
                      width="$4"
                      name={avatar?.nombre}
                    />
                  );
                })}
              </HStack>
            )}
            {!icon && (
              <BadgeText ml="$1" bold textTransform="capitalize">
                {`${assignees[0]?.nombre} ${assignees[0]?.apellido}`}
                {assignees?.length > 1 ? ` +${assignees?.length - 1}` : ''}
              </BadgeText>
            )}
          </Badge>
        );
      }}
    >
      <TooltipContent p="$2" maxWidth="$72" bg="$background">
        <VStack space="xs">
          <Text size="sm" lineHeight="$md">
            {assignees?.length > 1 ? translate('assignees') : translate('assignee')}
          </Text>
          <VStack paddingVertical="$2" borderTopColor="$border" borderTopWidth="$1" space="sm">
            {assignees?.map((user) => {
              return (
                <HStack key={user?.IDUsuarios} space="xs">
                  <MemoizedAvatarCard
                    width="$5"
                    sizeAvatar="xs"
                    picture={user?.foto}
                    name={user?.nombre}
                  />
                  <Text fontSize="$sm" lineHeight="$md">
                    {`${user?.nombre} ${user?.apellido}`}
                  </Text>
                </HStack>
              );
            })}
          </VStack>
        </VStack>
      </TooltipContent>
    </Tooltip>
  );
}

export default React.memo(ChipAssignee);

{
  /* <MemoizedAvatarCard
              width="$4"
              sizeAvatar="xs"
              picture={assignees[0]?.foto}
              name={assignees[0]?.nombre}
            />
            <BadgeText bold ml="$1" textTransform="capitalize">
              {`${assignees[0]?.nombre} ${assignees[0]?.apellido}`}
              {assignees?.length > 1 ? ` +${assignees?.length - 1}` : ''}
            </BadgeText> */
}
