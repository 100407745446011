import React from 'react';
import translate from '../helpers/traslations';
import { uniqueId } from 'lodash';
import { getDisplayName, openURL } from '@/utils/functions';
import { URL_PMS_EDIT_CONTACT } from '@/utils/constants';

import { Text, View } from '@gluestack-ui/themed';
import ContactCard from '../atoms/ContactCard';
import CustomTooltip from './tooltip/CustomTooltip';
import MainCta from '../atoms/MainCta';
import ViewPhonesContactCard from '../atoms/ViewPhonesContactCard';
import ViewEmailsContactCard from '../atoms/ViewEmailsContactCard';
import { Pencil } from '@/assets/icons/Icons';

function RankedContacts({ rankedContacts, route, navigation, showToast }) {
  const handlePressNewContact = (id) => {
    openURL(URL_PMS_EDIT_CONTACT + id);
    navigation?.goBack();
  };

  return (
    <View
      style={{
        marginBottom: 20
      }}
    >
      <Text style={{ marginVertical: 10 }}>{translate('messages.contactsRanked')}</Text>
      {rankedContacts?.map((item) => (
        <ContactCard
          variant="accent"
          key={JSON.stringify(item)}
          name={getDisplayName(item)}
          avatar={item?.avatar}
          emails={
            <ViewEmailsContactCard
              user={{
                ...item,
                id: item?.contactPms,
                emails: item?.email ? [{ email: item?.email, id: uniqueId() }] : []
              }}
            />
          }
          phones={
            <ViewPhonesContactCard
              team={route?.params?.team}
              user={{
                ...item,
                id: item?.contactPms,
                phones: [{ phone: item?.phone, id: uniqueId() }]
              }}
              showToast={showToast}
            />
          }
          middle={
            <CustomTooltip title={translate(`editContact`)}>
              <MainCta
                icon={Pencil}
                onPress={() => handlePressNewContact(item?.contactPms)}
                iconSize="xs"
                buttonSize="xs"
                variant="outline"
              />
            </CustomTooltip>
          }
        />
      ))}
    </View>
  );
}

export default React.memo(RankedContacts);
