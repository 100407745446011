import React, { useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Asset } from 'expo-asset';
import { Image } from 'expo-image';
import i18n from '../../providers/Localization';
import { TText, TView } from '../themed';

function EmptyConversationList({ idString }) {
  const [image, setImage] = React.useState(null);

  useEffect(() => {
    const preloadImage = async () => {
      const [{ localUri }] = await Asset.loadAsync(
        `${require('@/assets/images/filter-empty.svg')}`
      );
      setImage(localUri);
    };
    preloadImage();
  }, []);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          display: 'flex',
          height: '100%',
          width: '100%',
          flex: 1,
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // backgroundColor: colors.backgroundSecondary,
          paddingHorizontal: 20
        },
        textTitile: {
          textAlign: 'center'
          // color: colors.gris
        }
      }),
    []
  );
  return (
    <TView bgColor="backgroundSecondary" style={styles.container}>
      <Image alt="no hay conversaciones" source={image} style={{ width: 200, height: 200 }} />
      <TText size="md" style={styles.textTitile}>
        {i18n.t(idString)}
      </TText>
    </TView>
  );
}

export default React.memo(EmptyConversationList);
