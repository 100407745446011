import { Text, Spinner, View } from '@gluestack-ui/themed';
import React from 'react';
import i18n from '@/providers/Localization';

function LoadingComponent({ idString }) {
  // loadingConversations
  return (
    <View
      bg="$backgroundSecondary"
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10
      }}
    >
      <Spinner size="small" color="$pxsol" />
      <Text>{i18n.t(idString)}...</Text>
    </View>
  );
}

export default React.memo(LoadingComponent);
