import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import { HStack, VStack, Text } from '@gluestack-ui/themed';
import MemoizedAvatarCard from './AvatarCard';
import { RootState, useAppSelector } from '@/redux/store';

function ItemUser() {
  const name = useAppSelector((state: RootState) => state.user.user?.name);
  const avatar = useAppSelector((state: RootState) => state.user.user?.avatar);
  const email = useAppSelector((state: RootState) => state.user.user?.email);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        menuItem: {
          flex: 1,
          paddingVertical: 5,
          // borderBottomColor: colors.border,
          borderBottomWidth: 0.05
        }
      }),
    []
  );

  return (
    <HStack alignItems="center" space="sm" style={styles.menuItem}>
      <MemoizedAvatarCard sizeAvatar="sm" picture={avatar} name={name} />
      <VStack space="xs">
        <Text>{name}</Text>
        <Text size="sm">{email}</Text>
      </VStack>
    </HStack>
  );
}

export default React.memo(ItemUser);
