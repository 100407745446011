import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { RootState, useAppSelector } from '../redux/store';
import { Spinner } from '@gluestack-ui/themed';
import InputComponent from '../components/conversation/footer/InputComponent';
import i18n from '../providers/Localization';
import { handleSearchInInput } from '../utils/functions';
import AlertComponent from '../atoms/AlertComponent';
import { Contacts } from '@/types/typesRedux/interfaces';
import RenderContacts from '../components/RenderContacts';
import { ThemedScrollView, TView, TVView } from '@/components/themed';

interface Props {
  button?: React.ReactNode | null;
  handleSelect?: (a: Contacts) => void;
  id?: number[] | null;
  setId?: (a: string[]) => void | null;
  name?: string | null;
  setName?: (a: string) => void | null;
  newConversation?: boolean;
  contacto?: Contacts[];
  loading?: boolean;
  idStringInfo?: string;
  ctaInfo?: string;
  labelId?: string;
}

function LayoutNewConversation({
  button,
  id = [],
  setId,
  name = '',
  setName,
  idStringInfo,
  contacto,
  handleSelect,
  loading
}: Props) {
  const [currentPage] = useState(1);
  const [contactsByPage] = useState(40);
  const indexOfLastContacts = currentPage * contactsByPage;
  const indexOfFirstContacts = indexOfLastContacts - contactsByPage;
  const currentContacts = contacto.slice(indexOfFirstContacts, indexOfLastContacts);
  const [nombreParticipante, setNombreParticipante] = useState('');
  const [filtros, setFiltros] = useState<Contacts[]>([]);

  useEffect(() => {
    setFiltros(currentContacts);
    return () => {
      setName && setName('');
      setId && setId([]);
    };
  }, []);

  // function handleSearch to search in contacts

  const handleSearch = (event: string) => {
    handleSearchInInput({
      event,
      arrayToFilter: currentContacts,
      arrayFilterNoPagination: contacto,
      callbackSetFilter: setFiltros,
      callbackOnChangeSearch: setNombreParticipante,
      inputSearch: nombreParticipante
    });
  };

  const styles = StyleSheet.create({
    input: {
      marginVertical: 5,
      width: '100%',
      height: 40
    }
  });

  return (
    <TVView
      bgColor="backgroundSecondary"
      style={{
        height: '100%',
        flex: 1,
        paddingHorizontal: 8,
        paddingVertical: 16,
        justifyContent: 'space-between'
      }}
    >
      <TVView
        style={{
          marginBottom: 8,
          gap: 8
        }}
      >
        <TView style={{ ...styles.input, width: '100%' }}>
          <InputComponent
            background="background"
            iconSearch
            onChange={handleSearch}
            placeholder={i18n.t('search')}
            value={nombreParticipante}
            multiline={false}
            autofocus={!!(id.length > 0 && name === '')}
          />
        </TView>
        <AlertComponent idString={idStringInfo} />
      </TVView>

      <ThemedScrollView style={{ width: '100%', flex: 1 }}>
        {loading ? (
          <Spinner color="$pxsol" />
        ) : (
          <RenderContacts filtros={filtros} callbackToSelectUser={handleSelect} id={id || []} />
        )}
      </ThemedScrollView>
      <TView
        style={{
          marginTop: 5
        }}
      >
        {button}
      </TView>
    </TVView>
  );
}

export default React.memo(LayoutNewConversation);
