enum Themes {
  DARK = 'dark',
  LIGHT = 'light'
}

type Actions = {
  toggle: () => void;
};

export type { Actions };

export { Themes };

export type ThemeMode = 'dark' | 'light';
