import React, { Component, ReactNode } from 'react';

import { Text, View } from '@gluestack-ui/themed';
import AlertComponent from '../../atoms/AlertComponent';
import MainCta from '@/atoms/MainCta';
import { copyToClipboard } from '@/utils/functions';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    // Actualiza el estado para que el siguiente renderizado muestre la UI alternativa.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Aquí también puedes registrar el error a un servicio de reporte de errores
    console.log('Error capturado en ErrorBoundary:', { error, errorInfo });
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // Puedes renderizar cualquier UI de respaldo
      return (
        <View w="$full">
          <AlertComponent
            action="error"
            variant="accent"
            idString={this.state.error?.toString()}
            translateText={false}
          />
          <View alignSelf="center" marginVertical={3}>
            <MainCta
              onPress={() =>
                copyToClipboard(
                  JSON.stringify({
                    state: this.state,
                    error: this.state.error?.toString()
                  })
                )
              }
              labelId="copyTheError"
            />
          </View>
        </View>
      );
    }

    return this.props.children;
  }
}

export default React.memo(ErrorBoundary);
