import React, { memo } from 'react';
import { HStack, VStack } from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';
import { Switch } from '@gluestack-ui/themed';
import { Pressable } from '@gluestack-ui/themed';
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import LayoutForm from './LayoutForm';
import { arrayDays } from '@/utils/constants';

const DaysComponent = ({ setAllDays, allDays, handleAddDay, conditonDay }) => {
  return (
    <VStack space="sm">
      <HStack
        justifyContent="space-between"
        alignItems="center"
        paddingVertical="$2"
        sx={{
          ':hover': {
            backgroundColor: '$background'
          }
        }}
      >
        <Text>Todos los días</Text>
        <Switch
          value={allDays}
          size="md"
          onToggle={() => {
            setAllDays(!allDays);
          }}
        />
      </HStack>

      {!allDays && (
        <LayoutForm
          idString="automatizations.dayOfWeek"
          icon={true}
          idStringTooltip="automatizations.dayOfWeekTooltip"
        >
          <HStack width="90%" justifyContent="space-between" alignItems="center" alignSelf="center">
            {arrayDays?.map((day) => (
              <CustomTooltip title={day?.title}>
                <Pressable
                  onPress={() => handleAddDay(day?.value)}
                  width={40}
                  height={40}
                  backgroundColor={
                    conditonDay.value.includes(day?.value) ? '$primary' : '$background'
                  }
                  borderWidth={1}
                  borderColor="$secondary"
                  borderRadius="$full"
                  key={day?.value}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text>{day?.label}</Text>
                </Pressable>
              </CustomTooltip>
            ))}
          </HStack>
        </LayoutForm>
      )}
    </VStack>
  );
};

export default memo(DaysComponent);
