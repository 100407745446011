import { RootState, useAppSelector } from '@/redux/store';
import { memo, useEffect } from 'react';
import { hotjar } from 'react-hotjar';

const HotjarProvider = ({ children }) => {
  const user = useAppSelector((state: RootState) => state?.user?.user);

  useEffect(() => {
    if (!user) return;
    hotjar.initialize({ id: 3589452, sv: 6, debug: false, nonce: 'nonce' });
    hotjar.identify(user?.id, { name: user?.name, email: user?.email });
    console.log('hotjar initialized', hotjar.initialized());
  }, [user]);

  return children;
};
export default memo(HotjarProvider);
