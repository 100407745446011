import {
  CommonActions,
  createNavigationContainerRef,
  StackActions
} from '@react-navigation/native';
import { Routes } from './routes';
import { NavigationParams } from './interfaces';
import { navigationRef } from './MainRouter';

export function push<RouteName extends keyof NavigationParams>(
  name: Routes,
  params?: NavigationParams[RouteName]
) {
  if (navigationRef.isReady()) navigationRef.current?.dispatch(StackActions.push(name, params));
}

export function pop(count: number) {
  if (navigationRef.isReady()) navigationRef.current?.dispatch(StackActions.pop(count));
}

export function navigation<RouteName extends keyof NavigationParams>(
  name: any,
  params?: NavigationParams[RouteName]
) {
  if (navigationRef.isReady()) navigationRef.navigate(name, params);
}

export function navigate(type: 'reset' | 'navigate', props) {
  if (navigationRef.isReady()) {
    // Perform navigation if the react navigation is ready to handle actions
    if (type === 'reset') {
      navigationRef.reset(props);
    } else {
      navigationRef.dispatch(CommonActions.navigate(props));
    }
  }
}

export function goBack() {
  if (navigationRef.isReady()) navigationRef.current?.goBack();
}
