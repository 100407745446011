import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import MemoizedAvatarCard from './AvatarCard';
import { THView, TText, TView, TVView } from '@/components/themed';
import { Check } from '@/assets/icons/Icons';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';

interface PropsContactCard {
  name: string;
  avatar: string;
  email?: string;
  middle?: React.ReactNode;
  rightElement?: React.ReactNode;
  phones?: React.ReactNode;
  variant?: 'accent' | 'solid';
  emails?: React.ReactNode;
  selected?: boolean;
}

function ContactCard({
  name,
  avatar,
  email,
  middle,
  rightElement,
  phones,
  variant,
  emails,
  selected
}: PropsContactCard) {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          justifyContent: 'flex-start',
          width: '100%',
          borderLeftWidth: variant === 'accent' ? 4 : 0,
          borderTopLeftRadius: variant === 'accent' ? 8 : 0,
          borderBottomLeftRadius: variant === 'accent' ? 8 : 0,
          borderTopWidth: variant === 'accent' ? 1 : 0,
          borderBottomWidth: variant === 'accent' ? 1 : 0,
          borderRightWidth: variant === 'accent' ? 1 : 0
        },
        user: {
          height: 50,
          alignItems: 'center',
          justifyContent: 'space-between',
          space: 'sm',
          flex: 1
        },
        leftContainer: {
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: rightElement ? 8 : 0,
          paddingHorizontal: 8,
          paddingVertical: 1,
          width: '100%',
          gap: 4
        },
        containerUser: {
          alignItems: 'center',
          gap: 8,
          maxWidth: '85%',
          paddingRight: 10,
          overflow: 'hidden'
        },
        nameEmail: {
          flexBasis: '85%'
        },
        selected: { alignItems: 'center', gap: 2, width: 40, height: 40, justifyContent: 'center' }
      }),
    [variant]
  );

  return (
    <TVView border={variant === 'accent' ? 'border' : 'transparent'} style={styles.container}>
      <THView style={styles.leftContainer}>
        <THView style={styles.user}>
          <THView style={styles.containerUser}>
            {selected ? (
              <TView style={styles.selected}>
                <MaterialCommunity size="md" icon={Check} />
              </TView>
            ) : (
              <MemoizedAvatarCard picture={avatar || avatar} width="$10" name={name} />
            )}
            <TVView style={styles.nameEmail}>
              <TText
                size="md"
                lineBreakMode="clip"
                numberOfLines={1}
                style={{
                  fontWeight: 'bold'
                }}
              >
                {name?.trim()}
              </TText>
              {email && (
                <TText lineBreakMode="clip" numberOfLines={1} size="md">
                  {email?.trim()}
                </TText>
              )}
            </TVView>
          </THView>
          {middle}
        </THView>
        {rightElement}
      </THView>
      {emails}
      {phones}
    </TVView>
  );
}

export default React.memo(ContactCard);
