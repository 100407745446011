import React, { useEffect, useState } from 'react';
import MenuActions from '@/components/menu/MenuActions';
import { MessageBadge } from '@/assets/icons/Icons';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store/index';
import { setCustomPropietyOfWorkflow } from '@/redux/actionTypes/index';
import { TriggerWorkflow } from '@/types/enums/workflows';
import translate from '@/helpers/traslations';
import { TText, TView } from '@/components/themed';

const MenuTrigger = ({ idString }: { idString: string }) => {
  const dispatch = useAppDispatch();
  const newWorkflow = useAppSelector((state: RootState) => state.automatization.newWorkflows);

  const [selectedTrigger, setSelectedTrigger] = useState<TriggerWorkflow>();

  useEffect(() => {
    if (newWorkflow) {
      setSelectedTrigger(newWorkflow?.trigger);
    }
  }, [newWorkflow]);

  const onChangeTrigger = (trigger) => {
    setSelectedTrigger(trigger?.key);
    dispatch(
      setCustomPropietyOfWorkflow({ idString: idString, value: trigger?.key, propiety: 'trigger' })
    );
  };

  const menuActions = [
    {
      label: 'messages.new_incoming_message',
      key: TriggerWorkflow.new_incoming_message,
      icon: MessageBadge,
      action: (e) => onChangeTrigger(e),
      available: true
    }
  ];
  return (
    <TView>
      <MenuActions
        trigger={
          selectedTrigger ? (
            <TText color="text">{translate(`messages.${selectedTrigger}`)}</TText>
          ) : (
            <TText color="secondary">
              {translate('automatizations.selectTheTriggerOfAutomation')}
            </TText>
          )
        }
        menuActions={menuActions}
        sx={{
          width: '100%',
          borderRadius: 8,
          borderWidth: 1,
          padding: 4,
          height: 42,
          justifyContent: 'center',
          backgroundColor: selectedTrigger ? '$backgroundActive' : '$backgroundSecondary'
        }}
      />
    </TView>
  );
};

export default React.memo(MenuTrigger);
