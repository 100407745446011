import { Tooltip, TooltipContent, TooltipText, View } from '@gluestack-ui/themed';
import React, { useEffect, useState } from 'react';

function CustomTooltip({ children, title }: { children: React.ReactNode; title: string }) {
  return (
    <Tooltip
      key={title}
      zIndex={99999999999}
      placement="top"
      closeOnClick={true}
      trigger={(triggerProps: ({ ref, onHoverIn, onHoverOut }: any, { open }: any) => void) => {
        return <View {...triggerProps}>{children}</View>;
      }}
    >
      <TooltipContent bg="$background">
        <TooltipText maxWidth="$48" fontSize="$xs">
          {title}
        </TooltipText>
      </TooltipContent>
    </Tooltip>
  );
}

export default React.memo(CustomTooltip);
