import React, { useEffect, useState } from 'react';
import { IconClose } from '@/assets/icons/Icons';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';

import {
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputField,
  Text,
  TextareaInput
} from '@gluestack-ui/themed';
import { ModalFooter } from '@gluestack-ui/themed';
import { Heading, ModalBody, ModalContent, ModalHeader } from '@gluestack-ui/themed';
import { ModalCloseButton } from '@gluestack-ui/themed';
import translate from '@/helpers/traslations';
import MainCta from '../../atoms/MainCta';
import { useAppDispatch } from '@/redux/store';
import {
  createLabel,
  updateLabel,
  updateLabelsInConversation
} from '@/redux/slices/labels/requests';
import { Textarea } from '@gluestack-ui/themed';
import { HandleModalProp } from '@/providers/interfaces';

const ContentModalLabel = ({
  setModalLabel,
  showToast,
  data
}: {
  setModalLabel: (value: boolean) => void;
  showToast: (value) => void;
  data: HandleModalProp;
}) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data?.label) {
      setName(data?.label?.tag_name);
      setDescription(data?.label?.tag_description);
    }
  }, [data]);

  const hanldeSumit = () => {
    if (name) {
      setLoading(true);
      data?.label?.id
        ? dispatch(
            updateLabel({ tag_name: name, tag_description: description ?? '', id: data?.label?.id })
          )
            .then((res) => {
              setName('');
              setDescription('');
              showToast({
                title: translate('success'),
                subtitle: translate('messages.labelUpdated'),
                variant: 'accent',
                type: 'success'
              });
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setModalLabel(false);
            })
        : dispatch(createLabel({ tag_name: name, tag_description: description ?? '' }))
            .then((res) => {
              console.log('createLabel: ', res);
              if (data?.conversationId) {
                dispatch(
                  updateLabelsInConversation({
                    conversationsId: [data?.conversationId],
                    action: 'add',
                    labels: [res?.id]
                  })
                )
                  .then((res) => {
                    showToast({
                      title: translate('success'),
                      subtitle: translate('messages.labelsUpdated'),
                      variant: 'accent',
                      type: 'success'
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              setName('');
              setDescription('');
              showToast({
                title: translate('success'),
                subtitle: translate('messages.labelCreated'),
                variant: 'accent',
                type: 'success'
              });
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setModalLabel(false);
            });
    }
  };

  return (
    <ModalContent height="auto" bg="$backgroundSecondary">
      <ModalHeader>
        <Heading size="lg">{translate('actions.newLabel')}</Heading>
        <ModalCloseButton>
          <MaterialCommunity icon={IconClose} />
        </ModalCloseButton>
      </ModalHeader>
      <ModalBody>
        <Text>{translate('messages.labelSlogan')}</Text>

        <FormControl isRequired mt="$4">
          <FormControlLabel mb="$1" gap="$1">
            <FormControlLabelText>{translate('messages.labelName')}</FormControlLabelText>
          </FormControlLabel>
          <Input borderColor="$transparent" bg="$background" borderRadius="$full">
            <InputField
              aria-label="type-name-of-label"
              value={name}
              onChange={(e) => setName(e.nativeEvent.text)}
              fontSize="$md"
            />
          </Input>
        </FormControl>
        <FormControl w="$full">
          <FormControlLabel mb="$1" gap="$1">
            <FormControlLabelText>{translate('description')}</FormControlLabelText>
          </FormControlLabel>
          <Textarea
            bg="$background"
            borderColor="$border"
            sx={{ ':focus': { borderColor: '$border' } }}
          >
            <TextareaInput
              value={description}
              onChange={(e) => setDescription(e.nativeEvent.text)}
              placeholder="Escribe una descripción para tu etiqueta..."
            />
          </Textarea>
        </FormControl>
      </ModalBody>
      <ModalFooter gap="$3">
        <MainCta
          labelId="actions.cancel"
          variant="outline"
          buttonSize="sm"
          iconColor="$disabled"
          onPress={() => {
            setModalLabel(false);
          }}
        />
        <MainCta
          labelId={data?.label?.id ? 'actions.update' : 'actions.create'}
          variant="solid"
          buttonSize="sm"
          disabled={!name || loading}
          loading={loading}
          onPress={() => {
            hanldeSumit();
          }}
        />
      </ModalFooter>
    </ModalContent>
  );
};

export default ContentModalLabel;
