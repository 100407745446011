import { Text, VStack, HStack } from '@gluestack-ui/themed';
import React, { memo } from 'react';

interface Props {
  message: string;
}
function EmptyContacts({ message }: Props) {
  return (
    <HStack
      alignItems="center"
      style={{
        flex: 1,
        width: '100%'
      }}
    >
      <VStack
        justifyContent="center"
        style={{
          width: '100%'
        }}
      >
        <Text size="sm">{message}</Text>
      </VStack>
    </HStack>
  );
}

export default memo(EmptyContacts);
