import React, { memo } from 'react';
import { SafeAreaView } from 'react-native';

import { Routes } from '../../routers/routes';
import { View } from '@gluestack-ui/themed';
import { verifyIsMobile } from '@/utils/functions';

import { useAppDispatch } from '@/redux/store';
import { setIntroShown } from '@/redux/actionTypes/index';
import SliderApp from '../slider/SliderApp';
import { SliderItem } from '../slider/interface';
import { URL_TO_CONNECT_CHANNEL } from '@/utils/constants';

const slides: SliderItem[] = [
  {
    key: 'one',
    title: '¡Te damos la bienvenida a Conversaciones!',
    description:
      '¡Descubre la nueva App Conversaciones y lleva tu hotel al siguiente nivel de comunicación!',
    image: require('@/assets/images/intro_main-min.png'),
    getFile: false,
    ctaUrl: URL_TO_CONNECT_CHANNEL,
    ctaTextId: 'scheduleDemo',
    ctaEventId: 'connectWhatsApp',
    ctaEventLabel: 'connectWhatsAppFromTour'
  },
  {
    key: 'two',
    title: '¡Aprovecha la bandeja de entrada compartida!',
    getFile: false,
    description:
      'La colaboración y eficiencia de tu equipo se centrará en una bandeja de entrada compartida que optimizará el flujo de trabajo a través de filtros según el estado de una reserva.',
    image: require('@/assets/images/tour/bandeja-de-entrada-compartida.svg')
  },
  {
    key: 'three',
    title: '¡Experimenta la comunicación automatizada y personalizada!',
    getFile: false,
    version: 'ALPHA',
    description:
      'Ofrecerás una experiencia de comunicación personalizada con mensajes automáticos a través de un chatbot que proporciona respuestas coherentes y útiles generadas por IA.',
    image: require('@/assets/images/tour/comunicacion-automatizada.svg')
  },
  {
    key: 'four',
    title: '¡Descubre la eficiencia de las asociaciones entre chats y reservas!',
    getFile: false,
    description:
      'Te ayudamos con la gestión de las interacciones con tus usuarios permitiéndote asociar chats con reservas y habitaciones para un seguimiento efectivo, organizado y en un solo lugar.',
    image: require('@/assets/images/tour/eficiencia-asociaciones.svg')
  },
  {
    key: 'five',
    title: 'Y mucho más! ',
    image: require('@/assets/images/tour/envia-informacion-relevante.svg'),
    getFile: false,
    description:
      'Comienza a utilizar la versión integrada de Conversaciones con el Chat Interno, ¡y agenda una demo de la integración con WhatsApp e IA hoy mismo!',
    ctaUrl: URL_TO_CONNECT_CHANNEL,
    ctaTextId: 'scheduleDemo',
    ctaEventId: 'connectWhatsApp',
    ctaEventLabel: 'connectWhatsAppFromTour'
  }
];

function IntroSlider(introProps) {
  const dispatch = useAppDispatch();

  const handleEndSlider = () => {
    dispatch(setIntroShown(true));
    introProps.navigation.navigate(Routes.Login);
  };

  return (
    <SafeAreaView
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        alignSelf: 'center',
        backgroundColor: '#1A1A1A'
      }}
    >
      <View
        style={{
          maxWidth: verifyIsMobile() ? '80%' : 600,
          width: '100%'
        }}
      >
        <SliderApp handleEndSlider={handleEndSlider} data={slides} />
      </View>
    </SafeAreaView>
  );
}

export default memo(IntroSlider);
