import React, { useMemo, memo } from 'react';
import { THView, TVView } from '@/components/themed';

import ItemSoundConversation from './ItemSoundConversation';
import RenderAssociationCard from '@/atoms/RenderAssociationCard';
import RenderBadges from '@/atoms/RenderBadges';
import { IMPORTANTS } from '@/utils/filters';
import MoreOptions from '@/components/partials/conversationCard.tsx/MoreOptions';
import { StyleSheet } from 'react-native';
import { RootState, useAppSelector } from '@/redux/store';
import FooterConversationCard from './FooterConversationCard';
import HeaderConversationCard from './HeaderConverastionCard';
import ItemImportant from './ItemImportant';

const RightComponentCard = ({ conversationId }) => {
  const labels = useAppSelector((state: RootState) => state.labels.labels);
  const conversation = useAppSelector(
    (state: RootState) => state.conversations.conversations?.[conversationId]
  );

  const styles = useMemo(
    () =>
      StyleSheet.create({
        containerMessage: {
          width: '90%',
          justifyContent: 'space-between',
          gap: 2,
          paddingHorizontal: 2,
          paddingLeft: 4
        },
        tview2: {
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          gap: 1
        },
        hedearCard: {
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 1,
          overflow: 'hidden',
          maxWidth: '75%'
        },
        itemMoreOptions: {
          alignItems: 'center',
          gap: 1,
          overflow: 'hidden',
          width: 65,
          paddingHorizontal: 2,
          justifyContent: 'flex-end'
        }
      }),
    []
  );

  return (
    <TVView style={styles.containerMessage}>
      {conversation?.platform !== 'internal' && (
        <THView style={styles.tview2}>
          <THView style={styles.hedearCard}>
            <ItemSoundConversation conversationId={conversationId} />
            <RenderAssociationCard fragmentId={conversation?.fragments[0]} />
            <RenderBadges
              badges={conversation?.tags.filter((tag: string) => tag !== IMPORTANTS)}
              data={labels}
              findKey="id"
              showKey="tag_name"
              numbers={1}
            />
          </THView>
          <THView style={styles.itemMoreOptions}>
            <ItemImportant conversationId={conversationId} platform={conversation?.platform} />
            <MoreOptions conversationId={conversationId} />
          </THView>
        </THView>
      )}
      <HeaderConversationCard createdAt={conversation?.updatedAt} conversationId={conversationId} />
      <FooterConversationCard conversationId={conversationId} />
    </TVView>
  );
};

export default memo(RightComponentCard, (prevProps, nextProps) => {
  return prevProps.conversationId === nextProps.conversationId;
});
