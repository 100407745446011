import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { RootStackParams } from './interfaces';
import { Routes } from './routes';

import HeaderAppBar from '@/atoms/HeaderAppBar';
import MainCta from '@/atoms/MainCta';
import { IconClose } from '@/assets/icons/Icons';
import translate from '@/helpers/traslations';
import { RootState, useAppSelector } from '../redux/store';
import { IS_WEB_PC } from '@/utils/functions';
import AutomatizationTemplate from '@/screens/AutomatizationTemplate';
import AutomatizationRange from '@/screens/AutomatizationRange';
import colors from '../../config/colors';
import { ContractsEnum } from '@/types/enums/conversation';
import Fallback from '@/screens/Fallback';

const Stack = createStackNavigator<RootStackParams>();

const AutomatizationStack = ({ route, onClose }) => {
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);
  const contracts = useAppSelector((state: RootState) => state.user?.contracts);
  const isPxsol = useAppSelector((state: RootState) => state.user?.user?.isPxsol);
  console.log('route', route);

  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: false,
        headerShown: false,
        cardStyle: {
          backgroundColor: colors[themeMode]?.backgroundSecondary,
          flex: 1
        }
      }}
    >
      <Stack.Screen
        name={Routes.AutomatizationTemplate}
        component={AutomatizationTemplate}
        initialParams={{
          team: route?.params?.team
        }}
        options={{
          headerShown: true,
          header: () => (
            <HeaderAppBar
              ctaBack={
                IS_WEB_PC ? (
                  <MainCta icon={IconClose} variant="outline" iconSize="md" onPress={onClose} />
                ) : null
              }
              idStringTitle="navigation.newAutomatizationTemplate"
            />
          ),
          title: translate('navigation.newAutomatizationTemplate'),
          cardStyle: {
            backgroundColor: colors[themeMode]?.backgroundSecondary,
            borderLeftWidth: 1,
            borderLeftColor: colors[themeMode]?.border,
            flex: 1
          }
        }}
      />
      <Stack.Screen
        name={Routes.AutomatizationBot}
        component={
          contracts?.includes(ContractsEnum?.chat) || isPxsol ? AutomatizationTemplate : Fallback
        }
        initialParams={{
          team: route?.params?.team
        }}
        options={{
          headerShown: true,
          header: () => (
            <HeaderAppBar
              ctaBack={
                IS_WEB_PC ? (
                  <MainCta icon={IconClose} variant="outline" iconSize="md" onPress={onClose} />
                ) : null
              }
              idStringTitle="navigation.newAutomatizationBot"
            />
          ),
          title: translate('navigation.newAutomatizationBot'),
          cardStyle: {
            backgroundColor: colors[themeMode]?.backgroundSecondary,
            borderLeftWidth: 1,
            borderLeftColor: colors[themeMode]?.border,
            flex: 1
          }
        }}
      />
      <Stack.Screen
        name={Routes.AutomatizationRange}
        component={AutomatizationRange}
        options={{
          headerShown: true,
          header: () => (
            <HeaderAppBar ctaBack={null} idStringTitle="navigation.newAutomatizationRange" />
          ),
          title: translate('navigation.newAutomatizationRange'),
          cardStyle: {
            backgroundColor: colors[themeMode]?.backgroundSecondary,
            borderLeftWidth: 1,
            borderLeftColor: colors[themeMode]?.border,
            flex: 1
          }
        }}
      />
    </Stack.Navigator>
  );
};

export default React.memo(AutomatizationStack);
