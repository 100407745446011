import { View, StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import translate from '@/helpers/traslations';
import HeaderMessageComponent from '../conversation/body/message/HeaderMessageComponent';
import IconDateComponent from '../../atoms/IconDateComponent';
import LastMessageContainer from '../conversation/conversationCard/LastMessageContainer';
import { Message } from '@/types/message';

function ItemMessage({ message }: { message: Message }) {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        leftMessage: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flex: 1,
          gap: 5
        },
        headerMessage: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flex: 1,
          gap: 5
        }
      }),
    []
  );
  return (
    <View style={styles.leftMessage}>
      <View style={styles.headerMessage}>
        <HeaderMessageComponent
          name={message?.from?.name ?? translate('internal')}
          text={message?.from?.name ?? translate('internal')}
          avatar={message?.from?.avatar}
        />
        <IconDateComponent
          position="left"
          status={message?.status}
          createdAt={message?.createdAt}
        />
      </View>
      <LastMessageContainer lastMessage={message} fragmentsStatus="open" />
    </View>
  );
}

export default React.memo(ItemMessage);
