import { AxiosError, AxiosResponse } from 'axios';
import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native';
import { TypesSections } from '../../../types';
import {
  addNewFragmentoToSection,
  addOrUpdateMessageInFragment,
  setFlagMessage,
  setFragments,
  setImagesToState,
  setUnreadCountToConversation,
  setUploadFile,
  setZeroUnreadCount
} from '@/redux/actionTypes';
import { Thunk } from '@/redux/store';
import axiosInstance from '@/utils/network/instanceAxios';
import { PostMessage, UploadFileInterface, statusMessage } from './interfaces';
import { ETypesSections } from '@/routers/MainRouter';

export const createMessage =
  (props: PostMessage): Thunk =>
  async (dispatch): Promise<AxiosResponse | AxiosError> => {
    console.log(
      '%c [ INIT CREATE MESSAGE] 🕐 Waiting for response... ',
      'color: #e1e1e1; font-size:16px;  background-color: #7c7af9; padding: 5px;',
      props
    );
    // Armo el objeto con los _id en from y to para enviar a fran...
    const { from, to, ...rest } = props.message;
    const messageToSend = {
      ...rest,
      from: from._id,
      to: to?._id
    };
    try {
      const response = await axiosInstance.post('/message/createMessage', {
        message: messageToSend
      });
      if (response.status == 404 || response.status == 500 || response.status == 202) {
        console.log(
          '%c [ ERROR CREATE MESSAGE] ❌ ERROR 404',
          'color:#3a3a3; background-color: red; font-size:16px; padding:5px;',
          response.data
        );
        const message = {
          ...props.message,
          status: 'error'
        };
        dispatch(addOrUpdateMessageInFragment({ newMessage: message, isUpdate: true }));
        throw new Error(response.data);
      }
      console.log(
        '%c [ SUCCESS CREATE MESSAGE] ✅ Mensaje Creado...',
        'color:#3a3a3; background-color: green; font-size:16px; padding:5px;',
        response
      );
      dispatch(
        addOrUpdateMessageInFragment({
          newMessage: response?.data?.data?.data,
          isUpdate: true
        })
      );
      return response.data.data.id_conversation as AxiosResponse;
    } catch (error) {
      console.log(
        '%c [ ERROR CREATE MESSAGE] ❌ ERROR',
        'color:#3a3a3; background-color: red; font-size:16px; padding:5px;',
        error
      );
      const message = {
        ...props.message,
        status: 'error'
      };
      dispatch(addOrUpdateMessageInFragment({ newMessage: message, isUpdate: true }));
      return error?.response as AxiosError;
    }
  };

export const setAllMessagesReadByConverId =
  (id): Thunk =>
  async (dispatch, getState): Promise<AxiosResponse | AxiosError> => {
    try {
      const state = getState();
      const conversation = state.conversations.conversations;
      const teamId = state.user?.team?.id;

      let type = ETypesSections?.chat;
      if (conversation[id]) {
        if (Object.keys(conversation[id]).length > 0) {
          conversation[id].platform === 'internal'
            ? (type = ETypesSections?.chat)
            : (type = ETypesSections?.clients);
        }
      }
      dispatch(
        setUnreadCountToConversation({
          conversationId: id,
          count: 0
        })
      );
      dispatch(
        setZeroUnreadCount({
          id,
          status: 'read',
          email: state.user.user?.email
        })
      );
    } catch (error) {
      console.log(
        '%c [ ERROR SET ALL MESSAGES READ BY CONVER ID] ❌ ERROR',
        'color:#3a3a3; background-color: red; font-size:16px; padding:5px;',
        error
      );
      return error as AxiosError;
    }
  };

export const UpdateMessageStatusEventByConversationId =
  ({ id, group }: statusMessage): Thunk =>
  async (dispatch) => {
    if (!id) return;
    try {
      const response = await axiosInstance.put(
        `/chat/UpdateMessageStatusByConversationId/${id}/${group}`
      );
      if (response.data.status == '200') {
        dispatch(setAllMessagesReadByConverId(id));
      }
    } catch (error) {
      console.log('ERROR TO UPDATE MESSAGE STATUS: ', error);
    }
  };

export const getMoreMessages =
  ({ idFragment }): Thunk =>
  async (dispatch): Promise<AxiosResponse | AxiosError> => {
    console.log(
      '%c [ GET MORE MESSAGES] INIT 🕐 Waiting for response... ',
      'color:#3a3a3; background-color: blue; font-size:16px; padding:5px;'
    );
    dispatch(setFlagMessage(true));
    try {
      const response = await axiosInstance.get(`/message/getMoreMessages/${idFragment}`);

      dispatch(setFragments(response.data.fragment));
      let idConver = Object.keys(response.data.section)[0];
      dispatch(
        addNewFragmentoToSection({
          newSection: response.data.section[idConver],
          idConver
        })
      );
      return response.data as AxiosResponse;
    } catch (error) {
      dispatch(setFlagMessage(false));

      console.error(
        '%c [ GET MORE MESSAGES] ❌ ERROR',
        'color:#3a3a3; background-color: red; font-size:16px; padding:5px;',
        error
      );

      throw error as AxiosError;
    }
  };

export const UploadFile =
  ({
    file,
    uri,
    originalName,
    platform,
    mimeType,
    conversationId,
    temp
  }: UploadFileInterface): Thunk =>
  async (dispatch) => {
    dispatch(setUploadFile(true));
    try {
      // set header multipart/form-data
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      // new form data to append file
      const formData: any = new FormData();

      if (Platform.OS === 'web') {
        formData.append('file', file);
      } else {
        formData.append('file', {
          uri,
          name: originalName,
          type: mimeType
        });
      }
      formData.append('conversationId', conversationId);
      formData.append('platform', platform);
      formData.append('originalName', originalName);

      console.log('formData', formData);

      const response = await axiosInstance.post('/chat/upload', formData, config);
      console.log('response to upload file', response);

      dispatch(setUploadFile(false));
      return response.data as AxiosResponse;
    } catch (error) {
      console.error('Error uploading file: ', error);
      dispatch(setUploadFile(false));
      throw error?.response?.data as AxiosError;
    } finally {
      if (temp) await FileSystem.deleteAsync(uri);
    }
  };

export const getFile =
  ({ id, platform }): Thunk =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/chat/getFile/${id}/${platform}`);
      if (response.data.status === 'success') {
        console.log('response GET FILE success ✅');
        const currentDate = new Date();
        const expiredAt = new Date(currentDate.setDate(currentDate.getDate() + 6)).toDateString();
        dispatch(setImagesToState({ id, url: response.data.message, expiredAt }));
      }
      return response.data as AxiosResponse;
    } catch (error) {
      console.log('error GET FILE ❌', error);
      return '404';
    }
  };

export const uploadFileAndSendMessage =
  ({ fileData }) =>
  async (dispatch) => {
    try {
      return new Promise((resolve, reject) => {
        dispatch(UploadFile(fileData))
          .then((response) => {
            console.log({ response });
            resolve(response?.data);
          })
          .catch((error) => {
            console.log({ error });
            reject(error);
          });
      });
    } catch (error) {
      console.log('error GET FILE ❌', error);
      throw error as AxiosError;
    }
  };
