import React, { useCallback, useMemo, useState } from 'react';
import { View, Button, Image, StyleSheet } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import MainCta from '@/atoms/MainCta';
import { AlertCircle, IconAdd, IconClose, RotateImage } from '@/assets/icons/Icons';
import { HStack, useMedia } from '@gluestack-ui/themed';
import { Box } from '@gluestack-ui/themed';
import ImageActivity from '@/atoms/ImageActivity';
import { RootState, useAppSelector } from '@/redux/store';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { base64ToBlob } from '@/utils/functions';

const ImageEditor = ({ previewFiles, setPreviewFiles, image }) => {
  const uploadFile = useAppSelector((state: RootState) => state.messages.uploadFile);

  const [rotation, setRotation] = useState(0);

  const manipulateImage = useCallback(
    async (rotate: number) => {
      setRotation(rotate);
      if (image?.uri) {
        const manipResult = await ImageManipulator.manipulateAsync(
          image?.uri,
          [{ rotate: rotate }],
          {
            compress: 0.5,
            format: ImageManipulator.SaveFormat.JPEG
          }
        );
        const file = await base64ToBlob(manipResult?.uri);
        const newImage = previewFiles.map((item: any) => {
          if (item === image) {
            return {
              file,
              base64: manipResult.base64,
              height: manipResult.height,
              uri: manipResult.uri,
              width: manipResult.width
            };
          }

          return item;
        });
        setPreviewFiles(newImage);
      }
    },
    [rotation, image, previewFiles, setPreviewFiles]
  );

  const outImageOfArray = (preview: any) => {
    const newPreview = previewFiles.filter((item: any) => item !== preview);
    setPreviewFiles(newPreview);
  };

  return (
    <View style={styles.container}>
      <Box style={styles.imageContainer}>
        <HStack
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
            backgroundColor: 'rgba(0,0,0,0.7)',
            width: '100%',
            justifyContent: 'flex-end'
          }}
        >
          <MainCta
            icon={RotateImage}
            onPress={() => manipulateImage(rotation + 90)}
            variant="outline"
          />
          <MainCta
            onPress={() => outImageOfArray(image)}
            icon={IconClose}
            variant="outline"
            iconColor="$pxsol"
          />
        </HStack>
        {uploadFile ? (
          <ImageActivity imageUrl={image?.uri} downloading={uploadFile} />
        ) : (
          // <ImageActivity imageUrl={image?.uri} downloading={uploadFile} />
          image?.uri && (
            <View style={styles.containerImage}>
              <Image
                source={{ uri: image?.uri }}
                style={[styles.image, { transform: [{ rotate: `${rotation}deg` }] }]}
              />
              {image?.error && (
                <MaterialCommunity style={styles.iconError} icon={AlertCircle} size="md" />
              )}
            </View>
          )
        )}
      </Box>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: 300,
    height: 300,
    borderRadius: 10
  },
  image: {
    width: 300,
    height: 300,
    borderRadius: 10
  },
  add: {
    width: 300,
    height: 300,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  containerImage: {
    position: 'relative',

    borderRadius: 10
  },
  iconError: {
    position: 'absolute',
    bottom: 0,
    right: 0
  }
});

export default ImageEditor;
