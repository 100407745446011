import React from 'react';

import { Box, Progress, ProgressFilledTrack, Spinner } from '@gluestack-ui/themed';
import { RootState, useAppSelector } from '@/redux/store';

function TopFlatlistCard({
  loadMore,
  progress
}: {
  loadMore: boolean;
  progress: number;
}): React.ReactElement {
  const loading = useAppSelector((state: RootState) => state.conversations.loading);

  return (
    <Box
      style={{
        width: '100%'
      }}
    >
      {loading && (
        <Spinner
          size="small"
          style={{
            marginVertical: 5
          }}
          animating
          color="$pxsol"
          // color={colors.primary}
        />
      )}
      {loadMore && (
        <Progress value={progress} w={300} size="sm">
          <ProgressFilledTrack />
        </Progress>
      )}
    </Box>
  );
}

export default React.memo(TopFlatlistCard);

/**
 *
 * @param {boolean} loading
 * @param {boolean} loadMore
 * @param {number} progress
 * @returns {React.ReactElement}
 */
