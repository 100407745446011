import React, { Suspense, useMemo } from 'react';

import { RootState, useAppSelector } from '@/redux/store';

const LayoutConversations = React.lazy(() => import('./LayoutConversations'));
const LayoutListConversation = React.lazy(() => import('./LayoutListConversation'));
import LoadingComponent from '@/components/loading/LoadingComponent';
import { THView } from '@/components/themed';
import { Spinner } from '@gluestack-ui/themed';
import { StyleSheet } from 'react-native';

const LayoutConversationStack = (routeStack) => {
  const loadingChangeTeam = useAppSelector((state: RootState) => state.user.loadingChangeTeam);

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        height: '100%',
        width: '100%'
      }
    });
  }, []);

  return loadingChangeTeam ? (
    <LoadingComponent idString="changingTeam" />
  ) : (
    <Suspense fallback={<Spinner color="pxsol" />}>
      <THView style={styles.container}>
        <LayoutListConversation {...routeStack} />
        <LayoutConversations {...routeStack} />
      </THView>
    </Suspense>
  );
};

export default React.memo(LayoutConversationStack, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
