import React from 'react';
import { Linking } from 'react-native';
import i18n from '@/providers/Localization';
import {
  Badge,
  BadgeText,
  Menu,
  MenuItem,
  BadgeIcon,
  Icon,
  VStack,
  HStack,
  Pressable,
  Text
} from '@gluestack-ui/themed';
import { Calendar, IconArrowRight, RoomService } from '@/assets/icons/Icons';

import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { dateYearFormat, getActionBadgeByStatus } from '@/utils/functions';
import { PropsChipBooking } from './interfaces';
import BadgeItemBooking from '@/components/partials/searchBooking/BadgeItemBooking';

function ChipBooking({ booking, icon }: PropsChipBooking) {
  return (
    <Menu
      onSelectionChange={(item: any) => {
        Linking.openURL(`http://pms.pxsol.com/bookings/cid.html?CID=${item?.currentKey}`);
      }}
      closeOnSelect={true}
      selectionMode="single"
      trigger={({ ...triggerProps }) => {
        return icon ? (
          <Pressable
            {...triggerProps}
            borderRadius="$full"
            style={{
              width: 22,
              height: 22,
              borderRadius: 100,
              justifyContent: 'center',
              alignItems: 'center'
            }}
            sx={{
              ':hover': {
                backgroundColor: '$backgroundSecondary'
              }
            }}
          >
            <MaterialCommunity icon={RoomService} size="sm" />
          </Pressable>
        ) : (
          <Pressable {...triggerProps}>
            <Badge
              size="md"
              variant="solid"
              borderRadius="$none"
              action={getActionBadgeByStatus(booking?.[0]?.instancia)}
              sx={{
                pointerEvents: 'none'
              }}
            >
              <BadgeIcon as={RoomService} mr="$2" />
              <BadgeText size="2xs">
                {booking.length === 1 ? i18n.t('booking') : i18n.t('bookings')}
              </BadgeText>
            </Badge>
          </Pressable>
        );
      }}
    >
      {booking?.map((item) => {
        return (
          <MenuItem key={item?.IDInbox} textValue={item?.instancia}>
            <VStack space="sm">
              <HStack alignItems="center" justifyContent="space-between" space="sm">
                <Text>{`${item?.nombre} ${item?.apellido}`}</Text>

                <BadgeItemBooking instancia={item?.instancia} />
              </HStack>
              <HStack space="sm" alignItems="center">
                <Icon as={Calendar} size="sm" />
                <Text>{dateYearFormat(item?.fechaIngreso)}</Text>
                <MaterialCommunity icon={IconArrowRight} size="sm" />
                <Text>{dateYearFormat(item?.fechaEgreso)}</Text>
              </HStack>
            </VStack>
          </MenuItem>
        );
      })}
    </Menu>
  );
}

export default React.memo(ChipBooking);
