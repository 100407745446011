import React from 'react';
import { Alert, AlertIcon, AlertText, HStack, VStack } from '@gluestack-ui/themed';

import translate from '@/helpers/traslations';
import { InfoOutline } from '@/assets/icons/Icons';

type Action = 'info' | 'success' | 'warning' | 'error' | 'muted';
type Variant = 'solid' | 'outline' | 'accent';

function AlertComponent({
  action = 'info',
  variant = 'accent',
  idString,
  idStringTitle,
  translateText = true
}: {
  action?: Action;
  variant?: Variant;
  idString: string;
  idStringTitle?: string;
  translateText?: boolean;
}) {
  return (
    <Alert action={action} variant={variant}>
      <HStack alignItems="center" justifyContent="flex-start" width="100%">
        <AlertIcon as={InfoOutline} mr="$3" />
        <VStack space="sm" width="90%">
          {idStringTitle && (
            <AlertText fontWeight="bold" fontSize="$md" w="100%">
              {translateText ? translate(idStringTitle) : idStringTitle}
            </AlertText>
          )}
          <AlertText>{translateText ? translate(idString) : idString}</AlertText>
        </VStack>
      </HStack>
    </Alert>
  );
}

export default React.memo(AlertComponent);
