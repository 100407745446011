import React from 'react';
import { StyleSheet } from 'react-native';
import MemoizedAvatarCard from '@/atoms/AvatarCard';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { Group } from '@/assets/icons/Icons';
import { PropsAvatarConversationCard } from './interfaces';
import { TView } from '@/components/themed';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { DESKTOP_SCREEN_LARGE } from '@/utils/globales';

const AvatarConversationCard = ({
  status,
  avatar,
  name,
  platform,
  contacto,
  picture
}: PropsAvatarConversationCard) => {
  const [isPc] = useMediaQuery([
    {
      minWidth: DESKTOP_SCREEN_LARGE
    }
  ]);

  return status ? (
    avatar !== '' ? (
      <MemoizedAvatarCard textSize="sm" picture={avatar} width="$10" name={name} />
    ) : (
      <TView bgColor="background" style={styles.IconGroup}>
        <MaterialCommunity icon={Group} size="md" color="$text" />
      </TView>
    )
  ) : (
    <MemoizedAvatarCard
      textSize="sm"
      iconIdString={platform !== 'internal' && platform}
      picture={picture}
      width={isPc ? '$10' : '$8'}
      name={contacto}
    />
  );
};

const styles = StyleSheet.create({
  IconGroup: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100
  }
});

export default React.memo(AvatarConversationCard);
