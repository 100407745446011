import React from 'react';
import { PressableProps, StyleProp, ViewStyle } from 'react-native';
import { colorsProps } from '@/hooks/useTheme';
import { MouseEvent } from 'react-native';
import { Pressable } from '@gluestack-ui/themed';

export type ThemedPressableProps = PressableProps & {
  lightColor?: string;
  darkColor?: string;
  bgColor?: colorsProps;
  border?: colorsProps;
  hover?: colorsProps;
  noOpacity?: boolean;
};

const TPressable = React.memo(
  ({
    style,
    lightColor,
    darkColor,
    bgColor = 'transparent',
    border,
    onHoverIn,
    onHoverOut,
    hover = 'backgroundActive',
    noOpacity,
    ...otherProps
  }: ThemedPressableProps) => {
    const handleMouseEnter = (event: MouseEvent) => {
      if (onHoverIn) {
        onHoverIn(event); // Ejecuta la función pasada por prop al hacer hover
      }
    };

    const handleMouseLeave = (event: MouseEvent) => {
      if (onHoverOut) {
        onHoverOut(event); // Ejecuta la función pasada por prop al salir del hover
      }
    };

    return (
      <Pressable
        bg={`$${bgColor}`}
        onHoverIn={handleMouseEnter}
        android_ripple={{ color: 'rgba(0,0,0,0.2)', foreground: true }}
        onHoverOut={handleMouseLeave}
        style={style as StyleProp<ViewStyle>}
        sx={{
          _web: {
            ':hover': {
              bgColor: `$${hover}`
            },
            ':active': {
              bgColor: `$${hover}`
            }
          }
        }}
        {...otherProps}
      />
    );
  }
);

export default TPressable;
