import React from 'react';

import { TextDisabledUpperProps } from './interfaces';
import { TText } from '@/components/themed';

function TextDisabledUpper({ text, my = 0, sx }: TextDisabledUpperProps) {
  return (
    <TText
      style={[
        sx,
        {
          textTransform: 'uppercase',
          fontSize: 12,
          marginVertical: my
        }
      ]}
      numberOfLines={1}
      color="secondary"
    >
      {text}
    </TText>
  );
}

export default React.memo(TextDisabledUpper);
