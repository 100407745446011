import { Linking, StyleSheet, useWindowDimensions } from 'react-native';
import React, { useMemo } from 'react';
import { MOBILE_SCREEN } from '@/utils/globales';
import { URL_PMS_NEW_CONTACT } from '@/utils/constants';
import { View } from '@gluestack-ui/themed';
import { Guides, PersonAdd } from '@/assets/icons/Icons';
import ContextMenu from './ContextMenu';
import MainCta from '../../atoms/MainCta';

function ContainerIconsHeader() {
  const { width } = useWindowDimensions();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        containerItems: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 5,
          justifyContent: 'flex-end'
        }
      }),
    []
  );

  const handleNewChat = async () => {
    const canOpen = await Linking.canOpenURL(URL_PMS_NEW_CONTACT);

    if (canOpen) {
      Linking.openURL(URL_PMS_NEW_CONTACT);
    } else {
      console.log('No se puede abrir la URL');
    }
  };

  return (
    <View style={styles.containerItems}>
      {width < MOBILE_SCREEN && (
        <MainCta
          borderRadius="$full"
          iconSize="md"
          onPress={handleNewChat}
          icon={PersonAdd}
          buttonSize="md"
          variant="outline"
        />
      )}
      <MainCta
        onPress={() => Linking.openURL('https://ayuda.pxsol.com/es/conversaciones')}
        icon={Guides}
        borderRadius="$full"
        action="secondary"
        variant="outline"
        buttonSize="md"
      />

      <ContextMenu />
    </View>
  );
}

export default React.memo(ContainerIconsHeader);
