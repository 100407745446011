import React from 'react';
import i18n from '@/providers/Localization';
import { TText, TVView } from '@/components/themed';

function DrawerSection({ children, idString }) {
  return (
    <TVView
      border="border"
      style={{
        width: '100%',
        marginHorizontal: 'auto',
        borderBottomWidth: 1,
        marginBottom: 10,
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }}
    >
      <TText
        style={{
          marginBottom: 10
        }}
        size="sm"
      >
        {i18n.t(idString)}
      </TText>
      {children}
    </TVView>
  );
}

export default React.memo(DrawerSection);
