import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FragmentsState } from './interfaces';
import { setFragments } from '@/redux/actionTypes';

const initialState: FragmentsState = {
  fragments: {}
};

const fragmentsSlice = createSlice({
  name: 'fragments',
  initialState,
  reducers: {
    setNewFragment: (state, action: PayloadAction<any>) => {
      const id: string[] = Object.keys(action.payload);
      state.fragments = {
        ...state.fragments,
        [id[0]]: Object.values(action.payload)[0]
      };
    },
    clearFragments: (state) => {
      state.fragments = {};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setFragments, (state, action) => {
      console.log('setFragments', action.payload);
      // ver si el fragmento ya existe
      // si existe actualizarlo
      // si no existe crearlo
      if (action.payload === undefined) return;
      state.fragments = {
        ...state.fragments,
        ...action.payload
      };
    });
  }
});

export const { setNewFragment, clearFragments } = fragmentsSlice.actions;

export default fragmentsSlice.reducer;
