import React, { memo } from 'react';
import { Channel } from '@/types/typesRedux/interfaces';
import { TText, THView, TVView, TView } from '../../themed';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import getIconsToFilters from '@/assets/icons';
import MainCta from '@/atoms/MainCta';
import { useAppDispatch } from '@/redux/store';
import { setDialogChannels } from '@/redux/slices/theme';
import {
  getDescriptionChannelItem,
  getLabelCtaChannelItem,
  getTitleChannelItem
} from '@/utils/channels/functions';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { MOBILE_SCREEN } from '@/utils/globales';
import { Linking } from 'react-native';
import { URL_TO_CREATE_WIDGET } from '@/utils/constants';
import { platforms } from '@/redux/slices/filters/interfaces';
import BadgeComponent from '@/atoms/BadgeComponent';

const ItemSettingChannel = ({ channel }: { channel: Channel }) => {
  const dispatch = useAppDispatch();

  const hanldeOpenChannel = (channel: Channel) => {
    if (channel?.name === 'widget' && !channel?.available) {
      Linking.openURL(URL_TO_CREATE_WIDGET);
    } else {
      dispatch(
        setDialogChannels({
          status: true,
          data: { name: channel.name, available: channel?.available, soon: !channel?.soon }
        })
      );
    }
  };

  const [isMobile] = useMediaQuery([
    {
      maxWidth: MOBILE_SCREEN
    }
  ]);

  return (
    <TView
      bgColor="backgroundSecondaryNavigationPxsol"
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        flexWrap: 'wrap',
        gap: isMobile ? 16 : 0,
        padding: 16,
        width: '100%',
        justifyContent: 'space-between',
        borderRadius: 8,
        marginBottom: 16,
        overflow: 'hidden'
      }}
    >
      <THView
        bgColor="backgroundSecondaryNavigationPxsol"
        style={{
          gap: 8,
          flex: 1
        }}
      >
        <MaterialCommunity icon={getIconsToFilters({ idString: channel.name })} size="xl" />
        <TVView
          bgColor="backgroundSecondaryNavigationPxsol"
          style={{
            flex: 1
          }}
        >
          <THView
            style={{
              alignItems: 'center',
              gap: 8
            }}
          >
            <TText size="lg">{getTitleChannelItem(channel.name)}</TText>
            {channel.name === platforms?.widget && (
              <BadgeComponent item="FREE" badgeBackground="$success" />
            )}
          </THView>
          <TText>{getDescriptionChannelItem(channel.name)}</TText>
        </TVView>
      </THView>

      <MainCta
        variant={channel.name === platforms?.widget ? 'solid' : 'outline'}
        action="positive"
        trl={false}
        labelId={`${getLabelCtaChannelItem(channel.name)} ${channel.name}`}
        sx={{
          borderWidth: 1,
          borderColor: '$secondary'
        }}
        onPress={() => hanldeOpenChannel(channel)}
      />
    </TView>
  );
};

export default memo(ItemSettingChannel);
