import React, { useEffect } from 'react';
import { Image } from 'expo-image';
import { Progress, ProgressFilledTrack } from '@gluestack-ui/themed';
import i18n from '../../providers/Localization';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import ItemLogout from '@/atoms/ItemLogout';
import { setProgress } from '@/redux/actionTypes';
import { THView, TText, TView, TVView } from '../themed';

interface Props {
  message: string;
}

const themeImages = {
  light: require('../../assets/gifs/light.gif'),
  dark: require('../../assets/gifs/dark.gif')
};

function WaitingComponent({ message }: Props) {
  const dispatch = useAppDispatch();
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);
  const msgError = useAppSelector((state: RootState) => state.user.msgError);
  const converError = useAppSelector((state: RootState) => state.conversations.error);
  const progress = useAppSelector((state: RootState) => state.user.progress);
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
  const [currentMessageIndex, setCurrentMessageIndex] = React.useState(0);
  const source = themeImages[themeMode];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        return nextIndex < errorMessages.length ? nextIndex : 0;
      });
    }, 2000);
    return () => clearInterval(interval);
  }, [errorMessages]);

  useEffect(() => {
    const messages = [];
    if (msgError) {
      messages.push(msgError);
    }
    if (converError?.msg) {
      messages.push(converError.msg);
    }

    messages.push('Esta demorando mas de lo esperado');
    messages.push('Intenta nuevamente');
    setErrorMessages(messages);

    return () => {
      dispatch(setProgress(0));
    };
  }, [msgError, converError]);

  return (
    <TView
      bgColor="backgroundSecondary"
      style={{
        height: '100%',
        gap: 8,
        flex: 1,
        width: '100%',
        justifyContent: 'space-evenly',
        alignItems: 'center'
      }}
    >
      <TVView
        style={{
          alignItems: 'center'
        }}
      >
        <Image
          alt={i18n.t('waiting')}
          source={source}
          style={{
            width: 400,
            height: 400
          }}
        />

        <TText
          size="md"
          color="secondary"
          style={{
            marginTop: 20
          }}
        >
          {`${i18n.t(message)} ${progress <= 100 ? progress : 100}%`}
        </TText>
        <Progress value={progress} size="md">
          <ProgressFilledTrack bg="$pxsol" />
        </Progress>
      </TVView>

      {(converError?.status || msgError) && (
        <THView
          style={{
            gap: 8,
            width: '50%',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}
        >
          <TText color="secondary">{errorMessages[currentMessageIndex]}</TText>
          <TView>
            <ItemLogout />
          </TView>
        </THView>
      )}
    </TView>
  );
}

export default React.memo(WaitingComponent);
