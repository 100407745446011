import React, { useEffect } from 'react';
import { enableScreens } from 'react-native-screens';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { RouteProp, useFocusEffect, useRoute } from '@react-navigation/native';
import CustomDrawerComponent, { RouteDrawer } from '@/routers/CustomDrawer/CustomDrawerComponent';
import { Spinner } from '@gluestack-ui/themed';
import LayoutConversationStack from '../layout/LayoutConversationStack';
import SidebarFilters from '../components/sidebar/sidebarFilters/SidebarFilters';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { setType } from '../redux/slices/filters';
import LayoutListConversation from '../layout/LayoutListConversation';
import { filtersBase } from '../utils/filters';

import { IS_WEB_PC, getClientsFilters, verifyIsNotNativeDevice } from '../utils/functions';

import CustomTabBar from '@/components/tabs/CustomTabBar';
import { Filters } from '@/redux/slices/filters/interfaces';
import { useCollapsed } from '@/providers/CollpasedProvider';
import { TABLET_SCREEN, WIDTH_SIDEBAR_COLLAPSED } from '@/utils/globales';
import { StackScreenProps } from '@react-navigation/stack';
import { RootChatStackParams, RootStackParams } from '@/routers/interfaces';
import { ConversationProvider } from '@/providers/ConversationProvider';
import { useMediaQuery } from '@/hooks/useMediaQuery';

const Tab = createBottomTabNavigator();

enableScreens();

function ChatStack(routeChat: StackScreenProps<RootChatStackParams>) {
  const routes = useRoute<RouteProp<RootStackParams>>();
  const filters = useAppSelector((state: RootState) => state.filters.filters);
  const [filterByName, setFiltersByName] = React.useState<Filters[]>(filtersBase);

  const dispatch = useAppDispatch();
  const { collapsed } = useCollapsed();

  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  useEffect(() => {
    if (routeChat?.route?.name) {
      const filterToGetClient = IS_WEB_PC
        ? filters
        : {
            assignee: filters?.assignee,
            labels: filters?.labels
          };
      const result = getClientsFilters(filterToGetClient, routeChat?.route?.name);
      setFiltersByName(result);
    }
  }, [filters, routeChat?.route?.name]);

  useFocusEffect(
    React.useCallback(() => {
      // Código a ejecutar cuando la pestaña de chat gana el foco
      dispatch(setType(routeChat?.route?.name.toString().toLowerCase()));
    }, [])
  );

  const filtros = filtersBase.filter((filter) => filter.types.includes(routes?.name));
  const routesFilter: RouteDrawer[] = filtros?.map((filter, index) => ({
    id: index?.toString(),
    name: filter.idString,
    component: LayoutConversationStack,
    initialParams: {
      filter: filter.idString,
      team: routes?.params?.team
    },
    options: {
      headerShown: false
    },
    usersPlans: filter.contracts
    // headerComponent: () => <SidebarFilters type={routeClient?.route?.name} filtros={filters} />
  }));

  return routesFilter?.length > 0 ? (
    isPc ? (
      <ConversationProvider>
        <CustomDrawerComponent
          DrawerSidebar={SidebarFilters}
          routes={routesFilter}
          drawePermanent={!!verifyIsNotNativeDevice()}
          maxWidthSidebar={
            verifyIsNotNativeDevice() ? (collapsed ? WIDTH_SIDEBAR_COLLAPSED : 200) : '100%'
          }
          borderTopLeft={10}
        />
      </ConversationProvider>
    ) : filtersBase?.length > 0 ? (
      <Tab.Navigator
        tabBar={(props) => (
          <CustomTabBar
            {...props}
            filterByName={filterByName}
            routeName={routeChat?.route?.name}
            team={routeChat?.route?.params?.team}
          />
        )}
        screenOptions={{
          headerShown: false
          // tabBarActiveBackgroundColor: '$backgroundSecondary'
        }}
      >
        {filtersBase?.map((filter) => {
          if (filter?.types?.includes(routeChat?.route?.name) && filter?.idString !== 'searchs') {
            return (
              <Tab.Screen
                key={filter?.id}
                name={filter?.name?.toLowerCase()}
                component={LayoutListConversation}
                initialParams={{ filter: filter?.idString }}
                options={{
                  tabBarIcon: () => null,
                  tabBarLabel: filter?.name,
                  tabBarBadge: 10,
                  tabBarBadgeStyle: {
                    position: 'absolute',
                    top: -5,
                    right: -20
                  },
                  tabBarStyle: {
                    flex: 1
                  }
                }}
              />
            );
          }
          return null;

          // Puedes devolver null si no quieres que se muestre nada para los filtros que no incluyen 'chat'.
        })}
      </Tab.Navigator>
    ) : (
      <Spinner size="small" color="$pxsol" />
    )
  ) : (
    <Spinner size="small" color="$pxsol" />
  );
}

export default React.memo(ChatStack);
