import React from 'react';

import { Check, IconBot } from '@/assets/icons/Icons';
import ContactCard from './ContactCard';
import MaterialCommunity from '../components/Icons/MaterialCommunity';
import { THView, TPressable, TText } from '@/components/themed';
import { RootState, useAppSelector } from '@/redux/store';

function RenderContactsCard({ callbackOnPress, user, id }) {
  const loadingContact = useAppSelector((state: RootState) => state.contacts.loadingContact);

  const isSelected = id?.includes(user?.id_user);
  return (
    <TPressable
      onPress={(event) => callbackOnPress(user, event)}
      disabled={loadingContact}
      style={{
        width: '100%',
        borderRadius: 5,
        marginVertical: 2
      }}
      bgColor={isSelected ? 'secondary' : 'transparent'}
      key={user._id}
    >
      <ContactCard
        name={user?.name}
        avatar={user?.avatar}
        email={user?.email}
        selected={isSelected}
        middle={
          user?.isBot && (
            <THView style={{ alignItems: 'center', gap: 2 }}>
              <MaterialCommunity icon={IconBot} size="md" color="$secondary" />
              <TText color="secondary">(IA)</TText>
            </THView>
          )
        }
      />
    </TPressable>
  );
}

export default React.memo(RenderContactsCard);
