import React, { memo, useMemo } from 'react';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { RootState, useAppSelector } from '@/redux/store';
import { VolumeOff } from '@/assets/icons/Icons';
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import translate from '@/helpers/traslations';

const ItemSoundConversation = ({ conversationId }) => {
  const conversationNotification = useAppSelector(
    (state: RootState) => state.user.user?.notifications_config?.conversation_notification
  );

  const isMuted = useMemo(() => {
    return conversationNotification?.includes(conversationId);
  }, [conversationNotification, conversationId]);

  return (
    isMuted && (
      <CustomTooltip title={translate('notification.disabledNotificationsConversation')}>
        <MaterialCommunity icon={VolumeOff} size={'sm'} />
      </CustomTooltip>
    )
  );
};

export default memo(ItemSoundConversation);
