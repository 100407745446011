import { useWindowDimensions } from 'react-native';
import React, { memo, useMemo } from 'react';
import { IS_WEB_PC } from '@/utils/functions';
import { TABLET_SCREEN } from '@/utils/globales';
import { Badge, BadgeIcon, BadgeText } from '@gluestack-ui/themed';
import getIconsToFilters from '../assets/icons';
import { THView } from '@/components/themed';
import { StyleSheet } from 'react-native';

const RenderBadges = ({
  badges,
  data,
  findKey,
  numbers = 2,
  showKey,
  secondShowKey = null,
  iconKey = null,
  size = '2xs'
}: {
  badges: string[];
  data: any[];
  findKey: string;
  numbers?: number;
  showKey: string;
  secondShowKey?: string;
  iconKey?: string;
  size?: 'xs' | '2xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';
}) => {
  const { width } = useWindowDimensions();

  const mobile = !IS_WEB_PC || width <= TABLET_SCREEN;

  const totalbadgesInConver = useMemo(() => badges?.length, [badges]);
  const renderbadges = useMemo(() => badges?.slice(0, mobile ? 1 : numbers), [badges, numbers]);

  const restbadges = useMemo(
    () => totalbadgesInConver - renderbadges?.length,
    [totalbadgesInConver, renderbadges]
  );

  const getBadge = (data, label, findKey) => {
    return data?.find((l) => l[findKey] == label);
  };

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          alignItems: 'center',
          gap: 2,
          flexWrap: 'wrap',
          justifyContent: 'flex-start'
        }
      }),
    []
  );

  return (
    badges?.length > 0 && (
      <THView style={styles.container}>
        {renderbadges?.map((label) => {
          const myBadge = getBadge(data, label, findKey);
          return (
            myBadge && (
              <Badge key={label} variant="solid" action="muted" gap="$1">
                {iconKey && <BadgeIcon as={getIconsToFilters({ idString: myBadge[iconKey] })} />}
                <BadgeText size={size} textTransform="capitalize" color="$text" numberOfLines={1}>
                  {myBadge[showKey]?.length ? myBadge[showKey] : myBadge[secondShowKey]}
                </BadgeText>
              </Badge>
            )
          );
        })}
        {restbadges > 0 && (
          <Badge key="more" variant="solid" action="muted">
            <BadgeText color="$text">+{restbadges}</BadgeText>
          </Badge>
        )}
      </THView>
    )
  );
};

export default memo(RenderBadges, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
