import { Calendar } from '@/assets/icons/Icons';
import MainCta from '@/atoms/MainCta';
import { getFile } from '@/redux/slices/messages/requests';
import { useAppDispatch } from '@/redux/store';
import { PlatformsEnum } from '@/types/enums/conversation';
import { verifyIsMobile } from '@/utils/functions';
import { TABLET_SCREEN } from '@/utils/globales';
import { Spinner, Text } from '@gluestack-ui/themed';
import { View } from '@gluestack-ui/themed';
import { Image } from 'expo-image';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { Linking, StyleSheet } from 'react-native';
import { useWindowDimensions } from 'react-native';

const RenderItemSlider = ({ item, height }) => {
  const dispatch = useAppDispatch();
  const windowDimensions = useWindowDimensions();
  const [url, setUrl] = useState('');
  const [gettingImage, setGettingImage] = useState(false);
  const isSmallDevice = useMemo(
    () => windowDimensions.height <= TABLET_SCREEN,
    [windowDimensions.height]
  );

  function onPressCta(item) {
    Linking.openURL(item?.ctaUrl);
    if (Platform.OS === 'web') {
      if (typeof window.gtag === 'function' && item.ctaEventId) {
        window.gtag('event', item.ctaEventId, {
          event_category: item.ctaEventId,
          event_label: item.ctaEventLabel ?? 'noLabel'
        });
      }
    }
  }

  const styles = StyleSheet.create({
    slide: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'red',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 8,
      // height: 900,
      maxHeight: verifyIsMobile() ? '80%' : '90%',
      lineHeight: 35
    },
    image: {
      width: isSmallDevice ? 400 : windowDimensions.width * 0.7,
      height: isSmallDevice ? 400 : windowDimensions.height * 0.7,
      maxHeight: windowDimensions.height * 0.7,
      maxWidth: '100%',
      marginBottom: 10,
      resizeMode: 'contain'
    },
    text: {
      color: '$text',
      textAlign: 'center',
      lineHeight: 25,
      maxWidth: 600,
      fontSize: isSmallDevice ? 14 : 16
    },
    secondaryText: {
      color: '#ffeb3b',
      textAlign: 'center',
      lineHeight: 25,
      maxWidth: 600,
      fontSize: isSmallDevice ? 14 : 16,
      marginTop: 20
    },
    title: {
      fontSize: isSmallDevice ? 20 : 22,
      paddingBottom: 10,
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      lineHeight: 30
    }
  });

  async function getFilesById({ id }) {
    setGettingImage(true);
    const response: any = await dispatch(getFile({ id: id, platform: PlatformsEnum.whatsapp }));
    if (response == '404') {
      setGettingImage(false);
      setUrl('');
      return;
    }
    if (response?.status === 'success') {
      setGettingImage(false);
      setUrl(response.message);
    } else {
      setGettingImage(false);
      setUrl('');
    }
  }

  useEffect(() => {
    if (item.getFile) {
      getFilesById({ id: item?.image });
    }
    return () => {
      setUrl('');
    };
  }, [item]);

  return (
    <View
      height={height}
      style={[
        styles.slide,
        {
          backgroundColor: item?.bg
        }
      ]}
    >
      {item.getFile ? (
        gettingImage ? (
          <Spinner color="$pxsol" />
        ) : (
          <Image alt={item?.title} source={url} style={styles.image} />
        )
      ) : (
        <Image alt={item?.title} source={item?.image} style={styles.image} />
      )}
      <Text style={styles.title}>
        {item?.title}
        {item?.version && (
          <Text style={{ color: '#03a9f4', marginLeft: 5 }} size="sm">
            {item?.version}
          </Text>
        )}
      </Text>
      <Text style={styles.text}>{item?.description}</Text>
      {item?.secondaryText && <Text style={styles.secondaryText}>{item?.secondaryText}</Text>}
      {item?.ctaUrl && (
        <View style={{ marginTop: 20 }}>
          <MainCta
            iconPosition="left"
            icon={Calendar}
            labelId={item?.ctaTextId}
            onPress={() => onPressCta(item)}
          />
        </View>
      )}
    </View>
  );
};

export default memo(RenderItemSlider);
