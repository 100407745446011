import React from 'react';
import TextFormatter from './TextFormatter';

import MaterialCommunity from '../components/Icons/MaterialCommunity';
import { colorsProps } from '@/hooks/useTheme';
import { THView } from '@/components/themed';

interface props {
  message: string;
  color: colorsProps;
  icon: any | null;
  italic?: boolean;
}

function LastMessageIcon({ message, color, icon }: props) {
  return (
    <THView
      style={{
        justifyContent: 'flex-start',
        gap: 4,
        width: '100%'
      }}
    >
      {icon && <MaterialCommunity icon={icon} color={color} size="xs" />}
      <TextFormatter numberOfLines={1} color={color}>
        {message}
      </TextFormatter>
    </THView>
  );
}

export default React.memo(LastMessageIcon);
