import React from 'react';
import { createIcon } from '@gluestack-ui/themed';
import { Circle, LinearGradient, Path, RadialGradient, Rect, Stop } from 'react-native-svg';

export const Facebook = createIcon({
  viewBox: '0 0 42 42',
  path: (
    <>
      <Path
        d="M38.2758 1.58398H3.7275C2.54414 1.58398 1.58484 2.54328 1.58484 3.72664V38.2749C1.58484 39.4583 2.54414 40.4176 3.7275 40.4176H38.2758C39.4591 40.4176 40.4184 39.4583 40.4184 38.2749V3.72664C40.4184 2.54328 39.4591 1.58398 38.2758 1.58398Z"
        fill="#3D5A98"
      />
      <Path
        d="M28.3763 40.4145V25.3766H33.4229L34.1776 19.5162H28.3763V15.7756C28.3763 14.0792 28.8488 12.9209 31.2802 12.9209H34.3843V7.67093C32.8812 7.51457 31.3706 7.44008 29.8594 7.44781C25.3904 7.44781 22.3126 10.1712 22.3126 15.1948V19.5162H17.266V25.3766H22.3126V40.4145H28.3763Z"
        fill="white"
      />
    </>
  )
});

export const Instagram = createIcon({
  viewBox: '0 0 48 48',
  path: (
    <>
      <RadialGradient
        id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1"
        cx="19.38"
        cy="42.035"
        r="44.899"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset="0" stop-color="#fd5" />
        <Stop offset=".328" stop-color="#ff543f" />
        <Stop offset=".348" stop-color="#fc5245" />
        <Stop offset=".504" stop-color="#e64771" />
        <Stop offset=".643" stop-color="#d53e91" />
        <Stop offset=".761" stop-color="#cc39a4" />
        <Stop offset=".841" stop-color="#c837ab" />
      </RadialGradient>
      <Path
        fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)"
        d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"
      />

      <RadialGradient
        id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2"
        cx="11.786"
        cy="5.54"
        r="29.813"
        gradientTransform="matrix(1 0 0 .6663 0 1.849)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset="0" stop-color="#4168c9" />
        <Stop offset=".999" stop-color="#4168c9" stop-opacity="0" />
      </RadialGradient>

      <Path
        fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)"
        d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"
      />

      <Path
        fill="#fff"
        d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"
      />

      <Circle cx="31.5" cy="16.5" r="1.5" fill="#fff" />

      <Path
        fill="#fff"
        d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"
      />
    </>
  )
});

export const WhatsApp = createIcon({
  viewBox: '0 0 48 48',
  path: (
    <>
      <Path
        fill="#fff"
        d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
      />
      <Path
        fill="#fff"
        d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
      />
      <Path
        fill="#cfd8dc"
        d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
      />
      <Path
        fill="#40c351"
        d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
      />
      <Path
        fill="#fff"
        fill-rule="evenodd"
        d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
        clip-rule="evenodd"
      />
    </>
  )
});

export const Widget = createIcon({
  viewBox: '0 0 44 44',
  path: (
    <>
      <Rect x="6" y="6" width="31" height="31" rx="7" fill="#C00001" />
      <Path
        d="M17.7367 19.1751C17.7367 20.3096 17.3303 21.1731 16.5089 21.7742C15.6961 22.3753 14.536 22.6801 13.0289 22.6801H11.7926V26.7268H9.83667V15.8988H13.2744C14.7647 15.8988 15.8823 16.1782 16.6275 16.7285C17.3726 17.2788 17.7452 18.0915 17.7452 19.1751H17.7367ZM11.7842 21.19H12.8172C13.8163 21.19 14.553 21.0292 15.0187 20.716C15.4844 20.4027 15.7215 19.9032 15.7215 19.2344C15.7215 18.6164 15.5098 18.1508 15.0949 17.846C14.68 17.5412 14.0195 17.3888 13.1389 17.3888H11.7842V21.19Z"
        fill="white"
      />
      <Path
        d="M17.8213 15.8819L21.403 20.7922L15.7214 28.5809L18.2447 29.3428L22.9441 22.9002L30.2937 32.9663L34.1548 34.1261L24.4682 20.8176L31.132 11.6743L27.1862 12.868L22.9102 18.7096L20.2938 15.12L17.8213 15.8819Z"
        fill="white"
      />
    </>
  )
});
