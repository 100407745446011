import { Platform } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';

import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { GRUPOS, SIN_ASIGNAR, TODOS } from '@/utils/filters';
import { Fab, FabIcon } from '@gluestack-ui/themed';
import { Group, Pencil } from '../assets/icons/Icons';

import { RootStackParams } from '../routers/interfaces';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { setNewGroup } from '../redux/actionTypes';

import { getClientsFilters, verifyIsMobile } from '@/utils/functions';
import { filterTypes, Filters } from '@/redux/slices/filters/interfaces';

import ConversationList from '../components/conversation/conversationCard/ConversationList';
import ModalApp from '../components/modal/ModalApp';
import NewConversationExternal from '@/screens/NewConversationExternal';
import { useLinkss } from '@/providers/LinkProvider';
import NewGroup from '@/screens/NewGroup';
import NewConversation from '@/screens/NewConversation';
import HeaderLayoutList from '@/components/partials/layoutListConversation/HeaderLayoutList';
import { setModaNewChat, setPopoverChannel } from '@/redux/slices/theme';
import { TypeSections } from '@/types/enums';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { TABLET_SCREEN } from '@/utils/globales';
import { TView } from '@/components/themed';
import { StyleSheet } from 'react-native';
import { enableScreens } from 'react-native-screens';

enableScreens();

function LayoutListConversation(routeStack: any) {
  const filters = useAppSelector((state: RootState) => state.filters.filters);
  const modalNewChat = useAppSelector((state: RootState) => state.settings.modalNewChat);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<StackNavigationProp<RootStackParams>>();
  const type = useAppSelector((state: RootState) => state.filters.type);
  const [filter, setFilters] = useState<Filters>();

  const [visibleGroup, setVisibleGroup] = useState(false);
  const [visibleConver, setVisibleConver] = useState(false);
  const { showToast } = useLinkss();

  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  const teamId = useMemo(() => {
    return routeStack?.route?.params?.team;
  }, [routeStack?.route?.params?.team]);

  useEffect(() => {
    if (routeStack?.route?.params?.filter) {
      const filterKeys = getClientsFilters(filters, type);

      const current = filterKeys?.find(
        (item) => item?.idString === routeStack?.route?.params?.filter
      );
      current && setFilters(() => current);
    }
  }, [filters, type, navigation]);

  let currentFilter = '';
  if (!verifyIsMobile() && Platform.OS === 'web') {
    currentFilter = routeStack?.route?.params?.filter;
  } else {
    currentFilter = routeStack?.route?.params?.filter;
  }
  if (!currentFilter) currentFilter = type === filterTypes.chat ? TODOS : SIN_ASIGNAR;

  const handleNewGroup = () => {
    dispatch(setNewGroup(true));
    setVisibleGroup(true);
  };

  const handleNewConver = () => {
    dispatch(setNewGroup(false));
    setVisibleConver(true);
  };

  const handleNewConverExternal = (bool) => {
    dispatch(setModaNewChat(bool));
    dispatch(setPopoverChannel(false));
  };

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        borderRightWidth: 1,
        borderStyle: 'solid',
        height: '100%',
        width: isPc ? '30%' : '100%',
        minWidth: 300
      }
    });
  }, [isPc]);

  return (
    <TView bgColor="backgroundSecondary" border="border" style={styles.container}>
      {isPc && (
        <HeaderLayoutList
          type={type}
          currentFilter={currentFilter}
          handleNewGroup={handleNewGroup}
          handleNewConver={handleNewConver}
        />
      )}

      <ConversationList teamId={teamId} filtro={filter} />

      {(Platform.OS !== 'web' || !isPc) &&
        (type?.toLowerCase() === TypeSections?.chat ? (
          currentFilter?.toLowerCase() === GRUPOS ? (
            <Fab
              size="lg"
              placement="bottom right"
              isHovered={false}
              isDisabled={false}
              bg="$pxsol"
              isPressed={false}
              onPress={handleNewGroup}
            >
              <FabIcon size="md" color="$blanco" as={Group} />
            </Fab>
          ) : (
            <Fab
              size="lg"
              placement="bottom right"
              isHovered={false}
              isDisabled={false}
              bg="$pxsol"
              isPressed={false}
              onPress={handleNewConver}
            >
              <FabIcon size="md" color="$blanco" as={Pencil} />
            </Fab>
          )
        ) : (
          <Fab
            size="lg"
            placement="bottom right"
            isHovered={false}
            isDisabled={false}
            bg="$pxsol"
            isPressed={false}
            onPress={() => handleNewConverExternal(true)}
          >
            <FabIcon size="md" color="$blanco" as={Pencil} />
          </Fab>
        ))}

      <ModalApp modalVisible={modalNewChat} setModalVisible={handleNewConverExternal}>
        <NewConversationExternal showToast={showToast} route={routeStack} navigation={navigation} />
      </ModalApp>

      <ModalApp modalVisible={visibleGroup} setModalVisible={setVisibleGroup}>
        <NewGroup
          route={routeStack?.route}
          showToast={showToast}
          setModalVisible={setVisibleGroup}
        />
      </ModalApp>
      <ModalApp modalVisible={visibleConver} setModalVisible={setVisibleConver}>
        <NewConversation
          team={teamId}
          showToast={showToast}
          navigation={navigation}
          setModalVisible={setVisibleConver}
        />
      </ModalApp>
    </TView>
  );
}

export default React.memo(LayoutListConversation);
