import React, { memo, useMemo, useState } from 'react';
import MainCta from '@/atoms/MainCta';
import { IconSave } from '@/assets/icons/Icons';
import CustomSelectForm from '@/atoms/CustomSelectForm';
import LayoutForm from '@/components/partials/automatizations/LayoutForm';

import {
  ConditionsWorkflow,
  PropertyConditions,
  PropertyType,
  TypeConditions
} from '@/types/enums/workflows';
import { Condition } from '@/components/automatizations/interface.d';
import SectionFrom from '@/components/partials/automatizations/SectionFrom';
import { Switch } from '@gluestack-ui/themed';
import DateModals from '@/components/partials/automatizations/DateModals';
import TimeModals from '@/components/partials/automatizations/TimeModals';
import { setRangeDate } from '../redux/slices/automatization';
import { useAppDispatch } from '../redux/store';

import TimeComponent from '@/components/partials/automatizations/TimeComponent';
import DaysComponent from '@/components/partials/automatizations/DaysComponent';
import { THView, TPressable, TVView } from '@/components/themed';

const AutomatizationRange = (navigationRange) => {
  const dispatch = useAppDispatch();

  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [end, setEnd] = useState(true);
  const [allDays, setAllDays] = useState(false);
  const [allHours, setAllHours] = useState(false);
  const [openEndTime, setOpenEndTime] = useState(false);
  const [openInitialTime, setOpenInitialTime] = useState(false);

  const [conditonDay, setConditonDay] = useState<Condition<number[]>>({
    type: TypeConditions.objectProperty,
    property: PropertyConditions?.createAt,
    property_type: PropertyType.day_of_week,
    condition: ConditionsWorkflow.in,
    value: []
  });
  const [conditonTime, setConditonTime] = useState<Condition<string[]>>({
    type: TypeConditions.objectProperty,
    property: PropertyConditions?.createAt,
    property_type: PropertyType.time,
    condition: ConditionsWorkflow.btw,
    value: ['', '']
  });
  const [conditonDate, setConditonDate] = useState<Condition<string[]>>({
    type: TypeConditions.objectProperty,
    property: PropertyConditions?.createAt,
    property_type: PropertyType.date,
    condition: ConditionsWorkflow.btw,
    value: ['', '']
  });

  const errorDateEnd = useMemo(() => {
    if (!end) return false;
    if (conditonDate?.value[0]?.length > 0 && conditonDate?.value[1]?.length === 0) {
      return true;
    } else {
      return false;
    }
  }, [conditonDate, end]);

  const errorTime = useMemo(() => {
    if (allHours) return false;
    if (conditonTime?.value[0]?.length > 0 && conditonTime?.value[1]?.length === 0) {
      return true;
    } else {
      return false;
    }
  }, [conditonTime, allHours]);

  const errorDay = useMemo(() => {
    if (allDays) return false;
    if (conditonDay?.value.length === 0) {
      return true;
    } else {
      return false;
    }
  }, [conditonDay, allDays]);

  const buttonDisabled = useMemo(() => {
    if (!errorDay && !errorTime && !errorDateEnd && conditonDate.value[0]) {
      return false;
    } else {
      return true;
    }
  }, [conditonDay, conditonTime, conditonDate, errorDateEnd]);

  const range: {
    type: string;
    label: string;
    conditions: Condition<string[] | number[] | string>[];
  } = useMemo(
    () => ({
      type: 'AND',
      label: 'range_date',
      conditions: [
        {
          ...conditonDate,
          condition: !end ? ConditionsWorkflow.gte : ConditionsWorkflow.btw,
          value: !end
            ? `${conditonDate?.value[0]}`
            : [conditonDate?.value[0], conditonDate?.value[1]]
        }
      ]
    }),
    [conditonDate, end]
  );

  const handleAddDay = (day) => {
    const isDayExist = conditonDay.value.includes(day);
    if (isDayExist) {
      setConditonDay((prev) => ({
        ...prev,
        value: prev.value.filter((d) => d !== day)
      }));
    } else {
      setConditonDay((prev) => ({
        ...prev,
        value: [...prev.value, day]
      }));
    }
  };

  const handleSaveRange = () => {
    if (!allHours) {
      range.conditions.push(conditonTime);
    } else {
      // sacar del arreglo de conditions el conditonTime
      range.conditions = range.conditions.filter(
        (condition) => condition.property_type !== PropertyType.time
      );
    }
    if (!allDays) {
      range.conditions.push(conditonDay);
    } else {
      // sacar del arreglo de conditions el conditonDay
      range.conditions = range.conditions.filter(
        (condition) => condition.property_type !== PropertyType.day_of_week
      );
    }
    dispatch(setRangeDate(range));
    navigationRange?.navigation?.goBack();
  };

  return (
    <TVView
      style={{
        width: '95%',
        alignSelf: 'center',
        paddingVertical: 16,
        paddingHorizontal: 8,
        gap: 8,
        height: '100%',
        justifyContent: 'space-between'
      }}
    >
      <SectionFrom
        title="Rangos de la automatización"
        idStringSubtitle="Define dias, horas y fechas de la automatización"
      >
        <LayoutForm
          idString="automatizations.init"
          icon={true}
          idStringTooltip="automatizations.initTooltip"
        >
          <TPressable
            border={errorDateEnd ? 'pxsol' : 'border'}
            bgColor={conditonDate?.value[0]?.length > 0 ? 'background' : 'backgroundSecondary'}
            style={{
              borderRadius: 8,
              borderWidth: errorDateEnd ? 1 : 0
            }}
            onPress={() => setOpenStartDate(true)}
          >
            <CustomSelectForm
              idString={
                conditonDate?.value[0]
                  ? new Date(conditonDate?.value[0])?.toLocaleDateString()
                  : 'Seleccionar fecha'
              }
              trlate={false}
              color={conditonDate?.value[0]?.length > 0 ? 'text' : 'secondary'}
              sx={{
                borderRadius: 8,
                borderWidth: 1,
                padding: 4,
                height: 42,
                gap: 10,
                backgroundColor: 'transparent',
                width: '100%'
              }}
            />
          </TPressable>
        </LayoutForm>

        <LayoutForm
          idString={`automatizations.${end ? 'end' : 'noEnd'}`}
          icon={true}
          idStringTooltip="automatizations.endTooltip"
          leftIcon={<Switch value={end} size="md" onToggle={() => setEnd(!end)} />}
          color={end ? (conditonDate?.value[0]?.length > 0 ? 'text' : 'secondary') : 'secondary'}
        >
          <TPressable
            border={errorDateEnd ? 'pxsol' : 'border'}
            bgColor={conditonDate?.value[1]?.length > 0 ? 'background' : 'backgroundSecondary'}
            style={{
              borderRadius: 8,
              borderWidth: errorDateEnd ? 1 : 0
            }}
            onPress={() => setOpenEndDate(true)}
            disabled={!end || !conditonDate?.value[0]}
          >
            <CustomSelectForm
              idString={
                conditonDate?.value[1]
                  ? new Date(conditonDate?.value[1])?.toLocaleDateString()
                  : end
                    ? 'Seleccionar fecha'
                    : 'Sin fecha de fin'
              }
              color={
                end ? (conditonDate?.value[0]?.length > 0 ? 'text' : 'secondary') : 'secondary'
              }
              trlate={false}
              sx={{
                borderRadius: 8,
                borderWidth: 1,
                padding: 4,
                height: 42,
                gap: 10,
                backgroundColor: 'transparent',
                width: '100%'
              }}
            />
          </TPressable>
        </LayoutForm>

        <TimeComponent
          allHours={allHours}
          setAllHours={setAllHours}
          conditonTime={conditonTime}
          setOpenEndTime={setOpenEndTime}
          setOpenInitialTime={setOpenInitialTime}
        />

        <DaysComponent
          setAllDays={setAllDays}
          allDays={allDays}
          handleAddDay={handleAddDay}
          conditonDay={conditonDay}
        />
      </SectionFrom>

      <DateModals
        conditonDate={conditonDate}
        openStartDate={openStartDate}
        setOpenStartDate={setOpenStartDate}
        setConditonDate={setConditonDate}
        openEndDate={openEndDate}
        setOpenEndDate={setOpenEndDate}
      />
      <TimeModals
        conditonTime={conditonTime}
        setConditonTime={setConditonTime}
        openInitialTime={openInitialTime}
        setOpenInitialTime={setOpenInitialTime}
        openEndTime={openEndTime}
        setOpenEndTime={setOpenEndTime}
      />

      <THView style={{ width: '100%', justifyContent: 'flex-end' }}>
        <MainCta
          labelId="actions.add"
          variant="outline"
          sx={{
            borderWidth: 1,
            borderColor: '$border'
          }}
          loading={false}
          disabled={buttonDisabled}
          icon={IconSave}
          iconPosition="left"
          onPress={() => handleSaveRange()}
        />
      </THView>
    </TVView>
  );
};

export default memo(AutomatizationRange);
