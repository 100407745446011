import { axiosInstanceApi2 } from '@/utils/network/instanceAxios';

export const getContactsApi2 =
  ({ page }) =>
  async () => {
    try {
      const response = await axiosInstanceApi2.get(
        `/conversations/contact_conversation/list?page=${page}`
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  };
