import React, { useEffect, useMemo, useState } from 'react';
import { verifyAssisneee } from '@/utils/functions';
import { RootState, useAppSelector } from '@/redux/store';
import ChipBooking from '../components/conversation/body/FragmentComponent/ChipBooking';
import ChipAssignee from '../components/conversation/body/FragmentComponent/ChipAssignee';
import translate from '../helpers/traslations';
import { Badge } from '@gluestack-ui/themed';
import { BadgeText } from '@gluestack-ui/themed';
import { THView } from '@/components/themed';
import { StyleSheet } from 'react-native';

function RenderAssociationCard({ fragmentId }: { fragmentId: string }) {
  const fragment = useAppSelector((state: RootState) => state.fragments.fragments?.[fragmentId]);
  const [arrayAssignee, setArrayAssignee] = useState<any>([]);
  const [booking, setBooking] = useState<any>([]);

  useEffect(() => {
    if (fragmentId) {
      if (verifyAssisneee(fragment?.associations, 'assignee')) {
        setArrayAssignee(fragment?.asociationResponse?.associations?.assignee);
      } else {
        setArrayAssignee([]);
      }
      if (verifyAssisneee(fragment?.associations, 'booking')) {
        setBooking(fragment?.asociationResponse?.associations?.booking);
      } else {
        setBooking([]);
      }
    }
  }, [fragment, fragmentId]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 1,
          flexWrap: 'wrap'
        }
      }),
    []
  );

  return (
    <THView style={styles.container}>
      {arrayAssignee?.length > 0 ? (
        <ChipAssignee icon assignees={arrayAssignee} />
      ) : (
        <Badge variant="solid" action="muted">
          <BadgeText fontStyle="italic" fontSize="$xs">
            {translate('noAssignee')}
          </BadgeText>
        </Badge>
      )}
      {booking?.length > 0 && <ChipBooking booking={booking} icon />}
    </THView>
  );
}

export default React.memo(RenderAssociationCard, (prevProps, nextProps) => {
  return prevProps.fragmentId === nextProps.fragmentId;
});
