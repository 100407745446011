import React, { useCallback, useEffect, useState } from 'react';
import MenuActions from '@/components/menu/MenuActions';
import getIconsToFilters from '@/assets/icons/index';
import { MenuItem } from '@gluestack-ui/themed';
import { ActiveChannel } from '@/types/typesRedux/interfaces.d';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store/index';
import RenderBadges from '@/atoms/RenderBadges';
import { ConditionsWorkflow, PropertyConditions, TypeConditions } from '@/types/enums/workflows';

import { setMoreConditions } from '@/redux/slices/automatization/index';
import CustomSelectForm from '@/atoms/CustomSelectForm';
import CustomCheckbox from '@/components/form/CustomCheckbox';
import { findIdChannelValue } from '@/redux/slices/automatization/functions';
import { TView } from '@/components/themed';

const MenuCheckbox = ({ teamId }) => {
  const dispatch = useAppDispatch();
  const newWorkflow = useAppSelector((state: RootState) => state.automatization.newWorkflows);
  const channels = useAppSelector((state: RootState) => state.user?.activeChannels);

  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);

  useEffect(() => {
    const currentChannels = findIdChannelValue(newWorkflow);
    currentChannels ? setSelectedChannels(currentChannels) : setSelectedChannels([]);
  }, [newWorkflow]);

  const toggleLabel = (channel: ActiveChannel) => {
    setSelectedChannels((currentChannels) => {
      const isSelected = currentChannels?.some(
        (currentChannel) => currentChannel == channel?.channel_id
      );
      return isSelected
        ? currentChannels?.filter((currentChannel) => currentChannel != channel?.channel_id)
        : [...currentChannels, channel?.channel_id];
    });
  };

  const verifyIsSelected = useCallback(
    (channel: ActiveChannel) => {
      const include = selectedChannels?.find(
        (selectedChannel) => selectedChannel == channel?.channel_id
      );
      if (include) {
        return true;
      } else {
        return false;
      }
    },
    [selectedChannels]
  );

  const onChangeChannel = () => {
    const channel = {
      type: TypeConditions?.objectProperty,
      property: PropertyConditions.idChannel,
      condition: ConditionsWorkflow?.in,
      value: selectedChannels
    };
    dispatch(setMoreConditions(channel));
  };

  return (
    <TView>
      <MenuActions
        callbackOnClose={() => onChangeChannel()}
        trigger={
          selectedChannels?.length > 0 ? (
            <RenderBadges
              badges={selectedChannels}
              data={channels[teamId]}
              findKey="channel_id"
              iconKey="channel_type"
              showKey="channel_data"
              secondShowKey="channel_type"
              size="2xs"
            />
          ) : (
            <CustomSelectForm idString="automatizations.selectTheChannel" />
          )
        }
        sx={{
          width: '100%',
          borderRadius: '$sm',
          borderWidth: 1,
          borderColor: '$border',
          p: '$2',
          height: 42,
          justifyContent: 'center',
          backgroundColor:
            selectedChannels?.length > 0 ? '$backgroundActive' : '$backgroundSecondary'
        }}
      >
        {channels[teamId] &&
          channels[teamId]?.map((channel: ActiveChannel) => (
            <MenuItem
              textValue={channel?.channel_id}
              key={channel?.channel_id}
              closeOnSelect={false}
              onPress={() => toggleLabel(channel)}
              disabled={channel?.conection_status === 'disable'}
              width="100%"
            >
              <CustomCheckbox
                keyCheck={channel?.channel_id}
                value={channel?.channel_id}
                isDisabled={channel?.conection_status === 'disable'}
                isChecked={verifyIsSelected(channel)}
                label={
                  channel?.channel_data?.length
                    ? channel?.channel_data
                    : `${channel?.channel_type} ${channel?.channel_label}`
                }
                middleIcon={
                  <MaterialCommunity
                    size="lg"
                    icon={getIconsToFilters({ idString: channel?.channel_type })}
                  />
                }
              />
            </MenuItem>
          ))}
      </MenuActions>
    </TView>
  );
};

export default React.memo(MenuCheckbox);
