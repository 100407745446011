import React, { createContext, useContext, useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import Constants from 'expo-constants';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import {
  UpdateMessageStatusEventAndCountMessagesNoRead,
  cleanMessages,
  newMessage
} from '../redux/slices/messages';
import { setAllMessagesReadByConverId } from '../redux/slices/messages/requests';
import { addConverToState } from '../redux/slices/conversation';
import {
  cleanContacts,
  cleanStateConversation,
  cleanStateUser,
  clearLabels,
  clearMessagesByConverationId,
  clearOutConversationToStateById,
  clearParticipantsByConversationId,
  compareConversation,
  deleteLabel,
  // outConversationToFilter,
  removeConversationFromFilter,
  setFragments,
  setNewsParticipants,
  setSections,
  setSocket,
  setVersion,
  setViewSliderVersion,
  updateParticipant,
  updateTagNameLabelById
} from '../redux/actionTypes';
import { CompareParticipantsEvent } from './interfaces';
import { getLabels } from '@/redux/slices/labels/requests';
import { clearParticipants } from '@/redux/slices/participants';
import { cleanTemplates } from '@/redux/slices/templates';
import { clearFragments } from '@/redux/slices/fragments';
import { crearFilters } from '@/redux/slices/filters';
import { firstCharge } from '@/redux/slices/auth';

const PusherContext = createContext<Pusher | null>(null);

export const usePusher = () => useContext(PusherContext);

function PusherProvider({ children }) {
  const [pusherInstance, setPusherInstance] = useState<Pusher | null>(null);
  const userId = useAppSelector((state: RootState) => state.user.user?.id);
  const socketPusher = useAppSelector((state: RootState) => state.user.socket);
  const id_empresa = useAppSelector((state: RootState) => state.user.company?.IDEmpresas);
  const token = useAppSelector((state: RootState) => state.user.auth0Token);
  const teamUser = useAppSelector((state: RootState) => state.user.team);
  const waitingForResponse = useAppSelector(
    (state: RootState) => state.conversations.waitingForResponse
  );

  const dispatch = useAppDispatch();
  let channelTeam;
  let channelCompany;
  let channelUpdate;
  const [teamId, setTeam] = useState<any>(teamUser?.id);
  const { pusherkey, pusherCluster, backendUrl } = Constants.expoConfig.extra;

  function getDataIfReconnect() {
    if (waitingForResponse) return;
    dispatch(firstCharge())
      .then(() => {
        console.log('getUser PusherProvider --Pusher---');
      })
      .catch((error) => {
        console.error('getUser  PusherProvider --Pusher---', error);
      });
  }

  useEffect(() => {
    if (token && pusherkey && pusherCluster && backendUrl) {
      const pusher = new Pusher(pusherkey, {
        cluster: pusherCluster,
        forceTLS: true,
        authEndpoint: `${backendUrl}/chat/registerSocketId`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      setPusherInstance(pusher);
    }
  }, [token]);

  useEffect(() => {
    if (teamUser?.id) {
      setTeam((prev: number) => {
        if (prev !== teamUser?.id) {
          pusherInstance?.unsubscribe(`private-team_room_${prev}`);
          channelTeam = pusherInstance?.subscribe(`private-team_room_${teamUser?.id}`);
          return teamUser?.id;
        }
      });
    }
  }, [teamUser]);

  if (pusherInstance && userId && id_empresa) {
    pusherInstance.connection.bind('connected', () => {
      console.log(
        ' 🚀 PUSHER SOCKET CONNECTED ~ file: PusherProvider.tsx ~ line 53 ~ pusherInstance.connection.bind ~ Connected '
      );
      if (!socketPusher) {
        dispatch(setSocket(true));
        getDataIfReconnect();
      }
    });

    pusherInstance.connection.bind('connecting', () => {
      console.log(
        '%c 🤔 CONNECTING PUSHER SOCKET... ',
        'color:#000; background-color:yellow; padding: 10px; font-size:16px'
      );
    });

    pusherInstance.connection.bind('reconnecting', () => {
      console.log(
        '%c 🤔 RECONNECTING PUSHER SOCKET... ',
        'color:#000; background-color:yellow; padding: 10px; font-size:16px'
      );
    });
    pusherInstance.connection.bind('disconnected', () => {
      console.log(
        '%c 🍆 PUSHER SOCKET DISCONNECTED ',
        'color:#FFF; background-color:red; padding: 10px; font-size:16px'
      );
      dispatch(setSocket(false));
    });

    pusherInstance.connection.bind('disconnecting', () => {
      console.log(
        '%c 🤔 DISCONNECTING PUSHER SOCKET... ',
        'color:#000; background-color:orange; padding: 10px; font-size:16px'
      );
      dispatch(setSocket(false));
    });

    pusherInstance.connection.bind('unavailable', () => {
      console.log(
        '%c 🍆 PUSHER SOCKET UNAVAILABLE ',
        'color:#FFF; background-color:red; padding: 10px; font-size:16px'
      );
      dispatch(setSocket(false));
    });

    pusherInstance.connection.bind('failed', () => {
      console.log(
        '%c 🍆 PUSHER SOCKET FAILED ',
        'color:#FFF; background-color:red; padding: 10px; font-size:16px'
      );
      dispatch(setSocket(false));
    });

    channelTeam = pusherInstance.subscribe(`private-team_room_${teamUser?.id}`);
    const channel = pusherInstance.subscribe(`private-client_room_${userId.toString()}`);
    channelCompany = pusherInstance.subscribe(`private-company_room_${id_empresa}`);
    channelUpdate = pusherInstance.subscribe(`public-update-app-pxsol`);

    channelUpdate.bind('pusher:subscription_succeeded', (data) => {
      console.log(data.subscription_count);
      console.log(channelUpdate.subscription_count);
    });

    channelUpdate.bind('pusher:subscription_error', (err) => {
      console.log('ERROR REGISTER CHANNEL', err);
    });

    channelUpdate.unbind_all();
    channelUpdate.bind('new-version-app', (data) => {
      dispatch(setVersion(true));
      dispatch(setViewSliderVersion(true));
    });

    // Eventos de la compañia

    channelCompany.bind('pusher:subscription_succeeded', () => {
      console.log(
        '🚀 ~ SUCCESS REGISTER CHANNEL - file: PusherProvider.tsx ~ channelCompany.bind ~ channelCompany',
        `private-company_room_${id_empresa}`
        // channelCompany
      );
      dispatch(setSocket(true));
    });

    // ### CHANNEL COMPANY BINDS ###
    channelCompany.bind('pusher:subscription_error', (err) => {
      dispatch(setSocket(false));
      console.log(
        '🍆 ~ ERROR REGISTER CHANNEL - file: PusherProvider.tsx ~ ERROR ~ channelCompany',
        `private-company_room_${id_empresa}`,
        err
      );
    });

    channelCompany.bind('pusher:disconnect', () => {
      dispatch(setSocket(false));
      console.log(
        '🚀 ~ file: PusherProvider.tsx ~ line 53 ~ DISCONNECT ~ channelCompany',
        `private-company_room_${id_empresa}`
      );
    });
    channelCompany.unbind_all();

    channelCompany.bind('InsertMessageEvent', (data) => {
      dispatch(newMessage(data?.messages));
    });
    channelCompany.bind('InsertConversationEvent', (data) => {
      dispatch(setSections(data?.section));
      dispatch(
        addConverToState({
          fragments: data?.fragment,
          filter: data?.filter,
          conversation: data?.conversation,
          teamId: teamUser?.id
        })
      );
      dispatch(
        setNewsParticipants({
          participants: data?.participants[data?.conversation?._id],
          conversation_id: data?.conversation?._id
        })
      );
    });
    channelCompany.bind('UpdateFragmentEvent', (data) => {
      dispatch(setFragments(data?.fragmentInfo));
    });

    channelCompany.bind('UpdateMessageStatusEvent', (data) => {
      if (data?.messages?.length > 0) {
        dispatch(
          UpdateMessageStatusEventAndCountMessagesNoRead({
            status: data?.messages[0]?.status,
            msg: data?.messages[0]
          })
        );
      }
    });

    channelCompany.bind('UpdateAllMessageStatusEvent', (data) => {
      dispatch(setAllMessagesReadByConverId(data?.id_conversation));
    });

    // ### CHANNEL TEAM BINDS ###

    channel?.bind('pusher:subscription_succeeded', () => {
      console.log(
        '🚀 ~ SUCCESS REGISTER CHANNEL - file: PusherProvider.tsx ~ channel.bind ~ channel',
        `private-client_room_${userId.toString()}`
      );
    });

    channel?.bind('pusher:subscription_error', (err) => {
      console.log(
        '%c 🚀 ~ file: PusherProvider.tsx ~ line 53 ~ ERROR ~ channel',
        'color:#FFF; background-color:red; padding: 10px; font-size:16px',
        err
      );
    });

    channel.bind('pusher:disconnected', () => {
      console.log(
        '%c 🤔 DESCONNECTING PUSHER SOCKET CHANNEL USER... ',
        'color:#FFF; background-color:red; padding: 10px; font-size:16px'
      );
      // Maybe show a UI notification to the user that they're offline
    });

    channel.bind('pusher:reconnecting', () => {
      console.log(
        '%c 🤔 RECONNECTING PUSHER SOCKET CHANNEL USER... ',
        'color:#000; background-color:yellow; padding: 10px; font-size:16px'
      );
      // Optionally, you can implement logic to save the state
    });

    channel.bind('pusher:reconnected', () => {
      console.log(
        '%c 🤔 CONNECTED PUSHER SOCKET CHANNEL USER... ',
        'color:#FFF; background-color:green; padding: 10px; font-size:16px'
      );
      // Fetch missed messages from your server
    });

    channel.unbind_all();

    channel.bind('UpdateFragmentEvent', (data) => {
      dispatch(setFragments(data?.fragmentInfo));
    });

    channel.bind('ClearData', (data) => {
      dispatch(cleanStateConversation());
      dispatch(cleanStateUser());
      dispatch(cleanContacts());
      dispatch(cleanMessages());
      dispatch(clearParticipants());
      dispatch(cleanTemplates());
      dispatch(clearFragments());
      dispatch(crearFilters());
      dispatch(clearLabels());
    });

    channel.bind('InsertConversationEvent', (data) => {
      dispatch(setSections(data?.section));
      setTimeout(() => {
        dispatch(
          addConverToState({
            fragments: data?.fragment,
            filter: data?.filter,
            conversation: data?.conversation,
            teamId: teamUser?.id
          })
        );
        dispatch(
          setNewsParticipants({
            participants: data?.participants[data?.conversation?._id],
            conversation_id: data?.conversation?._id
          })
        );
      }, 1000);
    });

    channel.bind('CompareParticipantsEvent', (data: CompareParticipantsEvent) => {
      if (data?.participants?.length > 0) {
        dispatch(
          setNewsParticipants({
            participants: data?.participants,
            conversation_id: data?.conversationId
          })
        );
      } else {
        console.log('No hay participantes');
      }
    });

    channel.bind('InsertMessageEvent', (data) => {
      dispatch(newMessage(data?.messages));
    });

    channel.bind('CompareGroupNameEvent', (data) => {
      dispatch(compareConversation(data));
    });
    channel.bind('UpdateMessageStatusEvent', (data) => {
      if (data?.messages?.length > 0) {
        dispatch(
          UpdateMessageStatusEventAndCountMessagesNoRead({
            status: data?.messages[0]?.status,
            msg: data?.messages[0]
          })
        );
      }
    });

    channel.bind('UpdateAllMessageStatusEvent', (data) => {
      dispatch(setAllMessagesReadByConverId(data?.id_conversation));
    });

    channel.bind('YouWereRemovedEvent', (data) => {
      dispatch(clearOutConversationToStateById(data?.id_conversation));
      dispatch(removeConversationFromFilter(data?.id_conversation));
      dispatch(clearMessagesByConverationId(data?.id_conversation));
      dispatch(clearParticipantsByConversationId(data?.id_conversation));
    });

    // ### CAHENNEL TEAM BINDS ###

    channelTeam?.bind('pusher:subscription_succeeded', () => {
      console.log('🚀 ~ file: PusherProvider.tsx ~ line 53 ~ channelTeam?.bind ~ channelTeam');
    });

    channelTeam?.bind('pusher:subscription_error', (err) => {
      console.log(
        '%c 🚀 ~ file: PusherProvider.tsx ~ line 53 ~ ERROR ~ channelTeam',
        'color:#FFF; background-color:red; padding: 10px; font-size:16px',
        err
      );
    });

    channelTeam.unbind_all();
    channelTeam.bind('CompareGroupNameEvent', (data) => {
      dispatch(compareConversation(data));
    });

    channelTeam?.bind('LabelsEvent', (data) => {
      const { type, tag_name, id } = data;
      switch (type) {
        case 'add':
          dispatch(getLabels(teamUser?.id));
          break;
        case 'delete':
          dispatch(deleteLabel({ id }));
          break;
        case 'update':
          dispatch(updateTagNameLabelById({ id, tag_name }));
          break;
        default:
          break;
      }
    });

    channelTeam.bind('ChangeTeamEvent', (data) => {
      dispatch(clearOutConversationToStateById(data?.id_conversation));
      dispatch(removeConversationFromFilter(data?.id_conversation));
      dispatch(clearMessagesByConverationId(data?.id_conversation));
      dispatch(clearParticipantsByConversationId(data?.id_conversation));
    });

    channelTeam.bind('UpdateFragmentEvent', (data) => {
      dispatch(setFragments(data?.fragmentInfo));
    });

    channelTeam.bind('InsertConversationEvent', (data) => {
      dispatch(setSections(data?.section));
      setTimeout(() => {
        dispatch(
          addConverToState({
            fragments: data?.fragment,
            filter: data?.filter,
            conversation: data?.conversation,
            teamId: teamUser?.id
          })
        );
        dispatch(
          setNewsParticipants({
            participants: data?.participants[data?.conversation?._id],
            conversation_id: data?.conversation?._id
          })
        );
      }, 500);
    });

    channelTeam.bind('CompareParticipantsEvent', (data: CompareParticipantsEvent) => {
      dispatch(
        setNewsParticipants({
          participants: data?.participants,
          conversation_id: data?.conversationId
        })
      );
    });
    channelTeam.bind('InsertSectionEvent', (data) => {
      dispatch(setSections(data?.section));
    });
    channelTeam.bind('InsertMessageEvent', (data) => {
      dispatch(newMessage(data?.messages));
    });

    channelTeam.bind('UpdateMessageStatusEvent', (data) => {
      if (data?.messages?.length > 0) {
        dispatch(
          UpdateMessageStatusEventAndCountMessagesNoRead({
            status: data?.messages[0]?.status,
            msg: data?.messages[0]
          })
        );
      }
    });
    channelTeam.bind('UpdateInfoParticipantEvent', (data) => {
      dispatch(updateParticipant(data?.participants));
    });

    channelTeam.bind('UpdateAllMessageStatusEvent', (data) => {
      dispatch(setAllMessagesReadByConverId(data?.id_conversation));
    });
  }
  return <PusherContext.Provider value={pusherInstance}>{children}</PusherContext.Provider>;
}

export default React.memo(PusherProvider);
