export enum PlatformsEnum {
  whatsapp = 'whatsapp',
  facebook = 'facebook',
  instagram = 'instagram',
  internal = 'internal',
  widget = 'widget'
}

export enum ContractsEnum {
  chat = 'chat',
  internal = 'internal',
  widget = 'widget'
}
