import moment from 'moment';

export default function FormatDate(fecha: string): string {
  const fechaActual = moment(fecha); // Using local time
  const now = moment(); // Using local time
  let fechaFormateada: string;

  if (now.diff(fechaActual, 'minutes') < 60) {
    fechaFormateada = fechaActual.format('HH:mm');
  } else if (fechaActual.isSame(now, 'day')) {
    fechaFormateada = fechaActual.format('HH:mm');
  } else if (
    fechaActual.clone().startOf('day').isSame(now.clone().subtract(1, 'day').startOf('day'))
  ) {
    fechaFormateada = `ayer ${fechaActual.format('HH:mm')}`;
  } else if (now.diff(fechaActual, 'days') < 7) {
    fechaFormateada = fechaActual.format('dddd'); // Day of the week without time
  } else {
    fechaFormateada = fechaActual.format('L'); // Local date format (dd/mm/yy or mm/dd/yy depending on locale)
  }

  return fechaFormateada;
}

export function formatDateComplete(fecha: string): string {
  const fechaActual = moment(fecha); // Using local time
  const fechaFormateada = fechaActual.format('L'); // Local date format (dd/mm/yy or mm/dd/yy depending on locale)
  return fechaFormateada;
}
