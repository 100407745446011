import { View, Text } from 'react-native';
import React from 'react';
import MainCta from '@/atoms/MainCta';
import { IconPlus } from '@/assets/icons/Icons';
import { useCollapsed } from '@/providers/CollpasedProvider';

const ButtonNewLabel = () => {
  const { hableModalLabel } = useCollapsed();
  return (
    <MainCta
      labelId="actions.newLabel"
      variant="outline"
      iconPosition="left"
      icon={IconPlus}
      onPress={hableModalLabel}
      buttonSize="xs"
      iconColor="$disabled"
    />
  );
};

export default React.memo(ButtonNewLabel);
