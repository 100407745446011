import React, { useEffect } from 'react';
import { Platform } from 'react-native';

import OmniboxComponent from './OmniboxComponent';
import OmniboxProvider from './OmniboxProvider';
import { useLinkss } from '@/providers/LinkProvider';
import ModalApp from '../modal/ModalApp';

function ModalSearch({ modalVisible, setModalVisible }) {
  const { showToast } = useLinkss();
  // only windows if pressed esc close modal
  useEffect(() => {
    if (Platform.OS !== 'web') return;
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setModalVisible(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setModalVisible]);

  return (
    <ModalApp modalVisible={modalVisible} setModalVisible={setModalVisible}>
      <OmniboxProvider showToast={showToast} setModalVisible={setModalVisible}>
        <OmniboxComponent setModalVisible={setModalVisible} />
      </OmniboxProvider>
    </ModalApp>
  );
}

export default React.memo(ModalSearch);
