import React, { useEffect, useState } from 'react';

import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import RenderContacts from '@/components/RenderContacts';
import InputComponent from '@/components/conversation/footer/InputComponent';

import { HStack, Spinner, Text, VStack, View } from '@gluestack-ui/themed';
import translate from '@/helpers/traslations';
import { Participant } from '@/redux/slices/participants/interfaces';
import { Contacts } from '@/types/typesRedux/interfaces';
import { handleSearchInInput } from '@/utils/functions';
import { setIdConversation, setNewGroup } from '../redux/actionTypes';
import { createConversation } from '../redux/slices/conversation';
import { ParticipantCreateConversation } from '../types/participants';
import { useAppSelector, useAppDispatch, RootState } from '../redux/store';
import MainCta from '@/atoms/MainCta';

import { navigateToConversation } from '@/providers/LinkProvider';
import { PropsNewGroup } from './interfaces';
import { GRUPOS } from '@/utils/filters';
import { ThemedScrollView, THView, TText, TView, TVView } from '@/components/themed';

function NewGroup({ route, showToast, setModalVisible }: PropsNewGroup) {
  const contacts = useAppSelector((state: RootState) => state.contacts.contacto);
  const loadingContact = useAppSelector((state: RootState) => state.contacts.loadingContact);

  const newGroup = useAppSelector((state: RootState) => state.conversations.newGroup);
  const user = useAppSelector((state: RootState) => state.user.user);
  const company_id = useAppSelector((state: RootState) => state.user.company.IDEmpresas);
  const participants = useAppSelector((state: RootState) => state.participants.participants);

  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const [slected, setSlected] = useState<ParticipantCreateConversation[]>([]);
  const [id, setId] = useState<string[]>([]);
  const [igual, setIgual] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [filtros, setFiltros] = useState<Contacts[]>([]);
  const [nombreParticipante, setNombreParticipante] = useState('');

  useEffect(() => {
    setFiltros(contacts);
    return () => {
      setId && setId([]);
    };
  }, []);

  useEffect(() => {
    if (id?.length === participants[route?.params?.id]?.length) {
      let igual = true;
      id?.forEach((id: string) => {
        if (
          !participants[route?.params?.id]?.find((item: Participant) => item?.userId?._id === id)
        ) {
          igual = false;
        }
      });
      setIgual(igual);
    } else {
      setIgual(false);
    }
  }, [id, participants, route?.params?.id]);

  const handleSelect = (part: Contacts) => {
    let include = false;
    if (part?._id === user?.idMongoUser) return;

    slected.forEach((item: ParticipantCreateConversation) => {
      if (item?.userId === part?._id) {
        include = true;
      }
    });
    if (include) {
      // si el part es un participante de la conversacion no lo elimino y emito una alerta avisando que no se puede eliminar
      const newSelected = slected.filter(
        (item: ParticipantCreateConversation) => item?.userId !== part?._id
      );
      const newid = id.filter((id: string) => id !== part?.id_user);
      setId(newid);
      setSlected(newSelected);
    } else {
      const userId = part?._id;
      userId &&
        setSlected([
          ...slected,
          {
            userId,
            isOwner: false,
            hasNotification: true
          }
        ]);
      setId([...id, part?.id_user]);
    }
  };

  const handleCreateGroup = () => {
    if (name === '') {
      showToast({
        subtitle: translate('El nombre del grupo no puede estar vacio'),
        type: 'error'
      });
      return;
    }
    setLoading(true);
    const owner = {
      userId: `${user.idMongoUser}`,
      isOwner: true,
      hasNotification: true
    };
    slected.push(owner);

    dispatch(
      createConversation(GRUPOS, {
        status: 'new',
        participants: slected,
        company_id: `${company_id}`,
        platform: 'internal',
        getMessages: false,
        group: {
          status: true,
          name,
          avatar: ''
        },
        id_channel: ''
      })
    )
      .then((res: unknown) => {
        if (res === '404') {
          showToast({
            subtitle: translate('messageError.errorToCreateGroup'),
            type: 'error'
          });
          setLoading(false);
          return;
        }
        dispatch(setIdConversation(res as string));
        setLoading(false);
        navigateToConversation({
          idConver: res as string,
          platform: 'internal',
          filter: GRUPOS
        });
        setModalVisible(false);
        dispatch(setNewGroup(false));
        setName('');
        setSlected([]);
        setId([]);
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
        showToast({
          type: 'error',
          title: translate('error'),
          subtitle: translate('messageError.noCreateConversation')
        });
      });
  };

  const handleSearch = (event: string) => {
    handleSearchInInput({
      event,
      arrayToFilter: contacts,
      arrayFilterNoPagination: contacts,
      callbackSetFilter: setFiltros,
      callbackOnChangeSearch: setNombreParticipante,
      inputSearch: nombreParticipante
    });
  };

  const styles = StyleSheet.create({
    input: {
      marginVertical: 5,
      width: '100%',
      height: 40
    },
    containerInputText: {
      marginHorizontal: 'auto',
      width: '100%',
      gap: 10,
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  });

  return (
    <TVView
      bgColor="backgroundSecondary"
      style={{
        height: '100%',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingVertical: 8
      }}
    >
      <TView style={{ ...styles.input, width: '100%' }}>
        <InputComponent
          background="background"
          iconSearch
          onChange={handleSearch}
          placeholder={translate('search')}
          value={nombreParticipante}
          multiline={false}
          autofocus
        />
      </TView>
      <TVView
        style={{
          marginHorizontal: 'auto',
          width: '100%',
          gap: 5,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <THView style={[styles.containerInputText]}>
          <TText size="sm" numberOfLines={1} style={{ fontWeight: 'bold' }}>
            {translate('nameOfGroup')}
          </TText>
          <TView
            border="primary"
            style={{
              ...styles.input,
              flex: 1,
              borderWidth: id.length > 0 && name === '' ? 1 : 0,
              borderRadius: 20
            }}
          >
            <InputComponent
              onChange={setName}
              placeholder={translate('nameOfGroup')}
              value={name}
              multiline={false}
              background="background"
            />
          </TView>
        </THView>
      </TVView>

      <ThemedScrollView
        showsVerticalScrollIndicator={false}
        style={{
          width: '100%',
          flex: 1
        }}
      >
        {loading ? (
          <Spinner color="$pxsol" />
        ) : (
          <RenderContacts
            filtros={filtros}
            callbackToSelectUser={handleSelect}
            loading={loadingContact}
            id={id || []}
          />
        )}
      </ThemedScrollView>
      <TView
        style={{
          marginTop: 5
        }}
      >
        <MainCta
          disabled={
            (newGroup ? slected.length === 0 || name === '' : !(slected.length > 0 && !igual)) ||
            loading
          }
          onPress={handleCreateGroup}
          labelId="newGroup"
        />
      </TView>
    </TVView>
  );
}
export default React.memo(NewGroup);
