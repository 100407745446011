import { createSlice } from '@reduxjs/toolkit';
import { setFilters, setNewFilterToKey, setOrderToFilters } from '@/redux/actionTypes';
import { FilterItemState, filterTypes, FiltersState, OrderOptions, Filters } from './interfaces';

const initialState: FiltersState = {
  filterChat: '',
  filterClients: '',
  filters: {} as FilterItemState,
  type: filterTypes.chat,
  orderOptions: [
    { label: 'Cronológico', value: OrderOptions?.recent, id: 2 },
    { label: 'Importantes', value: OrderOptions?.importants, id: 1 }
  ],
  currentOrder: OrderOptions?.recent
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
    crearFilters: (state) => {
      state.filterChat = '';
      state.filterClients = '';
      state.filters = {} as FilterItemState;
      state.type = filterTypes.chat;
      state.currentOrder = OrderOptions?.recent;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setFilters, (state, action) => {
      state.filters = action.payload;
      state.orderOptions = [
        { label: 'Cronológico', value: OrderOptions?.recent, id: 2 },
        { label: 'Importantes', value: OrderOptions?.importants, id: 1 }
      ];
    });
    builder.addCase(setNewFilterToKey, (state, action) => {
      state.filters[action.payload.key] = [
        ...state.filters[action.payload.key],
        ...action.payload.value
      ];
    });
    builder.addCase(setOrderToFilters, (state, action) => {
      const keysFilter = Object.keys(state.filters);
      keysFilter?.forEach((key) => {
        state.filters[key].forEach((filter) => {
          filter.query.conversation = {
            ...filter.query.conversation,
            order: action.payload === 'recent' ? false : action.payload
          };
        });
      });
      state.currentOrder = action.payload;
    });
  }
});

export const { setType, crearFilters } = filtersSlice.actions;

export default filtersSlice.reducer;
