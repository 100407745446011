import React from 'react';
import { Badge, BadgeText } from '@gluestack-ui/themed';
import { getActionBadgeByStatus } from '@/utils/functions';
import { InstanceType } from '@/components/conversation/body/FragmentComponent/interfaces';

const BadgeItemBooking = ({ instancia }: { readonly instancia: InstanceType }) => {
  return (
    <Badge
      size="sm"
      variant="solid"
      borderRadius="$none"
      action={getActionBadgeByStatus(instancia)}
      alignSelf="flex-start"
    >
      <BadgeText>{instancia}</BadgeText>
    </Badge>
  );
};

export default React.memo(BadgeItemBooking);
