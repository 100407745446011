import React from 'react';
import { View } from 'react-native';

import { Image } from 'expo-image';
import { Spinner } from '@gluestack-ui/themed';

function ImageActivity({ imageUrl, downloading }) {
  return (
    <View>
      <Image alt="activity" source={imageUrl} style={{ width: 200, height: 200, opacity: 0.06 }} />
      {downloading && (
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            width: 200,
            height: 200
          }}
        >
          <Spinner color="$pxsol" size="large" />
        </View>
      )}
    </View>
  );
}
export default React.memo(ImageActivity);
