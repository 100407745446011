import React, { useState, useEffect } from 'react';
import { StyleSheet, Image } from 'react-native';
import { useAuth0 } from 'react-native-auth0';
import { Button, ButtonIcon, ButtonText, View, Text } from '@gluestack-ui/themed';

import { RootState, useAppDispatch, useAppSelector } from '../redux/store';

import { firstCharge } from '../redux/slices/auth';
import { saveTokenAuth0 } from '../redux/actionTypes';

import translate from '../helpers/traslations';
import { IconArrowRight } from '@/assets/icons/Icons';

function Login() {
  const msgError = useAppSelector((state: RootState) => state.user.msgError);
  const userLoading = useAppSelector((state: RootState) => state.user.userLoading);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { authorize, isLoading, getCredentials, clearSession, user, error } = useAuth0();

  const onPress = async () => {
    try {
      setLoading(true);
      // execute authorize() and request refresh toke
      const auto = await authorize();
      console.log('authorize', auto);

      getCredentials()
        .then((credentials) => {
          if (credentials) {
            const { idToken } = credentials;
            console.log('credentials', credentials);

            dispatch(saveTokenAuth0({ idToken }));
            dispatch(firstCharge())
              .then((res) => {
                console.log('getUser --LoginWeb---', res);
              })
              .catch((error) => {
                console.error('getUser --LoginWeb---', error);
                throw error;
              });
            // navigation.navigate(Routes.Team);
            setLoading(false);
          } else {
            console.log('login failed or canceled', credentials);
            setLoading(false);
            throw new Error('login failed or canceled');
          }
        })
        .catch((error) => {
          console.log('error to get credentials', error);
          setLoading(false);
        });
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await clearSession();

      setLoading(false);
    } catch (error) {
      console.error('error', error);
    }
  };
  console.log('INFOOOO', { error }, { user });
  useEffect(() => {
    // verificar si userloading es true o si isloading es true y setaer un loading en true
    if (userLoading || isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userLoading, isLoading]);

  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      backgroundColor: '$backgroundSecondary',
      width: '100%',
      height: '100%'
    },
    card: {
      flex: 1,
      maxWidth: 500,
      maxHeight: '100%',
      width: '95%',
      boxSizing: 'inherit',
      borderColor: '$border',
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 20
    },
    logoContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    },
    logo: {
      width: '50%',
      height: '50%'
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    }
  });

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <View style={styles.logoContainer}>
          <Image
            alt="Bienvenido"
            source={require('@/assets/images/intro_main-min.png')}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <Text
          size="sm"
          style={{
            textAlign: 'center',
            fontWeight: 'bold'
          }}
        >
          {translate('messages.welcome')}
        </Text>

        {msgError && (
          <Text
            color="$error"
            size="sm"
            style={{
              textAlign: 'center',
              fontWeight: 'bold'
            }}
          >
            {msgError}
          </Text>
        )}
        <Text
          size="sm"
          style={{
            textAlign: 'center'
          }}
        >
          {msgError && msgError}
          {translate('messages.slogan')}
        </Text>
        <View style={styles.formContainer}>
          <Button isDisabled={loading} onPress={onPress}>
            <ButtonIcon size="sm" as={IconArrowRight} />
            <ButtonText>{translate('actions.login')}</ButtonText>
          </Button>
        </View>
        <Button onPress={logout}>
          <ButtonText>{translate('actions.logout')}</ButtonText>
        </Button>
      </View>
    </View>
  );
}

export default Login;
