import React from 'react';
import i18n from '@/providers/Localization';
import { HStack, Text, VStack } from '@gluestack-ui/themed';

import { Linking } from 'react-native';
import MainCta from '@/atoms/MainCta';
import { Guides } from '@/assets/icons/Icons';

function Help() {
  return (
    <VStack bg="$backgroundSecondary" h="$full">
      <HStack
        bg="$backgroundSecondary"
        justifyContent="space-between"
        alignItems="center"
        w="$full"
        style={{
          padding: 20
        }}
      >
        <Text size="md">{i18n.t('messages.needHelp')}</Text>
        <MainCta
          onPress={() => Linking.openURL('https://ayuda.pxsol.com/es/conversaciones')}
          icon={Guides}
          borderRadius="$full"
          action="secondary"
          buttonSize="md"
          labelId="viewGuides"
        />
      </HStack>
    </VStack>
  );
}

export default React.memo(Help);
