import React, { useEffect, useMemo } from 'react';
import { InputSlot, Spinner } from '@gluestack-ui/themed';
import { IconAdd, Mangify, Wand2 } from '@/assets/icons/Icons';
import MainCta from '@/atoms/MainCta';
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import {
  setFileAttachment,
  setLimitReached,
  setMenuDinamic,
  setModalMore
} from '@/redux/slices/theme';

import translate from '@/helpers/traslations';
import { ContractsEnum } from '@/types/enums/conversation';
import { verifyLimitUsage } from '@/redux/slices/conversation/requests';
import { setDialogConnect } from '@/redux/slices/theme';

const InputSlotLeft = ({
  iconSearch,
  iconAdd = false,
  hasMagic,
  magicLoading,
  onPressIconMagic,
  setDialogVisible,
  agentId
}: {
  iconSearch: boolean;
  iconAdd: boolean;
  hasMagic: boolean;
  magicLoading: boolean;
  onPressIconMagic: () => void;
  setDialogVisible: (arg: boolean) => void;
  agentId: string | null;
}) => {
  const dispatch = useAppDispatch();
  const modalMore = useAppSelector((state: RootState) => state.settings.modalMore);
  const modalDinamic = useAppSelector((state: RootState) => state.settings.modalDinamic);
  const converId = useAppSelector((state: RootState) => state.conversations.id_conversation);
  const contracts = useAppSelector((state: RootState) => state.user.contracts);
  const limitReached = useAppSelector((state: RootState) => state.settings.limitReached);
  const company_id = useAppSelector((state: RootState) => state.user.company?.IDEmpresas);

  const [verifing, setVerifing] = React.useState(false);

  useEffect(() => {
    if (contracts?.includes(ContractsEnum?.chat) && limitReached) {
      dispatch(setLimitReached(false));
    }
  }, [contracts, limitReached]);

  const toogleModalMore = () => {
    modalDinamic && dispatch(setMenuDinamic(false));
    dispatch(setModalMore(!modalMore));
    if (modalMore) {
      dispatch(setFileAttachment({ conversationId: converId, fileAttachment: null }));
    }
  };

  const messagseTooltip = useMemo(
    () =>
      !contracts?.includes(ContractsEnum?.chat)
        ? translate('messages.limitFreeMessages')
        : limitReached
          ? translate('messages.freeLimiRreached')
          : agentId === null
            ? translate('messages.noAgentCreated')
            : translate('messages.generateSmartResponse'),
    [agentId, contracts]
  );

  const handleVerify = () => {
    if (agentId === null) {
      setDialogVisible(true);
      return;
    }
    console.log('onPressIconMagic', contracts?.includes(ContractsEnum?.chat), { contracts });

    if (!contracts?.includes(ContractsEnum?.chat)) {
      setVerifing(true);
      dispatch(verifyLimitUsage({ company_id }))
        .then((res) => {
          if (res?.data?.limit_reached) {
            dispatch(setLimitReached(true));
            dispatch(setDialogConnect(true));
            setVerifing(false);
          } else {
            dispatch(setLimitReached(false));
            onPressIconMagic();
            setVerifing(false);
          }
          setVerifing(false);
        })
        .catch((err) => {
          setVerifing(false);
          console.log(err);
        })
        .finally(() => {
          setVerifing(false);
        });
    } else {
      console.log('onPressIconMagic else');
      onPressIconMagic();
    }
  };

  return (
    <InputSlot flexDirection="row" gap="$1">
      {iconSearch && <MainCta iconColor="$iconos" icon={Mangify} iconSize="md" variant="outline" />}
      {iconAdd && (
        <MainCta
          iconColor="$iconos"
          onPress={() => toogleModalMore()}
          variant="outline"
          icon={IconAdd}
          iconSize="md"
        />
      )}

      {hasMagic &&
        (magicLoading || verifing ? (
          <Spinner size="small" color="$pxsol" />
        ) : (
          <CustomTooltip title={messagseTooltip}>
            <MainCta
              onPress={handleVerify}
              icon={Wand2}
              variant="outline"
              disabled={verifing}
              iconColor="$iconos"
              iconSize="md"
            />
          </CustomTooltip>
        ))}
    </InputSlot>
  );
};

export default React.memo(InputSlotLeft);
