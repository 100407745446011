import React, { ReactElement } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Platform, SafeAreaView } from 'react-native';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from '@/redux/store';
import Auth0Provider from '@/providers/Auth0Provider';
//@ts-ignore
import NotificationProvider from '@/providers/NotificationProvider';
//@ts-ignore
import UpdateChecker from '@/components/update/UpdateChecker';
//@ts-ignore
import HotjarProvider from '@/providers/HotjarProvider';
//@ts-ignore
import VersionProvider from '@/providers/VersionProvider';
import { PaperProvider } from 'react-native-paper';

import MainRouter from '@/routers/MainRouter';
import CustomThemeProvider from '@/providers/CustomThemeProvider';
import { SafeAreaProvider } from 'react-native-safe-area-context';

export default function App(): ReactElement {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <SafeAreaProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <PaperProvider>
              <CustomThemeProvider>
                <Auth0Provider>
                  <NotificationProvider>
                    <HotjarProvider>
                      <VersionProvider>
                        <GestureHandlerRootView style={{ flex: 1 }}>
                          {Platform.OS === 'web' ? (
                            <>
                              <UpdateChecker />
                              <MainRouter />
                            </>
                          ) : (
                            <MainRouter />
                          )}
                        </GestureHandlerRootView>
                      </VersionProvider>
                    </HotjarProvider>
                  </NotificationProvider>
                </Auth0Provider>
              </CustomThemeProvider>
            </PaperProvider>
          </PersistGate>
        </Provider>
      </SafeAreaProvider>
    </SafeAreaView>
  );
}
