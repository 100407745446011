import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  Center,
  ModalBackdrop,
  ModalContent,
  Text,
  ModalHeader,
  HStack,
  VStack
} from '@gluestack-ui/themed';

import { Modal } from '@gluestack-ui/themed';
import { Heading } from '@gluestack-ui/themed';
import { ModalBody } from '@gluestack-ui/themed';
import { ModalFooter } from '@gluestack-ui/themed';
import MainCta from '@/atoms/MainCta';
import PopoverChannels from '../newConverExternal/PopoverChannels';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { setPopoverChannel } from '@/redux/slices/theme';
import translate from '@/helpers/traslations';
import { PlatformsEnum } from '@/types/enums/conversation';

const ModalInitConver = ({
  showModal,
  setShowModal,
  handleAcceptConversation,
  teamId,
  loading,
  channelWhatsapp
}) => {
  const dispatch = useAppDispatch();
  const selectedChannel = useAppSelector(
    (state: RootState) => state.settings.selectedChannelToInitConversation
  );

  const handleAccetp = () => {
    if (selectedChannel?.length === 0 || !selectedChannel) {
      dispatch(setPopoverChannel(true));
      return;
    }
    handleAcceptConversation();
  };

  return (
    <Center>
      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <ModalBackdrop />
        <ModalContent height={300}>
          <ModalHeader>
            <Heading size="lg">{translate('messages.initConversation')}</Heading>
          </ModalHeader>
          <ModalBody>
            <VStack space="sm">
              <Text>{translate('messages.toCreateConversationSelectChannel')}</Text>
              <PopoverChannels channelWhatsapp={channelWhatsapp} />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack alignItems="center" space="sm">
              <MainCta
                variant="outline"
                action="secondary"
                labelId="actions.cancel"
                onPress={() => {
                  setShowModal(false);
                }}
              />

              <MainCta
                loading={loading}
                disabled={loading}
                labelId="actions.create"
                onPress={handleAccetp}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  );
};

export default memo(ModalInitConver);
