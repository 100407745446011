import { createSlice } from '@reduxjs/toolkit';
import {
  clearLabels,
  deleteLabel,
  setLabels,
  setNewLabel,
  updateTagNameLabelById
} from '@/redux/actionTypes';
import { LabelState } from './interfaces';

const initialState: LabelState = {
  labels: [],
  loading: false,
  error: ''
};

const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setLabels, (state, action) => {
      state.labels = action.payload;
    });
    builder.addCase(setNewLabel, (state, action) => {
      state.labels = [...state.labels, action.payload];
    });
    builder.addCase(deleteLabel, (state, action) => {
      state.labels = state.labels.filter((label) => label.id !== action.payload.id);
    });
    builder.addCase(updateTagNameLabelById, (state, action) => {
      state.labels = state.labels.map((label) => {
        if (label.id === action.payload.id) {
          return { ...label, tag_name: action.payload.tag_name };
        }
        return label;
      });
    });
    builder.addCase(clearLabels, (state) => {
      state.labels = [];
    });
  }
});

export const {} = labelsSlice.actions;

export default labelsSlice.reducer;
