import { createSlice } from '@reduxjs/toolkit';
import { Workflow } from '@/components/automatizations/interface';
import { AutomatizationState } from './interface';
import {
  deleteWorkflowOfState,
  setCreatingWorkflow,
  setCustomPropietyOfWorkflow,
  setNewWorkflow,
  setWorkflows
} from '../../actionTypes';

const initialState: AutomatizationState = {
  workflows: {},
  newWorkflows: {} as Workflow,
  rangeDate: [],
  gettingWorkflows: false,
  idStringWorkflow: ''
};

const automatizationSlice = createSlice({
  name: 'automatization',
  initialState,
  reducers: {
    setMoreConditions: (state, action) => {
      if (state.newWorkflows?.conditions === undefined) return;
      const existingCondition = state.newWorkflows?.conditions?.find(
        (condition) => condition?.property === action.payload.property
      );
      if (!existingCondition) {
        state.newWorkflows = {
          ...state.newWorkflows,
          conditions: [...state.newWorkflows.conditions, action.payload]
        };
      } else {
        const stateOutChannels = state.newWorkflows.conditions.filter(
          (condition) => condition?.property !== action.payload.property
        );
        state.newWorkflows = {
          ...state.newWorkflows,
          conditions: [...stateOutChannels, action.payload]
        };
      }
    },
    setDatesRanges: (state, action) => {
      state.rangeDate = action.payload;
    },
    setRangeDate: (state, action) => {
      state.rangeDate = [...state.rangeDate, action.payload];
    },
    deleteRangeDate: (state, action) => {
      state.rangeDate = state.rangeDate.filter((_, index) => index !== action.payload);
    },
    clearAllRangeDate: (state) => {
      state.rangeDate = [];
    },
    setGettingWorkflows: (state, action) => {
      state.gettingWorkflows = action.payload;
    },
    setIdStringWorkflow: (state, action) => {
      state.idStringWorkflow = action.payload;
    },
    updateWorkflow: (state, action) => {
      state.workflows = {
        ...state.workflows,
        [action.payload.idString]: state.workflows[action.payload.idString].map((workflow) =>
          workflow._id === action.payload.workflow._id ? action.payload.workflow : workflow
        )
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setNewWorkflow, (state, action) => {
      state.workflows = {
        ...state.workflows,
        [action.payload.idString]: [
          ...state.workflows[action.payload.idString],
          action.payload.workflow
        ]
      };
    });
    builder.addCase(setWorkflows, (state, action) => {
      state.workflows = {
        ...state.workflows,
        [action.payload.idString]: action.payload.workflows
      };
    });
    builder.addCase(setCreatingWorkflow, (state, action) => {
      state.newWorkflows = action.payload;
    });
    builder.addCase(setCustomPropietyOfWorkflow, (state, action) => {
      state.newWorkflows = {
        ...state.newWorkflows,
        [action.payload.propiety]: action.payload.value
      };
    });
    builder.addCase(deleteWorkflowOfState, (state, action) => {
      state.workflows = {
        ...state.workflows,
        [action.payload.idString]: state.workflows[action.payload.idString].filter(
          (workflow) => workflow._id !== action.payload.id
        )
      };
    });
  }
});

export const {
  setMoreConditions,
  deleteRangeDate,
  setRangeDate,
  clearAllRangeDate,
  setGettingWorkflows,
  setIdStringWorkflow,
  setDatesRanges,
  updateWorkflow
} = automatizationSlice.actions;

export default automatizationSlice.reducer;
