import React, { View, type ViewProps } from 'react-native';
import { colorsProps, useThemeColor } from '@/hooks/useTheme';

export type ThemedViewProps = ViewProps & {
  lightColor?: string;
  darkColor?: string;
  bgColor?: colorsProps;
  border?: colorsProps;
};

export function TVView({
  style,
  lightColor,
  darkColor,
  bgColor,
  border,
  ...otherProps
}: ThemedViewProps) {
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, bgColor);
  const borderColor = useThemeColor({ light: lightColor, dark: darkColor }, border);

  return (
    <View
      style={[
        {
          display: 'flex',
          flexDirection: 'column',
          backgroundColor,
          borderColor
        },
        style
      ]}
      {...otherProps}
    />
  );
}
