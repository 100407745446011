import React, { memo, useEffect, useMemo } from 'react';
import {
  LinkingOptions,
  NavigationContainer,
  createNavigationContainerRef
} from '@react-navigation/native';
import { enableScreens } from 'react-native-screens';
import { StatusBar } from 'react-native';
import * as Linking from 'expo-linking';
import LinkProvider from '@/providers/LinkProvider';
import NetInfo from '@react-native-community/netinfo';
import { setSocket } from '@/redux/actionTypes';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';

import PrivateStack from './PrivateStack';
import PublicStack from './PublicStack';
import { RouteSettings, Routes } from './routes';
import { IS_WEB_PC, getClientsFilters, verifyIsNotNativeDevice } from '@/utils/functions';
import { listCurrency, listLanguage } from '@/redux/slices/auth/requests';

import { filterTypes } from '@/redux/slices/filters/interfaces';
import { NavigationParams, RootStackParams } from './interfaces';
import WaitingComponent from '@/components/waiting';
import { setDialogConnect } from '@/redux/slices/theme';
import { TView } from '@/components/themed';

export enum ETypesSections {
  chat = 'chat',
  clients = 'clients'
}

// enableScreens() optimizes the native stack navigator performance
// It improves memory usage and performance by using native navigation components
// This function should be called before rendering any navigation components
enableScreens();

type MyPathConfig = LinkingOptions<{}> & { screens?: RootStackParams };

export const navigationRef = createNavigationContainerRef<NavigationParams>();

function MainRouter() {
  const isSignedIn = useAppSelector((state: RootState) => state.user.isSignedIn);
  const expiredToken = useAppSelector((state: RootState) => state.user.expiredToken);
  const userData = useAppSelector((state: RootState) => state.user.user);
  const auth0Token = useAppSelector((state: RootState) => state.user.auth0Token);
  const company = useAppSelector((state: RootState) => state.user.company);
  const filters = useAppSelector((state: RootState) => state.filters.filters);
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);
  const waitingForResponse = useAppSelector(
    (state: RootState) => state.conversations.waitingForResponse
  );
  const dialogConnect = useAppSelector((state: RootState) => state.settings.dialogConnect);

  const [filterRoutes, setFilterRoutes] = React.useState([]);
  const [linking, setLinking] = React.useState<MyPathConfig>();
  const [rutasFiltrosClients, setRutasFiltrosClients] = React.useState({});
  const [rutasFiltrosChat, setRutasFiltrosChat] = React.useState({});
  const [navigationReady, setNavigationReady] = React.useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (dialogConnect) {
      dispatch(setDialogConnect(false));
    }
  }, [dialogConnect]);

  useEffect(() => {
    const filterToGetClient = IS_WEB_PC
      ? filters
      : {
          assignee: filters?.assignee,
          labels: filters?.labels
        };

    const resultClient = getClientsFilters(filterToGetClient, filterTypes?.clients);
    const resultChat = getClientsFilters(filterToGetClient, filterTypes?.chat);
    setFilterRoutes([...resultClient, ...resultChat]);
  }, [filters]);

  useEffect(() => {
    const rutasFiltrosClients = {};
    const rutasFiltrosChat = {};

    filterRoutes?.forEach((filtro) => {
      const nombreFiltro = filtro?.idString;
      if (filtro?.types === ETypesSections?.clients) {
        rutasFiltrosClients[nombreFiltro] = {
          path: nombreFiltro,
          screens: {
            conversation: {
              path: ':id?'
            },
            fallback: {
              path: '/'
            },
            info: {
              path: 'info/:id?'
            },
            add: {
              path: 'add/:id?'
            },
            associationcontacts: {
              path: 'associationcontacts/:id?',
              screens: {
                search: {
                  path: 'searchcontact/:id_fragment?'
                },
                create: {
                  path: 'createcontact/:id_fragment?'
                }
              }
            },
            associationbooking: {
              path: 'associationbooking/:id?',
              screens: {
                search: {
                  path: 'searchbooking/:id_fragment?'
                },
                create: {
                  path: 'createbooking/:id_fragment?'
                }
              }
            }
          }
        };
      } else {
        rutasFiltrosChat[nombreFiltro] = {
          path: nombreFiltro,
          screens: {
            conversation: {
              path: ':id?'
            },
            info: {
              path: 'info/:id?'
            },
            add: {
              path: 'add/:id?'
            }
          }
        };
      }
    });
    setRutasFiltrosClients(rutasFiltrosClients);
    setRutasFiltrosChat(rutasFiltrosChat);
  }, [filterRoutes]);

  useEffect(() => {
    setLinking({
      prefixes: [Linking.createURL('/'), 'pxsol.chat://'],
      config: {
        screens: {
          team: {
            path: ':team?',
            screens: {
              clients: {
                path: 'clients',
                screens: { ...rutasFiltrosClients }
              },
              chat: {
                path: 'chat',
                screens: {
                  ...rutasFiltrosChat,
                  newgroup: {
                    path: 'newgroup'
                  },
                  newconversation: {
                    path: 'newconversation'
                  }
                }
              },
              settings: {
                path: 'settings',
                screens: {
                  settingsprofile: {
                    path: 'settingsprofile'
                  },
                  settingsnotifications: {
                    path: 'settingsnotifications'
                  },
                  settingschat: {
                    path: 'settingschat'
                  },
                  help: {
                    path: 'help'
                  },
                  settingscustomizations: {
                    path: 'settingscustomizations'
                  },
                  settingsChangelog: {
                    path: 'settingsChangelog'
                  },
                  settingsChannels: {
                    path: 'settingsChannels'
                  }
                }
              }
            }
          },
          login: {
            path: 'login'
          },
          intro: {
            path: 'intro'
          },
          NotFound: '*' // https://reactnavigation.org/docs/configuring-links/#notfound
        }
      }
    });
  }, [rutasFiltrosClients, rutasFiltrosChat]);

  const linkingNative = useMemo(
    () => ({
      prefixes: [Linking.createURL('/'), 'pxsol.chat://'], // You can also add 'https://myapp.com' if you have a domain you're linking from.
      config: {
        screens: {
          [Routes.Team]: {
            path: ':team',
            parse: {
              team: (team) => `${team}` // Convert id to string.
            },
            screens: {
              [Routes.clientes]: {
                path: 'clients',
                screens: { ...rutasFiltrosClients }
              },
              [Routes.Chat]: {
                path: 'chat',
                screens: {
                  ...rutasFiltrosChat,
                  [Routes.NewGroup]: 'new-group',
                  [Routes.NewConversation]: 'new-conversation'
                }
              },
              [Routes.Settings]: {
                path: 'settings',
                screens: {
                  [RouteSettings.settingsChat]: 'settings-profile',
                  [RouteSettings.settingsNotifications]: 'settings-notifications',
                  [RouteSettings.help]: 'help',
                  [RouteSettings.settingsCustomizations]: 'settings-customizations',
                  [RouteSettings.settingsChangelog]: 'settings-changelog'
                }
              }
            }
          },
          [Routes.ConversationStack]: 'conversation-stack',
          [Routes.NewConversation]: 'new-conversation',
          [Routes.NewGroup]: {
            path: 'new-group/:group',
            parse: {
              group: (group) => group === 'true' // Convert the group to a boolean.
            }
          }
        }
      }
    }),
    [rutasFiltrosClients, rutasFiltrosChat]
  );

  const shouldRenderPrivateStack = useMemo(
    () => isSignedIn && !expiredToken && userData && company?.IDEmpresas,
    [isSignedIn, expiredToken, userData, company]
  );

  useEffect(() => {
    const handleConnectivityChange = (state) => {
      dispatch(setSocket(state.isConnected));
    };
    const unsubscribe = NetInfo.addEventListener(handleConnectivityChange);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    shouldRenderPrivateStack && Promise.all([dispatch(listCurrency()), dispatch(listLanguage())]);
  }, [shouldRenderPrivateStack]);

  return linking ? (
    <TView
      bgColor="background"
      style={{
        flex: 1
      }}
    >
      <StatusBar
        animated
        // backgroundColor={colors.background}
        barStyle={themeMode === 'dark' ? 'light-content' : 'dark-content'}
        hidden={false}
      />
      <NavigationContainer
        ref={navigationRef}
        linking={verifyIsNotNativeDevice() ? linking : linkingNative}
        onReady={() => setNavigationReady(true)}
      >
        {shouldRenderPrivateStack ? (
          <LinkProvider navigationReady={navigationReady} navigationRef={navigationRef}>
            {waitingForResponse && (
              <TView
                style={{
                  position: 'absolute',
                  zIndex: 9999,
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%'
                }}
              >
                <WaitingComponent message="loadingData" />
              </TView>
            )}
            <PrivateStack />
          </LinkProvider>
        ) : (
          <PublicStack />
        )}
      </NavigationContainer>
    </TView>
  ) : (
    <WaitingComponent message="waiting" />
  );
}

export default memo(MainRouter);
