import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import FormatDate from '../../../utils/date/formatDate';
import { PropsHeaderConversationCard } from './Interfaces';
import { RootState, useAppSelector } from '@/redux/store';
import { THView, TText } from '@/components/themed';
import { Participant } from '@/redux/slices/participants/interfaces';

function HeaderConversationCard({ createdAt, conversationId }: PropsHeaderConversationCard) {
  const participants = useAppSelector((state: RootState) => state.participants.participants);
  const userId = useAppSelector((state: RootState) => state.user.user?.id);

  const conversation = useAppSelector(
    (state: RootState) => state.conversations.conversations?.[conversationId]
  );
  const unread = useAppSelector(
    (state: RootState) => state.conversations?.unread?.[conversationId] ?? 0
  );

  const [contacto, setContacto] = useState<string | undefined>('');

  useEffect(() => {
    if (conversation?.group?.status && conversation?.group?.name?.length !== 0) {
      setContacto(conversation?.group.name);
    } else {
      participants[conversationId]?.forEach((participant: Participant) => {
        // cast participant.id and user.id to strings
        const participantId = participant?.userId?.id_user?.toString();
        const Id = userId?.toString();
        if (participantId !== Id) {
          setContacto(participant?.userId?.name);
          // setIdContactos(participant.id);
        }
      });
    }
  }, [conversation?.group?.name, participants[conversationId]]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        containerHeader: {
          position: 'relative',
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '98%'
        },
        containerContact: {
          flexBasis: '60%',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 4
        },
        containerDate: {
          flexBasis: '40%',
          alignItems: 'center',
          justifyContent: 'flex-end',
          textAlign: 'right',
          gap: 4
        }
      }),
    []
  );

  return (
    <THView style={styles.containerHeader}>
      <THView style={styles.containerContact}>
        <TText
          size="md"
          style={{ fontWeight: unread !== 0 ? 'bold' : 'normal' }}
          numberOfLines={1}
          lineBreakMode="clip"
        >
          {contacto}
        </TText>
      </THView>

      <TText
        size="sm"
        color={unread !== 0 ? 'primary' : 'secondary'}
        numberOfLines={1}
        style={styles.containerDate}
        lineBreakMode="clip"
      >
        {FormatDate(createdAt)}
      </TText>
    </THView>
  );
}

export default React.memo(HeaderConversationCard);
