import React, { memo, useEffect, useMemo, useState } from 'react';
import AcordionCustom from '@/atoms/AcordionCustom';
import ItemDrawerSettings from '@/atoms/ItemDrawerSettings';
import { TView } from '@/components/themed';
import { Filters } from '@/redux/slices/filters/interfaces';
import { RootState, useAppSelector } from '@/redux/store';
import { StyleSheet } from 'react-native';

const ContainerAgentFilters = ({
  activeRoute,
  collapsed,
  handlePressedFilter,
  routeName
}: {
  activeRoute: string;
  collapsed: boolean;
  handlePressedFilter: (filter: Filters) => void;
  routeName: string;
}) => {
  const filters = useAppSelector((state: RootState) => state.filters.filters);
  const [filtrosAgentes, setFiltrosAgentes] = useState<Filters[]>([]);

  useEffect(() => {
    const filtrosAgen = filters?.agents?.filter((filter) => filter.types.includes(routeName));

    setFiltrosAgentes(filtrosAgen);
  }, [filters]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        childrenAcordion: {
          minWidth: 150,
          flexGrow: 1,
          width: '100%'
        }
      }),
    []
  );

  return (
    <AcordionCustom collapsed={collapsed} idString="agents" defaultOpen={false}>
      <TView bgColor="transparent" style={styles.childrenAcordion}>
        {filtrosAgentes?.length > 0 &&
          filtrosAgentes.map((item) => (
            <ItemDrawerSettings
              key={item?.id}
              onPress={() => handlePressedFilter(item)}
              svg
              style={{ routeName: item?.idString, activeRoute }}
              idString={item?.name}
              icon={item?.icon}
              color="text"
              name={item?.name}
              translate={false}
              collapsed={collapsed}
            />
          ))}
      </TView>
    </AcordionCustom>
  );
};

export default memo(ContainerAgentFilters, (prevProps, nextProps) => {
  return (
    prevProps.collapsed === nextProps.collapsed &&
    prevProps.activeRoute === nextProps.activeRoute &&
    prevProps.handlePressedFilter === nextProps.handlePressedFilter &&
    prevProps.routeName === nextProps.routeName
  );
});
