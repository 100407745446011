import React, { memo, useMemo } from 'react';
import AcordionCustom from '@/atoms/AcordionCustom';
import { TText, TView } from '@/components/themed';
import { Filters } from '@/redux/slices/filters/interfaces';
import { RootState, useAppSelector } from '@/redux/store';
import getIconsToFilters from '@/assets/icons';
import translate from '@/helpers/traslations';
import ItemDrawerSettings from '@/atoms/ItemDrawerSettings';
import ButtonNewLabel from '@/components/partials/headerConversation/ButtonNewLabel';
import { StyleSheet } from 'react-native';

const ContainerLabelsFilters = ({
  collapsed,
  handlePressedFilter,
  activeRoute
}: {
  collapsed: boolean;
  handlePressedFilter: (item: Filters) => void;
  activeRoute: string;
}) => {
  const filters = useAppSelector((state: RootState) => state.filters.filters);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        childrenAcordion: {
          minWidth: 150,
          flexGrow: 1,
          width: '100%'
        }
      }),
    []
  );

  return (
    <AcordionCustom collapsed={collapsed} idString="labels" defaultOpen>
      <TView bgColor="transparent" style={styles.childrenAcordion}>
        {filters?.labels?.length > 0 ? (
          filters?.labels?.map((item: Filters) => (
            <ItemDrawerSettings
              key={item?.id}
              id={item?.id}
              onPress={() => handlePressedFilter(item)}
              style={{ routeName: item?.idString, activeRoute }}
              idString={item?.name}
              icon={getIconsToFilters({ idString: item?.icon })}
              name={item?.name}
              color="secondary"
              translate={false}
              collapsed={collapsed}
              dosVertical
            />
          ))
        ) : (
          <TText
            style={{
              paddingVertical: 2,
              paddingHorizontal: 4
            }}
            color="secondary"
          >
            {translate('messages.noLabels')}
          </TText>
        )}

        <ButtonNewLabel />
      </TView>
    </AcordionCustom>
  );
};

export default memo(ContainerLabelsFilters, (prevProps, nextProps) => {
  return (
    prevProps.collapsed === nextProps.collapsed &&
    prevProps.activeRoute === nextProps.activeRoute &&
    prevProps.handlePressedFilter === nextProps.handlePressedFilter
  );
});
