export enum TriggerWorkflow {
  new_incoming_message = 'new_incoming_message'
}

export enum ConditionsWorkflow {
  ex = '$ex', // Exists
  nex = '$nex', // Not Exists
  eq = '$eq', // Equal
  gt = '$gt', // Greater Than
  gte = '$gte', // Greater Than or Equal
  lt = '$lt', // Less Than
  lte = '$lte', // Less Than or Equal
  ne = '$ne', // Not Equal
  in = '$in', // In
  nin = '$nin', // Not In
  btw = '$btw' // Between
}

export enum TypeActions {
  sendResponse = 'send_response'
}

export enum ActionsParametersResponseTypes {
  template = 'template',
  apollo = 'apollo_ai'
}

export enum TypeConditions {
  objectProperty = 'object_property'
}

export enum PropertyConditions {
  apolloAutoResponseEnabled = 'apolloAutoResponseEnabled',
  platform = 'platform',
  isFirstMessage = 'is_first_message',
  createAt = 'createdAt',
  idChannel = 'id_channel'
}

export enum PropertyType {
  date = 'date',
  month = 'month',
  day = 'day',
  time = 'time',
  number = 'number',
  day_of_week = 'day_of_week',
  string = 'string'
}
