import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';

import MainCta from './MainCta';
import { IconClose } from '@/assets/icons/Icons';
import { THView, TText, TView } from '@/components/themed';

function HeaderBottomSheet({ onClose, title = null }) {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        secondary: {
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          alignItems: 'center',
          flex: 1,
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          marginLeft: 30
        },
        back: {
          display: 'flex',
          height: 4,
          width: 64,
          alignSelf: 'center',
          borderRadius: 5
          // backgroundColor: colors.secondary
        }
      }),
    []
  );

  return (
    <THView
      border="border"
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderBottomWidth: 1
      }}
    >
      {title && (
        <TView
          style={{
            paddingHorizontal: 20
          }}
        >
          <TText
            style={{
              fontWeight: 'bold'
            }}
          >
            {title}
          </TText>
        </TView>
      )}
      <TView style={styles.secondary}>
        <TView style={styles.back}></TView>
      </TView>
      <MainCta onPress={onClose} icon={IconClose} variant="outline" />
    </THView>
  );
}

export default React.memo(HeaderBottomSheet);
