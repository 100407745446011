import React, { useEffect, useMemo } from 'react';
import FormAutomatization from '@/components/automatizations/FormAutomatization';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import {
  ActionsParametersResponseTypes,
  TriggerWorkflow,
  TypeActions
} from '@/types/enums/workflows';
import { setCreatingWorkflow } from '@/redux/actionTypes';
import { setDatesRanges } from '@/redux/slices/automatization';
import { TView } from '@/components/themed';

const AutomatizationTemplate = (routeAutomatization) => {
  const dispatch = useAppDispatch();
  const newWorkflow = useAppSelector((state: RootState) => state.automatization.newWorkflows);
  const companyId = useAppSelector((state: RootState) => state.user?.company?.IDEmpresas);

  const myWorkflow = useMemo(() => {
    return {
      company_id: companyId,
      name: '',
      description: '',
      trigger: TriggerWorkflow.new_incoming_message,
      priority: 1,
      object_type: 'message',
      enabled: false,
      conditions: [],
      actions: [],
      team_id: Number(routeAutomatization?.route?.params?.team)
    };
  }, [companyId, routeAutomatization?.route?.params?.team]);

  useEffect(() => {
    if (!newWorkflow?.conditions) {
      const templateWorkflow = {
        ...myWorkflow,
        actions: [
          {
            type: TypeActions.sendResponse,
            parameters: {
              response_type: ActionsParametersResponseTypes.template,
              id: ''
            }
          }
        ]
      };
      dispatch(setCreatingWorkflow(templateWorkflow));
      dispatch(setDatesRanges([]));
    }
  }, []);

  return (
    <TView
      style={{
        width: '95%',
        paddingVertical: 4,
        alignSelf: 'center',
        height: '100%'
      }}
    >
      <FormAutomatization {...routeAutomatization} />
    </TView>
  );
};

export default React.memo(AutomatizationTemplate);
