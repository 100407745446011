import React, { memo } from 'react';
import ItemSettingChannel from '@/components/partials/settingsChannels/ItemSettingChannel';
import { ThemedScrollView } from '@/components/themed';
import { RootState, useAppSelector } from '@/redux/store';
import { Channel } from '@/types/typesRedux/interfaces';

const SettingsChannels = () => {
  const channels = useAppSelector((state: RootState) => state.user.channels);

  return (
    <ThemedScrollView
      bgColor="backgroundSecondary"
      style={{
        flex: 1,
        padding: 16
      }}
    >
      {channels.map((channel: Channel) => (
        <ItemSettingChannel key={channel.id} channel={channel} />
      ))}
    </ThemedScrollView>
  );
};

export default memo(SettingsChannels);
