import React, { useCallback, useMemo, useState } from 'react';
import { Linking, StyleSheet } from 'react-native';
import { InputField, Input } from '@gluestack-ui/themed';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';

import MainCta from '@/atoms/MainCta';
import { IconArrowRight } from '@/assets/icons/Icons';
import DialogComponent from '@/components/DialogComponent';
import { verifyIsNotNativeDevice } from '@/utils/functions';
import { InputComponentProps } from './interfaces.d';
import InputSlotRight from '@/components/partials/inputComponent/InputSlotRight';
import InputSlotLeft from '@/components/partials/inputComponent/InputSlotLeft';
import {
  KEY_OMIT_HIGILIGHT,
  LIMIT_OF_CHARACTERS,
  MAX_HEIGHT_INPUT,
  MIN_INPUT_HEIGHT,
  URL_TO_CONNECT_CHANNEL
} from '@/utils/constants';
import translate from '@/helpers/traslations';
import { setLastInputKeyPress } from '../../../redux/slices/theme';
import { TextInputKeyPressEventData } from 'react-native';
import { NativeSyntheticEvent } from 'react-native';
import { TABLET_SCREEN } from '@/utils/globales';
import { THView, TText } from '@/components/themed';
import colors from '../../../../config/colors';
import { useMediaQuery } from '@/hooks/useMediaQuery';

function InputComponent({
  iconSend,
  onChange,
  placeholder,
  iconAdd = false,
  textInputRef,
  handleFormSubmit,
  handlepressEnter,
  value,
  autofocus,
  hasMagic,
  disabled = false,
  multiline = true,
  iconSearch = false,
  background,
  agentId,
  iconIdea,
  handleMagic,
  magicLoading,
  setDataBc,
  inputHeight,
  setHeight = null,
  sx,
  onSelecionChange = null,
  onFocus = null,
  lastKeyPress = null
}: InputComponentProps) {
  const dispatch = useAppDispatch();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogMaxLegngth, setDialogMaxLength] = useState(false);
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);

  const [isMobile] = useMediaQuery([
    {
      maxWidth: TABLET_SCREEN
    }
  ]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          borderRadius: 8,
          height: inputHeight ? inputHeight + 18 : MIN_INPUT_HEIGHT,
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        },
        input: {
          flex: 1,
          height: '100%',
          marginLeft: 10,
          maxHeight: MAX_HEIGHT_INPUT,
          borderRadius: 8,
          color: '$text',
          borderColor: '$transparent'
        }
      }),
    [inputHeight]
  );

  const hiddeIconMagic = useMemo(() => {
    return isMobile && value?.length ? false : hasMagic;
  }, [isMobile, hasMagic, value]);

  const veryfyMaxLength = (e: any) => {
    if (e.nativeEvent.text.length >= LIMIT_OF_CHARACTERS) {
      setDialogMaxLength(true);
    } else {
      onChange(e.nativeEvent.text);
    }
  };

  const onPressIconSend = (e: any) => {
    e.preventDefault();
    handleFormSubmit(e);
    setHeight && setHeight(MIN_INPUT_HEIGHT);
  };

  const sendToEnter = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData & { timeStamp: number }>) => {
      if (e.nativeEvent.key === 'Enter' && lastKeyPress?.split('-')[0] === 'Shift') return;

      if (KEY_OMIT_HIGILIGHT.includes(e.nativeEvent.key)) {
        e.preventDefault();
      }
      if (
        e.nativeEvent.key === 'ArrowDown' ||
        e.nativeEvent.key === 'ArrowUp' ||
        e.nativeEvent.key !== lastKeyPress?.split('-')[0]
      ) {
        const keyUniqueEvent = `${e.nativeEvent.key}-${e.nativeEvent.timeStamp}`;
        dispatch(setLastInputKeyPress(keyUniqueEvent));
      }

      if (e.nativeEvent.key === 'Enter' && handlepressEnter) {
        handlepressEnter(e);
      }
    },
    [lastKeyPress, handlepressEnter]
  );

  const hideDialog = () => setDialogVisible(false);

  const onPressIconTrash = () => {
    onChange('');
    setHeight && setHeight(MIN_INPUT_HEIGHT);
  };

  const handleContentSizeChange = useCallback(
    (event) => {
      const newHeight = event.nativeEvent.contentSize.height;
      const heightDifference = Math.abs(newHeight - inputHeight);
      if (heightDifference > 10 && newHeight < MAX_HEIGHT_INPUT) {
        setHeight(newHeight);
      }
    },
    [inputHeight, setHeight]
  );

  return (
    <THView bgColor={background ?? 'background'} style={styles.container}>
      <Input
        sx={sx}
        paddingVertical="$2"
        style={styles.input}
        variant="outline"
        w="$full"
        borderColor="$transparent"
        alignItems={setHeight ? 'flex-end' : 'center'}
      >
        <InputSlotLeft
          iconSearch={iconSearch}
          iconAdd={iconAdd}
          hasMagic={hiddeIconMagic}
          magicLoading={magicLoading}
          onPressIconMagic={handleMagic}
          setDialogVisible={setDialogVisible}
          agentId={agentId}
        />
        <InputField
          ref={textInputRef}
          autoCorrect
          maxLength={LIMIT_OF_CHARACTERS}
          editable={!disabled}
          autoFocus={!!verifyIsNotNativeDevice() || autofocus}
          onChange={(e) => veryfyMaxLength(e)}
          value={value}
          fontSize="$md"
          multiline={multiline}
          onFocus={onFocus}
          onKeyPress={sendToEnter}
          onEndEditing={(event) => console.log('onEndEditing', event)}
          allowFontScaling
          onSelectionChange={onSelecionChange && onSelecionChange}
          onContentSizeChange={handleContentSizeChange}
          height="$full"
          color={colors[themeMode]?.text}
          // minHeight={inputHeight ? MIN_INPUT_HEIGHT : 40}
          alignItems="center"
          display="flex"
          placeholder={
            magicLoading
              ? 'Estamos generando una respuesta, aguarda un momento, por favor...'
              : placeholder
          }
        />
        <InputSlotRight
          iconIdea={iconIdea}
          onPressIconTrash={onPressIconTrash}
          value={value}
          iconSend={iconSend}
          onPressIconSend={onPressIconSend}
          setDataBc={setDataBc}
        />
      </Input>
      <DialogComponent
        dialogVisible={dialogVisible}
        setDialogVisible={setDialogVisible}
        idStringTitle="yourTeamNotHaveAnAssistant"
        idStringDescription="connectAnAssistantToYourTeam"
        Cta={
          <MainCta
            action="secondary"
            sx={{ backgroundColor: value?.length > 0 ? '$pxsol' : 'transparent' }}
            labelId="connect"
            icon={IconArrowRight}
            iconSize="md"
            onPress={() => {
              //     // report the event to analytics
              Linking.openURL(URL_TO_CONNECT_CHANNEL);
              hideDialog();
              if (typeof window.gtag === 'function') {
                window.gtag('event', 'connectApollo', {
                  event_category: 'connectApollo',
                  event_label: 'connectApolloToTeamFromDialog'
                });
              }
            }}
          />
        }
      />
      <DialogComponent
        dialogVisible={dialogMaxLegngth}
        setDialogVisible={setDialogMaxLength}
        buttonCancel={false}
        children={
          <TText>{`${translate('messages.maxCharactersDescription')} ${LIMIT_OF_CHARACTERS}`}</TText>
        }
        idStringTitle="messages.maxCharacters"
        idStringDescription="messages.limitReached"
        Cta={
          <MainCta action="secondary" labelId="accept" onPress={() => setDialogMaxLength(false)} />
        }
      />
    </THView>
  );
}

export default React.memo(InputComponent);
