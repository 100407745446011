import {
  FormControlError,
  FormControlErrorText,
  FormControlHelper,
  FormControlHelperText,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputField,
  FormControlErrorIcon,
  FormControl
} from '@gluestack-ui/themed';
import React from 'react';
import { AlertCircle } from '@/assets/icons/Icons';
import { InputTextFormProps } from './interfaces';
import { TView } from '@/components/themed';

const InputTextForm = ({
  label,
  type = 'text',
  helperText = '',
  placeholder,
  errorText,
  isDisabled = false,
  isInvalid = false,
  isReadOnly = false,
  isRequired = false,
  onChange,
  value,
  bgColor
}: InputTextFormProps) => {
  return (
    <TView style={{ width: '100%' }}>
      <FormControl
        size="md"
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        isReadOnly={isReadOnly}
        isRequired={isRequired}
      >
        <FormControlLabel mb="$1" gap="$1">
          <FormControlLabelText>{label}</FormControlLabelText>
        </FormControlLabel>
        <Input bg={bgColor}>
          <InputField
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            fontSize="$md"
          />
        </Input>
        <FormControlHelper>
          <FormControlHelperText>{helperText}</FormControlHelperText>
        </FormControlHelper>
        <FormControlError>
          <FormControlErrorIcon as={AlertCircle} />
          <FormControlErrorText>{errorText}</FormControlErrorText>
        </FormControlError>
      </FormControl>
    </TView>
  );
};

export default React.memo(InputTextForm);
