import React, { memo } from 'react';
import { CheckIcon, Checkbox, CheckboxIcon, CheckboxIndicator } from '@gluestack-ui/themed';
import { THView, TText } from '../themed';
// import MaterialCommunity from '../Icons/MaterialCommunity';

const CustomCheckbox = ({
  isDisabled,
  isChecked,
  value,
  keyCheck,
  label,
  middleIcon
}: {
  isDisabled?: boolean;
  isChecked: boolean;
  value: string;
  keyCheck: string | number;
  label: string;
  middleIcon: React.ReactNode;
}) => {
  return (
    <Checkbox
      aria-label="checkbox"
      key={keyCheck}
      value={value}
      size="md"
      w="100%"
      isDisabled={isDisabled}
      isChecked={isChecked}
    >
      <CheckboxIndicator mr="$2">
        <CheckboxIcon as={CheckIcon} />
      </CheckboxIndicator>
      <THView
        bgColor="transparent"
        style={{
          gap: 4,
          flex: 1
        }}
      >
        {middleIcon}
        <TText
          numberOfLines={1}
          ellipsizeMode="clip"
          style={{
            textTransform: 'capitalize',
            width: '100%'
          }}
        >
          {label}
        </TText>
      </THView>
    </Checkbox>
  );
};

export default memo(CustomCheckbox);
