import React from 'react';
import { StyleSheet, Platform } from 'react-native';

import { Image } from 'expo-image';
import MainCta from '@/atoms/MainCta';
import { AlertCircle, IconAdd, IconClose } from '@/assets/icons/Icons';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { ScrollView, View, Box, HStack } from '@gluestack-ui/themed';
import ImageActivity from '../../../atoms/ImageActivity';
import { RootState, useAppSelector } from '../../../redux/store';
import { useConversation } from '@/providers/ConversationProvider';
import ImageEditor from './ImageEditor';

interface PreviewFilesComponentProps {
  previewFiles: any;
  setPreviewFiles: (value: any) => void;
  pickImage: () => void;
}

function PreviewImages({ previewFiles, setPreviewFiles, pickImage }: PreviewFilesComponentProps) {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: 5,
      gap: 5,
      borderRadius: 10,
      marginBottom: 10
    },
    imageContainer: {
      position: 'relative',
      width: 200,
      height: 200
    },
    image: {
      width: 200,
      height: 200,
      borderRadius: 10
    },
    add: {
      width: 200,
      height: 200,
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    containerImage: {
      position: 'relative',

      borderRadius: 10
    },
    iconError: {
      position: 'absolute',
      bottom: 0,
      right: 0
    }
  });

  return (
    <ScrollView
      horizontal
      bg="$background"
      contentContainerStyle={styles.container}
      paddingHorizontal="$4"
      showsHorizontalScrollIndicator={Platform.OS === 'web'}
    >
      {previewFiles?.map((preview) => (
        <ImageEditor
          key={JSON.stringify(preview?.uri)}
          image={preview}
          previewFiles={previewFiles}
          setPreviewFiles={setPreviewFiles}
        />
      ))}
      <Box style={styles.add}>
        <MainCta icon={IconAdd} onPress={pickImage} />
      </Box>
    </ScrollView>
  );
}

export default React.memo(PreviewImages);
