import React, { useMemo } from 'react';

import { StyleSheet } from 'react-native';
import { getColor, getIcons } from '@/utils/functions';
import { Status } from '@/types/index';
import translate from '@/helpers/traslations';
import { HStack, Text } from '@gluestack-ui/themed';
import CustomTooltip from '../components/tooltip/CustomTooltip';
import MaterialCommunity from '../components/Icons/MaterialCommunity';
import FormatDate from '../utils/date/formatDate';

function IconDateComponent({
  description,
  position,
  status,
  createdAt
}: {
  position?: 'left' | 'right';
  status?: Status;
  createdAt?: string;
  description?: string;
}) {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        dateAndIcon: {
          pl: 1.5,
          pb: 0.5,
          pr: 1.5,
          gap: 2,
          paddingHorizontal: 4
        }
      }),
    []
  );

  return (
    <HStack
      justifyContent="flex-start"
      alignItems={position === 'left' ? 'flex-start' : 'flex-end'}
      style={styles.dateAndIcon}
    >
      {position === 'right' && (
        <CustomTooltip
          title={
            description
              ? translate(`messageError.${description}`)
              : translate(`statusMessages.${status}`)
          }
        >
          <MaterialCommunity
            icon={status === 'open' ? getIcons('read') : getIcons(status)}
            size="xs"
            color={getColor(status)}
          />
        </CustomTooltip>
      )}
      <Text
        size="sm"
        color="$secondary"
        style={{
          fontSize: 12
        }}
      >
        {FormatDate(createdAt)}
      </Text>
    </HStack>
  );
}

export default React.memo(IconDateComponent);
