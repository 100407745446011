import React, { useEffect, useState, useCallback, useRef, useMemo, memo } from 'react';
import { VirtualizedList } from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';
import { RootStackParams } from '@/routers/interfaces';
import { ASIGNADOS_A_MI } from '@/utils/filters';
import { Spinner } from '@gluestack-ui/themed';
import ConversationCard from './ConversationCard';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store';
import { getAllConversationsByFilter, setLoading } from '../../../redux/slices/conversation';
import EmptyConverationList from '../../emptystates/EmptyConverationList';
import { Filters } from '@/redux/slices/filters/interfaces';
import { outConversationToFilter, setIdConversation } from '@/redux/actionTypes';
import TopFlatlistCard from '../../../atoms/TopFlatlistCard';
import { verifyIsNotNativeDevice } from '@/utils/functions';
import { StyleSheet } from 'react-native';
import { RefreshControl } from '@gluestack-ui/themed';

function ConversationList({ filtro, teamId }: { filtro: Filters; teamId: string }) {
  const dispatch = useAppDispatch();
  const userID = useAppSelector((state: RootState) => state.user.user?.id);

  const filterConversations = useAppSelector(
    (state: RootState) =>
      state.conversations.filterConversations[teamId]?.[filtro?.types]?.[filtro?.idString] ?? []
  );

  const loading = useAppSelector((state: RootState) => state.conversations.loading);
  const outConversationToFilterQueue = useAppSelector(
    (state) => state.conversations.outConversationToFilterQueue
  );
  const conversationListRef = useRef(null);
  const pagesOfFilters = useAppSelector((state: RootState) => state.conversations.pagesOfFilters);
  const [gettingMore, setGettingMore] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [progress, setProgress] = useState(0);

  const [getMoreConvers, setGetMoreConvers] = useState(
    !!(
      pagesOfFilters[filtro?.idString]?.nextPage !== null ||
      pagesOfFilters[filtro?.idString]?.totalPages > 1
    )
  );

  const route = useRoute<RouteProp<RootStackParams>>();

  const scrollTimeoutRef = useRef(null);

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        flex: 1,
        height: '100%',
        width: '100%',
        paddingHorizontal: 4,
        marginTop: 4
      }
    });
  }, []);

  const onScrollBeginDrag = () => {
    setLoadMore(true);
    setProgress(0);

    // Llenar la barra de progreso gradualmente
    scrollTimeoutRef.current = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 1) {
          clearInterval(scrollTimeoutRef.current);
          handlePressedFilter(filtro);
          setLoadMore(false);
          return 1;
        }
        return oldProgress + 0.1; // Aumentar el progreso
      });
    }, 100);
  };

  const onScrollEndDrag = () => {
    // Si el usuario suelta el scroll antes de 1 segundo, cancelar el temporizador
    if (scrollTimeoutRef.current) {
      setLoadMore(false);
      clearTimeout(scrollTimeoutRef.current);
      scrollTimeoutRef.current = null;
    }
  };

  const conversationsArray = useMemo(() => {
    if (filterConversations) {
      if (filterConversations?.conversations?.length > 0) {
        return filterConversations?.conversations.map((c) => c.id);
      } else {
        return [];
      }
    }
    return [];
  }, [filterConversations]);

  const getMore = useCallback(async () => {
    if (gettingMore) return;
    let query = { ...filtro?.query };
    if (
      pagesOfFilters[filtro?.idString] &&
      pagesOfFilters[filtro?.idString]?.totalPages > 1 &&
      pagesOfFilters[filtro?.idString]?.page <= pagesOfFilters[filtro?.idString]?.totalPages &&
      pagesOfFilters[filtro?.idString]?.nextPage &&
      getMoreConvers
    ) {
      setGettingMore(true);
      if (filtro.idString === ASIGNADOS_A_MI) {
        query = {
          ...query,
          fragment: {
            ...query.fragment,
            associations: {
              assignee: [userID]
            }
          },
          page: pagesOfFilters[filtro.idString]?.nextPage,
          id_team: Number(teamId)
        };
      } else {
        query = {
          ...query,
          page: pagesOfFilters[filtro.idString]?.nextPage,
          id_team: Number(teamId)
        };
      }
      if (!gettingMore) {
        dispatch(
          getAllConversationsByFilter({
            query,
            filter: filtro.idString,
            type: filtro.types,
            teamId: teamId
          })
        )
          .then((res: any) => {
            if (res?.nextPages) {
              setGettingMore(false);
              setGetMoreConvers(true);
            } else {
              setGettingMore(false);
              setGetMoreConvers(false);
            }
          })
          .catch((err) => {
            console.error('err gettingMore: ', err);
            setGettingMore(false);
            setGetMoreConvers(false);
          });
      } else {
      }
    }
  }, [filtro, teamId, gettingMore, getMoreConvers, pagesOfFilters]);

  const handlePressedFilter = useCallback((item: Filters) => {
    if (Object.keys(outConversationToFilterQueue).length > 0) {
      const teamQueue = outConversationToFilterQueue[teamId];
      Object.keys(teamQueue).forEach((key) => {
        dispatch(outConversationToFilter(outConversationToFilterQueue[key]));
      });
    }
    dispatch(setLoading(true));

    dispatch(setIdConversation('1'));
    let query = { ...item.query };
    if (item.idString === ASIGNADOS_A_MI) {
      query = {
        ...query,
        fragment: {
          ...query.fragment,
          associations: {
            assignee: [userID]
          }
        },
        page: 1,
        // como pasammos el id del equipo por parametro, lo agregamos al query
        id_team: Number(route?.params?.team)
      };
    } else {
      query = {
        ...query,
        page: 1,
        // como pasammos el id del equipo por parametro, lo agregamos al query
        id_team: Number(route?.params?.team)
      };
    }
    dispatch(
      getAllConversationsByFilter({
        query,
        filter: item.idString,
        type: item.types,
        teamId: route?.params?.team
      })
    ).finally(() => {
      dispatch(setLoading(false));
    });
  }, []);

  const scrollToConversation = useCallback(
    (conversationId: string) => {
      const index = conversationsArray.findIndex((id: string) => id === conversationId);
      if (
        index !== -1 &&
        index > 7 &&
        conversationListRef.current &&
        conversationsArray.length > 6
      ) {
        conversationListRef.current.scrollToIndex({
          animated: true,
          index,
          viewOffset: 50,
          viewPosition: 0.5
        });
      }
    },
    [conversationsArray, conversationListRef]
  );

  useEffect(() => {
    if (route?.params?.id && conversationListRef?.current) {
      scrollToConversation(route?.params?.id);
    }
    return () => {
      clearTimeout(scrollTimeoutRef.current);
    };
  }, [route, conversationListRef]);

  const renderItem = useCallback(
    ({ item }) => {
      return <ConversationCard conversationId={item} />;
    },
    [filtro, route]
  );

  return (
    <VirtualizedList
      data={conversationsArray}
      ref={conversationListRef}
      renderItem={renderItem}
      refreshControl={<RefreshControl refreshing={loading} />}
      keyExtractor={(item, index) => `${item}_${index}`}
      showsVerticalScrollIndicator={false}
      getItemCount={(data) => data.length}
      getItem={(data, index) => data[index]}
      onEndReachedThreshold={0.8}
      onEndReached={getMore}
      windowSize={15}
      initialNumToRender={12}
      style={styles.container}
      onScrollBeginDrag={verifyIsNotNativeDevice() ? null : onScrollBeginDrag}
      onScrollEndDrag={verifyIsNotNativeDevice() ? null : onScrollEndDrag}
      getItemLayout={(data, index) => ({
        length: 89,
        offset: 89 * index,
        index
      })}
      ListHeaderComponent={memo(() => (
        <TopFlatlistCard loadMore={loadMore} progress={progress} />
      ))}
      ListFooterComponent={
        gettingMore
          ? memo(() => <Spinner size="small" style={{ marginVertical: 10 }} color="$pxsol" />)
          : null
      }
      ListEmptyComponent={
        !loading &&
        memo(() => <EmptyConverationList idString="messages.noConversationForThisFilter" />)
      }
      removeClippedSubviews={true}
    />
  );
}

export default React.memo(ConversationList, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
