import { Text, View } from '@gluestack-ui/themed';
import { Asset } from 'expo-asset';
import { Image } from 'expo-image';
import React, { memo, useState } from 'react';
import { StyleSheet } from 'react-native';

const NotFound404 = ({ text }: { text: string }) => {
  const [image, setImage] = useState(null);

  React.useEffect(() => {
    const preloadImage = async () => {
      const [{ localUri }] = await Asset.loadAsync(`${require('@/assets/images/404_error.svg')}`);
      setImage(localUri);
    };
    preloadImage();
  }, []);

  return (
    <View style={styles.container}>
      <Image
        alt="empty-state-1"
        source={image}
        style={{
          width: '90%',
          height: '60%'
        }}
      />
      <Text size="xl" fontWeight="bold">
        {text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default memo(NotFound404);
