import React, { useContext, useRef, useState } from 'react';
import { CollapsedContextType, HandleModalProp } from './interfaces';
import { Modal, ModalBackdrop } from '@gluestack-ui/themed';
import ContentModalLabel from '@/components/modal/ContentModalLabel';
import { useLinkss } from './LinkProvider';

const CollapseSidebar = React.createContext<CollapsedContextType | undefined>(null);

export const useCollapsed = (): CollapsedContextType => {
  const context = useContext(CollapseSidebar);
  if (!context) {
    throw new Error('useCollapsed must be used within a CollapsedProvider');
  }
  return context;
};

const CollpasedProvider = ({ children }: { children: React.ReactNode }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [modalLabel, setModalLabel] = useState(false);
  const [dataModal, setDataModal] = React.useState<HandleModalProp>();
  const { showToast } = useLinkss();

  const ref = useRef(null);

  const hableModalLabel = (data?: HandleModalProp) => {
    setDataModal(data);
    setModalLabel(true);
  };

  const contextValue = React.useMemo(
    () => ({
      hableModalLabel,
      collapsed,
      setCollapsed
    }),
    [hableModalLabel, collapsed, setCollapsed]
  );

  return (
    <CollapseSidebar.Provider value={contextValue}>
      {children}
      <Modal
        isOpen={modalLabel}
        onClose={() => {
          setModalLabel(false);
        }}
        finalFocusRef={ref}
      >
        <ModalBackdrop />
        <ContentModalLabel data={dataModal} showToast={showToast} setModalLabel={setModalLabel} />
      </Modal>
    </CollapseSidebar.Provider>
  );
};

export default React.memo(CollpasedProvider);
