import React, { useState } from 'react';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Pressable,
  RadioGroup,
  RadioLabel,
  PopoverBackdrop,
  Radio,
  Text,
  VStack,
  HStack
} from '@gluestack-ui/themed';
import { ChevronDown, ChevronUp } from '@/assets/icons/Icons';
import TextDisabledUpper from '@/atoms/TextDisabledUpper';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import translate from '@/helpers/traslations';
import { setOrderToFilters } from '@/redux/actionTypes';
import { OrderOptions } from '@/redux/slices/filters/interfaces';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';

const OrderOfFilters = () => {
  const orderOptions = useAppSelector((state: RootState) => state.filters.orderOptions);
  const currentOrder = useAppSelector((state: RootState) => state.filters.currentOrder);
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(currentOrder);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelectOrder = (value: OrderOptions) => {
    setValues(value);
    dispatch(setOrderToFilters(value));
    setIsOpen(false);
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      width="100%"
      maxHeight="100%"
      trigger={(triggerProps) => {
        return (
          <Pressable
            {...triggerProps}
            sx={{
              paddingHorizontal: '$2',
              borderRadius: '$sm'
            }}
          >
            <HStack justifyContent="space-between" width="100%">
              <VStack flexBasis="80%">
                <TextDisabledUpper text={translate('messages.order')} />
                <Text numberOfLines={1} size="xs">
                  {orderOptions?.find((option) => option?.value === values)?.label ??
                    translate('messages.order')}
                </Text>
              </VStack>
              <MaterialCommunity icon={isOpen ? ChevronUp : ChevronDown} size="md" />
            </HStack>
          </Pressable>
        );
      }}
    >
      <PopoverBackdrop />
      <PopoverContent width="100%" bg="$background">
        <PopoverArrow bg="$background" />
        <PopoverBody width="100%" paddingHorizontal="$2">
          <RadioGroup
            width="100%"
            value={values}
            onChange={(value) => handleSelectOrder(value)}
            gap="$2"
          >
            {orderOptions?.map((option, index) => (
              <Radio
                padding="$2"
                borderRadius="$sm"
                bgColor={values === option?.value ? '$backgroundSecondary' : ''}
                width="100%"
                value={option?.value}
                key={index}
              >
                <RadioLabel>{option?.label ?? 'aaa'}</RadioLabel>
              </Radio>
            ))}
          </RadioGroup>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default React.memo(OrderOfFilters, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
