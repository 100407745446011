import { createSlice } from '@reduxjs/toolkit';
import { setThemeMode, setVersion, setViewSliderVersion } from '../../actionTypes';

import { ThemeMode, Themes } from '../../../types/theme';
import { ActiveChannel } from '@/types/typesRedux/interfaces';
import { ActiveDialog } from '@/components/sidebar/sidebarFilters/interface';

export interface ThemeState {
  themeMode: ThemeMode;
  newVersion: boolean;
  newChangelog: boolean;
  conversation: {
    [conversationId: string]: {
      inputValue?: string;
      inputHeight?: number;
      selection?: { start: number; end: number };
      textToSearchDinamic?: string;
      fileAttachment?: {
        file: Blob;
        name: string;
      };
    };
  };
  lastInputKeyPress: string;
  modalDinamic: boolean;
  modalMore: boolean;
  menuRigth: boolean;
  modalNewChat: boolean;
  viewSliderVersion: boolean;
  selectedChannelToInitConversation: string[];
  popoverChannel: boolean;
  dialogConnect: boolean;
  limitReached: boolean;
  popoverCloseInConverStack: boolean;
  dialogChannels: {
    status: boolean;
    data: ActiveDialog;
  };
}

const initialState: ThemeState = {
  themeMode: Themes?.DARK,
  newVersion: false,
  modalNewChat: false,
  newChangelog: false,
  conversation: {},
  lastInputKeyPress: '',
  modalDinamic: false,
  modalMore: false,
  menuRigth: false,
  dialogConnect: false,
  viewSliderVersion: true,
  selectedChannelToInitConversation: [],
  popoverChannel: false,
  limitReached: false,
  popoverCloseInConverStack: false,
  dialogChannels: {
    status: false,
    data: {} as ActiveDialog
  }
};

const themeSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setChangelog(state, action) {
      state.newChangelog = action.payload;
    },
    setConversationInputValue(state, action) {
      state.conversation[action.payload.conversationId] = {
        ...state.conversation[action.payload.conversationId],
        inputValue: action.payload.inputValue
      };
    },
    setConversationInputHeight(state, action) {
      const stateInputHeight = state.conversation[action.payload.conversationId]?.inputHeight;
      if (stateInputHeight === action.payload.inputHeight) {
        return;
      }
      state.conversation[action.payload.conversationId] = {
        ...state.conversation[action.payload.conversationId],
        inputHeight: action.payload.inputHeight
      };
    },
    setLastInputKeyPress(state, action) {
      state.lastInputKeyPress = action.payload;
    },
    setMenuDinamic(state, action) {
      state.modalDinamic = action.payload;
    },
    setModalMore(state, action) {
      state.modalMore = action.payload;
    },
    setSelections(state, action) {
      state.conversation[action.payload.conversationId] = {
        ...state.conversation[action.payload.conversationId],
        selection: action.payload.selection
      };
    },
    setTextToSearchDinamic(state, action) {
      if (action.payload.textToSearchDinamic?.length === 0) return;
      state.conversation[action.payload.conversationId] = {
        ...state.conversation[action.payload.conversationId],
        textToSearchDinamic: action.payload.textToSearchDinamic
      };
    },
    setModaNewChat(state, action) {
      state.modalNewChat = action.payload;
    },
    setSelectedChannelToInitConversation(state, action) {
      state.selectedChannelToInitConversation = action.payload;
      state.popoverChannel = false;
    },
    setPopoverChannel(state, action) {
      state.popoverChannel = action.payload;
    },
    setMenuRigth(state, action) {
      state.menuRigth = action.payload;
    },
    setFileAttachment(state, action) {
      state.conversation[action.payload.conversationId] = {
        ...state.conversation[action.payload.conversationId],
        fileAttachment: action.payload.fileAttachment
      };
    },
    setDialogConnect(state, action) {
      state.dialogConnect = action.payload;
    },
    setLimitReached(state, action) {
      state.limitReached = action.payload;
    },
    setPopoverCloseInConverStack(state, action) {
      state.popoverCloseInConverStack = action.payload;
    },
    setDialogChannels(state, action) {
      state.dialogChannels = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setThemeMode, (state, action) => {
      state.themeMode = action.payload as Themes;
    });
    builder.addCase(setVersion, (state, action) => {
      state.newVersion = action.payload;
    });
    builder.addCase(setViewSliderVersion, (state, action) => {
      state.viewSliderVersion = action.payload;
    });
  }
});

export const {
  setChangelog,
  setConversationInputValue,
  setConversationInputHeight,
  setLastInputKeyPress,
  setMenuDinamic,
  setModalMore,
  setSelections,
  setTextToSearchDinamic,
  setModaNewChat,
  setSelectedChannelToInitConversation,
  setPopoverChannel,
  setMenuRigth,
  setFileAttachment,
  setDialogConnect,
  setLimitReached,
  setPopoverCloseInConverStack,
  setDialogChannels
} = themeSlice.actions;

export default themeSlice.reducer;
