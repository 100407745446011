import React, { useMemo } from 'react';
import translate from '@/helpers/traslations';
import { StyleSheet } from 'react-native';
import { TText, TVView } from '../themed';
import { colorsProps } from '@/hooks/useTheme';

const EmptyState = ({
  image,
  idString,
  children,
  textSize = 'lg',
  color = 'text'
}: {
  image: React.ReactElement;
  idString: string;
  children: React.ReactNode;
  textSize?: 'xs' | '2xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  color?: colorsProps;
}) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: '100%',
          paddingHorizontal: 16,
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        },
        textTitile: {
          textAlign: 'center',
          fontWeight: 'bold',
          lineHeight: 40
        },
        center: {
          alignItems: 'center',
          justifyContent: 'center'
        }
      }),
    []
  );

  return (
    <TVView bgColor="backgroundSecondary" style={styles.container}>
      <TVView style={styles.center}>{image}</TVView>
      <TText color={color} size={textSize} style={styles.textTitile}>
        {translate(idString)}
      </TText>
      {children}
    </TVView>
  );
};

export default React.memo(EmptyState);
