import React, { memo } from 'react';
import AcordionCustom from '@/atoms/AcordionCustom';
import { InfoOutline, MessageBadge, Web } from '@/assets/icons/Icons';
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import translate from '@/helpers/traslations';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import CardCustom from '@/atoms/CardCustom';
import { Platform } from 'react-native';

const { useNotifications } = Platform.select({
  native: () => require('@/providers/NotificationProvider.native'),
  default: () => require('@/providers/NotificationProvider.web')
})();

const BrowserNotifications = () => {
  const { toggleNotifications, notificationsEnabled, dennied } = useNotifications();

  return (
    <AcordionCustom
      iconLeft={Web}
      idString="notification.browser"
      titleSize="lg"
      bgColor="$background2"
      mbItem="$0"
      defaultOpen={false}
      sx={{
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginBottom: 20,
        borderRadius: 8
      }}
      middleElement={
        <CustomTooltip title={translate('notification.browserTooltip')}>
          <MaterialCommunity icon={InfoOutline} size="md" color="$info" />
        </CustomTooltip>
      }
    >
      <CardCustom
        idString="notification.browser"
        leftElement={<MaterialCommunity icon={Web} color="$text" size="xl" />}
        idStringSubtitle="notification.browserSubtitle"
        switchProp={{
          value: notificationsEnabled,
          onChange: () => toggleNotifications(),
          disabled: false
        }}
      />
    </AcordionCustom>
  );
};

export default memo(BrowserNotifications);
