import { ASIGNADOS_A_MI, CERRADOS, EXPIRED, FILTERS_TO_GET_FIRST_CHARGE } from '@/utils/filters';
import { getAllConversationsByFilter, setLoadingGetAllFilter } from '../conversation';
import { Filters, IQuery } from './interfaces';

export const getAllFilterData =
  ({ filters, id_team, userID }: { filters: Filters[]; id_team: number; userID: string }) =>
  async (dispatch) => {
    dispatch(setLoadingGetAllFilter(true));
    const filtersToGetFistCharge = filters?.filter((filtro: Filters) => {
      return FILTERS_TO_GET_FIRST_CHARGE.includes(filtro?.idString);
    });

    const promises = filtersToGetFistCharge?.map((filtro: Filters) => {
      let query: IQuery = { ...filtro.query };

      if (filtro?.idString === ASIGNADOS_A_MI) {
        query = {
          ...query,
          fragment: {
            ...query.fragment,
            associations: {
              assignee: [userID]
            }
          },
          page: 1,
          id_team: Number(id_team)
        };
      } else {
        query = {
          ...query,
          page: 1,
          id_team: Number(id_team)
        };
      }
      // Assuming the dispatch returns a promise, we return it here
      return getAllConversationsByFilter({
        query,
        filter: filtro?.idString,
        type: filtro?.types,
        teamId: id_team?.toString()
      });
    });

    // This will wait for all the promises to either resolve or reject
    await Promise.all(promises?.map((promise) => dispatch(promise))).catch((error) => {
      throw error?.message;
    });
    dispatch(setLoadingGetAllFilter(false));
  };
