import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import { HStack, Pressable, Spinner, VStack, Text } from '@gluestack-ui/themed';
import MemoizedAvatarCard from '../../atoms/AvatarCard';
import IconDateComponent from '../../atoms/IconDateComponent';
import { RootState, useAppSelector } from '@/redux/store';
import LastMessageContainer from '../conversation/conversationCard/LastMessageContainer';
import { RootObject } from './interfaces';
import { THView, TPressable, TVView } from '../themed';

function ResponseConversations({
  loading,
  conversations,
  onSelectConversation
}: {
  loading: any;
  conversations: RootObject[];
  onSelectConversation: any;
}) {
  const userEmail = useAppSelector((state: RootState) => state.user.user?.email);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flex: 1,
          gap: 5,
          justifyContent: 'flex-start'
        },
        element: {
          flex: 1,
          gap: 5,
          width: '100%',
          borderWidth: 1,
          padding: 10,
          borderRadius: 10,
          justifyContent: 'space-between'
        },
        leftMessage: {
          flex: 1,
          gap: 5,
          justifyContent: 'flex-start'
        },
        headerMessage: {
          flex: 1,
          gap: 5,
          justifyContent: 'flex-start'
        }
      }),
    []
  );

  return (
    <TVView style={styles.container}>
      {conversations?.map((conversation: RootObject) => (
        <TPressable
          key={conversation?._id}
          onPress={() => onSelectConversation(conversation?.conversacion?._id)}
          style={{
            borderRadius: 10,
            width: '100%'
          }}
        >
          <THView border="border" style={styles.element}>
            <MemoizedAvatarCard
              name={conversation?.participantes[0]?.name}
              picture={conversation?.participantes[0]?.avatar}
              width="$10"
            />
            <TVView style={styles.leftMessage}>
              <THView style={styles.headerMessage}>
                <Text>
                  {conversation?.participantes[0]?.name ?? conversation?.participantes[0]?.phone}
                </Text>
                <IconDateComponent
                  position={userEmail === conversation?.participantes[0]?.email ? 'right' : 'left'}
                  status={conversation?.conversacion?.last_message?.status}
                  createdAt={conversation?.conversacion?.last_message?.updatedAt}
                />
              </THView>
              <LastMessageContainer
                lastMessage={conversation?.conversacion?.last_message}
                fragmentsStatus={conversation?.conversacion?.last_fragment?.status}
              />
            </TVView>
            {loading[conversation?.conversacion?._id] && <Spinner color="$pxsol" />}
          </THView>
        </TPressable>
      ))}
    </TVView>
  );
}

export default React.memo(ResponseConversations);
