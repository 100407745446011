import React from 'react';
import { HStack, View } from '@gluestack-ui/themed';
import { RootState, useAppSelector } from '../redux/store';
import TextFormatter from './TextFormatter';
import { LastMessageProps } from './interfaces';
import translate from '@/helpers/traslations';

function LastMessage({ message, isGroup, unread = 0 }: LastMessageProps) {
  const userEmail = useAppSelector((state: RootState) => state.user.user.email);

  return (
    <HStack w="$full" alignItems="center" justifyContent="space-between" space="sm">
      {message?.from?.email === userEmail ? (
        <HStack w="$full" justifyContent="flex-start" alignItems="center">
          <TextFormatter numberOfLines={1} color={unread === 0 ? 'secondary' : 'text'}>
            {isGroup && `${message?.from?.name?.trim()}: `}
          </TextFormatter>
          <TextFormatter numberOfLines={1} color={unread === 0 ? 'secondary' : 'text'}>
            {message?.content?.text ?? translate('noContent')}
          </TextFormatter>
        </HStack>
      ) : (
        <View w="$full">
          <TextFormatter numberOfLines={1} color={unread === 0 ? 'secondary' : 'text'}>
            {isGroup && `${message?.from?.name?.trim()}: `}
          </TextFormatter>
          <TextFormatter numberOfLines={1} color={unread === 0 ? 'secondary' : 'text'}>
            {message?.content?.text ?? translate('noContent')}
          </TextFormatter>
        </View>
      )}
    </HStack>
  );
}

export default React.memo(LastMessage);
